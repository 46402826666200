import React from "react";
// Customizable Area Start
import {
    Box,
    Breadcrumbs,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    Paper,
    Popover,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import Loader from '../../../components/src/Loader.web'
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { TanentProfileIcon, TemplateIcon } from "./assets";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import ClearIcon from '@material-ui/icons/Clear';
import AlertErrorWeb from "../../../components/src/AlertError.web";
import AlertSuccessWeb from "../../../components/src/AlertSuccess.web";
import { TFunction, SavedTemplatesItem } from "./SavedTemplateDetailsController.web";
// Customizable Area End

import SavedTemplateDetailsController, { Props } from "./SavedTemplateDetailsController.web";

class SavedTemplateDetails extends SavedTemplateDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSavedTemplateDetails=()=>{
        const { t }: { t: TFunction } = this.props;
        const { anchorEl, selectedId } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        return <SavedTemplateDetailsContainer>
            <Box>
                <Breadcrumbs >
                    <Typography color="inherit" id={"LeaseManagmentId"} onClick={() => this.handleNavigation("LeaseManagment")} className="notActiveBreadLink" data-testId={"issueNewContractHeadingTestId"}>
                        {t("Lease Management")}
                    </Typography>
                    <Typography color="textPrimary" className="activeBreadLink">
                    {t("View Template")}
                    </Typography>
                </Breadcrumbs>
                <Box className="headingContainer">
                    <Typography className="issueNewcontractBreadHeading">
                        {t("Templates")}
                    </Typography>
                    <Typography onClick={() => this.handleNavigation(this.state.savedTemplatesList||{})} className="issueaNewLeaseBtn" data-testId={"issueNewLeaseTestId"}>{t("ADD NEW TEMPLATE")}</Typography>
                </Box>
                
            </Box>
            <Paper elevation={1} className="issueNewContractContentContainer">
            <Box className="ownerDetailsSection1">
                    <img src={TanentProfileIcon}/>
                    <Box>
                        <Typography className="tanentNameLabel">{t("Owner Name")}</Typography>
                        <Typography className="tanentName">{this.state.ownerName}</Typography>
                    </Box>
                </Box>
                <Box className="issueNewContractHeaderContainer">
                    <Typography className="issueNewContractHeaderHeading">
                    {t("List of Templates")}
                    </Typography>
                    <TextField
                        variant="outlined"
                        className="searchTextField"
                        InputProps={{
                            startAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        placeholder={t("Search by template name")}
                        onChange={this.handleSearchChange}
                        value={this.state.searchText}
                        data-testId={"templateSearchInputTextId"}
                    />
                </Box>
                <Divider />
                <Box className="templateContainer">
                    <Grid container spacing={2}>
                        {this.state.savedTemplatesList !== null && this.state.savedTemplatesList.map(
                            (item:SavedTemplatesItem , savedTemplateIndex: number) => (
                                <Grid item xs={12} sm={6} md={4} lg={4} key={savedTemplateIndex}>
                                    <Box className={"singleTemplateContainer"}>
                                        <Box className="singleTemplateNameContainer">
                                            <img
                                                src={TemplateIcon}
                                                alt=""
                                                className="templateIcon"
                                            />
                                            <Box>
                                            <Typography className="templateName" data-testId={`templateName${savedTemplateIndex}`}>
                                                {item.attributes.template_name}
                                            </Typography>
                                            <Typography variant="subtitle2"><span className="orangeText">{item.attributes.template_in_used_count}</span> time used</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="templateMenuBtnContainer">
                                            
                                            <IconButton
                                                data-testId={`templateMenubtn${savedTemplateIndex}`}
                                                onClick={(event) => this.handleClick(event, Number(item.id))}
                                                className="dotIconBtn"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Popover
                                                open={open && selectedId === Number(item.id)}
                                                id={popoverId}
                                                onClose={this.handleClose}
                                                data-testId={`templateMenuContainer${savedTemplateIndex}`}
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    horizontal: "left",
                                                    vertical: "bottom",
                                                }}
                                                transformOrigin={{
                                                    horizontal: "right",
                                                    vertical: "top",
                                                }}
                                            >
                                                <PopoverContainer>
                                                    <MenuItem data-testId={`templateMenuView${savedTemplateIndex}`} onClick={()=>this.viewTemplate(item.id)}>
                                                    {t("View")}
                                                    </MenuItem>
                                                    <MenuItem data-testId={`templateMenuDelete${savedTemplateIndex}`} onClick={()=>this.handleDeleteTemplate(item.id)}>
                                                    {t("Delete")}
                                                    </MenuItem>
                                                    {item.attributes.custom_lease_template_pdf && ( 
                                                        <MenuItem data-testId={`templateMenuDownload${savedTemplateIndex}`} onClick={()=>this.downloadTemplate(this.handleNullValue(item.attributes.custom_lease_template_pdf))}>
                                                        {t("Download")}
                                                        </MenuItem>
                                                    )}
                                                </PopoverContainer>
                                            </Popover>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Box>
            </Paper>
        </SavedTemplateDetailsContainer>
    };

    renderSavedTemplateDetailModal=()=>{
            return (
              <>
                <Box>
                  <Modal open={this.state.viewSavedTemplateModal} onClose={this.handleSavedTemplateModal} data-testId={"savedTemplateModalTestId"}>
                    <ViewTemplateModalContainer>
                      <Paper className='savedTemplateDetailsModalContainer'>
                        <Box className='savedTemplateDetailsModalHeaderContainer'>
                          <IconButton onClick={this.handleSavedTemplateModal}><ClearIcon/></IconButton>
                        </Box>
                        <Divider />
                        <Paper className='savedTemplateDetailsContainer'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.savedTemplateModalData }}></div>
                          </Paper>
                      </Paper>
                    </ViewTemplateModalContainer>
                  </Modal>
                </Box>
              </>
            )
      }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainScreen>
                {this.renderSavedTemplateDetails()}
                {this.renderSavedTemplateDetailModal()}
                <Loader loading={this.state.loader} />
                <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.errorMessage} data-testId={"errorAlertTestId"} />
                <AlertSuccessWeb show={this.state.showSuccess} handleClose={this.handleCloseAlertSuccess} message={this.state.showSuccessMessage} data-testId={"successAlertTestId"}/>
            </MainScreen>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTranslation()(SavedTemplateDetails);
export { SavedTemplateDetails }
const SavedTemplateDetailsContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#f4f7ff",
    "& .orangeText":{
        color:"#fc8434",
        fontWeight: 900,
    },
    "& .ownerDetailsSection1":{
        display:"flex",
        justifyContent:"flex-start",
        alignItems: "flex-start",
        gap:"10px",
        paddingLeft:"20px",
        paddingTop:"20px",
    },
    "& .tanentNameLabel": {
        color: "#b6b6b6",
        fontSize: "16px"
    },
    "& .tanentName": {
        color: "#1c1d20",
        fontSize: "18px",
        fontWeight: 900
    },
    "& .headingContainer":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    "& .issueaNewLeaseBtn": {
        fontWeight: 800,
        color: "#fff !important",
        height: "50px",
        backgroundColor: "#f88538",
        borderRadius: "8px",
        padding: "0px 20px",
        display: "flex",
        width: "200px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        textTransform: "uppercase",
      },
    "& .notActiveBreadLink": {
        color: "#40434c",
        cursor: "pointer"
    },
    "& .activeBreadLink": {
        color: "#729be0",
        cursor: "pointer"
    },
    "& .issueNewcontractBreadHeading": {
        color: "#40434c",
        fontWeight: 900,
        fontSize: "32px",
        margin: "10px 0px",
    },
    "& .issueNewContractContentContainer": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        height: "95vh",
        overflow: "scroll",
        margin: "20px 0px",
    },
    "& .issueNewContractHeaderContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        "@media(max-width:576px)": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
        },
    },
    "& .issueNewContractHeaderHeading": {
        width: "100%",
        fontSize: "20px",
        fontWeight: 900,
        color: "#40434c",
    },
    "& .searchTextField": {
        width: "410px",
        border: "1px solid #f0f0f0",
        color: "darkgray",
        borderRadius: "10px !important",
        "@media(max-width:576px)": {
            width: "100%",
        },
        "& .MuiOutlinedInput-input::placeholder": {
            color: "darkgray !important",
        },
    },
    "& .templateContainer": {
        margin: "20px 0px",
        padding: "20px",
    },
    "& .singleTemplateContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #f3f3f3",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
    },
    "& .singleTemplateSelectContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #5f92f1",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
    },
    "& .singleTemplateNameContainer": {
        display: "flex",
        gap: "10px",
        alignItems: "center"
    },
    "& .templateIcon": {
        width: "20px",
    },
    "& .templateName": {
        color: "#242426",
        fontSize: "16px",
        fontWeight: 800,
    },

    "& .bottomButtonContainer": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "30px",
        padding: "20px 0px",
    },
    "& .cancelBtn": {
        width: "200px",
        padding: "10px 30px",
        border: "1px solid #6686bb",
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,

    },
    "& .continueBtnDisabled": {
        width: "200px",
        padding: "10px 30px",
        color: "#fff",
        fontWeight: 900,
        backgroundColor: "#e0e0e0"
    },
    "& .templateMenuBtnContainer": {
        display: "flex",
        alignItems: "center",
    },
    "& .dotIconBtn": {
        padding: "10px 0px"
    }
});

const PopoverContainer = styled(Box)({
    width: "150px",
});

const ViewTemplateModalContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '& .savedTemplateDetailsModalContainer': {
        width: '700px',
        height: "95%",
        overflow: "scroll"
    },
    '& .savedTemplateDetailsModalHeaderContainer': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    '& .savedTemplateDetailsContainer': {
        padding: 20,
        margin: 20,
        overflow: "scroll",
    },
    '& .TemplateModalChildHeading': {
        fontWeight: 800,
        fontSize: 25,
        padding: '20px',
    },
    '& .TemplateModalChildButton': {
        float: 'right',
        padding: 20
    },
    '& .TerminateModalChildButton1': {
        border: '1px solid #2f6ded',
        color: '#2f6ded',
        padding: '12px 40px ',
        fontWeight: 700,
        borderRadius: 10
    },
    '& .TerminateModalChildButton2': {
        background: '#2f6ded',
        color: 'white',
        marginLeft: 15,
        padding: '12px 40px ',
        fontWeight: 700,
        borderRadius: 10
    },
});
// Customizable Area End