// Customizable Area Start
import { runEngine } from "framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  jurisdictionSelected: any;
  handleChangeJurisdiction: any;
  step: number;
  handleNext: any;
  handleBack: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  complexID: any;
}

interface S {
  loading: boolean;
  juriId: string;

  jurisdictionList: any[];

  jurisdictionDetail: {
    currency: string;
    timezone: string;
    measurement: string;
    formula: string;
    mobileLength: string;
    contract: string;
  };
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetJurisdictionListCallId: string = "";
  getJurisdictionDetailCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      juriId: "",

      jurisdictionList: [],

      jurisdictionDetail: {
        currency: "",
        timezone: "",
        measurement: "",
        formula: "",
        mobileLength: "",
        contract: "",
      },
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetJurisdictionListCallId:
          this.handleJurisdictionList(responseJson);
          break;
        case this.getJurisdictionDetailCallId:
          this.handleJurisdictionDetailResponse(responseJson);
          break;
        default:
          break;
      }

      this.setState({ loading: false });

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    await this.getJurisdictionList();

    if (this.props.jurisdictionSelected) {
      this.getJurisdiction(this.props.jurisdictionSelected);
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevProps.jurisdictionSelected !== this.props.jurisdictionSelected) {
      this.getJurisdiction(this.props.jurisdictionSelected);
    }
  }

  getJurisdictionList = async () => {
    this.GetJurisdictionListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/company_employee/jurisdictions`,
    });
  };

  handleJurisdictionList = (responseJson: any) => {
    if (responseJson && responseJson.jurisdictions) {
      this.setState({
        jurisdictionList: responseJson.jurisdictions.data.map((item: any) => ({
          label: item.attributes.jurisdiction_name,
          value: item.id,
        })),
      });
    }
  };

  getJurisdiction = async (id: number | string) => {
    this.getJurisdictionDetailCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  };

  handleJurisdictionDetailResponse = (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      const jurisdiction = responseJson.data;
      this.setState(
        {
          jurisdictionDetail: {
            currency: jurisdiction.attributes.currency.currency,
            timezone: jurisdiction.attributes.timezone,
            measurement: jurisdiction.attributes.unit_of_measurement,
            formula: jurisdiction.attributes.formula,
            mobileLength: jurisdiction.attributes.mobile_number_length,
            contract: this.handleContract(jurisdiction.attributes.contracts),
          },
        },
        () => {
          this.props.handleChangeJurisdiction(jurisdiction.id, jurisdiction.attributes.currency.currency);
        }
      );
    }
  };

  handleContract = (contract: any) => {
    if (contract && contract.length > 0) {
      return contract[0].filename;
    }
    return "";
  };

  changeJurisdiction = (event: any) => {
    this.setState({ loading: true, juriId: event.target.value }, () => {
      this.getJurisdiction(event.target.value);
    });
  };

  handleNextJurisdiction = () => {
    this.props.handleChangeJurisdiction(this.state.juriId, this.state.jurisdictionDetail.currency);
    this.props.handleNext();
  };
}
// Customizable Area End
