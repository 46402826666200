// Customizable Area Start
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { IBlock } from "framework/src/IBlock";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import { handleImages } from "../../../../components/src/UtilFunction.web";

export interface Props {
  complexBuildingId: any;
  handleBack: any;
  handleNext: any;
  jurisdictionSelected: any;
  updateComplexBuildingId: any;
  step: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface IComplexBuildingForm {
  logo: any;
  buildingName: string;
  totalArea: string;
  measurement: string;
  images: any[];
  totalFloors: string;
  totalUnits: string;
  aboutUs: string;
  currency: string;
  accountManager: string;
  rentalSelected: string | number;
}

interface S {
  loading: boolean;

  buildingFormValues: IComplexBuildingForm;

  measurementList: any[];
  currencyList: any[];
  managerList: any[];
  leaseList: any[];
}

interface SS {
  id: any;
}

export default class CompEmpBuildingBlockStep2Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetBuildingCurrencyListCallId: string = "";
  GetIBuildingAccountManagerListCallId: string = "";
  GetIBuildingLeaseListCallId: string = "";
  AddEditIBuildingCallId: string = "";
  GetIBuildingDetailApiCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      buildingFormValues: {
        logo: null,
        buildingName: "",
        totalArea: "",
        measurement: "Sqft",
        images: [],
        totalFloors: "",
        totalUnits: "",
        aboutUs: "",
        currency: "",
        accountManager: "",
        rentalSelected: "",
      },

      measurementList: [
        {
          label: "Sq ft",
          value: "Sqft",
        },
        {
          label: "Sq m",
          value: "Sq m",
        },
      ],
      currencyList: [],
      managerList: [],
      leaseList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetBuildingCurrencyListCallId:
          return this.handleBuildingCurrencyListResponse(responseJson);
        case this.GetIBuildingAccountManagerListCallId:
          return this.handleIBuildingAccountManagerListResponse(responseJson);
        case this.GetIBuildingLeaseListCallId:
          return this.handleIBuildingLeaseListResponse(responseJson);
        case this.AddEditIBuildingCallId:
          return this.handleAddEditIBuildingResponse(responseJson);
        case this.GetIBuildingDetailApiCallId:
          return this.handleIBuildingDetailResponse(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    await this.getBuildingCurrencyList();
    await this.getIBuildingAccountManagerList();
    await this.getIBuildingLeaseList();

    if (this.props.complexBuildingId) {
      this.getIBuildingComplexDetails();
    }
  }

  handleSubmitIBuilding = async (values: IComplexBuildingForm) => {
    const realEstate = sessionStorage.getItem("real_estate_id") || "";
    const data = new FormData();
    data.append("society_management[logo]", values.logo);
    data.append("society_management[name]", values.buildingName);
    data.append("society_management[complex_area]", values.totalArea);
    data.append("society_management[description]", values.aboutUs);
    data.append("society_management[total_floor]", values.totalFloors);
    data.append("society_management[total_units]", values.totalUnits);
    data.append("society_management[account_manger_id]", values.accountManager);
    data.append("jurisdiction_id", this.props.jurisdictionSelected);
    data.append("society_management[real_estate_company_id]", realEstate);

    [...values.images].length &&
      [...values.images].forEach((image: any) => {
        data.append("society_management[photos][]", image);
      });

    if (this.props.complexBuildingId) {
      this.AddEditIBuildingCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_settings/building_managements/create_individual_building/${this.props.complexBuildingId}`,
        body: data,
      });
    } else {
      this.AddEditIBuildingCallId = await apiCall({
        method: "POST",
        endPoint: `bx_block_settings/building_managements/create_individual_building`,
        body: data,
      });
    }
  };

  handleAddEditIBuildingResponse = (responseJson: { data: { id: string | number } }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.props.updateComplexBuildingId(responseJson.data.id, responseJson.data.id);
      }
    });
  };

  getIBuildingComplexDetails = () => {
    this.setState({ loading: true }, async () => {
      this.GetIBuildingDetailApiCallId = await apiCall({
        method: "GET",
        endPoint: `bx_block_society_management/society_managements/${this.props.complexBuildingId}`,
        contentType: "application/json",
      });
    });
  };

  handleIBuildingDetailResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ loading: true }, async () => {
        const individualBuilding = responseJson.data;
        let photos: any[] = [];
        if (individualBuilding.attributes.photos.length > 0) {
          photos = await handleImages(individualBuilding.attributes.photos);
        }
        let logo: any = null;
        if (individualBuilding.attributes.logo) {
          const logos = await handleImages([individualBuilding.attributes.logo]);
          logo = logos[0];
        }

        this.setState({
          loading: false,
          buildingFormValues: {
            logo: logo,
            buildingName: individualBuilding.attributes.name,
            totalArea: individualBuilding.attributes.complex_area,
            measurement: individualBuilding.attributes.measurement_unit,
            images: photos,
            totalFloors: individualBuilding.attributes.total_floor,
            totalUnits: individualBuilding.attributes.total_units.length,
            aboutUs: individualBuilding.attributes.description,
            currency: individualBuilding.attributes.currency?.currency || "",
            accountManager: individualBuilding.attributes.account_manager_id,
            rentalSelected: "",
          },
        });
      });
    }
  };

  getIBuildingLeaseList = async () => {
    this.GetIBuildingLeaseListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contract/lease_templates`,
    });
  };

  handleIBuildingLeaseListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ leaseList: responseJson.data }, () => {
        if (responseJson.data.length > 0) {
          this.setState({
            buildingFormValues: {
              ...this.state.buildingFormValues,
              rentalSelected: responseJson.data[0].id,
            },
          });
        }
      });
    }
  };

  getBuildingCurrencyList = async () => {
    this.GetBuildingCurrencyListCallId = await apiCall({
      contentType: "application/json",
      endPoint: `bx_block_posts/classifieds/currency_list`,
      method: "GET",
    });
  };

  handleBuildingCurrencyListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        currencyList: responseJson.data.map((item: any) => ({ label: item.attributes.currency, value: item.id })),
      });
    }
  };

  getIBuildingAccountManagerList = async () => {
    this.GetIBuildingAccountManagerListCallId = await apiCall({
      contentType: "application/json",
      endPoint: `bx_block_real_estate_companies/account_manager_list`,
      method: "GET",
    });
  };

  handleIBuildingAccountManagerListResponse = (responseJson: { account_managers: any[] }) => {
    if (responseJson && responseJson.account_managers) {
      this.setState({
        managerList: responseJson.account_managers.map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  validationSchema = Yup.object().shape({
    logo: Yup.mixed().required(this.props.t("Please upload a logo")),
    buildingName: Yup.string()
      .min(3, this.props.t("Building name must be at least 3 characters"))
      .max(50, this.props.t("Building name must be at most 50 characters"))
      .required(this.props.t("Required")),
    totalArea: Yup.string().required(this.props.t("Required")),
    totalFloors: Yup.string().required(this.props.t("Required")).nullable(),
    images: Yup.array().nullable().min(1, this.props.t("At least one image is required")),
    totalUnits: Yup.string().required(this.props.t("Required")),
    aboutUs: Yup.string().required(this.props.t("Required")),
    currency: Yup.string().required(this.props.t("Required")),
  });
}
// Customizable Area End
