// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import CompEmpComplexBuildingBlockController, { Props } from "./CompEmpComplexBuildingBlockController.web";
import CompEmpComplexBuildingBlockStep1 from "./CompEmpComplexBuildingBlockStep1.web";
import CompEmpComplexBuildingBlockStep2 from "./CompEmpComplexBuildingBlockStep2.web";
import CompEmpComplexBuildingBlockStep3 from "./CompEmpComplexBuildingBlockStep3.web";
import CompEmpComplexBuildingBlockStep4 from "./CompEmpComplexBuildingBlockStep4.web";
import CompEmpComplexBuildingBlockStep5 from "./CompEmpComplexBuildingBlockStep5.web";
import { withTranslation } from "react-i18next";

class CompEmpComplexBuildingBlock extends CompEmpComplexBuildingBlockController {
  constructor(props: Props) {
    super(props);
  }

  multiPleButtonHeader = () => {
    const { t } = this.props;
    return (
      <>
        {this.state.multiPleButton.map((val: any, index: number) => {
          return (
            <Box
              key={index}
              style={{
                width: "max-content",
                height: "43px",
                marginRight: "15px",
                backgroundColor: this.state.step == index ? "#2B6FED" : "#C8C8C81F",
                borderRadius: "21.5px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                data-test-id="multiPleButtonHader"
                onClick={() => this.setState({ step: index })}
                style={{
                  color: this.getColor(index, this.state.step),
                  fontWeight: "bold",
                  padding: "0px 20.5px",
                  textTransform: "capitalize",
                }}
              >
                {t(val)}
              </Button>
            </Box>
          );
        })}
      </>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Box style={{ padding: "0 40px 40px", backgroundColor: "rgb(244, 247, 255)" }}>
        <Typography className="complex" style={{ fontSize: "16px", display: "flex" }}>
          {t("Complex")} / {t("Buildings")} /{" "}
          <span style={{ color: "#2B6FED" }}>
            {this.state.editBuildingId ? t("Edit New Complex Building") : t("Create New Complex Building")}{" "}
          </span>
        </Typography>
        <Typography className="bold-text" style={{ fontSize: "28px", fontWeight: "bold", marginTop: "8px" }}>
          {this.state.editBuildingId ? t("Edit New Complex Building") : t("Create New Complex Building")}
        </Typography>

        <Box style={{ marginTop: "25px", display: "flex", flexWrap: "wrap" }}>{this.multiPleButtonHeader()}</Box>
        <Grid className="topBarFormik">
          {this.state.step === 0 && (
            <CompEmpComplexBuildingBlockStep1
              step={this.state.step}
              complexId={this.state.sid}
              buildingId={this.state.buildingId}
              handleNext={this.handleNext}
              updateEditBuilding={this.updateEditBuilding}
              handleChangeCurrency={this.handleChangeCurrency}
            />
          )}
          {this.state.step === 1 && (
            <CompEmpComplexBuildingBlockStep2
              step={this.state.step}
              complexId={this.state.sid}
              buildingId={this.state.buildingId}
              handleBack={this.handleBackDoc}
              handleNext={this.handleNext}
            />
          )}
          {this.state.step === 2 && (
            <CompEmpComplexBuildingBlockStep3
              step={this.state.step}
              complexId={this.state.sid}
              handleNext={this.handleNext}
              handleBack={this.handleBackDoc}
              buildingId={this.state.buildingId}
            />
          )}
          {this.state.step === 3 && (
            <CompEmpComplexBuildingBlockStep4
              step={this.state.step}
              complexId={this.state.sid}
              buildingId={this.state.buildingId}
              handleNext={this.handleNext}
              handleBack={this.handleBackDoc}
              currency={this.state.currency}
            />
          )}
          {this.state.step === 4 && (
            <CompEmpComplexBuildingBlockStep5
              step={this.state.step}
              complexId={this.state.sid}
              buildingId={this.state.buildingId}
              handleBack={this.handleBackDoc}
              handleNext={this.handleNextCore}
              navigation={this.props.navigation}
            />
          )}
        </Grid>

        <Dialog fullWidth open={this.state.open} className="alert-save-dialog" aria-describedby="alert-dialog-description">
          <Box style={{ padding: "50px 0px" }}>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.03853 30.0835C6.03853 16.5273 17.0278 5.53804 30.584 5.53804C44.1402 5.53804 55.1294 16.5273 55.1294 30.0835C55.1294 43.6397 44.1402 54.6289 30.584 54.6289C17.0278 54.6289 6.03853 43.6397 6.03853 30.0835ZM30.584 0.0834961C14.0153 0.0834961 0.583984 13.5148 0.583984 30.0835C0.583984 46.6522 14.0153 60.0835 30.584 60.0835C47.1527 60.0835 60.584 46.6522 60.584 30.0835C60.584 13.5148 47.1527 0.0834961 30.584 0.0834961ZM42.7706 25.8643C41.6857 24.8232 39.9268 24.8232 38.8418 25.8643L29.6951 34.6456L23.3262 28.531C22.2412 27.4899 20.4823 27.4899 19.3973 28.531C18.3129 29.5726 18.3129 31.2611 19.3973 32.3027L27.7307 40.3027C28.8156 41.3438 30.5745 41.3438 31.6595 40.3027L42.7706 29.636C43.8551 28.5944 43.8551 26.9059 42.7706 25.8643Z"
                  fill="#FC8434"
                />
              </svg>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ textAlign: "center", color: "black", fontSize: "22px", fontWeight: 700 }}
                id="alert-dialog-description"
              >
                {this.handleSuccessHeading(t)}
              </DialogContentText>
              <DialogContentText
                style={{ textAlign: "center", color: "black", fontSize: "16px", fontWeight: 400 }}
                id="alert-dialog-description"
              >
                {this.handleSuccessDescription(t)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="buttonCancel" onClick={() => this.handleCloseCodeModal()}>
                {t("Okay")}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    );
  }
}

export default withTranslation()(CompEmpComplexBuildingBlock);
// Customizable Area End
