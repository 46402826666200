// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractReviewController,{Props, TFunction} from "./LeaseManagementIssueNewContractReviewController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { Box, Breadcrumbs, Button, Divider, Grid, Modal, Paper, TextField, Typography, styled } from "@material-ui/core";
import { DownloadIcon, UnitIcon, BuildingIcon, CalenderIcon, ProfileIcon, InfoIcon2, Classified_CorrectIcon } from "./assets";
import { withTranslation } from "react-i18next";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import AlertDialog from "../../../components/src/MuiDialog/AlertDialog.web";
import {ExclamationIcon} from '../../RolesPermissions2/src/assets'
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class LeaseManagementIssueNewContractReview extends LeaseManagementIssueNewContractReviewController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start
    renderActivatdContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.activateContract}>
            <ActivateModalContainer>
                <Box className="activateModalContainer">
                    <img src={Classified_CorrectIcon} alt="" />
                    <Typography className="modalHeading" data-testId={"activateContractTestId"}>{t("Activate Contract")}</Typography>
                    <Typography className="modalSubHeading">{t("Your contract has been activated. You can access this from contracts section.")}</Typography>
                    <Box className=" activateContractBtn">
                        <Button variant="contained" className="ContractDetailsButton2"
                        onClick={this.handleCloseContractActivatedModal}
                        data-testId={"okayBtnTestId"}
                        >
                            {t("Okay")}
                        </Button>
                    </Box>
                </Box>
            </ActivateModalContainer>
        </Modal>
    }
   
    renderSaveContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.saveTemplateModal}>
            <ActivateModalContainer>
                <Box className="activateModalContainer">
                    <img src={Classified_CorrectIcon} alt="" />
                    <Typography className="modalHeading" data-testId={"saveTemplateModalHeadingTestID"}>{t("Save Contract Template")}</Typography>
                    <Typography className="modalSubHeading">{t("Your contract document will be saved as template. You can access this document from contracts section.")}</Typography>
                    <TextField variant="outlined" fullWidth className="nameTextField" placeholder={t("Template Name")} onChange={this.handleTenantChange} value={this.state.tenantName} data-testId={"tenantNameInputTestId"}/>
                    <Box className="modalBottomSection">
                        <Button variant="text" className="ContractDetailsButton1"
                        data-testId={"savedTemplateModalCancelBtnTestID"}
                        onClick={this.handleCloseSaveTemplateModal}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="contained" className={this.state.tenantName === "" ? "ContractDetailsButton2Disabled" :"ContractDetailsButton2"} onClick={this.saveCreateContract} data-testId={"saveCreateContractTestId"} disabled={this.state.tenantName === "" ? true : false}>
                        {t("Save")}
                        </Button>
                    </Box>
                </Box>
            </ActivateModalContainer>
        </Modal>
    }
    renderGenerateContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.generateContractModal}>
            <ActivateModalContainer>
                <Box className="activateModalContainer">
                    <img src={InfoIcon2} alt="" />
                    <Typography className="modalHeading" data-testId={"genrateContractModalHeading"}>{t("Contract Activated")}</Typography>
                    <Typography className="modalSubHeading">{t("By confirming the contract will be activated, the Tenant will be notified, and the system will be record it. Are you sure you want to Generate the report now?")}</Typography>
                    <Box className="modalBottomSection">
                        <Button variant="text" className="ContractDetailsButton1"
                        data-testId={"generatecontractCancelBtnTestID"}
                        onClick={this.handleCloseGenerateModal}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="contained" className="ContractDetailsButton2"
                        data-testId={"confirmBtnTestId"}
                        onClick={this.createContract}>
                            {t("Confirm")}
                        </Button>
                    </Box>
                </Box>
            </ActivateModalContainer>
        </Modal>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <ContactDetailsHeader>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography id={"LeaseManagmentId"} onClick={() => this.handleExistRecordNavigation("LeaseManagment")} className="ContactDetailsBreadcrumbs" data-testId={"leaseManagementTestId"}>
                                {t("Lease Management")}
                            </Typography>
                            <Typography id={"IssueNewLeaseId"} onClick={() => this.handleExistRecordNavigation("IssueNewLease")} className="ContactDetailsBreadcrumbs" data-testId={"IssueNewLeaseTestId"}>
                                {t("Issue a new lease")}
                            </Typography>
                            <Typography id={"IssueNewContractId"} onClick={() => this.handleExistRecordNavigation("IssueNewContract")} className="ContactDetailsBreadcrumbs" data-testId={"IssueNewContractTestId"}>
                                {t("Issue a new contract")}
                            </Typography>
                            <Typography className="ContactDetailsBreadcrumbsActive">{t("Review Contract")}</Typography>
                        </Breadcrumbs>
                        <Typography className="ContactDetailsHeading" data-testId={"reviewContractTestID"}>
                            {t("Review Contract")}
                        </Typography>
                        <Paper elevation={2} className="ContactDetailsHeadingContainer">
                            <Box className="ContactDetailsHeadingBox">
                                <Typography className="ContactDetailsHeadingText">
                                    {t("Contract")}
                                </Typography>
                                <Box>
                                    <img
                                        className="ContractDetailDownloadIconStyled"
                                        src={DownloadIcon}
                                        alt={DownloadIcon}
                                        onClick={this.handleDownloadContract}
                                        data-testId={"downloadContractTestId"}
                                    />
                                </Box>
                            </Box>
                            <Divider />
                            <Box className="ContractDetailsContent">
                                <Grid container spacing={2}>
                                    <Grid item lg={3} xs={12} sm={6} md={4}  spacing={2}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                src={BuildingIcon}
                                                className="ContractDetailsChildImgReview"
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Complex")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {this.state.createContractPayloadData.complex === "Complex"?this.state.createContractPayloadData.complexName:"NA"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  xs={12} sm={6} md={4} lg={3} item spacing={2}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={BuildingIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Building")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {this.state.createContractPayloadData.complex === "Complex"?this.state.createContractPayloadData.buildingName:this.state.createContractPayloadData.complexName}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item spacing={2} sm={6} md={4} lg={3} xs={12} >
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                src={UnitIcon}
                                                className="ContractDetailsChildImgReview"
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Unit")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {this.state.createContractPayloadData.unitName}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  spacing={2} xs={12} sm={6} md={4} lg={3}item >
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={ProfileIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Owner")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {this.state.createContractPayloadData.owner}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={6} item spacing={2}  md={4} lg={3}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={ProfileIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Tenant")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                {this.state.createContractPayloadData.tenant_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  xs={12} sm={6} item spacing={2} md={4} lg={3}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={CalenderIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Contract Duration")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {this.state.createContractPayloadData.agreement_duration + " months"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  spacing={2} xs={12} item sm={6} md={4} lg={3}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={CalenderIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Expiry Date")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {this.state.createContractPayloadData.expires_on}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className="contractDetailContainer">
                                <Typography className="ContractDetailsContentHeading">
                                    {t("Resident Rental Lease Agreement")}
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.createContractPayloadData.contract_template }}></div>
                                </Typography>
                                {this.state.createContractPayloadData?.penalty_type && <Box>
                                    <Typography className="penaltyDetailsHeading">
                                        {t("Penalty Details")}
                                    </Typography>
                                    <Typography>Type:{" "}{this.state.createContractPayloadData?.penalty_type}</Typography>
                                    <Typography>Amount:{" "} {this.state.createContractPayloadData?.penalty_type === "Fixed Percentage"
                                        ? `${this.state.createContractPayloadData?.penalty_amount}%`
                                        : this.state.createContractPayloadData?.penalty_amount}</Typography>
                                </Box>}
                                {Array.isArray(this.state.createContractPayloadData.terms_label)&&
                                    <Box>
                                        <Typography className="penaltyDetailsHeading">
                                            {t("Terms")}
                                        </Typography >
                                        {this.state.createContractPayloadData?.terms_label.map((label) => {
                                            return <Typography>{label}</Typography>
                                        })}
                                    </Box>
                                }
                                {Array.isArray(this.state.createContractPayloadData.conditions_label)&&
                                    <Box>
                                        <Typography className="penaltyDetailsHeading">
                                            {t("Conditions")}
                                        </Typography>
                                        {this.state.createContractPayloadData?.conditions_label?.map((label) => {
                                            return <Typography>{label}</Typography>
                                        })}
                                    </Box>
                                }
                            </Box>
                        </Paper>
                    </Box>
                    <Box className="ContractDetailsButtonGroup">
                        <Button variant="text" className="ContractDetailsButton1" onClick={this.handleSaveContractAsDraft} data-testId={"saveContractAsDraftTestId"}>
                        {t("save the contract as draft")}
                        </Button>
                        <Button variant="text" className="ContractDetailsButton1" onClick={this.handleEditDocument} data-testId={"editDocumentTestId"}>
                        {t("Edit Document")}
                        </Button>
                        <Button variant="text" className="ContractDetailsButton1"
                        data-testId={"savedTemplateBtnTestID"}
                        onClick={this.handleOpenSaveTemplateModal}>
                            {t("save template")}
                        </Button>
                        <Button variant="contained" className="ContractDetailsButton2" onClick={this.handleOpenGenerateModal}
                        data-testId={"generateContractTestId"}
                        >
                            {t("Generate contract")}
                        </Button>
                    </Box>
                    {this.renderActivatdContractModal()}
                    {this.renderSaveContractModal()}
                    {this.renderGenerateContractModal()}
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorAlertTestId"} />
                    <AlertSuccess show={this.state.showSuccessMessage} handleClose={this.hanldeCloseSuccessAlert} message={this.state.successMessage}  data-testId={"successAlertTestId"}/>
                    <AlertDialog IsOpen={this.state.downloadContractAlert} Image={ExclamationIcon} CloseDialog={this.handleCancelMeetingDialog} Content={this.state.downloadContractMessage} data-testId={"alertDialogTestId"} />
                </ContactDetailsHeader>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractReview);
export { LeaseManagementIssueNewContractReview };
const ContactDetailsHeader = styled(Box)({
    padding: 30,
    background: "#f4f7ff",

    "& .ContactDetailsHeading": {
        fontWeight: 800,
        fontSize: "30px",
        padding: "20px 0px",

    },
    "& .ContactDetailsHeadingContainer": {
        background: "white",
    },
    "& .ContactDetailsBreadcrumbs": {
        color: "black",
        cursor: "pointer"
    },
    "& .ContactDetailsBreadcrumbsActive": {
        color: "#5373bd !important",
        cursor: "pointer"
    },
    "& .ContactDetailsHeadingBox": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        marginBottom: 15,
        padding:"20px"
    },
    "& .ContactDetailsHeadingText": {
        fontSize: "25px",
        fontWeight: 800,
    },
    "& .ContractDetailDownloadIconStyled": {
        height: "30px",
        width: "33px",
        marginRight: 15,
        cursor: "pointer"
    },
    "& .ContractDetailsButton1": {
        color: "#3274ce",
        fontWeight: 800,
        marginRight: 15,
        border: "1px solid #3274ce",
        backgroundColor:"#fff",
        padding:"10px 40px",
        cursor: "pointer",
    },
    "& .ContractDetailsButton2": {
        background: "#3274ce",
        color: "white",
        fontWeight: 800,
        cursor: "pointer",
    },
    "& .ContractDetailsContentHeading": {
        fontWeight: 800,
        marginTop: 30,
        marginBottom: 10,
    },
    "& .ContractDetailsContentHeadingText": {
        color: "gray",
    },
    "& .ContractDetailsButtonGroup": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-end",
        padding:"20px"
    },
    "& .ContractDetailPopover": {
        top: "300px",
        right: "700px",
        position: "absolute",
    },
    "& .ContractDetailsHeader": {
        color: "#fcd2ab",
        fontWeight: "700",
        marginLeft: 10,
    },
    "& .ContractDetailsHeader2": {
        marginLeft: 35,
        fontWeight: "bold",
    },
    "& .ContractDetailsContent": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: 10,
        padding:"20px"
    },
    "& .ContractDetailsContentHeader": {
        display: "flex",
    },
    "& .ContractDetailsIcons": {
        width: 25,
        height: 25,
    },
    "& .ContractDetailsChildReview": {
        display: "flex",
        gap: "10px",
    },
    "& .ContractDetailsChildImgReview": {
        width: "30px",
        height: "30px",
    },
    "& .ContractDetailsChildHeading": {
        color: "#d9a582",
        fontSize: "17px",
    },
    "& .ContractDetailsChildSubHeadingReview": {
        color: "#2c2d32",
        fontSize: "19px",
        fontWeight: 900,
    },
    "& .contractDetailContainer":{
        padding:"20px"
    },
    "& .penaltyDetailsHeading": {
        paddingTop: 20,
        fontWeight: 800,
        fontSize: "20px",
    }
});

const ActivateModalContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .activateModalContainer": {
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: "50px",
        width: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px"
    },
    "& .modalHeading": {
        fontSize: "21px",
        fontWeight: 900,
        textAlign: "center",
        color: "#17171f"
    },
    "& .modalSubHeading": {
        fontSize: "14.5px",
        textAlign: "center",
        color: "#3d3e41"
    },
    "& .modalBottomSection": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        width:"100%"
    },
    "& .ContractDetailsButton1": {
        color: "#3274ce",
        fontWeight: 800,
        border: "1px solid #3274ce",
        width:"180px",
        cursor: "pointer",
        height:"50px"
    },
    "& .ContractDetailsButton2": {
        background: "#3274ce",
        color: "white",
        fontWeight: 800,
        cursor: "pointer",
        width:"180px",
        height:"50px"
    },
    "& .ContractDetailsButton2Disabled":{
        background: "#eee",
        color: "gray",
        fontWeight: 800,
        cursor: "pointer",
        width:"180px",
        height:"50px"
    },
    "& .MuiTextField-root":{
        backgroundColor:"#f9f9f9 !important",
        borderRadius:"10px",
        border:"1px solid #f0efef"
    },
    '& .MuiTextField-root::placeholder': {
        color: 'green',
      },
    "& activateContractBtn":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%"
    }
    
     
})

// Customizable Area End