// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse, ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";

export interface Props {
  navigation: any;
  id: string;
  companyId?: any;
  t?: any;
  i18n?: any;
  match?: any;
  location?: any;
  history?: any;
}

interface S {
  stepComplex: number;
  newComplexId: any;
  userRole: string;

  jurisdictionSelected: any;

  isEditComplex: boolean;

  currency: string;
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetComplexDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      stepComplex: 0,
      newComplexId: "",
      userRole: "",

      jurisdictionSelected: "",

      isEditComplex: false,

      currency: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.GetComplexDetailsCallId) {
        this.handleComplexDetailsResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    const accountRole = localStorage.getItem("role");
    const complex_id = sessionStorage.getItem("createComplexId");
    const isCreateComplex = sessionStorage.getItem("isCreateComplex");
    const isCreateComplexStep = sessionStorage.getItem("isCreateComplexStep");

    const edit_complex_id = this.props.navigation.getParam("id");

    if (edit_complex_id) {
      this.setState({ newComplexId: edit_complex_id, isEditComplex: true }, () => {
        this.getComplexDetails();
        sessionStorage.setItem("isCreateComplex", isCreateComplex || "false");
        sessionStorage.setItem("createComplexId", edit_complex_id);
      });
    }

    if (!edit_complex_id && isCreateComplex === "false") {
      this.setState({ newComplexId: complex_id, isEditComplex: isCreateComplex === "false", stepComplex: 4 }, () => {
        this.getComplexDetails();
      });
    }

    if (isCreateComplexStep) {
      this.setState({ stepComplex: Number(isCreateComplexStep) });
    }

    this.setState({ userRole: accountRole || "" });
  }

  handleStepClick = (index: number) => {
    this.setState({ stepComplex: index });
  };

  handleChangeJurisdiction = (jurisdiction: any, currency: string) => {
    this.setState({ jurisdictionSelected: jurisdiction, currency: currency });
  };

  handleChangeCurrency = (currency: string) => {
    this.setState({ currency: currency });
  };

  handleUpdateComplexId = (complex: any) => {
    this.setState({ newComplexId: complex }, () => {
      this.handleNext();
    });
  };

  handleBack = () => {
    this.setState({ stepComplex: this.state.stepComplex - 1 });
  };

  handleNext = () => {
    this.setState({ stepComplex: this.state.stepComplex + 1 });
  };

  getComplexDetails = async () => {
    this.GetComplexDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/company_employee/complexes/${this.state.newComplexId}`,
    });
  };

  handleComplexDetailsResponse = async (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const complex = responseJson.data;
      if (complex.attributes.jurisdiction) {
        this.setState({ jurisdictionSelected: complex.attributes.jurisdiction.data.id });
      }
    }
  };

  handleCompanyListNavigation = () => {
    navigationFunc(this.props, "ComplexListing");
  };

  handleCompanyDetailNavigation = () => {
    if (this.state.isEditComplex) {
      navigationFunc(this.props, "CEComplexDetails", { id: this.state.newComplexId });
    } else {
      navigationFunc(this.props, "ComplexListing");
    }
  };
}

// Customizable Area End
