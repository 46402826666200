// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractController, { Props, TemplateItem, TFunction } from "./LeaseManagementIssueNewContractController.web";
import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    Modal,
    Paper,
    Popover,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import Loader from '../../../components/src/Loader.web'
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { TemplateIcon } from "./assets";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import ClearIcon from '@material-ui/icons/Clear';
import AlertErrorWeb from "../../../components/src/AlertError.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class LeaseManagementIssueNewContract extends LeaseManagementIssueNewContractController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start
 
      renderViewTemplate=()=>{
        const { t }: { t: TFunction } = this.props;
        const {viewTemplateModalData}=this.state
            return (
              <>
                <Box>
                  <Modal open={this.state.viewTemplateModal}>
                    <ViewTemplateModalContainer>
                      <Paper className='TemplateModalChild'>
                        <Box className='TemplateModalChild1'>
                          <Typography className='TemplateModalChildHeading'>{viewTemplateModalData.attributes.title}</Typography>
                          <IconButton onClick={()=>this.viewTemplate(this.state.viewTemplateModalData.id)}><ClearIcon/></IconButton>
                        </Box>
                        <Divider />
                        <Paper className='TemplateModalChild2'>
                            <div dangerouslySetInnerHTML={{ __html: viewTemplateModalData.attributes.template }}></div>
                          </Paper>
                          <Box className='TemplateModalChildButton'>
                            <Button variant='outlined' className='TerminateModalChildButton1' onClick={()=>this.viewTemplate(this.state.viewTemplateModalData.id)}>{t("Cancel")}</Button>
                            <Button variant='contained' className='TerminateModalChildButton2' onClick={this.selectTemplate} data-testId={"selectTemplateTestId"}>{t("Select")}</Button>
                          </Box>
                      </Paper>
                    </ViewTemplateModalContainer>
                  </Modal>
                </Box>
              </>
            )
      }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { anchorEl, selectedId } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <IssueNewContractContainer>
                <Loader loading={this.state.loader} />
                    <Box>
                        <Breadcrumbs >
                            <Typography color="inherit" id={"LeaseManagmentId"} onClick={() => this.handleExistRecordNavigation("LeaseManagment")} className="notActiveBreadLink" data-testId={"issueNewContractHeadingTestId"}>
                                {t("Lease Management")}
                            </Typography>
                            <Typography
                                color="inherit"
                                className="notActiveBreadLink"
                                id={"IssueNewLeaseId"}
                                onClick={() => this.handleExistRecordNavigation("IssueNewLease")}
                                data-testId={"IssueNewLeaseTestId"}
                            >
                                {t("Issue New Lease")}
                            </Typography>
                            <Typography color="textPrimary" className="activeBreadLink">
                            {t("Issue a New Contract")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="issueNewcontractBreadHeading">
                        {t("Issue a New Contract")}
                        </Typography>
                    </Box>
                    <Paper elevation={1} className="issueNewContractContentContainer">
                        <Box className="issueNewContractHeaderContainer">
                            <Typography className="issueNewContractHeaderHeading">
                            {t("Select the template to be used for the new contract")}
                            </Typography>
                            <TextField
                                variant="outlined"
                                className="searchTextField"
                                InputProps={{
                                    startAdornment: (
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                                placeholder={t("Search by template name")}
                                onChange={this.handleSearchChange}
                                value={this.state.templateSearchText}
                                data-testId={"templateSearchInputTextId"}
                            />
                        </Box>
                        <Divider />
                        <Box className="templateContainer">
                            <Grid container spacing={2}>
                                {this.state.templatesList.map(
                                    (item:TemplateItem , index: number) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                            <Box className={this.state.selectedTemplate===Number(item.id)?"singleTemplateSelectContainer":"singleTemplateContainer"}>
                                                <Box className="singleTemplateNameContainer">
                                                    <img
                                                        src={TemplateIcon}
                                                        alt=""
                                                        className="templateIcon"
                                                    />
                                                    <Typography className="templateName" data-testId={`templateName${index}`}>
                                                        {item.attributes.title}
                                                    </Typography>
                                                </Box>
                                                <Box className="templateMenuBtnContainer">
                                                    {index === 0 && <Chip label="Default" />}
                                                    <IconButton
                                                        data-testId={`templateMenubtn${index}`}
                                                        onClick={(event) => this.handleClick(event, Number(item.id))}
                                                        className="dotIconBtn"
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Popover
                                                        open={open && selectedId === Number(item.id)}
                                                        id={id}
                                                        onClose={this.handleClose}
                                                        data-testId={`templateMenuContainer${index}`}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                    >
                                                        <PopoverContainer>
                                                            <Typography className="popoverText" data-testId={`templateMenuView${index}`} onClick={()=>this.viewTemplate(item.id)}>
                                                            {t("View")}
                                                            </Typography>
                                                        </PopoverContainer>
                                                    </Popover>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Box>
                    </Paper>
                    <Box className="bottomButtonContainer">
                        <Button className="cancelBtn" onClick={() => this.handleExistRecordNavigation("IssueNewLease")} data-testId={"cancelBtnTestId"}>{t("CANCEL")}</Button>
                        <Button
                            variant="contained"
                            className={this.state.selectedTemplate===null?"continueBtnDisabled":"continueBtn"}
                            onClick={this.viewTemplateDetails}
                            disabled={this.state.selectedTemplate === null ? true : false}
                            data-testId={"continueButtonTestId"}
                            >
                            {t("CONTINUE")}
                        </Button>
                    </Box>
                    {this.renderViewTemplate()}
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} />
                </IssueNewContractContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContract);
export {LeaseManagementIssueNewContract}
const IssueNewContractContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#f4f7ff",

    "& .notActiveBreadLink": {
        color: "#40434c",
        cursor: "pointer"
    },
    "& .activeBreadLink": {
        color: "#729be0",
        cursor: "pointer"
    },
    "& .issueNewcontractBreadHeading": {
        color: "#40434c",
        fontWeight: 900,
        fontSize: "32px",
        margin: "10px 0px",
    },
    "& .issueNewContractContentContainer": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        height: "95vh",
        overflow: "scroll",
        margin: "20px 0px",
    },
    "& .issueNewContractHeaderContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        "@media(max-width:576px)": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
        },
    },
    "& .issueNewContractHeaderHeading": {
        width: "100%",
        fontSize: "20px",
        fontWeight: 900,
        color: "#40434c",
    },
    "& .searchTextField": {
        width: "410px",
        border: "1px solid #f0f0f0",
        color: "darkgray",
        borderRadius: "10px !important",
        "@media(max-width:576px)": {
            width: "100%",
        },
        "& .MuiOutlinedInput-input::placeholder": {
            color: "darkgray !important",
        },
    },
    "& .templateContainer": {
        margin: "20px 0px",
        padding: "20px",
    },
    "& .singleTemplateContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #f3f3f3",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
    },
    "& .singleTemplateSelectContainer":{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #5f92f1",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
    },
    "& .singleTemplateNameContainer": {
        display: "flex",
        gap: "10px",
    },
    "& .templateIcon": {
        width: "20px",
    },
    "& .templateName": {
        color: "#242426",
        fontSize: "16px",
        fontWeight: 800,
    },

    "& .bottomButtonContainer": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "30px",
        padding: "20px 0px",
    },
    "& .cancelBtn": {
        width: "200px",
        padding: "10px 30px",
        border: "1px solid #6686bb",
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
        
    },
    "& .continueBtnDisabled":{
        width: "200px",
        padding: "10px 30px",
        color: "#fff",
        fontWeight: 900,
        backgroundColor:"#e0e0e0"
    },
    "& .templateMenuBtnContainer": {
        display: "flex",
        alignItems: "center",
    },
    "& .dotIconBtn": {
        padding: "10px 0px"
    }
});
const PopoverContainer = styled(Box)({
    width: "150px",
    "& .popoverText": {
        padding: "10px 20px ",
    },
});

const ViewTemplateModalContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '& .TemplateModalChild': {
        width: '700px',
        height: "95%",
        overflow: "scroll"
    },
    '& .TemplateModalChild1': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '& .TemplateModalChild2': {
        padding: 20,
        margin: 20,
        overflow: "scroll",
    },
    '& .TemplateModalChildHeading': {
        fontWeight: 800,
        fontSize: 25,
        padding: '20px',
    },
    '& .TemplateModalChildButton': {
        float: 'right',
        padding: 20
    },
    '& .TerminateModalChildButton1': {
        border: '1px solid #2f6ded',
        color: '#2f6ded',
        padding: '12px 40px ',
        fontWeight: 700,
        borderRadius: 10
    },
    '& .TerminateModalChildButton2': {
        background: '#2f6ded',
        color: 'white',
        marginLeft: 15,
        padding: '12px 40px ',
        fontWeight: 700,
        borderRadius: 10
    },
})
// Customizable Area End