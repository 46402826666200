import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { debounce } from "../../../components/src/helperFunctions.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    i18n: I18nInterface
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    individualBuildingList: Array<any>;
    openMenu: HTMLElement | null;
    openPopOver: HTMLElement | null;
    userRole: string | null;
    country: string;
    city: string;
    building: string;
    subscriptionEndsIn: string;
    accountManager: string;
    company: string;
    complex: string;
    salesManager: string;
    cooPageNumber: number;
    salesPageNumber: number;
    accountPageNumber: number;
    searchBuildingText: string;
    searchCompanyText: string;
    countryList: Array<string>;
    cityList: Array<string>;
    companyList: Array<any>;
    salesManagerList: Array<any>;
    accountManagerList: Array<any>;
    showError: boolean;
    errorMessage: string;
    showSuccess: boolean;
    showSuccessMessage: string;
    latitude: null | number | string;
    longitude: null | number | string;
    openBuildingModal: boolean;
    companyId: string;
    complexId: string;
    salesManagerId: string;
    accountManagerId: string;
    buildingId: string;
    loader: boolean;
    paginationData: PaginationDataInterface | null;
    complexList: Array<any>;
    buildingList: Array<any>;
    openReassignModal: boolean;
    modalBuildingId: number | null;
    modalBuildingName:string | null;
    modalCurrentSalesManager:string | null;
    modalCurrentAccountManager:string | null;
    accountManagerAssign: string;
    salesManagerAssign: string;
    noteText: string;
    ownerDetails: OwnerDetailsResponse;
    employeeAccountId: number | null;
    newAccountManagerList: Array<AccountManagerListItem>;
    newSalesManagerList: Array<SalesManagerListItem>;
    // Customizable Area End
}
// Customizable Area Start
export interface PaginationDataInterface {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}
export interface IndividualBuildingListSuccessResponse {
    data: Array<any>;
    meta: {
        pagination: PaginationDataInterface | null;
    }
}

export interface ComplexListSuccessResponse {
    data: Array<any>;
}


export interface CompanyListSuccessResponse {
    data: Array<any>;
};

export interface CountryListResponse {
    data: {
        countries: Array<string>
    }
}

export interface CityListResponse {
    data: {
        cities: Array<string>;
    }
}

export interface ComplexListResponse {
    data: Array<any>;
}

export interface BuildingListResponse {
    data: {
        building_list: Array<any>;
    }
}

export interface SalesManagerSuccessResponse {
    sales_manager_listing: Array<any>
};


export interface AccountManagerSuccessResponse {
    account_manager_listing: Array<any>
};

export type TFunction = (value: string) => string;

export interface I18nInterface {
    language: string;
}
export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
    contract: string
};

export interface OwnerDetailsResponse {
    data: {
        reporting_person: string | null;
        employee_account: string | null;
        object_id: number | null;
        object_name: string | null;
    }
}

export interface AccountListResponse {
    manager_liting: Array<AccountManagerListItem>;
}

export interface SalesListResponse {
    manager_liting: Array<SalesManagerListItem>;
}

export interface AccountManagerListItem {
    id: number | null;
    first_name: string;
}

export interface SalesManagerListItem {
    id: number | null;
    full_name: string;
}

export interface ReassignToAnotherManagerResponse {
    manager_listing: string;
}
interface ItemType1 {
        id: string;
        type: string;
        attributes: {
          id: number;
          building_name: string;
          company_name: null | string;
          account_manager: null | string;
          no_of_floor: number;
          totle_unit: number;
          totle_building: number;
          subscription_ends: string;
          country: string;
          city: string;
          lat: null | string;
          long: null | string;
        }
}
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class IndivisualBuildingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    individualBuildingApiCallId: string = "";
    companyListApiCallId: string = "";
    countryApiCallId: string = "";
    cityApiCallId: string = "";
    buildingApiCallId: string = "";
    complexListApiCallId: string = "";
    salesManagerApiCallId: string = "";
    accountManagerApiCallId: string = "";
    ownerDetailsApiCallId: string = "";
    accountListApiCallId: string = "";
    salesListApiCallId: string = "";
    reassignAnotherManagerApiCallId: string = "";

    [key: string]: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            individualBuildingList: [],
            openMenu: null,
            openPopOver: null,
            userRole: null,
            country: "",
            city: "",
            building: "",
            subscriptionEndsIn: "",
            accountManager: "",
            company: "",
            complex: "",
            salesManager: "",
            cooPageNumber: 1,
            salesPageNumber: 1,
            accountPageNumber: 1,
            searchBuildingText: "",
            searchCompanyText: "",
            countryList: [],
            cityList: [],
            companyList: [],
            salesManagerList: [],
            accountManagerList: [],
            showError: false,
            errorMessage: "",
            showSuccess: false,
            showSuccessMessage: "",
            latitude: null,
            longitude: null,
            openBuildingModal: false,
            companyId: "",
            complexId: "",
            salesManagerId: "",
            accountManagerId: "",
            buildingId: "",
            loader: false,
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0
            },
            complexList: [],
            buildingList: [],
            openReassignModal: false,
            modalBuildingId: null,
            modalBuildingName: "",
            modalCurrentSalesManager: "",
            modalCurrentAccountManager: "",
            accountManagerAssign: "",
            salesManagerAssign: "",
            noteText: "",
            ownerDetails: {
                data: {
                    reporting_person: null,
                    employee_account: null,
                    object_id: null,
                    object_name: null
                }
            },
            employeeAccountId: null,
            newAccountManagerList: [],
            newSalesManagerList: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.debouncedHandleSearchSubmit = debounce(this.handleFilterSubmit.bind(this), 300);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if ((responseJson && !responseJson.errors) || responseJson.contracts) {
                this.totalIndividualBuildingApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.totalIndividualBuildingApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        await this.getUserType();
        this.getCountryList();
        this.getCompanyListing();
        this.getSalesManagerListing();
        this.getAccountManagerListing();
        this.getIndividualBuildingListing("1");
        if(this.state.userRole === "sales_manager" || this.state.userRole === "account_manager"){
        }
    };

    totalIndividualBuildingApiCall = async (data: APIPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = data;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let individualBuildingsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        individualBuildingsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        individualBuildingsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        individualBuildingsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        individualBuildingsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? individualBuildingsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : individualBuildingsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(individualBuildingsrequestMessage.id, individualBuildingsrequestMessage);
        return individualBuildingsrequestMessage.messageId;
    };

    totalIndividualBuildingApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: CompanyListSuccessResponse & CountryListResponse & CityListResponse & ComplexListResponse & BuildingListResponse & SalesManagerSuccessResponse & AccountManagerSuccessResponse & IndividualBuildingListSuccessResponse & ComplexListSuccessResponse & OwnerDetailsResponse & AccountListResponse & SalesListResponse & ReassignToAnotherManagerResponse) => {
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.individualBuildingApiCallId) {
            this.individualBuildingListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingApiCallId) {
            this.buildingListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityApiCallId) {
            this.cityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companyListApiCallId) {
            this.companyListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerApiCallId) {
            this.salesManagerSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerApiCallId) {
            this.accountManagerSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerSucessCallBack(responseJson);
        }
    };

    totalIndividualBuildingApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.individualBuildingApiCallId) {
            this.individualBuildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingApiCallId) {
            this.buildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.countryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityApiCallId) {
            this.cityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companyListApiCallId) {
            this.companyListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerApiCallId) {
            this.salesManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerApiCallId) {
            this.accountManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerFailureCallBack(responseJson);
        }
    };

    getIndividualBuildingListing = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.individualBuilidingListEndpoint}page=${page}`;
        let { country, city, companyId, complexId, salesManagerId, subscriptionEndsIn, searchBuildingText, accountManagerId, building,searchCompanyText } = this.state;
        if (country) {
            endPoint += `&country=${country}`;
        }
        if (city) {
            endPoint += `&city=${city}`;
        }
        if (companyId) {
            endPoint += `&company=${companyId}`;
        }
        if (complexId) {
            endPoint += `&complex=${complexId}`;
        }
        if (building) {
            endPoint += `&building=${building}`;
        }
        if (salesManagerId) {
            endPoint += `&sales_manager=${salesManagerId}`;
        }
        if (accountManagerId) {
            endPoint += `&account_manager=${accountManagerId}`;
        }
        if (subscriptionEndsIn) {
            endPoint += `&subscription_ends_in=${subscriptionEndsIn}`;
        }
        if (searchBuildingText) {
            endPoint += `&search_term=${searchBuildingText}`;
        }
        if (searchCompanyText) {
            endPoint += `&search_term=${searchCompanyText}`;
        }
        this.individualBuildingApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint,
        });
    };

    individualBuildingListSucessCallBack = (response: IndividualBuildingListSuccessResponse) => {
        this.setState({ individualBuildingList: response.data, paginationData: response.meta.pagination, loader: false });
    };

    individualBuildingListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getComplexListing = async () => {
        this.complexListApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.complexListEndpoint}city=${this.state.city}&is_building=true`,
        });
    };

    complexListSucessCallBack = (response: ComplexListSuccessResponse) => {        
        this.setState({ complexList: response.data });
    };

    complexListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getBuildingListing = async () => {
        this.buildingApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.buildingListEndpoint}`,
        });
    };

    buildingListSucessCallBack = (response: BuildingListResponse) => {
        this.setState({ buildingList: response.data.building_list });
    };

    buildingListFailureCallBack = (response: ApiFailureResponse) => {        
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCompanyListing = async () => {
        this.companyListApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.companyListEndpoint,
        });
    };

    companyListSucessCallBack = (response: CompanyListSuccessResponse) => { 
                this.setState({ companyList: response.data })
    };

    companyListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCountryList = async () => {
        this.countryApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint
        });
    };
    handleMapmodal = () => {
        this.setState({
            openBuildingModal: true,
        })
    }

    countryListSucessCallBack = (response: CountryListResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndpoint}${this.state.country}`
        });
    };

    cityListSucessCallBack = (response: CityListResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getSalesManagerListing = async () => {
        this.salesManagerApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.salesManagerEndPoint,
        });
    };

    salesManagerSuccessCallBack = (response: SalesManagerSuccessResponse) => {
        this.setState({ salesManagerList: response.sales_manager_listing })
    };

    salesManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getAccountManagerListing = async () => {
        this.accountManagerApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.accountManagerEndPoint
        });
    };

    accountManagerSuccessCallBack = (response: AccountManagerSuccessResponse) => {
        this.setState({ accountManagerList: response.account_manager_listing })
    };

    accountManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getOwnerDetails = async (companyId: string | number) => {
        this.setState({ loader: true });
        this.ownerDetailsApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.ownerDetailsEndpoint}${companyId}`
        });
    };

    ownerDetailsSucessCallBack = (response: OwnerDetailsResponse) => {   
        this.setState({ 
            ownerDetails: response, 
            loader: false, 
            openReassignModal: true,
            modalBuildingId: response.data.object_id,
            modalBuildingName: response.data.object_name,
            modalCurrentSalesManager: response.data.reporting_person,
            modalCurrentAccountManager: response.data.employee_account
        });
    };

    ownerDetailsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
    };

    getAccountList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.accountListApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    accountListSucessCallBack = (response: AccountListResponse) => {
        this.setState({ newAccountManagerList: response.manager_liting, loader: false, openReassignModal: true });
    };

    accountListFailureCallBack =(response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
    };

    getSalesList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.salesListApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    salesListSucessCallBack = (response: SalesListResponse) => {
        this.setState({ newSalesManagerList: response.manager_liting, loader: false, openReassignModal: true });
    };

    salesListFailureCallBack =(response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
    };

    reassignToAnotherAccountManager = async () => {
        this.setState({ loader: true });
        this.reassignAnotherManagerApiCallId = await this.totalIndividualBuildingApiCall({
            method: configJSON.httpPut,
            endPoint: `${configJSON.reassignAccountManagerEndpoint}employee_account_id=${this.state.employeeAccountId}&object_id=${this.state.modalBuildingId}&note=${this.state.noteText}`
        });
    };

    reassignToAnotherAccountManagerSucessCallBack = (response: ReassignToAnotherManagerResponse) => {
        this.setState({ showSuccessMessage: response.manager_listing,openReassignModal: false, showSuccess: true,loader:false });
    };

    reassignToAnotherAccountManagerFailureCallBack = async (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
    };

    getUserType = async () => {
        const storedRole = await localStorage.getItem('role');
        this.setState({ userRole: storedRole });
    };

    handleCompanyChange = (event: ChangeEvent<{ value: unknown }>) => {
        let company = this.state.companyList.find((company) => { return company.attributes.name === event.target.value });
        if (company) {
            this.setState({ company: event.target.value as string, companyId: company.id });
        }
    };

    handleComplexChange = (event: ChangeEvent<{ value: unknown }>) => {
        let complex = this.state.complexList.find((complex) => { return complex.attributes.name === event.target.value });
        if (complex) {
            this.setState({ complex: event.target.value as string, complexId: complex.id });
        }
    };

    handleSubscriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ subscriptionEndsIn: event.target.value as string });
    };

    handleSalesManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
        let salesManager = this.state.salesManagerList.find((salesManager) => { return salesManager.full_name === event.target.value });
        if (salesManager) {
            this.setState({ salesManager: event.target.value as string, salesManagerId: String(salesManager.id) });
        }
    };

    handleCountryChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string }, () => {
            this.getCityList();
        });
    };

    handleCityChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string }, () => {
            this.getComplexListing();
        });
    };

    handleBuildingChange = (event: ChangeEvent<{ value: unknown }>) => {
        let building = this.state.buildingList.find((building) => { return building.name === event.target.value });
        if (building) {
            this.setState({ building: event.target.value as string, buildingId: String(building.id) });
        }
    };

    handleAccountManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
        let accountManager = this.state.accountManagerList.find((accountManager) => { return accountManager.full_name === event.target.value });
        if (accountManager) {
            this.setState({ accountManager: event.target.value as string, accountManagerId: String(accountManager.id) });
        }
    };

    handleSearchBuildingChange = (event: any) => {
        this.setState({ searchBuildingText: event.target.value },()=>{
            this.getIndividualBuildingListing("1")
        });
    };

    handleCloseMenu = () => {
        this.setState({ openMenu: null });
    };

    handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openMenu: event.currentTarget });
    };

    handleClosePopover = () => {
        this.setState({ openPopOver: null });
    };

    handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openPopOver: event.currentTarget });
    };

    handleUserType = (userType: string) => {
        if (this.state.userRole === userType) {
            return true
        } else {
            return false
        }
    };


    handleSearchCompanyChange = (event: any) => {
        this.setState({ searchCompanyText: event.target.value },()=>{
            this.debouncedHandleSearchSubmit();
        });
    };

    handleFilterSubmit = () => {
        this.getIndividualBuildingListing("1");
    };

    handleCloseViewBuilding = () => {
        this.setState({ openBuildingModal: false })
    };

    handleAlertError = () => {
        this.setState({ errorMessage: "", showError: false });
    };

    handlePagination=(event: React.ChangeEvent<unknown>, value: number)=>{
        this.getIndividualBuildingListing(String(value));
    };

    handleRedirection = (path: string) => {        
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        this.send(msgData);
    };

    handleSalesManager = (event: React.ChangeEvent<{ value: unknown }>) => {
        let salesManager = this.state.newSalesManagerList.find((sales) => { return sales.full_name === event.target.value });
        if (salesManager) {
            this.setState({
                salesManagerAssign: event.target.value as string,
                employeeAccountId: salesManager.id,
                accountManagerAssign: "",
            });
        }
    };

    handleAccountManager = (event: React.ChangeEvent<{ value: unknown }>) => {
        let accountManager = this.state.newAccountManagerList.find((account) => { return account.first_name === event.target.value });
        if (accountManager) {
            this.setState({ accountManagerAssign: event.target.value as string, salesManagerAssign: "", employeeAccountId: accountManager.id });
        }
    };

    handleOpenReassignModal = (companyId: string) => {
        this.setState({openMenu: null});
        this.getOwnerDetails(companyId);
        if(this.state.userRole === 'coo'){   
            this.getAccountList(configJSON.cooProfileAccountManagerListingEndpoint)
            this.getSalesList(configJSON.cooProfileSalesManagerListingEndpoint)
        }else {
            this.getAccountList(configJSON.salesProfileAccountManagerListingEndpoint)
        }
    };

    handleCloseReassignModal = () => {
        this.setState({ openReassignModal: false });
    };

    handleNoteModal = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ noteText: event.target.value });
    };

    handleSuccessAlert =() => {
        this.setState({ showSuccess: false });
    };
    handleMapvalues = (item:ItemType1) => {
        this.setState({
            latitude:item&&item.attributes&&item.attributes.lat,
            longitude:item&&item.attributes&&item.attributes.long,
        })
    }
    // Customizable Area End
}
