// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";

export interface Props {
  navigation: any;
  id: string;
  companyId: any;
  t?: any;
  i18n?: any;
  match?: any;
  location?: any;
  history?: any;
}

interface S {
  loading: boolean;
  isEditComplex: boolean;
  stepBuilding: number;
  complexBuildingId: string;
  accountType: string;
  jurisdictionSelected: any;
  buildingId: any;
  currency: string | any;
}

interface SS {
  id: any;
}

export default class CompEmpBuildingBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetComplexDetailsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      loading: false,
      isEditComplex: false,

      stepBuilding: 0,

      complexBuildingId: "",
      buildingId: "",

      accountType: "",
      jurisdictionSelected: "",

      currency: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.GetComplexDetailsCallId) {
        this.handleComplexDetailsResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    const accountRole = localStorage.getItem("role") || "";
    const complex_id = this.props.navigation.getParam("id");

    this.setState({ accountType: accountRole, loading: true });

    if (complex_id) {
      this.setState({ complexBuildingId: complex_id, isEditComplex: true }, () => {
        this.getComplexDetails();
      });
    }
  }

  handleStepClick = (index: number) => {
    this.setState({ stepBuilding: index });
  };

  handleNextBuilding = () => {
    this.setState({ stepBuilding: this.state.stepBuilding + 1 });
  };

  handleBackBuilding = () => {
    this.setState({ stepBuilding: this.state.stepBuilding - 1 });
  };

  handleChangeJurisdiction = (jurisdiction: any, currency: any) => {
    this.setState({ jurisdictionSelected: jurisdiction, currency: currency });
  };

  handleUpdateComplexBuildingId = (complexBuildingId: string, buildingId: any) => {
    this.setState({
      complexBuildingId: complexBuildingId,
      buildingId: buildingId,
      stepBuilding: this.state.stepBuilding + 1,
    });
  };

  getComplexDetails = async () => {
    this.GetComplexDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/company_employee/complexes/${this.state.complexBuildingId}`,
    });
  };

  handleComplexDetailsResponse = async (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const building = responseJson.data;
      if (building.attributes.jurisdiction) {
        this.setState({ jurisdictionSelected: building.attributes.jurisdiction.data.id });
      }
    }
  };

  handleCompanyListNavigation = () => {
    navigationFunc(this.props, "ComplexListing");
  };

  handleHeading = (t: any) => {
    return this.state.isEditComplex ? t("Edit New Individual Building") : t("Create New Individual Building");
  };

  handleCompanyDetailNavigation = () => {
    if (this.state.isEditComplex) {
      navigationFunc(this.props, "ComplexBuildingDetails", { id: this.state.complexBuildingId });
    } else {
      navigationFunc(this.props, "ComplexListing");
    }
  };
}
// Customizable Area End
