// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractAddTermsController, { Props, TFunction, GetConditionsSuccessResponseItem, GetTermsSuccessResponseItem } from "./LeaseManagementIssueNewContractAddTermsController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { Box, Breadcrumbs, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, MenuItem, Modal, Paper, Select, TextField, Typography, styled } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTranslation } from "react-i18next";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class LeaseManagementIssueNewContractAddTerms extends LeaseManagementIssueNewContractAddTermsController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderAddMoreCondtion = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal
            open={this.state.addMoreConditionModal}
        >
            <ModalContainer >
                <Box className="addMoreConditionModalContainer">
                    <Box className="addMoreConditionModalHeader">
                        <Typography className="addMoreConditionModalHeading">{t("Add More Conditions")}</Typography>
                        <Button className="crossIcon" onClick={this.handleCloseAddMoreCondition}>X</Button>
                    </Box>
                    <Divider />
                    <Box className="addMoreConditionModalContent">
                        <Typography className="addMoreConditionModalContentHeading">{t("Condtions")}</Typography>
                        {this.state.conditionList.map((item: GetConditionsSuccessResponseItem, index: number) => {
                            return <Box key={index}>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={item.text}
                                    onChange={() => this.handleSelectedConditions(item.id, item.text)}
                                    data-testId={`conditions${index}`}
                                    checked={this.handleConditionsCheck(item.id)}
                                />
                            </Box>
                        })}

                        <Typography className="addMoreConditionModalContentHeading">{t("Terms")}</Typography>
                        {this.state.termsList.map((item: GetTermsSuccessResponseItem, index: number) => {
                            return <Box key={index}>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={item.text}
                                    onChange={() => this.handleSelectedTerms(item.id, item.text)}
                                    data-testId={`terms${index}`}
                                    checked={this.handleTermsCheck(item.id)}
                                />
                            </Box>
                        })}
                        <Button className="addMoreCondtionBtn" onClick={this.handleOpenMoreTermsModal} data-testId={"addMoreTermTestId"}>{t("Add more terms")}</Button>
                        <Box className="modalBottomSection">
                            <Button className="cancelBtn" onClick={this.handleCloseAddMoreCondition} data-testId={"addtermsConditionsCloseBtnTestId"}>{t("Close")}</Button>
                            <Button variant="contained" className="continueBtn" data-testId={"addTermConditionsConfirmBtnTestID"} onClick={this.handleConfirm}>
                                {t("Confirm")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ModalContainer>
        </Modal>
    };

    renderAddMoreTermsModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.addMoreTermsModal}>
            <ModalAddMoreTermsContainer>
                <Box className="addMoreTermsModalContainer">
                    <Box className="addMoreTermsModalHeader">
                        <Typography className="addMoreConditionModalHeading">{t("Add More Terms")}</Typography>
                        <Button className="crossIcon">X</Button>
                    </Box>
                    <Divider />
                    <Box className="addMoreTermsModalContentContainer">
                        <textarea placeholder="Add terms" className="addMoreTermsModalTextarea" onChange={this.handleAddTermChange} data-testId={"addTermTextAreaTestId"} />
                        <Box className="modalBottomSection">
                            <Button className="cancelBtn" onClick={this.handleCloseMoreTermsModal} data-testid={"addMoreTermsCancelBtnTestId"}>{t("Cancel")}</Button>
                            <Button variant="contained" className="continueBtn" onClick={this.addTermsApi} data-testId={"addTermsBtnTestId"}>
                                {t("Add")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ModalAddMoreTermsContainer>
        </Modal>
    };

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <IssueNewContractContainer>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="inherit" id={"LeaseManagmentId"} onClick={() => this.handleExistRecordNavigation("LeaseManagment")} className="notActiveBreadLink" data-testId={"addTermsConditionHeadingTestId"}>
                                {t("Lease Management")}
                            </Typography>
                            <Typography
                                color="inherit"
                                className="notActiveBreadLink"
                                id={"IssueNewLeaseId"}
                                onClick={() => this.handleExistRecordNavigation("IssueNewLease")}
                                data-testId={"issueNewLeaseHeadingTestId"}
                            >
                                {t("Issue New Lease")}
                            </Typography>
                            <Typography
                                className="activeBreadLink">
                                {t("Issue a New Contract")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="issueNewcontractHeading">
                            {t("Issue a New Contract")}
                        </Typography>
                    </Box>
                    <Paper
                        className="issueNewContractContentContainer" elevation={1} >

                        <Box className="issueNewContractHeaderContainer">
                            <Typography className="issueNewContractHeaderHeading">
                                {t("Add Terms")}
                            </Typography>

                        </Box>
                        <Divider />
                        <Box className="templateDetailsContainer">
                            <Typography className="addTermsContent">
                                <div dangerouslySetInnerHTML={{ __html: this.state.createContractPayloadData.contract_template }}></div>
                            </Typography>
                            <Box className="addTermsCheckboxContainer">
                                <Typography>
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        onChange={this.handlePenaltyCheckbox}
                                        data-testId={"penaltyCheckboxTestId"}
                                    />
                                    <span className="penaltyCheckboxText">{t("Penalty for late rent payments")}</span>
                                </Typography>
                                {this.state.penalty &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    IconComponent={ExpandMoreIcon}
                                                    className="addTermstextfield"
                                                    value={this.state.penaltyType}
                                                    displayEmpty
                                                    onChange={this.handlePenaltyType}
                                                    data-testId={"penaltyTypeSelectTestId"}
                                                >
                                                    <MenuItem value={""}><em>{t("Select Penalty")}</em></MenuItem>
                                                    {this.state.penaltyTypeOptions.map((penaltyType: string, penaltyTypeIndex: number) => {
                                                        return <MenuItem value={penaltyType} key={penaltyTypeIndex}>{penaltyType}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>                                    </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <TextField placeholder={this.state.penaltyType} variant="outlined" fullWidth className="addTermstextfield" value={this.state.penaltyAmount} onChange={this.handlePenaltyChange} data-testId={"penaltyInputTestId"} />
                                        </Grid>
                                    </Grid>
                                }
                                <Button className="addMoreCondtionBtn" onClick={this.handleOpenAddMoreCondition} data-testId={"addMoreConditionsTestId"}>{t("Add more conditions")}</Button>
                            </Box>
                        </Box>
                    </Paper>
                    <Box className="bottomButtonContainer">
                        <Button className="cancelBtn" onClick={this.handleBackButton} data-testId={"backBtnTestId"}>{t("Back")}</Button>
                        <Button variant="contained" className="continueBtn"
                            data-testId={"reviewContractBtnTestId"}
                            onClick={this.handleNext}
                        >
                            {t("Review Contract")}
                        </Button>
                    </Box>
                    {this.renderAddMoreCondtion()}
                    {this.renderAddMoreTermsModal()}
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorAlertTestId"} />
                    <AlertSuccess show={this.state.showSuccessMessage} handleClose={this.hanldeCloseSuccessAlert} message={this.state.successMessage} data-testId={"successAlertTestId"} />
                </IssueNewContractContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractAddTerms);
export { LeaseManagementIssueNewContractAddTerms };
const IssueNewContractContainer = styled(Box)({
    backgroundColor: "#f4f7ff",
    padding: "50px",
    "& .notActiveBreadLink": {
        color: "#40434c",
        cursor: "pointer"
    },
    "& .activeBreadLink": {
        color: "#729be0",
        cursor: "pointer"
    },
    "& .issueNewcontractHeading": {
        color: "#40434c",
        fontSize: "32px",
        fontWeight: 900,
        margin: "10px 0px",
    },
    "& .issueNewContractContentContainer": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        margin: "20px 0px",
    },
    "& .issueNewContractHeaderContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        "@media(max-width:576px)": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: "20px",
        },
    },
    "& .issueNewContractHeaderHeading": {
        width: "100%",
        fontSize: "20px",
        fontWeight: 900,
        color: "#40434c",
    },

    "& .templateName": {
        color: "#242426",
        fontWeight: 800,
        fontSize: "14px",
    },

    "& .bottomButtonContainer": {
        display: "flex",
        gap: "30px",
        justifyContent: "flex-end",
        padding: "20px 0px",
    },
    "& .cancelBtn": {
        border: "1px solid #6686bb",
        padding: "10px 30px",
        color: "#557dc8 !important",
        fontWeight: "900",
        width: "250px"
    },
    "& .continueBtn": {
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
        width: "250px"
    },
    "& .templateHeading": {
        fontSize: "17px",
        fontWeight: 900,
        color: "#40434c",
    },
    "& .templateDetailsContainer": {
        padding: "20px"
    },
    "& .addTermsCheckboxContainer": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        marginTop: "20px",
        gap: "20px"
    },
    "& .addMoreCondtionBtn": {
        color: "#ec924b",
        fontWeight: 800,
        fontSize: "16px",
    },
    "& .addTermsContent": {
        color: "#a2a2a2",
        fontSize: "16px",
    },
    "& .addTermstextfield": {
        borderRadius: "10px",
        background: "#f9f9f9 !important",
        border: "1px solid #f1f1f1",

    },
    "& .penaltyCheckboxActive":{
        color:"#3b7aef"
    },
    "& .penaltyCheckbox":{
        color:"yellow"
    },
    "& .penaltyCheckboxText": {
        cursor: "default"
    }
});
const ModalContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    oveflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .addMoreConditionModalContainer": {
        backgroundColor: "#fff !important",
        borderRadius: "20px",
        width: "650px",
        height: "95%",
        overflow: "scroll"
    },
    "& .addMoreConditionModalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .addMoreConditionModalHeading": {
        color: "#1a1b20",
        fontSize: "20px",
        fontWeight: 900
    },
    "& .crossIcon": {
        color: "1c1c1c",
        fontSize: "18px"
    },
    "& .addMoreConditionModalContent": {
        padding: "0px 20px"
    },
    "& .addMoreConditionModalContentHeading": {
        color: "1a1b20",
        fontSize: "18px",
        fontWeight: 800,
        padding: "10px"
    },
    "& .addMoreCondtionBtn": {
        color: "#ec924b",
        fontSize: "16px",
        fontWeight: 800
    },

    "& .cancelBtn": {
        border: "1px solid #6686bb",
        padding: "10px 30px",
        color: "#557dc8 !important",
        fontWeight: "900",
        width: "250px"
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
    },
    "& .modalBottomSection": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px 0px",
        gap: "20px"
    },
    

})
const ModalAddMoreTermsContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    oveflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .addMoreTermsModalContainer": {
        backgroundColor: "#fff !important",
        borderRadius: "20px",
        width: "650px",
    },
    "& .addMoreTermsModalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .addMoreConditionModalHeading": {
        color: "#1a1b20",
        fontSize: "20px",
        fontWeight: 900
    },
    "& .crossIcon": {
        color: "1c1c1c",
        fontSize: "18px"
    },
    "7 .addMoreTermsModalContainer": {
        padding: "20px",

    },
    "& .addMoreTermsModalTextarea": {
        height: "100px",
        width: "100%",
        padding: "10px"
    },
    "& .addMoreTermsModalContentContainer": {
        padding: "20px"
    },
    "& .cancelBtn": {
        border: "1px solid #6686bb",
        padding: "10px 30px",
        color: "#557dc8 !important",
        fontWeight: "900",
        width: "250px"
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
    },
    "& .modalBottomSection": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px 0px",
        gap: "20px"
    },
})

// Customizable Area End