// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";

import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { emptyFilterValue } from "../../../../components/src/UtilFunction.web";
import * as Yup from "yup";
import { debounce } from "../../../../components/src/helperFunctions.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  companyId: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  accountType: string;

  companyDetails: any;
  proofFileName: string;

  companiesList: any;
  countriesList: any;
  cityList: any;
  regionList: any;
  statusList: any;
  accountManagerList: any;

  companyFilter: string;
  countryFilter: string;
  cityFilter: string;
  regionFilter: string;
  statusFilter: string;
  accountManagerFilter: any;
  search: string;

  complexList: any[];
  mapPoints: any[];
  mapPointClick: number;

  page: number;
  pagination: any;

  mapSwitchEstate: boolean;

  tabNo: number;

  registerModal: any;
  deleteConfirmModal: boolean;
  reasonConfirmModal: boolean;

  isMapModalOpen: boolean;
  lat: number;
  long: number;
}

interface SS {
  id: any;
}

export default class RealEstateCompanyDetailsCardController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCompaniesFilterList: string = "";
  GetCountriesFilterList: string = "";
  GetRegionsFilterList: string = "";
  GetCityFilterList: string = "";
  GetStatusFilterList: any;
  GetAccountManagerFilterCardList: any;

  GetCompanyDetails: string = "";
  DeleteCompany: string = "";

  GetRealEstateComplexes: string = "";

  [key: string]: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.debouncedHandleFilterSubmit = debounce(this.handleFilterSubmit.bind(this), 300);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      loading: false,
      accountType: "",

      companyDetails: null,
      proofFileName: "",

      companiesList: [],
      countriesList: [],
      cityList: [],
      regionList: [],
      statusList: [],
      accountManagerList: [],

      accountManagerFilter: "",
      companyFilter: "",
      countryFilter: "",
      cityFilter: "",
      regionFilter: "",
      statusFilter: "",
      search: "",

      complexList: [],
      mapPoints: [],
      mapPointClick: 0,

      page: 1,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },

      mapSwitchEstate: false,

      tabNo: 0,

      registerModal: false,
      reasonConfirmModal: false,
      deleteConfirmModal: false,

      isMapModalOpen: false,
      lat: 0,
      long: 0,
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCompaniesFilterList:
          this.handleCompanyFiltersList(responseJson);
          break;
        case this.GetCountriesFilterList:
          this.handleCountryFiltersList(responseJson);
          break;
        case this.GetCityFilterList:
          this.handleCityFiltersList(responseJson);
          break;
        case this.GetRegionsFilterList:
          this.handleRegionFiltersList(responseJson);
          break;
        case this.GetStatusFilterList:
          this.handleFiltersList(responseJson, "statusList", "status");
          break;
        case this.GetAccountManagerFilterCardList:
          this.handleFiltersList(responseJson, "accountManagerList", "account_managers");
          break;
        case this.GetCompanyDetails:
          this.handleCompanyDetails(responseJson);
          break;
        case this.DeleteCompany:
          this.handleDeleteSuccess(responseJson);
          break;
        case this.GetRealEstateComplexes:
          this.handleComplexList(responseJson);
          break;
        default:
          break;
      }
      this.setState({ loading: false });

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    let filterTable = [
      {
        name: "GetStatusFilterList",
        endPoint: "list_status",
      },
    ];

    const accountRole = localStorage.getItem("role");
    if (accountRole) {
      const accountRoleNameCard = accountRole.replace(/"/g, "");
      this.setState({ accountType: accountRoleNameCard });

      if (accountRoleNameCard === "coo") {
        filterTable = [
          ...filterTable,
          {
            name: "GetAccountManagerFilterCardList",
            endPoint: "account_manager_list",
          },
        ];
      }
    }

    filterTable.forEach((filter) => {
      this.getFilterList(filter.name, `/bx_block_real_estate_companies/${filter.endPoint}`);
    });

    this.getFilterList("GetCompaniesFilterList", `bx_block_dashboard/assign_action_team/real_estate_company_list`);
    this.getFilterList("GetCountriesFilterList", `bx_block_address/country_list`);

    this.getCompanyDetails();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getComplexList(Boolean(this.state.tabNo));
    }
  }

  getCompanyDetails = () => {
    const companyId = this.props.companyId;
    this.setState({ loading: true }, async () => {
      this.GetCompanyDetails = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}`,
      });
    });
  };

  handleCompanyDetails = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const fileName = responseJson.data.attributes.registration_certificate?.url;
      if (fileName) {
        const splitFileName = fileName.split("/");
        this.setState({ proofFileName: splitFileName[splitFileName.length - 1] });
      }
      this.setState({ companyDetails: responseJson.data }, () => {
        this.getComplexList(false);
      });
    }
  };

  getComplexList = async (is_building: boolean) => {
    const {
      page,
      countryFilter,
      search,
      cityFilter,
      regionFilter,
      statusFilter,
      accountManagerFilter,
      companyDetails,
    } = this.state;

    this.GetRealEstateComplexes = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes?page=${page}&per_page=10&search=${search}&country=${emptyFilterValue(
        countryFilter
      )}&city=${emptyFilterValue(cityFilter)}&region=${emptyFilterValue(regionFilter)}&status=${emptyFilterValue(
        statusFilter
      )}&account_manager_id=${emptyFilterValue(accountManagerFilter)}&company_name=${
        companyDetails.attributes.company_name
      }&is_building=${is_building}`,
    });
  };

  handleComplexList = (responseJson: any) => {
    if (responseJson && responseJson.society_management && responseJson.society_management.data.length > 0) {
      const mapPoints = responseJson.society_management.data.map((complex: any) => {
        const locationObj = {
          lat: complex.attributes?.lat,
          lng: complex.attributes?.long,
          complex: complex.attributes?.name,
          complexId: complex.id,
          chairman: complex.attributes?.chairman,
          totalUnits: complex.attributes?.total_units[0],
          subscription: complex.attributes?.subscription_end_in,
        };
        return locationObj;
      });
      this.setState({
        complexList: responseJson.society_management?.data,
        page: responseJson?.meta?.pagination?.current_page,
        pagination: responseJson?.meta?.pagination,
        mapPoints: mapPoints,
      });
    } else {
      this.setState({ complexList: [], page: 1, pagination: null, mapPoints: [] });
    }
  };

  handleDeleteModal = () => {
    this.setState({ deleteConfirmModal: !this.state.deleteConfirmModal });
  };

  handleOpenReasonPopup = () => {
    this.setState({ deleteConfirmModal: false, reasonConfirmModal: true });
  };

  handleCloseReasonPopup = () => {
    this.setState({ reasonConfirmModal: false });
  };

  noteValidation: any = Yup.object().shape({
    note: Yup.string().required("Required").matches(/\S/, "Required"),
  });

  handleDelete = (value: { note: string }) => {
    const companyId = this.props.companyId;
    this.setState({ loading: true }, async () => {
      this.DeleteCompany = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}?deletion_reason=${value.note}`,
      });
    });
  };

  handleDeleteSuccess = (responseJson: any) => {
    if (responseJson) {
      this.props.navigation?.history?.push("/CompanyEmployee/realestate-companies");
    }
  };

  handleRegisterModal = () => {
    this.setState({ registerModal: !this.state.registerModal });
  };

  handlePaginationEstate = (event: any, value: any) => {
    this.setState({ page: value });
  };

  handleMapSwitchEstate = (event: any, val: boolean) => {
    this.setState({ mapSwitchEstate: val });
  };

  handleEditClick = () => {
    this.props.navigation.navigate("RealEstateEditCompany", { id: this.props.companyId });
  };

  handleDebounceSearch = (event: any) => {
    this.setState({ search: event.target.value }, () => {
      this.debouncedHandleFilterSubmit();
    });
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        tabNo: newValue,
        page: 1,
        accountManagerFilter: "",
        companyFilter: "",
        countryFilter: "",
        cityFilter: "",
        regionFilter: "",
        statusFilter: "",
        search: "",
      },
      () => {
        this.getComplexList(Boolean(newValue));
      }
    );
  };

  handleMapModal = () => {
    this.setState({ isMapModalOpen: !this.state.isMapModalOpen });
  };

  handleMapPointClick = (key: any) => {
    this.setState({ mapPointClick: key });
  };

  handleFilterSubmit = () => {
    this.getComplexList(Boolean(this.state.tabNo));
  };

  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value + "";
    this.setState({ ...this.state, [stateParam]: value }, () => {
      if (stateParam === "countryFilter") {
        this.getFilterList("GetCityFilterList", `bx_block_address/city_list?country=${value}`);
        this.getFilterList("GetRegionsFilterList", `bx_block_address/region_list?country=${value}`);
      }
    });
  };

  getFilterList = (method: string, endPoint: string) => {
    this.setState({ loading: true }, async () => {
      this[method] = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint,
      });
    });
  };

  handleFiltersList = (responseJson: any, stateParam: string, resParam: string) => {
    if (responseJson) {
      this.setState({ ...this.state, [stateParam]: responseJson[resParam] });
    }
  };

  handleCompanyFiltersList = (responseJson: { compneys: [] }) => {
    if (responseJson && responseJson.compneys) {
      this.setState({ ...this.state, companiesList: responseJson.compneys });
    }
  };

  handleCountryFiltersList = (responseJson: { data: { countries: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, countriesList: responseJson.data.countries });
    }
  };

  handleRegionFiltersList = (responseJson: { data: { regions: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, regionList: responseJson.data.regions });
    }
  };

  handleCityFiltersList = (responseJson: { data: { cities: [] } }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, cityList: responseJson.data.cities });
    }
  };

  handleCreateItem = (item: string) => {
    sessionStorage.setItem("real_estate_id", this.props.companyId + "");
    if (item === "Complex") {
      this.props.navigation.navigate("ComplexMainBlock");
    } else {
      this.props.navigation.navigate("BuildingMainBlock");
    }
  };
}
// Customizable Area End
