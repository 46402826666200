import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    i18n: I18nInterface
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    companiesData: Array<CompaniesDataItem>;
    complexData: Array<ComplexDataItem>
    cooComplexData: Array<COOComplexDataItem>;
    cooAccountData: Array<CooAccountDataItem>;
    tabValue: number;
    selectDetails: string;
    userRole: string | null;
    openPopOver: HTMLElement | null;
    company: string;
    country: string;
    city: string;
    building: string;
    complex: string;
    searchText: string;
    cooComplexPage: number;
    countryList: Array<string>;
    cityList: Array<string>;
    companyList: Array<CompanyListItem>;
    showError: boolean;
    showErrorMessage: string;
    salesManagerProfileDetails: SalesManagerProfileDetailsResponse;
    complexList: Array<ComplexListItem>;
    salesManagerList: Array<SalesManagerItem>;
    individualBuildingList: Array<TotalIndividualType>;
    paginationData: PaginationDataInterface | null;
    openMenu: HTMLElement | null;
    subscriptionEndsIn: string;
    salesManager: string;
    salesManagerId: string;
    companyId: string;
    complexId: string;
    accountManagerId: string;
    loader: boolean;
    openBuildingModal: boolean;
    latitude: number|null;
    longitude: number|null;
    buildingList: Array<BuildingListItem>;
    buildingId: string;
    sortBy: string;
    popoverAncher: HTMLElement | null;
    selectedItem: RegisterOwnerResponseItem | null;
    registeredOwner: Array<RegisterOwnerResponseItem>;
    unit: string;
    ownerStatus: string;
    payingClient: string;
    cooPage: number;
    salesPage: number;
    accountPage: number;
    page: number;
    propertyManagerList: Array<PropertyManagerListItem>;
    propertyManager: string;
    propertyManagerId: string;
    anchorEl: HTMLButtonElement | null;
    openReassignModal: boolean;
    modalBuildingId: string;
    accountManagerAssign: string;
    salesManagerAssign: string;
    noteText: string;
    // Customizable Area End
}
// Customizable Area Start
export interface CompaniesDataItem {
    companyName: string;
    buildingId: string;
    buildingName: string;
    unit: string;
    status: string;
};

export interface COOComplexDataItem {
    id: string | null;
    attributes: {
        id: number | null;
        complex_name: string | null;
        account_manager: number | null | string;
        totle_unit: number | null;
        subscription_ends: string | null;
        subscription_plan: string | null;
        paying_month: string | null;
        lat:number | null,
        long: number| null
    }
};

export interface ComplexDataItem {
    companyName: string;
    complexId: string;
    buildingId: string;
    buildingName: string;
    unit: string;
    status: string;
};

export interface CooAccountDataItem {
    id: string;
    attributes: {
        id: number;
        full_name: string;
        email: string;
        assigend_complexes: number;
        assigend_buildings: number;
        assigend_owners: number;
        assigend_property_developers: number;
    }
};

export type TFunction = (value: string) => string;

export interface APIPayloadType {
    method: string;
    contentType?: string;
    body?: object;
    endPoint: string;
    baseURL?: string;
    type?: string;
}

export interface CountryListResponse {
    data: {
        countries: Array<string>;
    }
}

export interface CityListResponse {
    data: {
        cities: Array<string>;
    }
}

export interface CompanyListSuccessResponse {
    data: Array<CompanyListItem>;
}

export interface CompanyListItem {
    id: string;
    attributes: {
        name: string;
    }
}

export interface ApiFailureErrorResponse {
    errors: Array<ApiFailureResponseItemError>;
};

export interface ApiFailureResponseItemError {
    contract: string;
};

export interface SalesManagerProfileDetailsResponse {
    data: {
        id: string;
        attributes: {
            id: number;
            full_name: {
                name: string | null;
                publilc_access: boolean;
            },
            bio: {
                bio: string | null;
                publilc_access: boolean;
            },
            full_phone_number: {
                country_code: string | null;
                phone_number: string | null;
                full_phone_number: string | null;
                public_access: boolean;
            },
            email: {
                email: string | null;
                publilc_access: boolean;
            },
            gender: {
                gender: number;
                publilc_access: boolean;
            },
            date_of_birth: {
                date_of_birth: string | null;
                publilc_access: boolean;
            },
            hobbies: {
                hobbies: [],
                public_access: boolean;
            },
            website: [
                {
                    twitter_link: string | null;
                    public_access: boolean;
                },
                {
                    instagram_link: string | null;
                    public_access: boolean;
                },
                {
                    fb_link: string | null;
                    public_access: boolean;
                },
                {
                    snapchat_link: string | null;
                    public_access: boolean;
                }
            ],
            profile_pic: {
                url: string | null;
                content_type: string | null;
                file_name: string | null;
            }
        }
    }
}

export interface I18nInterface {
    language: string;
}

export interface ComplexListResponse {
    data: Array<ComplexListItem>;
}

export interface ComplexListItem {
    id: string;
    attributes: {
        name: string;
    }
}

export interface SalesManagerSuccessResponse {
    sales_manager_listing: Array<SalesManagerItem>
};

export interface SalesManagerItem {
    id: number;
    full_name: string;
};

export interface IndividualBuildingListSuccessResponse {
    data: Array<TotalIndividualType>;
    meta: {
        pagination: PaginationDataInterface | null;
    }
}

export interface TotalIndividualType {
    id: string;
    type: string;
    attributes: {
        id: number;
        building_name: string;
        account_manager: null;
        no_of_floor: number
        totle_unit: number;
        subscription_ends: string;
        country: string;
        city: string;
    }
}

export interface PaginationDataInterface {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export interface ComplexListSuccessResponse {
    data: Array<ComplexListItem>;
}

export interface ComplexListItem {
    id: string;
    type: string;
    attributes: {
        name: string;
    }
}

export interface BuildingListResponse {
    data: {
        buildings: Array<BuildingListItem>;
    }
}

export interface BuildingListItem {
    id: string;
    name: string;
}

export interface RegisterOwnerResponseItem {
    id: string,
    type: string;
    attributes: {
        owner_id: number;
        owner_name: string;
        Total_units: number;
        paying_clients: string;
        managed_by: string;
        status: string;
    }
};

export interface RegisterOwnerResponse {
    data: Array<RegisterOwnerResponseItem>;
    meta: {
        pagination: PaginationDataInterface | null
    }
}

export interface PropertyManagerListItem {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        managed_units: number;
    }
}

export interface PropertyManagerListResponse {
    data: Array<PropertyManagerListItem>;
    meta: {
        pagination: PaginationDataInterface | null
    }
}

export interface ComplexTabDataResponse {
    data: Array<COOComplexDataItem>;
    meta: {
        pagination: PaginationDataInterface | null
    }
}

export interface AccounManagerTabDataResponse {
    data: Array<CooAccountDataItem>;
    meta: {
        pagination: PaginationDataInterface | null;
    }
}

export interface AccountManagerTabDataErrorResponse {
    errors: Array<string>;
}

// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SalesManagerProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    cityListApiCallId: string = "";
    companiesListApiCallId: string = "";
    salesManagerProfileDetailsApiCallId: string = "";
    individualBuildingApiCallId: string = "";
    complexListApiCallId: string = "";
    salesManagerApiCallId: string = "";
    buildingListApiCallId: string = "";
    registerOwnerApiCalId: string = "";
    propertyManagerApiCallId: string = "";
    complexTabDataApiCallId: string = "";
    accountManagerTabDataApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            company: "",
            country: "",
            city: "",
            building: "",
            complex: "",
            tabValue: 0,
            selectDetails: "",
            searchText: "",
            cooComplexPage: 0,
            companiesData: [
                {
                    companyName: "S.tech",
                    buildingId: "S159",
                    buildingName: "Building 1",
                    unit: "45",
                    status: "Rented",
                },
                {
                    companyName: "S.tech",
                    buildingId: "S159",
                    buildingName: "Building 1",
                    unit: "45",
                    status: "Occupied",
                },
            ],
            openPopOver: null,
            userRole: "",
            complexData: [
                {
                    companyName: "S.tech",
                    complexId: "SD120",
                    buildingId: "S159",
                    buildingName: "Building 1",
                    unit: "45",
                    status: "Rented",
                },
                {
                    companyName: "S.tech",
                    complexId: "SD120",
                    buildingId: "S159",
                    buildingName: "Building 1",
                    unit: "45",
                    status: "Occupied",
                },
            ],
            cooComplexData: [],
            cooAccountData: [],
            countryList: [],
            cityList: [],
            companyList: [],
            showError: false,
            showErrorMessage: "",
            salesManagerProfileDetails: {
                "data": {
                    "id": "",
                    "attributes": {
                        "id": 0,
                        "full_name": {
                            "name": "",
                            "publilc_access": false,
                        },
                        "bio": {
                            "bio": "",
                            "publilc_access": false
                        },
                        "full_phone_number": {
                            "country_code": "",
                            "phone_number": "",
                            "full_phone_number": "",
                            "public_access": false
                        },
                        "email": {
                            "email": "",
                            "publilc_access": false,
                        },
                        "gender": {
                            "gender": 0,
                            "publilc_access": false,
                        },
                        "date_of_birth": {
                            "date_of_birth": "",
                            "publilc_access": false,
                        },
                        "hobbies": {
                            "hobbies": [],
                            "public_access": false,
                        },
                        "website": [
                            {
                                "twitter_link": "",
                                "public_access": false,
                            },
                            {
                                "instagram_link": "",
                                "public_access": false,
                            },
                            {
                                "fb_link": "",
                                "public_access": false,
                            },
                            {
                                "snapchat_link": "",
                                "public_access": false,
                            }
                        ],
                        "profile_pic": {
                            "url": "",
                            "content_type": "",
                            "file_name": ""
                        }
                    }
                }
            },
            complexList: [],
            salesManagerList: [],
            individualBuildingList: [],
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0
            },
            openMenu: null,
            subscriptionEndsIn: "",
            salesManager: "",
            salesManagerId: "",
            companyId: "",
            complexId: "",
            accountManagerId: "",
            loader: false,
            openBuildingModal: false,
            latitude: 0,
            longitude: 0,
            buildingList: [],
            buildingId: "",
            sortBy: "",
            unit: "",
            ownerStatus: "",
            payingClient: "",
            cooPage: 1,
            salesPage: 1,
            accountPage: 1,
            popoverAncher: null,
            selectedItem: null,
            registeredOwner: [],
            page: 0,
            propertyManagerList: [],
            propertyManager: "",
            propertyManagerId: "",
            openReassignModal: false,
            anchorEl: null,
            accountManagerAssign: "",
            modalBuildingId: "",
            noteText: "",
            salesManagerAssign: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.salesManagerApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.salesManagerApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getUserType();
        this.getCountryList();
        this.getCompanyList();
        this.getSalesManagerProfileDetails();
        this.getSalesManagerListing();
        this.getComplexTabData("1");
    };

    salesManagerProfileApiCall = async (apiPayloadType: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = apiPayloadType;
        const header = {
            "Content-Type": contentType,
            token: token
        };
        let contractDetailsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
       
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
    };

    salesManagerApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: CountryListResponse & CityListResponse & CompanyListSuccessResponse & SalesManagerProfileDetailsResponse & IndividualBuildingListSuccessResponse & ComplexListSuccessResponse & SalesManagerSuccessResponse & BuildingListResponse & RegisterOwnerResponse & PropertyManagerListResponse & ComplexTabDataResponse & AccounManagerTabDataResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerProfileDetailsApiCallId) {
            this.salesManagerProfileDetailsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.individualBuildingApiCallId) {
            this.individualBuildingListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerApiCallId) {
            this.salesManagerListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.registerOwnerApiCalId) {
            this.registeredOwnerSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.propertyManagerApiCallId) {
            this.propertyManagerSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexTabDataApiCallId) {
            this.complexTabDataSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerTabDataApiCallId) {
            this.accountManagerTabDataSucessCallBack(responseJson);
        }
    };

    salesManagerApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureErrorResponse & AccountManagerTabDataErrorResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerProfileDetailsApiCallId) {
            this.salesManagerProfileDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.individualBuildingApiCallId) {
            this.individualBuildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerApiCallId) {
            this.salesManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.buildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.registerOwnerApiCalId) {
            this.registeredOwnerFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.propertyManagerApiCallId) {
            this.propertyManagerFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexTabDataApiCallId) {
            this.complexTabDataFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerTabDataApiCallId) {
            this.accountManagerTabDataFailureCallBack(responseJson);
        }
    };

    getCountryList = async () => {
        this.countryListApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint,
        });
    };

    countryListSucessCallBack = (response: CountryListResponse) => {
        this.setState({countryList:response.data.countries});
    };

    countryListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityListApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndPoint}${this.state.country}`
        });
    };

    cityListSucessCallBack = (response: CityListResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCompanyList = async () => {
        this.companiesListApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.companyListEndpoint,
        });
    };

    companyListSucessCallBack = (response: CompanyListSuccessResponse) => {
        this.setState({ companyList: response.data });
    };

    companyListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    }

    getSalesManagerProfileDetails = async () => {
        this.salesManagerProfileDetailsApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.salesManagerProfileDetailsEndpoint,
        });
    };

    salesManagerProfileDetailsSucessCallBack = (response: SalesManagerProfileDetailsResponse) => {
        this.setState({ salesManagerProfileDetails: response });
    };

    salesManagerProfileDetailsFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    }

    getIndividualBuildingListing = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.individualBuilidingListEndpoint}page=${page}`;
        let { country, city, companyId, complexId, salesManagerId, subscriptionEndsIn, searchText } = this.state;
        if (country) {
            endPoint += `&country=${country}`;
        }
        if (city) {
            endPoint += `&city=${city}`;
        }
        if (salesManagerId) {
            endPoint += `&sales_manager=${salesManagerId}`;
        }
        if (companyId) {
            endPoint += `&company=${companyId}`;
        }
        if (complexId) {
            endPoint += `&complex=${complexId}`;
        }
        if (searchText) {
            endPoint += `&search_term=${searchText}`;
        }
        if (subscriptionEndsIn) {
            endPoint += `&subscription_ends_in=${subscriptionEndsIn}`;
        }
        this.individualBuildingApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint,
        });
    };

    individualBuildingListSucessCallBack = (response: IndividualBuildingListSuccessResponse) => {
        this.setState({ individualBuildingList: response.data, paginationData: response.meta.pagination, loader: false });
    };

    individualBuildingListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getComplexListing = async () => {
        this.complexListApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.complexListEndpoint}city=${this.state.city}&is_building=true`,
        });
    };

    complexListSucessCallBack = (response: ComplexListSuccessResponse) => {
        this.setState({ complexList: response.data });
    };

    complexListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getSalesManagerListing = async () => {
        this.salesManagerApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.salesManagerEndPoint,
        });
    };

    salesManagerListSuccessCallBack = (response: SalesManagerSuccessResponse) => {
        this.setState({ salesManagerList: response.sales_manager_listing })
    };

    salesManagerListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getBuildingListing = async () => {
        this.buildingListApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.buidlingListEndPoint}${this.state.complexId}`,
        });
    };

    buildingListSuccessCallBack = (response: BuildingListResponse) => {
        this.setState({ buildingList: response.data.buildings })
    };

    buildingListFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getRegisteredOwner = async (page: string) => {
        this.setState({loader:true});
        let endPoint = `${configJSON.registeredOwnerDataEndPoint}page=${page}`;
        let { ownerStatus, payingClient, sortBy, searchText } = this.state;
        if (ownerStatus) {
            endPoint = endPoint + `&owner=${ownerStatus}`
        }
        if (payingClient) {
            endPoint = endPoint + `&paying_clients=${payingClient}`
        }
        if (sortBy) {
            endPoint = endPoint + `&sort_by=${sortBy}`
        }
        if (searchText) {
            endPoint = endPoint + `&q=${searchText}`
        }
        this.registerOwnerApiCalId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint,
        });
    };

    registeredOwnerSucessCallBack = (response: RegisterOwnerResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ registeredOwner: response.data, paginationData: response.meta.pagination, loader: false })
        } else {
            this.setState({
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0
                }
            });
        }
    };

    registeredOwnerFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getPropertyManagerList = async (page: string) => {
        let endPoint = `${configJSON.propertyManagerListEndpoint}?page=${page}`;
        let { country, city, propertyManagerId, searchText, sortBy } = this.state;
        if (country) {
            endPoint = endPoint + `&country=${country}`
        }
        if (city) {
            endPoint = endPoint + `&city=${city}`
        }
        if (propertyManagerId) {
            endPoint = endPoint + `&p_id=${propertyManagerId}`
        }
        if (searchText) {
            endPoint = endPoint + `&search_term=${searchText}`
        }
        if (sortBy) {
            endPoint = endPoint + `&sort_by=${sortBy}`
        }

        this.propertyManagerApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    propertyManagerSucessCallBack = (response: PropertyManagerListResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ propertyManagerList: response.data, paginationData: response.meta.pagination });
        } else {
            this.setState({
                propertyManagerList: [],
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0
                },
            });
        }
    };

    propertyManagerFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getComplexTabData = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.complexTabEndpoint}?page=${page}`;
        let { country, city, searchText, sortBy, companyId } = this.state;
        if (country) {
            endPoint = endPoint + `&country=${country}`
        }
        if (city) {
            endPoint = endPoint + `&city=${city}`
        }
        if (companyId) {
            endPoint = endPoint + `&company=${companyId}`
        }
        if (searchText) {
            endPoint = endPoint + `&search_term=${searchText}`
        }
        this.complexTabDataApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    complexTabDataSucessCallBack = (response: ComplexTabDataResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ cooComplexData: response.data, paginationData: response.meta.pagination, loader: false });
        } else {
            this.setState({
                propertyManagerList: [],
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0
                },
                loader: false
            });
        }
    };

    complexTabDataFailureCallBack = (response: ApiFailureErrorResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getAccountManagerTabData = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.accountManagerTabEndpoint}?page=${page}`;
        let { country, city, searchText, sortBy, companyId } = this.state;
        if (country) {
            endPoint = endPoint + `&country=${country}`
        }
        if (city) {
            endPoint = endPoint + `&city=${city}`
        }
        if (companyId) {
            endPoint = endPoint + `&company=${companyId}`
        }
        if (searchText) {
            endPoint = endPoint + `&search_term=${searchText}`
        }
        if (sortBy) {
            endPoint = endPoint + `&sort_by=${sortBy}`
        }

        this.accountManagerTabDataApiCallId = await this.salesManagerProfileApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    accountManagerTabDataSucessCallBack = (response: AccounManagerTabDataResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ cooAccountData: response.data, paginationData: response.meta.pagination, loader: false });
        } else {
            this.setState({
                propertyManagerList: [],
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0
                },
                loader: false
            });
        }
    };

    accountManagerTabDataFailureCallBack = (response: AccountManagerTabDataErrorResponse) => {            
        this.setState({ showError: true, showErrorMessage: response.errors[0], loader: false });
    };

    getUserType = async () => {
        const storedRole = await localStorage.getItem('role');
        this.setState({ userRole: storedRole });
    };

    handleUserType = (userType: string) => {
        if (this.state.userRole === userType) {
            return true
        } else {
            return false
        }
    };

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            tabValue: newValue,
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0
            },
            country:"",
            city:"",
            cityList:[],
            salesManager:"",
            company:"",
            propertyManager:"",
            propertyManagerId:"",
            ownerStatus:"",
            payingClient:"",
            complex:"",
            subscriptionEndsIn:"",
        }, () => {
            if (this.state.tabValue === 0 && this.state.userRole === "coo") {
                this.getComplexTabData("1");
            }
            if (this.state.tabValue === 1 && this.state.userRole === "coo") {
                this.getIndividualBuildingListing("1");
            }
            if (this.state.tabValue === 2 && this.state.userRole === "coo") {
                this.getRegisteredOwner("1");
            }
            if (this.state.tabValue === 3 && this.state.userRole === "coo") {
                this.getPropertyManagerList("1");
            }
            if (this.state.tabValue === 4 && this.state.userRole === "coo") {
                this.getAccountManagerTabData("1");
            }

        });
    };

    handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openPopOver: event.currentTarget });
    };

    handleClosePopover = () => {
        this.setState({ openPopOver: null });
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string },()=>{
            this.getCityList();
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string }, () => {
            this.getComplexListing();
        });
    };

    handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let building = this.state.buildingList.find((building) => { return building.name === event.target.value });
        if(building){
            this.setState({ building: event.target.value as string, buildingId: building.id });
        }
    };

    handleComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let complex = this.state.complexList.find((complex) => { return complex.attributes.name === event.target.value });
        if (complex) {
            this.setState({ complex: event.target.value as string, complexId: complex.id },()=>{
                this.getBuildingListing();
            });
        }
    };

    handleCompanyhange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let company = this.state.companyList.find((company) => { return company.attributes.name === event.target.value })
        if(company){
            this.setState({ company: event.target.value as string, companyId: company.id });
        }
    };

    handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value });
    };

    handleSearchCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value },()=>{
            this.getIndividualBuildingListing("1");
        });
    };

    handleSalesManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
        let salesManager = this.state.salesManagerList.find((salesManager) => { return salesManager.full_name === event.target.value });
        if (salesManager) {
            this.setState({ salesManager: event.target.value as string, salesManagerId: String(salesManager.id) });
        }
    };

    handleSubscriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
        this.setState({ subscriptionEndsIn: event.target.value as string });
    };

    handleCooComplexPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getComplexTabData(String(value));
    };

    handlePagination=(event: React.ChangeEvent<unknown>, value: number)=>{
        this.getIndividualBuildingListing(String(value));
    };

    handleOwnerStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ ownerStatus: event.target.value as string });
    };

    handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string }, () => {
            this.getRegisteredOwner("1");
        });
    };

    handlePayingClientsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ payingClient: event.target.value as string });
    };

    handleSearchInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ searchText: event.target.value as string },()=>{
            this.getRegisteredOwner("1");
        });
    };

    handleCooPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getRegisteredOwner(String(value));
    };

    handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
        this.setState({ popoverAncher: event.currentTarget, selectedItem: item })
    };

    handlePropertyManagerIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let propertyManager = this.state.propertyManagerList.find((propertyManager) => { return propertyManager.id === event.target.value });
        if(propertyManager){
            this.setState({ propertyManagerId: event.target.value as string, propertyManager: event.target.value as string });
        }
    };

    handleCooAccountPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getAccountManagerTabData(String(value))
    };

    handleErrorAlert = () => {
        this.setState({ showError: false });
    };

    handleCloseMenu = () => {
        this.setState({ openMenu: null });
    };

    handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openMenu: event.currentTarget });
    };

    handleCloseViewBuilding = () => {
        this.setState({ openBuildingModal: false })
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleNullValue = (value: string | null | number) => {
        return value === null ? "-" : value;
    };

    handleSalesManager = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ salesManagerAssign: event.target.value as string });
    };

    handleAccountManager = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ accountManagerAssign: event.target.value as string });
    };

    handleNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ noteText: event.target.value });
    };

    handleCloseReassignModal = () => {
        this.setState({ openReassignModal: false });
    };

    handleOpenReassignModal = () => {
        this.setState({ openReassignModal: true, openMenu: null });
    };
    handleMapvalues=(cooComplex: COOComplexDataItem)=>{
        this.setState({
            latitude:cooComplex&&cooComplex.attributes&&cooComplex.attributes.lat,
            longitude:cooComplex&&cooComplex.attributes&&cooComplex.attributes.long,
        })
    }
    handleOpenmapModal=()=>{
        this.setState({
            openBuildingModal: !this.state.openBuildingModal,
            openPopOver:null
        })
    }
    // Customizable Area End
}
