import React from "react";
// Customizable Area Start
import MainScreen from "./Company Employee/MainScreen.web";
import { Box, styled, Typography, Select, MenuItem, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Popover, Chip, Input, Modal, Divider, Breadcrumbs, Grid, InputLabel, TextField, TextareaAutosize, FormControl } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { TFunction, CompanyItem, SalesManagerItem, AccountMangerListItem, TotalComplexListItem, I18nInterface, ComplexListItem, SalesManagerListItem, AccountManagerListItem } from './TotalComplexController.web';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { SearchOutlined } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import AlertError from "../../../components/src/AlertError.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SuccessAlert from "../../../components/src/AlertSuccess.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";

// Customizable Area End

import TotalComplexController, { Props, } from "./TotalComplexController.web";

class TotalComplex extends TotalComplexController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoginModal = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <LoginModalStyle>
                <Box className="notificationHeading">
                    <Typography className="villacomplex" data-testId={"loginModalHeadingTestId"}>{t("Villa Complex")}</Typography>
                    <IconButton onClick={this.handlecloseLoginModal} data-testId={"loginModalCloseIconTestId"}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="searchLoginStyle">
                    <Input
                        placeholder={t("Email")}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        data-testId={"emailChangeTestId"}
                        disableUnderline
                        name="search"
                        startAdornment={<MailOutlineIcon className="inputStartAdorment" />}
                        className="searchInputStyle"
                        fullWidth
                    />
                    <Input
                        placeholder={t("Password")}
                        disableUnderline
                        data-testId={"passwordChangeTestId"}
                        name="search"
                        type={this.state.showPassword ? "text" : "password"}
                        startAdornment={<LockOutlinedIcon className="inputStartAdorment" />}
                        endAdornment={
                            this.state.showPassword ? (
                                <IconButton onClick={this.handlePassWordShow} data-testId={"hidePasswordTestId"}>
                                    <VisibilityOffIcon className="passWordAdormnet" />
                                </IconButton>
                            ) : (
                                <IconButton className="passWordAdormnet" data-testId={"showPasswordTestId"} onClick={this.handlePassWordShow}>
                                    <VisibilityIcon />
                                </IconButton>
                            )
                        }
                        onChange={this.handlePasswordText}
                        value={this.state.loginTextPassword}
                        className="searchInputStyle"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        className="modalLoginButton"
                    >
                        {t("Login")}
                    </Button>
                </Box>
            </LoginModalStyle>
        );
    };
    renderMapmodal=()=>{
        return(
            <MapDialog
            t={this.props.t}
            IsOpen={this.state.openBuildingModal}
            CloseDialog={this.handleCloseMap}
            lat={this.state.latitude}
            long={this.state.longitude}
            data-testId={"viewBuildingModalTestId"}
        />
        )
    }

    tabelOfAccountManagerComplexex = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table dir={languageCondition(language, "rtl", "ltr")}>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableHeaderStyle" align="left">{t("#")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Complex")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Company")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t('Chairman')}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Building")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Unit")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Subscrption End In")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Country")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("City")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.totalComplexList.map((totalComplex: TotalComplexListItem, totalComplexIndex: number) => (
                                <TableRow key={totalComplexIndex}>
                                    <TableCell>{totalComplexIndex + 1}</TableCell>
                                    <TableCell className={`totalComplex${totalComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_building)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_unit)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.subscription_ends)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.country)}</TableCell>
                                    <TableCell data-testId={`totalComplexCity${totalComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.city)}</TableCell>
                                    <TableCell>
                                        <IconButton data-testId={'tableMenuBtnTestId'} onClick={this.handleClick}>
                                            <MoreVertIcon
                                                fontSize="small"
                                                className="threeDotsStyle"
                                                data-testId={"handleMapvalueaAcc"}
                                                onClick={() =>{this.handleMapvalues(totalComplex)}}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box dir={languageCondition(language, "rtl", "ltr")} className="paginationStyle">
                    <PaginationModule handlePagination={this.handlePagination} pagination={this.state.paginationData} page={this.state.paginationData?.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>
                <Popover
                    onClose={this.handleClose}
                    anchorEl={anchorEl}
                    open={open}
                    id={popoverId}
                    data-testId={"popoverTestId"}
                    PaperProps={{
                        style: {
                            marginLeft: "20px",
                        },
                    }}
                    transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                    }}
                    anchorOrigin={{
                        horizontal: "left",
                        vertical: "bottom",
                    }}
                >
                    <MenuItem data-testId={"tableMenuMenuItem1TestId"}
                       onClick={this.handleOpenmapModal}
                    >
                        {t("View Location on Map")}</MenuItem>
                    <MenuStyle>
                        <Typography onClick={this.onHandleLoginModal} data-testId={"loginBtnTestId"}>{t("Login")}</Typography>
                        <Chip label="Action Required" className="chipStyle" />
                    </MenuStyle>
                </Popover>
                <Modal
                    open={this.state.loginModal}
                    onClose={this.handleLoginModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-testId={"loginModalTestId"}
                >
                    {this.renderLoginModal()}
                </Modal>
            </>
        );
    };

    accountManagerComplex = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                <AccountManagerComplexStyle>
                    <Box>
                        <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="mangingDashboard" onClick={() => this.handleNavigation("dashboard")} data-testId={"myDashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("My Dashboards")}</Typography>
                            <Typography onClick={() => this.handleNavigation("dashboard")} data-testId={"totalComplexHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                            <Typography className="assignedAccount">{t("Total Complexes")}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box className="selectComplexes" dir={languageCondition(language, "rtl", "ltr")}>
                    <Select
                            value={this.state.country}
                            onChange={this.handlecountryChange}
                            data-testId={"countrySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return <MenuItem className="defaultSelect" key={countryIndex} value={country} data-testId={`countryItem${countryIndex}`}>{country}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            data-testId={"citySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.company}
                            onChange={this.handleCompanyChange}
                            data-testId={"companySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((company: CompanyItem, companyIndex: number) => {
                                return <MenuItem value={company.attributes.company_name} key={companyIndex} className="defaultSelect" data-testId={`companyListItem${companyIndex}`}>{company.attributes.company_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.complex}
                            data-testId={"complexSelectTestId"}
                            onChange={this.handleComplexChange}
                            labelId="demo-simple-select-label"
                            className="selectManger"
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            id="demo-simple-select"
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={this.handleNullValues(complex.attributes.name)} key={complexIndex} data-testId={`complexListItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubcriptionChange}
                            data-testId={"subscriptionEndsInSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("Subscription End In")}
                            </MenuItem>
                            {this.renderMenu(t)}
                        </Select>
                       
                        <Button
                            variant="contained"
                            startIcon={<SearchOutlined />}
                            className="searchButton"
                            dir={languageCondition(language, "rtl", "ltr")}
                            onClick={() => this.getTotalComplexList("1")}
                            data-testId={"searchBtnTestId"}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Paper className="paperStyle">
                        <Box className="managerStyle" dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of Manage Complex")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                <Input
                                    placeholder={t("Search By Complex Name")}
                                    disableUnderline
                                    name="search"
                                    startAdornment={<SearchOutlined />}
                                    className="searchInputStyle"
                                    onChange={this.handleSearchInput}
                                    value={this.state.searchText}
                                    data-testId={"searchInputTestId"}
                                    dir={languageCondition(language, "rtl", "ltr")}
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tabelOfAccountManagerComplexex()}
                    </Paper>
                </AccountManagerComplexStyle>
            </>
        );
    };
    renderMenu=(t:any)=>{
        return(
            <>
            <MenuItem value="30">{t("Next 30 days")}</MenuItem>
            <MenuItem value="60">{t("Next 60 days")}</MenuItem>
            <MenuItem value="90">{t("Next 90 days")}</MenuItem>
            <MenuItem value="180">{t("Next 6 months")}</MenuItem>
            <MenuItem value="365">{t("Next 1 year")}</MenuItem>
            </>
        )
    }

    totalComplexRender = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                <TotalComplexStyle>
                    <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("My Dashboards")}</Typography>
                        <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"totalComplexHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                        <Typography className="assignedAccount">{t("Total Complexes")}</Typography>
                    </Breadcrumbs>
                    <Typography className="headingStyle" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Complexes")}</Typography>
                    <Box className="selectComplexes" dir={languageCondition(language, "rtl", "ltr")}>
                    <Select
                            value={this.state.country}
                            onChange={this.handlecountryChange}
                            data-testId={"countrySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("Select Country")}
                            </MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return <MenuItem value={country} className="defaultSelect" key={countryIndex} data-testId={`countryItem${countryIndex}`}>{country}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.city}
                            onChange={this.handleCityChange}
                            data-testId={"citySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("Select City")}
                            </MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.company}
                            onChange={this.handleCompanyChange}
                            data-testId={"companySelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((companyItem: CompanyItem, companyIndex: number) => {
                                return <MenuItem key={companyIndex} value={companyItem.attributes.company_name} className="defaultSelect" data-testId={`companyListItem${companyIndex}`}>{companyItem.attributes.company_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleComplexChange}
                            value={this.state.complex}
                            className="selectManger"
                            data-testId={"complexSelectTestId"}
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={this.handleNullValues(complex.attributes.name)} key={complexIndex}>{complex.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.salesManager}
                            onChange={this.handleSalesManagerChange}
                            data-testId={"salesManagerSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Sales Manager")}</MenuItem>
                            {this.state.salesManagerList.map((salesManager: SalesManagerItem, salesManagerIndex: number) => {
                                return <MenuItem value={salesManager.full_name} className="defaultSelect" key={salesManagerIndex} data-testId={`salesManagerItemtestId${salesManagerIndex}`}>{salesManager.full_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubcriptionChange}
                            data-testId={"subscriptionEndsInSelectTestId"}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscription End In")}</MenuItem>
                          
                            {this.renderMenu(t)}
                        </Select>
                        <Button
                            variant="contained"
                            startIcon={<SearchOutlined />}
                            className="searchButton"
                            dir={languageCondition(language, "rtl", "ltr")}
                            onClick={()=>{this.getTotalComplexList("1")}}
                            data-testId={"searchBtnTestId"}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Paper className="paperStyle">
                        <Box className="managerStyle">
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of Manage Complex")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                <Input
                                  name="search"
                                  startAdornment={<SearchOutlined className="searchIcon" />}
                                  className="searchInputStyle"
                                  dir={languageCondition(language, "rtl", "ltr")}
                                  data-testId={"searchInputTestIdCoo"}
                                    placeholder={t("Search By Complex Name")}
                                    value={this.state.searchText}
                                    onChange={this.handleSearchInput}
                                    disableUnderline
                                  
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tableOfComplexRender()}
                    </Paper>
                </TotalComplexStyle>
            </>
        );
    };

    tableOfComplexRender = () => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const currentPage=this.state.paginationData?.current_page as number
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tableHeaderStyle">
                                    #
                                </TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Complex")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Company")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Account Manager")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Building")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Total Unit")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Subscrption End In")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("Country")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left">{t("City")}</TableCell>
                                <TableCell className="tableHeaderStyle" align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.totalComplexList.map((totalComplex: TotalComplexListItem, totaComplexIndex: number) => (
                                <TableRow key={totaComplexIndex}>
                                    <TableCell>{(currentPage - 1) * 10 + totaComplexIndex + 1}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell>{this.handleCompanyNameValue(totalComplex.attributes.company_name)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.account_manager)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_building)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.totle_unit)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.subscription_ends)}</TableCell>
                                    <TableCell>{this.handleNullValues(totalComplex.attributes.country)}</TableCell>
                                    <TableCell data-testId={`totalComplexCity${totaComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.city)}</TableCell>
                                    <TableCell>
                                        <IconButton data-testId={"tableMenuBtnTestId"} onClick={this.handleClick}>
                                            <MoreVertIcon
                                                fontSize="small"
                                                className="threeDotsStyle"
                                                data-testId={"handleMapvalueaCoo"}
                                                onClick={() =>{this.handleMapvalues(totalComplex)}}
                                            />
                                                </IconButton>
                                        <Popover
                                            onClose={this.handleClose}
                                            open={open}
                                            anchorEl={anchorEl}
                                            id={popoverId}
                                            data-testId={"popoverTestId"}
                                            anchorOrigin={{
                                                horizontal: "left",
                                                vertical: "bottom",
                                            }}
                                            PaperProps={{
                                                style: {
                                                    marginLeft: "20px",
                                                },
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            dir={languageCondition(language, "rtl", "ltr")}
                                        >
                                            <MenuItem data-testId={"tableMenuMenuItem1TestId"}
                                                dir={languageCondition(language, "rtl", "ltr")}
                                                onClick={this.handleOpenmapModal}
                                            >{t("View Location on Map")}</MenuItem>
                                            <MenuItem onClick={() => this.handleOpenReassignModal(totalComplex.id)} data-testId={`reassignButton${totaComplexIndex}`}>{t("Reassign")}</MenuItem>
                                        </Popover>
                                       
                                    </TableCell>
                                </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <PaginationModule handlePagination={this.handlePagination} pagination={this.state.paginationData} page={this.state.paginationData?.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>
                <Modal
                    open={this.state.loginModal}
                    onClose={this.handleLoginModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {this.renderLoginModal()}
                </Modal>
            </>
        );
    };

    managedComplexesRender = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                 <MangedComplexStyle dir={languageCondition(language, "rtl", "ltr")}>
                    <Box dir={languageCondition(language, "rtl", "ltr")}>
                        <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="mangingDashboard" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("My Dashboards")}</Typography>
                            <Typography onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"totalComplexHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("General Dashboard")}</Typography>
                            <Typography className="assignedAccount" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Complexes")}</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box className="selectComplexes" dir={languageCondition(language, "rtl", "ltr")}>
                        <Select
                            value={this.state.country}
                            onChange={this.handlecountryChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"countrySelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((countryItem: string, countryIndex: number) => {
                                return <MenuItem value={countryItem} className="defaultSelect" key={countryIndex} data-testId={`countryItem${countryIndex}`}>{countryItem}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            labelId="demo-simple-select-label"
                            className="selectManger"
                            displayEmpty
                            IconComponent={KeyboardArrowDownIcon}
                            id="demo-simple-select"
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"citySelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.company}
                            onChange={this.handleCompanyChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"companySelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((companyItemData: CompanyItem, companyIndex: number) => {
                                return <MenuItem value={companyItemData.attributes.company_name} key={companyIndex} className="defaultSelect" data-testId={`companyListItem${companyIndex}`}>{companyItemData.attributes.company_name}</MenuItem>
                            })}
                        </Select>

                        <Select
                            value={this.state.complex}
                            onChange={this.handleComplexChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"complexSelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={this.handleNullValues(complex.attributes.name)} key={complexIndex} data-testId={`complexListItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.accountManager}
                            onChange={this.handleAccountManagerChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"accountManagerSelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</MenuItem>
                            {this.state.accountManagerList.map((accountManager: AccountMangerListItem, accountManagerIndex: number) => {
                                return <MenuItem key={accountManagerIndex} value={accountManager.full_name} className="defaultSelect" data-testId={`accountManagerListItem${accountManagerIndex}`}>{accountManager.full_name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubcriptionChange}
                            className="selectManger"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            IconComponent={KeyboardArrowDownIcon}
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" },
                            }}
                            data-testId={"subscriptionEndsInSelectTestId"}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscription End In")}</MenuItem>
                            {this.renderMenu(t)}
                           
                        </Select>
                        <Button
                            variant="contained"
                            startIcon={<SearchOutlined />}
                            className="searchButton"
                            dir={languageCondition(language, "rtl", "ltr")}
                            onClick={()=>this.getTotalComplexList("1")}
                            data-testId={"searchBtnTestId"}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Paper className="paperStyle" dir={languageCondition(language, "rtl", "ltr")}>
                        <Box className="managerStyle" dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of Manage Complex")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                <Input
                                    placeholder={t("Search By Complex Name")}
                                    disableUnderline
                                    name="search"
                                    value={this.state.searchText}
                                    data-testId={"searchInputTestIdsales"}
                                    dir={languageCondition(language, "rtl", "ltr")}
                                    startAdornment={<SearchOutlined />}
                                    className="searchInputStyle"
                                    onChange={this.handleSearchInput}
                                   
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tableOfmangedComplexes()}
                    </Paper>
                </MangedComplexStyle>
            </>
        );
    };

    tableOfmangedComplexes = () => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const currentPage=this.state.paginationData?.current_page as number
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table dir={languageCondition(language, "rtl", "ltr")}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tableHeaderStyle">#</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Complex")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Company")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Chairman")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Account Manager")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Total Building")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Total Unit")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Subscrption End In")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("Country")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle">{t("City")}</TableCell>
                                <TableCell align="left" className="tableHeaderStyle"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.totalComplexList.map((totalComplex: TotalComplexListItem, totalComplexIndex: number) => (
                                <TableRow key={totalComplexIndex}>
                                    <TableCell align="left">{(currentPage - 1) * 10 + totalComplexIndex + 1}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.building_name)}</TableCell>
                                    <TableCell align="left">{this.handleCompanyNameValue(totalComplex.attributes.company_name)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.account_manager)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.totle_building)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.totle_unit)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.subscription_ends)}</TableCell>
                                    <TableCell align="left">{this.handleNullValues(totalComplex.attributes.country)}</TableCell>
                                    <TableCell align="left" data-testId={`totalComplexCity${totalComplexIndex}`}>{this.handleNullValues(totalComplex.attributes.city)}</TableCell>
                                    <TableCell>
                                        <IconButton data-testId={"tableMenuBtnTestId"} onClick={this.handleClick}>
                                            <MoreVertIcon
                                                fontSize="small"
                                                className="threeDotsStyle"
                                                data-testId={"handleMapvalueasales"}
                                                onClick={() =>{this.handleMapvalues(totalComplex)}}
                                            />
                                        </IconButton>
                                        <Popover
                                            anchorEl={anchorEl}
                                            open={open}
                                            id={popoverId}
                                            data-testId={"popoverTestId"}
                                            onClose={this.handleClose}
                                            PaperProps={{
                                                style: {
                                                    marginLeft: "20px",
                                                },
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                        >
                                            <MenuItem data-testId={"tableMenuMenuItem1TestId"}
                                                dir={languageCondition(language, "rtl", "ltr")}
                                                onClick={this.handleOpenmapModal}
                                            >{t("View Location on Map")}</MenuItem>
                                            <MenuItem onClick={() => this.handleOpenReassignModal(totalComplex.id)} data-testId={`reassignButton${totalComplexIndex}`}>{t("Reassign")}</MenuItem>
                                            <MenuStyle dir={languageCondition(language, "rtl", "ltr")}>
                                                <Typography onClick={this.onHandleLoginModal} data-testId={"loginBtnTestId"}>{t("Login")}</Typography>
                                                <Chip label={t("Action Required")}className="chipStyle" />
                                            </MenuStyle>
                                        </Popover>
                                    </TableCell>
                                </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box dir={languageCondition(language, "rtl", "ltr")} className="paginationStyle">
                    <PaginationModule page={this.state.paginationData?.current_page} pagination={this.state.paginationData} handlePagination={this.handlePagination}  data-testId={"contractListPagintionTestId"} />
                </Box>               
                <Modal
                    open={this.state.loginModal}
                    onClose={this.handleLoginModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-testId={"closeLoginModalTestId"}
                >
                    {this.renderLoginModal()}
                </Modal>
            </>
        );
    };

    renderReassignForm = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box>
            <Modal open={this.state.openReassignModal} onClose={this.handleCloseReassignModal} data-testId={"reassignModal"}>
                <TotalComplexReassignForm>
                    <Paper elevation={2} className="modalMainContainer">
                    <Typography align="left" className="reassignModalHeadingMain">{t("Assign to another Manager")}</Typography>
                    <Divider />
                    <Box className="reassignForm">
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6} sm={12} md={6}>
                        <InputLabel className="modalLabelMain">{t("Building ID")}</InputLabel>
                            <TextField fullWidth variant="outlined" value={this.state.modalBuildingId} disabled className="modalInputFieldMain"/>
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6}>
                        <InputLabel className="modalLabelMain">{t("Building Name")}</InputLabel>
                            <TextField fullWidth variant="outlined" value={this.state.modalBuildingName} disabled className="modalInputFieldMain"/>
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6}>
                        <InputLabel className="modalLabelMain">{t("Sales Manager")}</InputLabel>
                            <TextField fullWidth variant="outlined" value={this.state.modalCurrentSalesManager} disabled className="modalInputFieldMain"/>
                        </Grid>
                        <Grid item xs={12} lg={6} sm={12} md={6}>
                        <InputLabel className="modalLabelMain">{t("Current Account Manager")}</InputLabel>
                                <TextField fullWidth variant="outlined" value={this.state.modalCurrentAccountManager} disabled className="modalInputFieldMain" />
                            </Grid>
                            {
                                    this.state.userRole === "coo" &&
                                    <Grid item xs={12} lg={12} sm={12} md={12}>
                                        <InputLabel className="modalLabelMain" id="demo-simple-select-filled">{t("New Sales Manager")}</InputLabel>
                                        <FormControl fullWidth variant="outlined" className="selectAccountManager">
                                            <InputLabel>{t("Select Option")}</InputLabel>
                                            <Select
                                                onChange={this.handleSalesManagerListChange}
                                                value={this.state.salesManagerAssign}
                                                data-testId={"reassignSalesManagerChangeTestId"}
                                                IconComponent={ExpandMoreIcon}
                                            >
                                                <MenuItem value="">{t("Select option")}</MenuItem>
                                                {this.state.newSalesManagerList.map((sales: SalesManagerListItem,salesIndex: number)=>{
                                                    return <MenuItem value={sales.full_name} key={salesIndex}>{t(sales.full_name)}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                <InputLabel className="modalLabelMain">{t("New Account Manager")}</InputLabel>
                                <FormControl fullWidth variant="outlined" className="selectAccountManager">
                                <InputLabel>{t("Select Option")}</InputLabel>
                                    <Select
                                        value={this.state.accountManagerAssign}
                                        onChange={this.handleAccountManagerListChange}
                                        IconComponent={ExpandMoreIcon}
                                        data-testId={"reassignAccountManagerChangeTestId"}
                                    >
                                            <MenuItem value="">{t("Select option")}</MenuItem>
                                            {this.state.newAccountManagerList.map((account: AccountManagerListItem, accountIndex: number) => {
                                                return <MenuItem value={account.first_name} key={accountIndex}>{t(account.first_name)}</MenuItem>
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className="modalLabelMain">{t("Notes")}</InputLabel>
                                <TextareaAutosize aria-label="minimum height" onChange={this.handleNoteChange} value={this.state.noteText} minRows={5}  placeholder="Notes" className="noteInputMain"  data-testId={"noteInputTestId"}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className="buttonContainer">
                                    <Button className="cancelBtnMain" variant="outlined" onClick={this.handleCloseReassignModal} data-testId={"reassignCancelBtnTestId"}>{t("Cancel")}</Button>
                                    <Button className="reassignBtn" variant="contained"data-testId={"reassignBtnTestID"} onClick={this.reassignToAnotherAccountManager}>{t("Reassign")}</Button>
                                </Box>
                            </Grid>
                    </Grid>
                    </Box>
                    </Paper>
                </TotalComplexReassignForm>
            </Modal>
        </Box>
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                 {this.renderMapmodal()}
                <AccountAssignedStyle>
                    {this.handleUserType('coo') && this.totalComplexRender()}
                    {this.handleUserType('sales_manager') && this.managedComplexesRender()}
                    {this.handleUserType('account_manager') && this.accountManagerComplex()}
                    {this.renderReassignForm()}
                </AccountAssignedStyle>
                <AlertError show={this.state.showError} handleClose={this.handleErrorAlert} message={this.state.showErrorMessage} data-testId={"errorAlertTestId"} />
                <Loader loading={this.state.loader} />
                <SuccessAlert show={this.state.showSuccess} message={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert} data-testId={"successAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(TotalComplex);
export { TotalComplex };
const AccountAssignedStyle = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "35px",
});

const AccountManagerComplexStyle = styled(Box)({
    "& .headingManger": {
        fontSize: "24px",
        fontWeight: 900,
    },
    "& .mangingDashboard": {
        fontSize: "18px",
        fontWeight: 400,
        color: "#3D424B",
        cursor: "pointer"
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .assignedAccount": {
        color: "#b3cbfa",
    },
    "& .paginationStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        marginLeft: "10px",
    },

    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },

    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#FC8E45",
            color: "white",
        },
        "& .MuiPaginationItem-outlined": {
            border: " 1px solid #EBEBEC",
            color: "grey",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .defaultSelect": {
        color: "#EBEBEC",
    },
    "& .paperStyle": {
        width: "100%",
        marginTop: "20px",
        borderRadius: "10px",
    },
    "& .managerStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
      },
    "& .selectManger": {
        background: "white",
        display: "flex",
        margin: "10px 20px 5px 3px",
        width: "min-content",
        flexWrap: "wrap",
        color: "grey",
        border: "1px solid #EBEBEC",
        borderRadius: "10px",
        height: "45px",
        padding: "0px 20px 0px 10px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {},
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
    },
    "& .searchInputStyle": {
        height: 40,
        width: "300px",
        backgroundColor: "#fafafa",
        borderRadius: "10px",
        padding: "0px 15px 0px 15px",
        border: "1px solid #EBEBEC",
    },
    "& .selectComplexes": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px",
    },
    "& .searchButton": {
        height: "38px",
        backgroundColor: "#2b70ed",
        color: "white",
        borderRaduis: "10px",
        margin: "10px 20px 5px 3px",
        textTransform: "capitalize",
    },
    "& .tableHeaderStyle": {
        fontWeight: 900,
        fontSize: "14px",
    },
});

const MenuStyle = styled(MenuItem)({
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #f1f1f1",
    alignItems: "center",
    "& .viewButton": {
        display: "flex",
        justifyContent: "space-between !important",
    },
    "& .chipStyle": {
        backgroundColor: "#fc8536",
        color: "white",
        marginLeft: "15px",
        height: "30px",
    },
});

const LoginModalStyle = styled(Box)({
    height: "340px",
    maxWidth: "400px",
    width: "calc(100% - 35px)",
    marginRight: "40px",
    backgroundColor: "white",
    overflowY: "scroll",
    position: "relative",
    padding: "20px",
    borderRaduis: "20px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "13px",
    "& .searchLoginStyle": {
        padding: "16px 24px",
    },
    "& .notificationHeading": {
        height: "70px",
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .villacomplex": {
        fontSize: "28px",
        fontWeight: "900",
    },
    "& .passWordAdormnet": {
        cursor: "pointer",
    },
    "& .searchInputStyle": {
        height: 56,
        width: "100%",
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        padding: "0px 15px 0px 15px",
        marginBottom: "20px",
        border: "1px solid #D8DADC",
        marginRight: "25px",
    },
    "& .modalLoginButton": {
        margin: "3px 0px 22px 0px",
        borderRadius: "8px",
        height: "48px",
        backgroundColor: "#2b70ed",
        color: "white",
    },
    "& .inputStartAdorment": {
        marginRight: "10px",
    },
});

const TotalComplexStyle = styled(Box)({
    "& .searchIcon": {
        color: "gray",
        margin: "0px"
    },
    "& .tableHeaderStyle": {
        fontSize: "14px",
        fontWeight: 900,
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-outlined": {
            border: " 1px solid #EBEBEC",
            color: "grey",
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#FC8E45",
            color: "white",
        },
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .paginationStyle": {
        justifyContent: "space-between",
        display: "flex",
        marginLeft: "10px",
        padding: "20px",
        alignItems: "center",
    },
    "& .paperStyle": {
        width: "100%",
        marginTop: "20px",
    },
    "& .searchInputStyle": {
        height: 40,
        width: 270,
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        padding: "0px 15px 0px 15px",
        border: "1px solid #EBEBEC",
        "& .MuiInputBase-input": {
            paddingLeft: "30px"
        }
    },
    "& .managerStyle": {
        justifyContent: "space-between",
        padding: "20px",
        display: "flex",
        alignItems: "center",
    },
    "& .selectStyles": {
        alignItems: "center",
        justifyContent: "space-Between",
        display: "flex",
    },
    "& .mangingDashboard": {
        fontWeight: 400,
        color: "#3D424B",
        fontSize: "18px",
        cursor: "pointer"
    },
    "& .dividerStyle": {
        backgroundColor: "grey",
        height: "1px",
    },
    "& .headingManger": {
        fontWeight: 600,
        fontSize: "24px",
    },
    "& .assignedAccount": {
        color: "#b3cbfa",
    },
    "& .reassignStyle": {
        borderTop: "1px solid #f1f1f1",
    },

    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },
    "& .selectComplexes": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px",
    },
    "& .selectManger": {
        display: "flex",
        flexWrap: "wrap",
        background: "white",
        width: "min-content",
        padding: "0px 20px 0px 10px",
        color: "grey",
        margin: "10px 20px 5px 3px",
        border: "1px solid #EBEBEC",
        height: "45px",
        borderRadius: "10px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {},
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
    },
    "& .searchButton": {
        backgroundColor: "#2b70ed",
        height: "38px",
        borderRaduis: "10px",
        color: "white",
        textTransform: "capitalize",
        margin: "10px 20px 5px 3px",
    },
});

const MangedComplexStyle = styled(Box)({
    "& .tableHeaderStyle": {
        fontWeight: 900,
        fontSize: "14px",
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-outlined": {
            color: "grey",
            border: "1px solid #EBEBEC",
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            color: "white",
            background: "#FC8E45",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .resultShowing": {
        fontWeight: 600,
        color: "#FC8E45",
    },
    "& .paginationStyle": {
        justifyContent: "space-between",
        display: "flex",
        marginLeft: "10px",
        padding: "20px",
        alignItems: "center",
    },
    "& .paperStyle": {
        marginTop: "20px",
        width: "100%",
    },
    "& .searchInputStyle": {
        width: "280px",
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        border: "1px solid #EBEBEC",
        height: 40,
        padding: "0px 15px 0px 15px",
    },
    "& .managerStyle": {
        justifyContent: "space-between",
        padding: "20px",
        display: "flex",
        alignItems: "center",
    },
    "& .selectStyles": {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-Between",
    },
    "& .mangingDashboard": {
        fontWeight: 400,
        color: "#3D424B",
        fontSize: "18px",
        cursor: "pointer"
    },
    "& .dividerStyle": {
        backgroundColor: "grey",
        height: "1px",
    },
    "& .headingManger": {
        fontWeight: 600,
        fontSize: "24px",
    },
    "& .reassignStyle": {
        borderTop: "1px solid #f1f1f1",
    },
    "& .assignedAccount": {
        color: "#b3cbfa",
    },

    "& .headingStyle": {
        fontWeight: "bold",
        marginTop: "10px",
        fontSize: "30px",
    },
    "& .selectComplexes": {
        flexWrap: "wrap",
        marginTop: "10px",
        display: "flex",
    },
    "& .selectManger": {
        background: "white",
        display: "flex",
        flexWrap: "wrap",
        width: "min-content",
        border: "1px solid #EBEBEC",
        color: "grey",
        height: "45px",
        padding: "0px 20px 0px 10px",
        margin: "10px 20px 5px 3px",
        borderRadius: "10px",
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {},
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
    },
    "& .searchButton": {
        borderRaduis: "10px",
        backgroundColor: "#2b70ed",
        height: "38px",
        color: "white",
        padding: "15px 25px",
        textTransform: "capitalize",
        margin: "10px 20px 5px 3px",
    },
});

const TotalComplexReassignForm = styled(Box)({
    alignItems: "center",
    display: "flex",
    height:"100vh",
    width:"50%",
    borderRadius:"20px",
    margin:"auto",
    "& .reassignModalHeadingMain":{
        fontWeight:900,
        fontSize:"25px",
        margin:"20px 0px 20px 20px",
        textAlign:"left",
    },
    "& .MuiDivider-root":{
        width:"100%"
    },
    "& .reassignForm":{
        padding:"20px"
    },
    "& .modalInputFieldMain":{
        backgroundColor:"#f0f0f0",
        borderRadius:"8px",
        border:"1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .noteInputMain":{
        width:"100%",
        borderRadius:"10px",
        padding:"10px",
        backgroundColor:"#f0f0f0",
        border:"1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .selectAccountManager":{
        backgroundColor:"#f0f0f0",
        border:"1px solid rgba(0, 0, 0, 0.12)",
        borderRadius:"10px",
    },
    "& .modalLabelMain":{
        color:"black",
        marginBottom:"10px",
    },
    "& .modalMainContainer":{
        borderRadius:"20px",
        overflow:"scroll",
        height:"95%",
    },
    "& .buttonContainer":{
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"center",
        gap:"20px",
    },
    "& .reassignBtn": {
        textTransform: "capitalize",
        borderRadius: "7px",
        padding:"9px 0px",
        backgroundColor: "#2B6FED",
        fontSize: "18px",
        width:"150px",
        fontWeight: 500,
        color: "#fff",
    },
    "& .cancelBtnMain": {
        borderRadius: "7px",
        border:"1px solid #2B6FED",
        padding:"9px 0px",
        textTransform: "capitalize",
        color: "#2B6FED",
        width:"150px",
        fontWeight: 500,
        fontSize: "18px",
    },
});
// Customizable Area End