// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation :typeof navigation;
  t:(value:string)=> string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  hasSuccessError: HasSuccessErrorType;
  templateDetails:TemplateSuccessResponseItem
  showError:boolean;
  error:string;
  templateId:string;
  createContractPayloadData:CreateContractPayloadData;
  loader:boolean;
  tenantDetails: string;
  // Customizable Area End
}

// Customizable Area Start
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}
export interface CreateContractPayloadData{
  country: string;
  templateId: string;
  complex: string;
  city: string;
  unitName: string;
  buildingName: string;
  tenant_name: TenantNameInterface;
  landlord_name: string;
  complexName: string;
  society_management_id: string;
  building_management_id: string;
  tenant_id: string;
  apartment_management_id: string;
  expires_on: string;
  term_ids: Array<number>;
  agreement_duration: string;
  start_date: string;
  rent_amount: string;
  terms_label: Array<string>;
  currency: string;
  lease_template_id: string;
  custom_contract: boolean;
  condition_ids: Array<number>;
  conditions_label: Array<string>;
  contract_template: string;
  penanlty_late_payment: boolean;
  penalty_type: string;
  penalty_amount: string;
  ownerNumber: string | null;
  ownerEmail: string | null;
  accountId: Account_id;
  owner: string | null;
  status: string;
  contractLink: string;
  isTenantNameEnabled: boolean | null;
}

type Account_id = string | number | null
type TenantNameInterface = string | number | null
export interface TemplateSuccessResponse{
  "data": TemplateSuccessResponseItem
}
export interface TemplateSuccessResponseItem  {
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "title_ar":string,
      "description": null,
      "template_type_id": null,
      "template": string,
      "template_ar": string,
      "template_pdf": {
          "url": string
      }
  }
}
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError{
  contract:string
}
interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
}

export type TFunction = (value: string) => string;
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  templateListingApiCallId: string = "";
  searchTemplatesApiCallId: string = "";
  deleteTemplateApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.CreateContractPayloadData),
      getName(MessageEnum.TenantDetails)
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: "",
        
      },
      createContractPayloadData:{
        templateId: "",
        country: "",
        complex: "",
        buildingName: "",
        unitName: "",
        complexName: "",
        tenant_name: "",
        landlord_name: "",
        city: "",
        building_management_id: "",
        society_management_id: "",
        tenant_id: "",
        expires_on: "",
        apartment_management_id: "",
        agreement_duration: "",
        start_date: "",
        term_ids: [],
        terms_label: [],
        lease_template_id: "",
        rent_amount: "",
        currency: "",
        condition_ids: [],
        conditions_label: [],
        custom_contract: false,
        penanlty_late_payment: false,
        penalty_type: "",
        penalty_amount: "",
        contract_template: "",
        owner: "",
        ownerNumber: "",
        ownerEmail: "",
        accountId: "",
        status: "create",
        contractLink: "",
        isTenantNameEnabled:null
      },
      templateId:"",
      loader:false,
      templateDetails:{
            "id": "",
            "type": "",
            "attributes": {
                "id": 0,
                "title": "",
                "title_ar": "",
                "description": null,
                "template_type_id": null,
                "template": "",
                "template_ar": "",
                "template_pdf": {
                    "url": ""
                }
            }
    },
      showError:false,
      error: "",
      tenantDetails: ""
      // Customizable Area End
    };
    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    // Customizable Area End
  }

  // Customizable Area Start
  templateListingApiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken")
    let leaseManagmentMainrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type !== "formData"
      ? leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(leaseManagmentMainrequestMessage.id, leaseManagmentMainrequestMessage);
    return leaseManagmentMainrequestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.templateListingResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.leaseManagmentMainResponseFailureCall(apiRequestCallId, responseJson);
      }
    }
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractdata = message.getData(getName(MessageEnum.CreateContractPayloadData));
      if (createContractdata) {
        this.setState({ createContractPayloadData: createContractdata }, () => {          
          if (this.state.createContractPayloadData.templateId) {
            this.getTemplatesDetails()
          }
        })
      }
    }
  }

  templateListingResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: TemplateSuccessResponse) => {
    if (apiRequestCallId === this.templateListingApiCallId) {
      this.templatesListingSucessCallBack(responseJson);
    }

  };

  leaseManagmentMainResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.templateListingApiCallId) {
      this.templatesListingFailureCallBack(responseJson);
    }
  };
  getTemplatesDetails = async () => {
    this.setState({loader:true})
    this.templateListingApiCallId = await this.templateListingApiCall({
      method: configJSON.templateListingMethod,
      endPoint: `${configJSON.templateListingEndPoint}/${this.state.createContractPayloadData.templateId}`,
    });
  };

  templatesListingSucessCallBack = (response: TemplateSuccessResponse) => {
        let template = response.data.attributes.template
    template = template.replace('{{TENANT_NAME}}', this.state.createContractPayloadData.tenant_name as string);
    template = template.replace('{{BUILDING_NAME}}', this.state.createContractPayloadData.buildingName);
    template = template.replace('{{UNIT_NAME}}', this.state.createContractPayloadData.unitName);
    template = template.replace('{{COMPLEX_NAME}}', this.state.createContractPayloadData.complexName);
    template = template.replace('{{START_DATE}}', this.state.createContractPayloadData.start_date);
    if (this.state.createContractPayloadData.landlord_name) {
      template = template.replace(/{{LANDLORD_NAME}}/g, this.state.createContractPayloadData.landlord_name);
    }
    template = template.replace('{{COMPLEX_ADDRESS}}',this.state.createContractPayloadData.city + ", " + this.state.createContractPayloadData.country);
    template = template.replace('{{END_DATE}}', this.state.createContractPayloadData.expires_on);
    template = template.replace('{{COMPLEX_ADDRESS}}', this.state.createContractPayloadData.city + ", " + this.state.createContractPayloadData.country);
    response.data.attributes.template = template;
    this.setState({templateDetails: response.data,loader:false});
  };

  templatesListingFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract})
  };
  
  handleCloseAlertError = () => { this.setState({ showError: false, error: "" }) };

  handleBack = () => {
    let createContractPayloadData = {
      templateId: this.state.createContractPayloadData.templateId,
      country: this.state.createContractPayloadData.country,
      city: this.state.createContractPayloadData.city,
      complex: this.state.createContractPayloadData.complex,
      tenant_name: this.state.createContractPayloadData.tenant_name,
      unitName: this.state.createContractPayloadData.unitName,
      building_management_id: this.state.createContractPayloadData.building_management_id,
      buildingName: this.state.createContractPayloadData.buildingName,
      tenant_id: this.state.createContractPayloadData.tenant_id,
      complexName: this.state.createContractPayloadData.complexName,
      apartment_management_id: this.state.createContractPayloadData.apartment_management_id,
      landlord_name: this.state.createContractPayloadData.landlord_name,
      expires_on: this.state.createContractPayloadData.expires_on,
      society_management_id: this.state.createContractPayloadData.society_management_id,
      lease_template_id: this.state.createContractPayloadData.lease_template_id,
      start_date: this.state.createContractPayloadData.start_date,
      term_ids: this.state.createContractPayloadData.term_ids,
      agreement_duration: this.state.createContractPayloadData.agreement_duration,
      rent_amount: this.state.createContractPayloadData.rent_amount,
      currency: this.state.createContractPayloadData.currency,
      condition_ids: this.state.createContractPayloadData.condition_ids,
      custom_contract: this.state.createContractPayloadData.custom_contract,
      penanlty_late_payment: this.state.createContractPayloadData.penanlty_late_payment,
      contract_template: this.state.templateDetails.attributes.template,
      owner: this.state.createContractPayloadData.owner,
      accountId: this.state.createContractPayloadData.accountId,
      status: this.state.createContractPayloadData.status
    };
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContract");
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayloadData);
    this.send(msgData);
  };

  handleNext=()=>{
    let createContractPayloadData = {
      templateId: this.state.createContractPayloadData.templateId,
      country: this.state.createContractPayloadData.country,
      city: this.state.createContractPayloadData.city,
      complex: this.state.createContractPayloadData.complex,
      buildingName: this.state.createContractPayloadData.buildingName,
      unitName: this.state.createContractPayloadData.unitName,
      complexName: this.state.createContractPayloadData.complexName,
      tenant_name: this.state.createContractPayloadData.tenant_name,
      landlord_name: this.state.createContractPayloadData.landlord_name,
      building_management_id: this.state.createContractPayloadData.building_management_id,
      society_management_id: this.state.createContractPayloadData.society_management_id,
      tenant_id: this.state.createContractPayloadData.tenant_id,
      expires_on: this.state.createContractPayloadData.expires_on,
      condition_ids: this.state.createContractPayloadData.condition_ids,
      apartment_management_id: this.state.createContractPayloadData.apartment_management_id,
      agreement_duration: this.state.createContractPayloadData.agreement_duration,
      start_date: this.state.createContractPayloadData.start_date,
      term_ids: this.state.createContractPayloadData.term_ids,
      lease_template_id: this.state.createContractPayloadData.lease_template_id,
      rent_amount: this.state.createContractPayloadData.rent_amount,
      currency: this.state.createContractPayloadData.currency,
      custom_contract: this.state.createContractPayloadData.custom_contract,
      owner: this.state.createContractPayloadData.owner,
      penanlty_late_payment: this.state.createContractPayloadData.penanlty_late_payment,
      contract_template: this.state.templateDetails.attributes.template,
      accountId: this.state.createContractPayloadData.accountId,
      status: this.state.createContractPayloadData.status,
      isTenantNameEnabled: this.state.createContractPayloadData.isTenantNameEnabled
    };   
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayloadData);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractForm");
    msgData.addData(getName(MessageEnum.TenantDetails), this.state.tenantDetails);
    this.send(msgData);
  }

  handleExistRecordNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    this.send(msgData);
  };
  // Customizable Area End
}