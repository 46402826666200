import React from "react";
// Customizable Area Start
import { withTranslation } from "react-i18next";
import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Grid,
    MenuItem,
    Tab,
    Tabs,
    Typography,
    Select,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    Table,
    Divider,
    InputAdornment,
    TextField,
    Popover,
    IconButton,
    FormControl,
    Paper,
    Input,
    Modal,
    InputLabel,
    TextareaAutosize
} from '@material-ui/core'
import { styled } from '@material-ui/styles';
import { profileImg, socialIcon, callIcon, mailIcon, accountIcon } from './assets'
import AddIcon from '@material-ui/icons/Add';
import SelectArrowIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import { 
    ComplexDataItem, 
    TFunction, 
    CompaniesDataItem, 
    COOComplexDataItem, 
    CooAccountDataItem, 
    CompanyListItem, 
    I18nInterface, 
    SalesManagerItem,
    ComplexListItem,
    TotalIndividualType,
    BuildingListItem,
    RegisterOwnerResponseItem,
    PropertyManagerListItem
} from './SalesManagerProfileController.web';
import MainScreen from "./Company Employee/MainScreen.web";
import { Pagination } from "@material-ui/lab";
import MoreIcon from "@material-ui/icons/MoreVert";
import AlertError from "../../../components/src/AlertError.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { SearchOutlined } from "@material-ui/icons";

// Customizable Area End

import SalesManagerProfileController, { Props, } from "./SalesManagerProfileController.web";

class SalesManagerProfile extends SalesManagerProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTabPanel = (children: React.ReactNode, value: number, childrenIndex: number) => {
        return (
            <Box
                role="tabpanel"
                hidden={value !== childrenIndex}
                id={`simple-tabpanel-${childrenIndex}`}
                aria-labelledby={`simple-tab-${childrenIndex}`}
            >
                {value === childrenIndex && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </Box>
        )
    };

    renderAccountOwner = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <AccountOwnerDetails>
                <Box>
                    <Breadcrumbs>
                        <Typography className="navigationHeading">{t("My Dashboards")}</Typography>
                        <Typography className="navigationHeading">{t("General Dashboard")}</Typography>
                        <Typography className="navigationHeading">{t("Registered Owners")}</Typography>
                        <Typography className="navigateRouteActive">{t("Owner Details")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="ownerName" data-testId={"salesManagerName"}>
                    {this.state.salesManagerProfileDetails.data.attributes.full_name.name}
                </Typography>
                <Typography className="generalDetails">
                    {t("General Details")}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <Box className='profileSection'>
                                <img src={profileImg} />
                                <Typography className="generalDetails">Ainara Vergara</Typography>
                                <Chip className="ownerLabel" label="Owner" />
                                <Box className="profileIcons">
                                    <img src={callIcon} className="socialIcons" />
                                    <img className="socialIcons" src={callIcon} />
                                    <img alt="" className="socialIcons" src={mailIcon} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8}>
                            <Box className="aboutSection">
                                <Typography className="aboutHeading">{t("About")}</Typography>
                                <Typography className="aboutPara">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                    Ut, itaque voluptas sed officia a possimus temporibus
                                    iure earum eveniet quidem eum amet animi
                                    veniam dignissimos illum non, consequuntur ex nam!
                                </Typography>
                                <Grid container>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography className="aboutHeading">Gender</Typography>
                                            <Typography>Male</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography className="aboutHeading">{t("DOB")}</Typography>
                                            <Typography>20-5-1978</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography className="aboutHeading">{t("Hobbies")}</Typography>
                                            <Chip className="hobbiesList" label="Gardening" />
                                            <Chip className="hobbiesList" label="Singing" />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box>
                                    <Typography className="aboutHeading">{t("Social")}</Typography>
                                    <img src={socialIcon} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Box className="tabSection">
                        <Box>
                            <TabsHeader onChange={this.handleTabChange} value={this.state.tabValue}>
                                <Tab label="complex" className='tabsItem' value={0}></Tab>
                                <Tab label="individual buildings" value={1} className='tabsItem'></Tab>
                            </TabsHeader>
                        </Box>
                        <Button startIcon={<AddIcon />} className='addUnit'>{t("Add another unit")}</Button>
                    </Box>
                    <Box className='tabsChild2'>
                        {this.renderTabPanel(this.renderComplexTable(), this.state.tabValue, 0)}
                        {this.renderTabPanel(this.renderBuildingTable(), this.state.tabValue, 1)}
                    </Box>
                </Box>
            </AccountOwnerDetails>
        )
    };

    renderComplexTable = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <ComplexDetails>
                <Box className="headingContainer">
                    <Select
                        onChange={this.handleCompanyhange}
                        value={this.state.company}
                        className="selectData"
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        data-testId={"companyTestId"}
                        displayEmpty
                        variant="outlined"
                        id="demo-simple-select"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">{t("Select Company")}</MenuItem>
                        {this.state.companyList && this.state.companyList.map((companyListItemData: CompanyListItem, companiesIndex: number) => {
                            return <MenuItem value={companyListItemData.attributes.name} key={companiesIndex} data-testId={`companyListItem${companiesIndex}`}>{companyListItemData.attributes.name}</MenuItem>
                        })}
                    </Select>

                    <Select
                        onChange={this.handleComplexChange}
                        value={this.state.complex}
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="selectData"
                        displayEmpty
                        variant="outlined"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">
                            {t("Select Complex")}
                        </MenuItem>
                    </Select>
                    <Select
                        value={this.state.building}
                        labelId="demo-simple-select-label"
                        onChange={this.handleBuildingChange}
                        className="selectData"
                        IconComponent={SelectArrowIcon}
                        variant="outlined"
                        id="demo-simple-select"
                        displayEmpty
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">
                            {t("Select Building")}
                        </MenuItem>
                    </Select>
                    <Select
                        className="selectData"
                        onChange={this.handleCountryChange}
                        value={this.state.country}
                        displayEmpty
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        labelId="demo-simple-select-label"
                        IconComponent={SelectArrowIcon}
                        id="demo-simple-select"
                        variant="outlined"
                        data-testId={"countryTestId"}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((countryListItem: string, countryIndex: number) => {
                            return <MenuItem value={countryListItem} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{countryListItem}</MenuItem>
                        })}
                    </Select>
                    <Select
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        className="selectData"
                        onChange={this.handleCityChange}
                        labelId="demo-simple-select-label"
                        value={this.state.city}
                        IconComponent={SelectArrowIcon}
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                    >
                        <MenuItem value="">{t("Selct City")}</MenuItem>
                        {this.state.cityList.map((cityListItems: string, cityIndex: number) => {
                            return <MenuItem value={cityListItems} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListItems}</MenuItem>
                        })}
                    </Select>
                    <Button
                        variant="contained"
                        className="searchButton"
                        color="primary"
                        startIcon={<SearchIcon />}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Box className='tableContainer'>
                    <Box className="listHeading">
                        <Typography className="listPara">
                            {t("Complex")}
                        </Typography>
                        <SearchTextField
                            placeholder="Search by building name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchIcon' />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableHeading">{t("#")}</TableCell>
                                        <TableCell className="tableHeading">{t("Company Name")}</TableCell>
                                        <TableCell className="tableHeading">{t("Complex Id")}</TableCell>
                                        <TableCell className="tableHeading">{t("Building Id")}</TableCell>
                                        <TableCell className="tableHeading">{t("Building Name")}</TableCell>
                                        <TableCell className="tableHeading">{t("Unit Number")}</TableCell>
                                        <TableCell className="tableHeading">{t("Status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.complexData.map((complex: ComplexDataItem, complexIndex: number) => (
                                        <TableRow>
                                            <TableCell>{complexIndex + 1}</TableCell>
                                            <TableCell>{complex.companyName}</TableCell>
                                            <TableCell>{complex.complexId}</TableCell>
                                            <TableCell>{complex.buildingId}</TableCell>
                                            <TableCell>{complex.buildingName}</TableCell>
                                            <TableCell>{complex.unit}</TableCell>
                                            <TableCell><Chip className={complex.status === "Rented" ? "rentedStatus" : "hobbiesList"} label={complex.status} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </ComplexDetails>
        )
    };

    renderBuildingTable = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <IndividualBuilding>
                <Box className="headingContainer">
                    <Select
                        onChange={this.handleCompanyhange}
                        value={this.state.company}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        displayEmpty
                        id="demo-simple-select"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        variant="outlined"
                        labelId="demo-simple-select-label"
                    >
                        <MenuItem value="">{t("Select Company")}</MenuItem>
                        {this.state.companyList && this.state.companyList.map((companyItem: CompanyListItem, companyIndex: number) => {
                            return <MenuItem key={companyIndex} value={companyItem.attributes.name} data-testId={`companyListItem${companyIndex}`}>{companyItem.attributes.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        labelId="demo-simple-select-label"
                        IconComponent={SelectArrowIcon}
                        id="demo-simple-select"
                        displayEmpty
                        className="selectData"
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        variant="outlined"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">
                            {t("Select Complex")}
                        </MenuItem>
                    </Select>
                    <Select
                        onChange={this.handleCountryChange}
                        value={this.state.country}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        id="demo-simple-select"
                        displayEmpty
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((country: string, countryIndex: number) => {
                            return <MenuItem value={country} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country}</MenuItem>
                        })}
                    </Select>
                    <Select
                        onChange={this.handleCityChange}
                        value={this.state.city}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        displayEmpty
                        id="demo-simple-select"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        labelId="demo-simple-select-label"
                        variant="outlined"
                    >
                        <MenuItem value="">{t("Selct City")}</MenuItem>
                        {this.state.cityList.map((cityItem: string, cityIndex: number) => {
                            return <MenuItem value={cityItem} data-testId={`cityItemTestId${cityIndex}`} key={cityIndex} >{cityItem}</MenuItem>
                        })}
                    </Select>
                    <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        className="searchButton"
                        color="primary"
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Box className='tableContainer'>
                    <Box className="listHeading">
                        <Typography className="listPara">
                            {t("Individual buildings")}
                        </Typography>
                        <SearchTextField
                            placeholder="Search by complex name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchIcon' />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="tableHeader">#</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Company Name")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Building Id")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Building Name")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Unit Number")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.map((companies: CompaniesDataItem, companiesIndex: number) => (
                                        <TableRow key={companiesIndex}>
                                            <TableCell>{companiesIndex + 1}</TableCell>
                                            <TableCell>{companies.companyName}</TableCell>
                                            <TableCell>{companies.buildingId}</TableCell>
                                            <TableCell>{companies.buildingName}</TableCell>
                                            <TableCell>{companies.unit}</TableCell>
                                            <TableCell><Chip className={companies.status === "Rented" ? "rentedStatus" : "hobbiesList"} label={companies.status} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </IndividualBuilding>
        )
    };

    renderSalesOwner = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <SalesOwnerDetails>
                <Box>
                    <Breadcrumbs>
                        <Typography className="navigationTopHeading">{t("My Dashboard")}</Typography>
                        <Typography className="navigationTopHeading">{t("General Dashboard")}</Typography>
                        <Typography className="navigationTopHeading">{t("Registered Owners")}</Typography>
                        <Typography color="primary">{t("Owner Details")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography data-testId={"salesManagerName"} className="ownerName">
                    {this.state.salesManagerProfileDetails.data.attributes.full_name.name}
                </Typography>
                <Typography className="generalDetails">
                    {t("General Details")}
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <Box className='profileSection'>
                                <img src={profileImg} className="userProfile" />
                                <Typography component={"p"} className="generalDetails">Ainara Vergara</Typography>
                                <Box className="profileLabel">
                                    <Chip className="ownerLabel" label="Owner" />
                                    <Chip className="payingLabel" label="Paying Client" />
                                </Box>
                                <Box className="profileIcons">
                                    <img src={callIcon} className="socialIcons" />
                                    <img alt="" className="socialIcons" src={callIcon} />
                                    <img src={mailIcon} className="socialIcons" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8}>
                            <Box className="aboutSection">
                                <Typography component={"p"} className="aboutHeading">{t("About")}</Typography>
                                <Typography component={"p"} className="aboutPara">
                                    veniam dignissimos illum non, consequuntur ex nam!
                                    Ut, itaque voluptas sed officia a possimus temporibus
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                    iure earum eveniet quidem eum amet animi
                                </Typography>
                                <Grid container>
                                    <Grid lg={4} item>
                                        <Box component={'div'} className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("Gender")}</Typography>
                                            <Typography component={"p"}>Male</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box component={'div'} className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("DOB")}</Typography>
                                            <Typography component={"p"}>20-5-1978</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box component={'div'} className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("Hobbies")}</Typography>
                                            <Chip className="hobbiesList" label="Gardening" />
                                            <Chip className="hobbiesList" label="Singing" />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box component={'div'}>
                                    <Typography component={"p"} className="aboutHeading">{t("Social")}</Typography>
                                    <img src={socialIcon} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} className="tabSection">
                        <Box component={'div'}>
                            <TabsHeader value={this.state.tabValue} onChange={this.handleTabChange}>
                                <Tab className='tabsItem' label="complex" value={0}></Tab>
                                <Tab className='tabsItem' label="individual buildings" value={1}></Tab>
                            </TabsHeader>
                        </Box>
                        <Button startIcon={<AddIcon />} className='addUnit'>{t("Add another unit")}</Button>
                    </Box>
                    <Box component={'div'} className='tabsChild2'>
                        {this.renderTabPanel(this.renderComplexSalesTable(), this.state.tabValue, 0)}
                        {this.renderTabPanel(this.renderBuildingSalesTable(), this.state.tabValue, 1)}
                    </Box>
                </Box>
            </SalesOwnerDetails>
        )
    };

    renderComplexSalesTable = () => {
        const { t }: { t: TFunction } = this.props;
        const open = Boolean(this.state.openPopOver);
        return (
            <SalesComplexDetails>
                <Box className="headingContainer">
                    <Select
                        onChange={this.handleCompanyhange}
                        value={this.state.company}
                        className="selectData"
                        data-testId={"companyTestId"}
                        labelId="demo-simple-select-label"
                        IconComponent={SelectArrowIcon}
                        displayEmpty
                        id="demo-simple-select"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        variant="outlined"
                    >
                        <MenuItem value="">{t("Select Company")}</MenuItem>
                        {this.state.companyList && this.state.companyList.map((companyListItem: CompanyListItem, companyIndex: number) => {
                            return <MenuItem key={companyIndex} value={companyListItem.attributes.name} data-testId={`companyListItem${companyIndex}`}>{companyListItem.attributes.name}</MenuItem>
                        })}
                    </Select>

                    <Select
                        onChange={this.handleComplexChange}
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        value={this.state.complex}
                        className="selectData"
                        data-testId={"complexTestId"}
                        labelId="demo-simple-select-label"
                        displayEmpty
                        id="demo-simple-select"
                        variant="outlined"
                        IconComponent={SelectArrowIcon}
                    >
                        <MenuItem value="">{t("Select Complex")}</MenuItem>
                        {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                            return <MenuItem value={complex.attributes.name} key={complexIndex} data-testId={`complexItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        className="selectData"
                        data-testId={"buildingTestId"}
                        id="demo-simple-select"
                        IconComponent={SelectArrowIcon}
                        displayEmpty
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        labelId="demo-simple-select-label"
                        variant="outlined"
                    >
                        <MenuItem value="">{t("Select Building")}</MenuItem>
                        {this.state.buildingList.map((building: BuildingListItem, buildingIndex: number) => {
                            return <MenuItem value={building.name} key={buildingIndex} data-testId={`buildingItem${buildingIndex}`}>{building.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        className="selectData"
                        value={this.state.country}
                        labelId="demo-simple-select-label"
                        data-testId={"countryTestId"}
                        onChange={this.handleCountryChange}
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                        IconComponent={SelectArrowIcon}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((countryItem: string, countryIndex: number) => {
                            return <MenuItem value={countryItem} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{countryItem}</MenuItem>
                        })}
                    </Select>
                    <Select
                        onChange={this.handleCityChange}
                        value={this.state.city}
                        className="selectData"
                        IconComponent={SelectArrowIcon}
                        data-testId={"cityTestId"}
                        labelId="demo-simple-select-label"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        id="demo-simple-select"
                        variant="outlined"
                        displayEmpty
                    >
                        <MenuItem value="">{t("Selct City")}</MenuItem>
                        {this.state.cityList.map((cityListItem: string, cityIndex: number) => {
                            return <MenuItem value={cityListItem} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListItem}</MenuItem>
                        })}
                    </Select>
                    <Button
                        variant="contained"
                        color="primary"
                        className="searchButton"
                        startIcon={<SearchIcon />}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Box className='tableContainer'>
                    <Box className="listHeading">
                        <Typography className="listPara">
                            {t("List of owned units")}
                        </Typography>
                        <SearchTextField
                            onChange={this.handleSearchInput}
                            value={this.state.searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchIcon' />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            data-testId={"seachTestId"}
                            placeholder="Search by complex name"
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableHeader" align="left">#</TableCell>
                                        <TableCell className="tableHeader" align="left">{t("Company Name")}</TableCell>
                                        <TableCell className="tableHeader" align="left">{t("Complex Id")}</TableCell>
                                        <TableCell className="tableHeader" align="left">{t("Building Id")}</TableCell>
                                        <TableCell className="tableHeader" align="left">{t("Building Name")}</TableCell>
                                        <TableCell className="tableHeader" align="left">{t("Unit Number")}</TableCell>
                                        <TableCell className="tableHeader" align="left">{t("Status")}</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.complexData.map((complex: ComplexDataItem, complexIndex: number) => (
                                        <TableRow>
                                            <TableCell className='tableContent' align="left">{complexIndex + 1}</TableCell>
                                            <TableCell className="tableContent" align="left">{complex.companyName}</TableCell>
                                            <TableCell className='tableContent' align="left">{complex.complexId}</TableCell>
                                            <TableCell className='tableContent' align="left">{complex.buildingId}</TableCell>
                                            <TableCell className='tableContent' align="left">{complex.buildingName}</TableCell>
                                            <TableCell className='tableContent' align="left">{complex.unit}</TableCell>
                                            <TableCell className='tableContent' align="left"><Chip className={complex.status === "Rented" ? "rentedStatus" : "hobbiesList"} label={complex.status} /></TableCell>
                                            <TableCell className='tableContent' align="left"><IconButton onClick={this.handleOpenPopover} data-testId={`tableMenuTestId${complexIndex}`}><MoreIcon /></IconButton></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Popover
                            anchorEl={this.state.openPopOver}
                            open={open}
                            onClose={this.handleClosePopover}
                            data-testId={"popoverTestId"}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem data-testId={"tableMenuItem1"}>{t("View Details")}</MenuItem>
                        </Popover>
                    </Box>
                </Box>
            </SalesComplexDetails>
        )
    };

    renderBuildingSalesTable = () => {
        const open = Boolean(this.state.openPopOver);
        const { t }: { t: TFunction } = this.props;
        return (
            <SalesBuildingDetails>
                <Box className="headingContainer">
                    <Select
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        onChange={this.handleCompanyhange}
                        value={this.state.company}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        id="demo-simple-select"
                        displayEmpty
                        variant="outlined"
                        labelId="demo-simple-select-label"
                    >
                        <MenuItem value="">{t("Select Company")}</MenuItem>
                        {this.state.companyList && this.state.companyList.map((companyListItem: CompanyListItem, companyIndex: number) => {
                            return <MenuItem value={companyListItem.attributes.name} key={companyIndex} data-testId={`companyListItem${companyIndex}`}>{companyListItem.attributes.name}</MenuItem>
                        })}
                    </Select>
                    <Select
                        value={this.state.building}
                        className="selectData"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        onChange={this.handleBuildingChange}
                        displayEmpty
                        IconComponent={SelectArrowIcon}
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        id="demo-simple-select"
                    >
                        <MenuItem value="">
                            {t("Select Building")}
                        </MenuItem>
                    </Select>
                    <Select
                        onChange={this.handleCountryChange}
                        value={this.state.country}
                        IconComponent={SelectArrowIcon}
                        className="selectData"
                        variant="outlined"
                        id="demo-simple-select"
                        displayEmpty
                        labelId="demo-simple-select-label"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                    >
                        <MenuItem value="">{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((countryItem: string, countryIndex: number) => {
                            return <MenuItem value={countryItem} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{countryItem}</MenuItem>
                        })}
                    </Select>
                    <Select
                        value={this.state.city}
                        className="selectData"
                        IconComponent={SelectArrowIcon}
                        id="demo-simple-select"
                        MenuProps={{
                            style: { marginTop: "50px" }
                        }}
                        onChange={this.handleCityChange}
                        displayEmpty
                        labelId="demo-simple-select-label"
                        variant="outlined"
                    >
                        <MenuItem value="">{t("Selct City")}</MenuItem>
                        {this.state.cityList.map((cityListsItem: string, cityIndex: number) => {
                            return <MenuItem value={cityListsItem} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListsItem}</MenuItem>
                        })}
                    </Select>
                    <Button
                        variant="contained"
                        className="searchButton"
                        color="primary"
                        startIcon={<SearchIcon />}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Box className='tableContainer'>
                    <Box className="listHeading">
                        <Typography className="listPara">
                            List of owned units
                        </Typography>
                        <SearchTextField
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchIcon' />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search by building name"
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="tableHeader">{t("#")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Company Name")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Building Id")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Building Name")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Unit Number")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Status")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.map((comapny: CompaniesDataItem, comapnyIndex: number) => (
                                        <TableRow key={comapnyIndex}>
                                            <TableCell className='tableContent'>{comapnyIndex + 1}</TableCell>
                                            <TableCell className='tableContent'>{comapny.companyName}</TableCell>
                                            <TableCell className='tableContent'>{comapny.buildingId}</TableCell>
                                            <TableCell className='tableContent'>{comapny.buildingName}</TableCell>
                                            <TableCell className='tableContent'>{comapny.unit}</TableCell>
                                            <TableCell className='tableContent'><Chip className={comapny.status === "Rented" ? "rentedStatus" : "hobbiesList"} label={comapny.status} /></TableCell>
                                            <TableCell className='tableContent'><IconButton onClick={this.handleOpenPopover}><MoreIcon /></IconButton></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Popover
                            anchorEl={this.state.openPopOver}
                            open={open}
                            onClose={this.handleClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                style: {
                                    width: "15ch",
                                },
                            }}
                        >
                            <MenuItem>{t("View")}</MenuItem>
                        </Popover>
                    </Box>
                </Box>
            </SalesBuildingDetails>
        )
    };

    renderCooOwner = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <CooOwnerDetails>
                <Box>
                    <Breadcrumbs>
                        <Typography className="topHeading">{t("My Dashboards")}</Typography>
                        <Typography className="topHeading">{t("General Dashboard")}</Typography>
                        <Typography className="topHeading">{t("Assigned Sales Managers")}</Typography>
                        <Typography color="primary">{t("Cynthia Little")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="salesManagerName" data-testId={"salesManagerName"}>
                    {this.state.salesManagerProfileDetails?.data?.attributes?.full_name?.name}
                </Typography>
                <Typography className="generalDetails">
                    ID : 12345
                </Typography>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <Box className='profileSection'>
                                <img src={'https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2960&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} className="userProfile" />
                                <Typography className="generalDetails">Ainara Vergara</Typography>
                                <Chip className="payingLabel" label="Sales Manager" />
                                <Box className="profileIcons">
                                    <img className="socialIcons" src={callIcon} />
                                    <img className="socialIcons" src={mailIcon} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8}>
                            <Box className="aboutSection">
                                <Typography className="aboutHeading">{t("About")}</Typography>
                                <Typography className="aboutPara">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                    veniam dignissimos illum non, consequuntur ex nam!
                                    iure earum eveniet quidem eum amet animi
                                    Ut, itaque voluptas sed officia a possimus temporibus
                                </Typography>
                                <Grid container>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("Gender")}</Typography>
                                            <Typography component={"p"}>Male</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("DOB")}</Typography>
                                            <Typography component={"p"}>20-5-1978</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box className="aboutDetails">
                                            <Typography component={"p"} className="aboutHeading">{t("Hobbies")}</Typography>
                                            <Chip className="hobbiesList" label="Gardening" />
                                            <Chip className="hobbiesList" label="Singing" />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box>
                                    <Typography className="aboutHeading">{t("Social")}</Typography>
                                    <img src={socialIcon} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Box className="tabSection">
                        <Box>
                            <TabsHeader value={this.state.tabValue} onChange={this.handleTabChange} data-testId={"cooTabChangeTestId"} >
                                <Tab label="complex" className='tabsItem' value={0}></Tab>
                                <Tab label="individual buildings" className='tabsItem' value={1}></Tab>
                                <Tab label="owners" className='tabsItem' value={2}></Tab>
                                <Tab label="poperly developers" className='tabsItem' value={3}></Tab>
                                <Tab label="account managers" className='tabsItem' value={4}></Tab>
                            </TabsHeader>
                        </Box>
                    </Box>
                    <Box className='tabsChild2'>
                        {this.renderTabPanel(this.renderComplexCooTable(), this.state.tabValue, 0)}
                        {this.renderTabPanel(this.renderIndividualBuildingCooTable(), this.state.tabValue, 1)}
                        {this.renderTabPanel(this.renderOwnerTable(), this.state.tabValue, 2)}
                        {this.renderTabPanel(this.renderPoperlyTable(), this.state.tabValue, 3)}
                        {this.renderTabPanel(this.renderAccountTable(), this.state.tabValue, 4)}
                    </Box>
                </Box>
            </CooOwnerDetails>
        )
    };

    renderComplexCooTable = () => {
        const open = Boolean(this.state.openPopOver);
        const { t }: { t: TFunction } = this.props;
        return (
            <CooComplexDetails>
                <Box className="headingContainer">
                    <Box className="selectSection">
                        <Select
                            data-testId={"companyTestId"}
                            onChange={this.handleCompanyhange}
                            value={this.state.company}
                            labelId="demo-simple-select-label"
                            className="selectData"
                            IconComponent={SelectArrowIcon}
                            variant="outlined"
                            id="demo-simple-select"
                            displayEmpty
                            MenuProps={{
                                style: { marginTop: "50px" }
                            }}
                        >
                            <MenuItem value="">{t("Select Company")}</MenuItem>
                            {this.state.companyList && this.state.companyList.map((company: CompanyListItem, companyIndex: number) => {
                                return <MenuItem value={company.attributes.name} key={companyIndex} data-testId={`companyListItem${companyIndex}`}>{company.attributes.name}</MenuItem>
                            })}
                        </Select>

                        <Select
                            value={this.state.country}
                            onChange={this.handleCountryChange}
                            IconComponent={SelectArrowIcon}
                            data-testId={"countryTestId"}
                            className="selectData"
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            displayEmpty
                            variant="outlined"
                            MenuProps={{
                                style: { marginTop: "50px" }
                            }}
                        >
                            <MenuItem value="">{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((countryItem: string, countryIndex: number) => {
                                return <MenuItem value={countryItem} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{countryItem}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            IconComponent={SelectArrowIcon}
                            className="selectData"
                            MenuProps={{
                                style: { marginTop: "50px" }
                            }}
                            id="demo-simple-select"
                            data-testId={"cityTestId"}
                            displayEmpty
                            variant="outlined"
                            labelId="demo-simple-select-label"
                        >
                            <MenuItem value="">{t("Selct City")}</MenuItem>
                            {this.state.cityList.map((cityListItem: string, cityIndex: number) => {
                                return <MenuItem value={cityListItem} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListItem}</MenuItem>
                            })}
                        </Select>
                        <Button
                            className="searchButton"
                            startIcon={<SearchIcon />}
                            variant="contained"
                            color="primary"
                            data-testId={"cooComplexSearchBtnTestId"}
                            onClick={() => this.getComplexTabData("1")}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Box>
                        <Button className='assignButton' variant='contained'>{t("Assign")}</Button>
                    </Box>
                </Box>
                <Box className='tableContainer'>
                    <Box className="listHeading" data-testId={"complexTabHeadingTestId"}>
                        <Typography className="listPara">
                            {t("List of Assigned Complexes")}
                        </Typography>
                        <SearchTextField
                            data-testId={"complexTabSeachTestId"}
                            value={this.state.searchText}
                            onChange={this.handleSearchInput}
                            placeholder="Search by complex name"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className='searchIcon' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="complexTableHeading">{t("#")}</TableCell>
                                        <TableCell className="complexTableHeading">{t("Complex Name")}</TableCell>
                                        <TableCell className="complexTableHeading">{t("Account Managers")}</TableCell>
                                        <TableCell className="complexTableHeading">{t("Total Units")}</TableCell>
                                        <TableCell className="complexTableHeading">{t("Subscription Ends in")}</TableCell>
                                        <TableCell className="complexTableHeading">{t("Subscription Type")}</TableCell>
                                        <TableCell className="complexTableHeading">{t("Paying Month")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.cooComplexData.map((cooComplex: COOComplexDataItem, cooComplexIndex) => (
                                        <TableRow key={cooComplexIndex}>
                                            <TableCell className='tableContent'>{cooComplexIndex + 1}</TableCell>
                                            <TableCell className="tableContent">
                                                <Box className='tableContentComplex'>
                                                    {this.handleNullValue(cooComplex.attributes.complex_name)}
                                                    <img className="accountIcon" src={accountIcon} />
                                                </Box>
                                            </TableCell>
                                            <TableCell className='tableContent' data-testId={`tableAccountManagerName${cooComplexIndex}`}>{this.handleNullValue(cooComplex.attributes.account_manager)}</TableCell>
                                            <TableCell className='tableContent'>{this.handleNullValue(cooComplex.attributes.totle_unit)}</TableCell>
                                            <TableCell className='tableContent'>{this.handleNullValue(cooComplex.attributes.subscription_ends)}</TableCell>
                                            <TableCell className='tableContent'>{this.handleNullValue(cooComplex.attributes.subscription_plan)}</TableCell>
                                            <TableCell className='tableContent'>{this.handleNullValue(cooComplex.attributes.paying_month)}</TableCell>
                                            <TableCell className='tableContent'><IconButton onClick={this.handleOpenPopover} data-testId={"tableMenuTestId"}><MoreIcon  data-testId={"handleMapvalueaCoo"}  onClick={() =>{this.handleMapvalues(cooComplex)}} /></IconButton></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box className="paginationData">
                               <PaginationModule data-testId={"cooComplexPaginationTestId"} page={this.state.paginationData?.current_page} pagination={this.state.paginationData} handlePagination={this.handleCooComplexPagination}/>
                            </Box>
                        </TableContainer>
                        <Box>
                            <Popover
                                anchorEl={this.state.openPopOver}
                                open={open}
                                onClose={this.handleClosePopover}
                                data-testId={"popoverTestId"}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}

                            >
                                <MenuItem data-testId={"tableMenuItem1"} onClick={this.handleOpenmapModal}>{t("View Location on map")}</MenuItem>
                                <MenuItem>{t("Go to Complex Page")}</MenuItem>
                                <MenuItem>{t("Revoke Login Permission")}</MenuItem>
                                <MenuItem>{t("Assign to another Manager")}</MenuItem>
                            </Popover>
                        </Box>
                    </Box>
                </Box>
            </CooComplexDetails>
        )
    };

    renderIndividualBuildingCooTable = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const open = Boolean(this.state.openMenu);
        const language = i18n.language;
        
        return (
            <TotalIndividual dir={languageCondition(language, "rtl", "ltr")}>
                <Box className="headingContainer" dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={SelectArrowIcon}
                            data-testId={"cooCompanySelectTestId"}
                            displayEmpty
                            value={this.state.company}
                            onChange={this.handleCompanyhange}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList && this.state.companyList.map((company: CompanyListItem, companyIndex: number) => {
                                return <MenuItem value={company.attributes.name} key={companyIndex} data-testId={`companyListItem${companyIndex}`}>{company.attributes.name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={SelectArrowIcon}
                            data-testId={"cooComplexSelectTestId"}
                            label={t("Complex")}
                            displayEmpty
                            value={this.state.complex}
                            onChange={this.handleComplexChange}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={complex.attributes.name} key={complexIndex} data-testId={`complexItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={SelectArrowIcon}
                            label={t("Complex")}
                            data-testId={"cooSalesManagerSelectTestId"}
                            displayEmpty
                            value={this.state.salesManager}
                            onChange={this.handleSalesManagerChange}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Sales Manager")}</MenuItem>
                            {this.state.salesManagerList.map((salesManager: SalesManagerItem, salesManagerIndex: number) => {
                                return <MenuItem value={salesManager.full_name} key={salesManagerIndex} data-testId={`salesManagerItem${salesManagerIndex}`}>{salesManager.full_name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"cooSubscriptionSelectTestId"}
                            label={t("Complex")}
                            IconComponent={SelectArrowIcon}
                            displayEmpty
                            value={this.state.subscriptionEndsIn}
                            onChange={this.handleSubscriptionChange}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscription ends in")}</MenuItem>
                            <MenuItem value={"10 Days"}>{"10 Days"}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <FormControl className={"complexSelect"} variant="outlined">
                        <SelectData
                            label={t("Complex")}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"cooCountrySelectTestId"}
                            onChange={this.handleCountryChange}
                            displayEmpty
                            IconComponent={SelectArrowIcon}
                            value={this.state.country}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")}  value="">{t("Country")}</MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return <MenuItem value={country} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={SelectArrowIcon}
                            onChange={this.handleCityChange}
                            data-testId={"cooCitySelectTestId"}
                            label={"Complex"}
                            value={this.state.city}
                            displayEmpty
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <Button
                        className="searchButton"
                        startIcon={<SearchIcon />}
                        color="primary"
                        variant="contained"
                        data-testId={"searchButtonTestId"}
                        onClick={() => this.getIndividualBuildingListing("1")}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Paper className="tableHeaderContainer">
                    <Box className="listHeader">
                        <Typography className="listPara">
                            {t("Total assigned individual building")}
                        </Typography>
                        <SearchField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="buildingSearchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleSearchCompanyChange}
                            variant="outlined"
                            placeholder={t("Search by building name")}
                            value={this.state.searchText}
                            data-testId={"individualBuildingSearchTestId"}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow className="tableHeading">
                                        <TableCell align="left" className="tableHeading">#</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Building")}</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("No. of floors")}</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscriptions Ends in")}</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Units")}</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Country")}</TableCell>
                                        <TableCell align="left" className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("City")}</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.individualBuildingList.map((totalIndividaul: TotalIndividualType, totalIndividaulIndex: number) => (
                                        <TableRow key={totalIndividaulIndex}>
                                            <TableCell align="left">{totalIndividaulIndex + 1}</TableCell>
                                            <TableCell align="left" component="th" scope="row" data-testId={`buildingTableName${totalIndividaulIndex}`}>{totalIndividaul.attributes.building_name}</TableCell>
                                            <TableCell align="left">{totalIndividaul.attributes.account_manager}</TableCell>
                                            <TableCell align="left">{totalIndividaul.attributes.no_of_floor}</TableCell>
                                            <TableCell align="left">{totalIndividaul.attributes.subscription_ends}</TableCell>
                                            <TableCell align="left">{totalIndividaul.attributes.totle_unit}</TableCell>
                                            <TableCell align="left">{totalIndividaul.attributes.country}</TableCell>
                                            <TableCell align="left">{totalIndividaul.attributes.city}</TableCell>
                                            <TableCell align="left">
                                                <IconButton onClick={this.handleOpenMenu} data-testId={`cooTableMenuBtn${totalIndividaulIndex}`}>
                                                    <MoreIcon />
                                                </IconButton>
                                                <Paper>
                                                    <Popover
                                                        open={open}
                                                        data-testId={"cooTablePopOverTestId"}
                                                        onClose={this.handleCloseMenu}
                                                        anchorEl={this.state.openMenu}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                width: "20ch",
                                                            },
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                    >
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")}>{t("View location on map")}</MenuItem>
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")} data-testId={`reassignButton${totalIndividaulIndex}`} onClick={this.handleOpenReassignModal}>{t("Reassign")}</MenuItem>
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")}>{t("Login")}</MenuItem>
                                                    </Popover>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <PaginationData>
                                <PaginationModule data-testId={"cooPagination"} page={this.state.paginationData?.current_page} pagination={this.state.paginationData} handlePagination={this.handlePagination}/>
                            </PaginationData>
                        </TableContainer>
                    </Box>
                </Paper>
            </TotalIndividual>
        )
    };

    renderOwnerTable = () => {
        const { t }: { t: TFunction } = this.props;
        const { popoverAncher, selectedItem } = this.state;
        const open = Boolean(popoverAncher);
        return (
            <RegisteredOwnerContainer>
                <Box className='registeredOwnerTopSectionContainer'>
                    <Box>
                    </Box>
                    <Box className='searchFilterContainer'>
                        <FormControl className={"sortBySearch"} variant="filled">
                            <Select
                                value={this.state.ownerStatus}
                                displayEmpty
                                className={"sortBySearch"}
                                variant='outlined'
                                placeholder='Owner Status'
                                IconComponent={SelectArrowIcon}
                                onChange={this.handleOwnerStatusChange}
                                data-testId={"ownerStatusTestId"}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                            >
                                <MenuItem value="">{t("Owner Status")}</MenuItem>
                                <MenuItem value="Accepted">{t("Accepted")}</MenuItem>
                                <MenuItem value="Invited">{t("Invited")}</MenuItem>
                                <MenuItem value="Awaited Approval">{t("Awaited Approval")}</MenuItem>
                                <MenuItem value="On Board">{t("On Board")}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="filled" className={"sortBySearch"}>
                            <Select
                                displayEmpty
                                variant='outlined'
                                onChange={this.handlePayingClientsChange}
                                IconComponent={SelectArrowIcon}
                                placeholder='Paying Clients'
                                data-testId={"payingClientTestId"}
                                value={this.state.payingClient}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                className={"payingClientsSelect"}
                            >
                                <MenuItem value="">{t("Paying Clients")}</MenuItem>
                                <MenuItem className="payingClientOption" value="Yes">{t("Yes")}</MenuItem>
                                <MenuItem className="payingClientOption" value="No">{t("No")}</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant='contained' className={"searchBtn"} startIcon={<SearchIcon className='searchbtnIcon' />} onClick={()=>this.getRegisteredOwner("1")} data-testId={"ownerSearchBtnTestId"}>{t("Search")}</Button>
                    </Box>
                    <Box component={Paper} className='registeredOwnerTableContainer'>
                        <Box className='registeredOwnerTableTopContainer'>
                            <Typography className='tableHeading' variant='h5'>List of registered owners</Typography>
                            <Box className='searchContainer'>
                                <FormControl variant="filled" className={"sortBySearch"}>
                                    <Select
                                        MenuProps={{
                                            style: {
                                                marginTop: "50px",
                                            },
                                        }}
                                        variant='outlined'
                                        value={this.state.sortBy}
                                        onChange={this.handleSortByChange}
                                        data-testId={"sortBySelectTestId"}
                                        displayEmpty className={"sortBySearch"}
                                        IconComponent={SelectArrowIcon}
                                        placeholder='Sort By'
                                    >
                                        <MenuItem value="">{t("Sort By")}</MenuItem>
                                        <MenuItem value="asc">{t("Ascending")}</MenuItem>
                                        <MenuItem value="dsc">{t("Descending")}</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    className='searchTextField'
                                    onChange={this.handleSearchInputChange}
                                    value={this.state.searchText}
                                    variant='outlined'
                                    data-testId={"searchInputTestId"}
                                    InputProps={{ startAdornment: <SearchIcon className='searchIcon' /> }}
                                    placeholder='Search by Owner Name or ID'
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <Box >
                            <TableContainer className='tableContainer'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='tableColumnHeading' align="center">#</TableCell>
                                            <TableCell className='tableColumnHeading' align="center">{t("Owner Name")}</TableCell>
                                            <TableCell className='tableColumnHeading' align="center">{t("Owner ID")}</TableCell>
                                            <TableCell className='tableColumnHeading' align="center">{t("Total Unit")}</TableCell>
                                            <TableCell className='tableColumnHeading' align="center">{t("Paying Clients")}</TableCell>
                                            <TableCell className='tableColumnHeading' align="center">{t("Managed By")}</TableCell>
                                            <TableCell align="left" className='tableColumnHeadingOwnerStatus'>{t("Owner Status")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.registeredOwner.length === 0 ? <Typography>{t("No data found")}</Typography> : this.state.registeredOwner.map((registerOwnerItem: RegisterOwnerResponseItem, registerOwnerIndex: number) => {
                                            return <TableRow className="cootablerow" key={registerOwnerIndex}>
                                                <TableCell className="cootableHeading" align="center">{registerOwnerIndex + 1}</TableCell>
                                                <TableCell className="cootableHeading" align="center" data-testId={`tableOwnerName${registerOwnerIndex}`}>{registerOwnerItem.attributes.owner_name}</TableCell>
                                                <TableCell className="cootableHeading" align="center">{registerOwnerItem.attributes.owner_id}</TableCell>
                                                <TableCell className="cootableHeading" align="center">{registerOwnerItem.attributes.Total_units}</TableCell>
                                                <TableCell className="cootableHeading" align="center">{registerOwnerItem.attributes.paying_clients}</TableCell>
                                                <TableCell className="cootableHeading" align="center">{registerOwnerItem.attributes.managed_by}</TableCell>
                                                <TableCell align="left" >
                                                    <Box className='ownerStatusContainer'>
                                                        <Box className='ownerStatusChipContainer'>
                                                            {registerOwnerItem.attributes.status === "Awaited Approval" && <Chip label={registerOwnerItem.attributes.status} className='ownerStatusOrange' />}
                                                            {registerOwnerItem.attributes.status === "On Board" && <Chip label={registerOwnerItem.attributes.status} className='ownerStatusGreen' />}
                                                            {registerOwnerItem.attributes.status === "Accepted" && <Chip label={registerOwnerItem.attributes.status} className='ownerStatusGreen' />}
                                                            {registerOwnerItem.attributes.status === "Invited" && <Chip label={registerOwnerItem.attributes.status} className='ownerStatusBlue' />}
                                                        </Box>
                                                        <Box>
                                                            <IconButton onClick={(event) => this.handleTableMenu(event, registerOwnerItem)} data-testId={`tableMenuTestId${registerOwnerIndex}`}>
                                                                <MoreIcon className="moreOptionIcon" />
                                                            </IconButton>
                                                            <Popover
                                                                open={open && selectedItem === registerOwnerItem}
                                                                anchorEl={this.state.popoverAncher}
                                                                onClose={this.handleClosePopover}
                                                                data-testId={`popoverTestId${registerOwnerIndex}`}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <MenuItem data-testId={"tableMenuItemTestId"}>{t("View Details")}</MenuItem>
                                                            </Popover>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                                <Box className='tablePaginationContainer'>
                                    <PaginationModule pagination={this.state.paginationData} handlePagination={this.handleCooPagination} page={this.state.paginationData?.current_page} data-testId={"cooPaginationTestId"} />
                                </Box>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </RegisteredOwnerContainer>
        )
    };

    renderPoperlyTable = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <CooPropertyManagerStyle>
                    <Box className="selctStyles" dir={languageCondition(language, "rtl", "ltr")}>
                        <Box className="selectAllItem" dir={languageCondition(language, "rtl", "ltr")}>
                            <Select
                                value={this.state.country}
                                onChange={this.handleCountryChange}
                                data-testId={"countrySelectTestId"}
                                className="selectManger"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="" className="defaultSelect">{t("Select Country")}</MenuItem>
                                {this.state.countryList.map((country: string, countryIndex: number)=>{
                                    return <MenuItem value={country} className="defaultSelect" key={countryIndex} data-testId={`countryItem${countryIndex}`}>{country}</MenuItem>
                                })}
                            </Select>
                            <Select
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                onChange={this.handleCityChange}
                                data-testId={"citySelectTestId"}
                                className="selectManger"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                value={this.state.city}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="" className="defaultSelect">{t("Select City")}</MenuItem>
                                {this.state.cityList.map((city: string, cityIndex: number)=>{
                                    return <MenuItem value={city} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                                })}
                            </Select>
                            <Select
                                value={this.state.propertyManagerId}
                                onChange={this.handlePropertyManagerIdChange}
                                data-testId={"propertyManagerIdSelectTestId"}
                                className="selectManger"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Property Manager Id")}</MenuItem>
                                {this.state.propertyManagerList.map((propertyManager: PropertyManagerListItem,propertyManagerIndex:number)=>{
                                    return <MenuItem value={propertyManager.id} key={propertyManagerIndex} className="defaultSelect">{propertyManager.id}</MenuItem>
                                })}
                            </Select>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SearchOutlined />}
                            className="searchButton"
                            dir={languageCondition(language, "rtl", "ltr")}
                            onClick={() => this.getPropertyManagerList("1")}
                            data-testId={"searchButtonTestId"}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Paper className="paperStyle">
                        <Box className="managerStyle" dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of assigned Sales Manager")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                <Select
                                    value={this.state.sortBy}
                                    onChange={this.handleSortByChange}
                                    data-testId={"sortBySelectTestId"}
                                    className="selectStyle"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    IconComponent={KeyboardArrowDownIcon}
                                    displayEmpty
                                    MenuProps={{
                                        style: { marginTop: "50px" }
                                    }}
                                    dir={languageCondition(language, "rtl", "ltr")}
                                >
                                    <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                        {t("Sort By")}
                                    </MenuItem>
                                    <MenuItem value={"asc"}>{t("Assending")}</MenuItem>
                                    <MenuItem value={"dsc"}>{t("Decending")}</MenuItem>
                                </Select>
                                <Input
                                    placeholder={t("Search By Name or Id")}
                                    disableUnderline
                                    name="search"
                                    startAdornment={<SearchOutlined />}
                                    className="searchInputStyle"
                                    value={this.state.searchText}
                                    onChange={this.handleSearchInputChange}
                                    data-testId={"searchInputTestId"}
                                    dir={languageCondition(language, "rtl", "ltr")}
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tabelOfPropertyManager()}
                    </Paper>
                </CooPropertyManagerStyle>
        )
    };

    tabelOfPropertyManager = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table dir={languageCondition(language, "rtl", "ltr")}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("#")}
                                </TableCell>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("Property Manager Name")}
                                </TableCell>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("Property Manager ID")}
                                </TableCell>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("Managed Units")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ paddingLeft: "10px" }}>
                            {this.state.propertyManagerList.map((accountManager: PropertyManagerListItem, accountManagerIndex: number) => (
                                <TableRow key={accountManager.id}>
                                    <TableCell>{accountManagerIndex + 1}</TableCell>
                                    <TableCell data-testId={`propertyManagerName${accountManagerIndex}`}>{accountManager.attributes.name}</TableCell>
                                    <TableCell>{accountManager.id}</TableCell>
                                    <TableCell>
                                        {" "}
                                        <Box className="tableMoreOption">
                                            {accountManager.id}{" "}
                                            <IconButton onClick={this.handleClick} data-testId={`tableMenuBtnTestId${accountManagerIndex}`}>
                                                <MoreIcon
                                                    className="threeDotsStyle"
                                                    fontSize="small"
                                                    
                                                />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData?.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    data-testId={"popoverTestId"}
                    id={popoverId}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        style: {
                            marginLeft: "20px",
                        },
                    }}
                    dir={languageCondition(language, "rtl", "ltr")}
                >
                    <MenuItem data-testId={"tableMenuItem1"} dir={languageCondition(language, "rtl", "ltr")}>{t("View Details")}</MenuItem>
                </Popover>
            </>
        );
    };

    renderAccountTable = () => {
        const open = Boolean(this.state.openPopOver);
        const { t }: { t: TFunction } = this.props;
        return (
            <CooAccountDetails>
                <Box className="headingContainer">
                    <Box className="selectSection">
                        <Select
                            data-testId={"companyTestId"}
                            onChange={this.handleCompanyhange}
                            value={this.state.company}
                            IconComponent={SelectArrowIcon}
                            className="selectData"
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            displayEmpty
                            variant="outlined"
                            MenuProps={{
                                style: { marginTop: "50px" }
                            }}
                        >
                            <MenuItem value="">{t("Select Company")}</MenuItem>
                            {this.state.companyList && this.state.companyList.map((companyListItem: CompanyListItem, companyIndex: number) => {
                                return <MenuItem value={companyListItem.attributes.name} key={companyIndex} data-testId={`companyListItem${companyIndex}`}>{companyListItem.attributes.name}</MenuItem>
                            })}
                        </Select>
                        <Select
                            MenuProps={{
                                style: { marginTop: "50px" }
                            }}
                            className="selectData"
                            onChange={this.handleCountryChange}
                            value={this.state.country}
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            IconComponent={SelectArrowIcon}
                            displayEmpty
                            variant="outlined"
                            data-testId={"accountTabCountryChangeTestId"}
                        >
                            <MenuItem value="">{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((countryListItem: string, countryIndex: number) => {
                                return <MenuItem key={countryIndex} value={countryListItem} data-testId={`countryListItem${countryIndex}`}>{countryListItem}</MenuItem>
                            })}
                        </Select>
                        <Select
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            IconComponent={SelectArrowIcon}
                            className="selectData"
                            id="demo-simple-select"
                            labelId="demo-simple-select-label"
                            displayEmpty
                            variant="outlined"
                            MenuProps={{
                                style: { marginTop: "50px" }
                            }}
                            data-testId={"accountTabCityChangeTestId"}
                        >
                            <MenuItem value="">{t("Selct City")}</MenuItem>
                            {this.state.cityList.map((cityListItem: string, cityIndex: number) => {
                                return <MenuItem value={cityListItem} key={cityIndex} data-testId={`cityItemTestId${cityIndex}`}>{cityListItem}</MenuItem>
                            })}
                        </Select>
                        <Button
                            startIcon={<SearchIcon />}
                            className="searchButton"
                            variant="contained"
                            color="primary"
                            data-testId={"accontTabSearchBtTestId"}
                            onClick={()=>this.getAccountManagerTabData("1")}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Box>
                        <Button className='assignButton' variant='contained'>{t("Assign")}</Button>
                    </Box>
                </Box>
                <Box className='tableContainer'>
                    <Box className="listHeading">
                        <Typography className="listPara" data-testId={"accountManagerTabHeadingTestId"}>
                            {t("List of assigned account managers")}
                        </Typography>
                        <Box>
                            <Select
                                value={this.state.sortBy}
                                onChange={this.handleSortByChange}
                                IconComponent={SelectArrowIcon}
                                id="demo-simple-select"
                                labelId="demo-simple-select-label"
                                displayEmpty
                                className="searchSortField"
                                variant="outlined"
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                data-testId={"accountTabSortByChangeTestId"}
                            >
                                <MenuItem value="">{t("Sort By")}</MenuItem>
                                <MenuItem value="asc">{t("Ascending")}</MenuItem>
                                <MenuItem value="dsc">{t("Descending")}</MenuItem>
                            </Select>
                            <TextField
                                variant="outlined"
                                className='searchNameField'
                                placeholder="Search by Name or ID"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon className='searchIcon' />
                                        </InputAdornment>
                                    ),
                                }}
                                data-testId={"accounTabSearchInput"}
                                onChange={this.handleSearchInput}
                                value={this.state.searchText}
                            />
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className="tableHeader">{t("#")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Account Manager")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("ID")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Email Address")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Assigned Complexes")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Assigned Buildings")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Assigned Owners")}</TableCell>
                                        <TableCell align="left" className="tableHeader">{t("Assigned Property Developers")}</TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.cooAccountData.map((cooAccount: CooAccountDataItem, cooAccountIndex: number) => (
                                        <TableRow>
                                            <TableCell align="left" className='tableContent'>{cooAccountIndex + 1}</TableCell>
                                            <TableCell align="left" className='tableContent' data-testId={`accountManagerName${cooAccountIndex}`}>{this.handleNullValue(cooAccount.attributes.full_name)}</TableCell>
                                            <TableCell align="left" className='tableContent'>{this.handleNullValue(cooAccount.id)}</TableCell>
                                            <TableCell align="left" className='tableContent'>{this.handleNullValue(cooAccount.attributes.email)}</TableCell>
                                            <TableCell align="left" className='tableContent'>{this.handleNullValue(cooAccount.attributes.assigend_complexes)}</TableCell>
                                            <TableCell align="left" className='tableContent'>{this.handleNullValue(cooAccount.attributes.assigend_buildings)}</TableCell>
                                            <TableCell align="left" className='tableContent'>{this.handleNullValue(cooAccount.attributes.assigend_owners)}</TableCell>
                                            <TableCell align="left" className='tableContent'>{this.handleNullValue(cooAccount.attributes.assigend_property_developers)}</TableCell>
                                            <TableCell align="left" className='tableContent'>
                                                <IconButton onClick={this.handleOpenPopover} data-testId={`accountTabTableMenuButtonTestId${cooAccountIndex}`}><MoreIcon /></IconButton>
                                                <Popover
                                                    anchorEl={this.state.openPopOver}
                                                    open={open}
                                                    onClose={this.handleClosePopover}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    data-testId={`accountTabTableMenu${cooAccountIndex}`}
                                                >
                                                    <MenuItem>{t("View Details")}</MenuItem>
                                                    <MenuItem>{t("Move to Another Sales Manager")}</MenuItem>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box className="paginationData">
                                <PaginationModule data-testId={"cooAccountPaginationTestId"} page={this.state.paginationData?.current_page} pagination={this.state.paginationData} handlePagination={this.handleCooAccountPagination} />
                            </Box>
                        </TableContainer>
                        
                    </Box>
                </Box>
            </CooAccountDetails>
        )
    };

    renderReassignMainForm = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box>
            <Modal open={this.state.openReassignModal} onClose={this.handleCloseReassignModal} data-testId={"reassignModal"}>
                <SalesManagerReassignForm>
                    <Paper elevation={2} className="mainModalContainer">
                    <Typography align="left" className="reassignModalHeading">{t("Assign to another Manager")}</Typography>
                    <Divider />
                    <Box className="reassignForm">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="inputModalLabel" id="demo-simple-select-filled-label">{t("Building ID")}</InputLabel>
                                    <TextField
                                        disabled
                                        className="modalMainInputField"
                                        variant="outlined"
                                        data-testId={"Buildingid"}
                                        fullWidth value={this.state.modalBuildingId}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="inputModalLabel" id="demo-simple-select-filled-label">{t("Building Name")}</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        disabled
                                        className="modalMainInputField"
                                        data-testId={"BuildingName"}
                                        fullWidth value={this.state.modalBuildingId}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="inputModalLabel" id="demo-simple-select-filled-label">{t("Sales Manager")}</InputLabel>
                                    <TextField
                                        data-testId={"SalesManager"}
                                        disabled
                                        className="modalMainInputField"
                                        fullWidth value={this.state.modalBuildingId}
                                        variant="outlined"
                                    />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="inputModalLabel" id="demo-simple-select-filled-label">{t("Current Account Manager")}</InputLabel>
                                <TextField variant="outlined"  data-testId={"AccountManager"} fullWidth value={this.state.modalBuildingId} disabled className="modalMainInputField" />
                            </Grid>
                            {
                                    this.state.userRole === "coo" &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <InputLabel className="inputModalLabel" id="demo-simple-select-filled">{t("New Sales Manager")}</InputLabel>
                                        <FormControl variant="outlined" fullWidth className="selectAccountManager">
                                            <Select
                                                value={this.state.salesManagerAssign}
                                                onChange={this.handleSalesManager}
                                                IconComponent={SelectArrowIcon}
                                                data-testId={"reassignSalesManagerChangeTestId"}
                                            >
                                                <MenuItem value="">{t("Select option")}</MenuItem>
                                                <MenuItem value={"Demo sales manager"}>{t("Demo sales manager")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <InputLabel className="inputModalLabel" id="demo-simple-select-filled-label">{t("New Account Manager")}</InputLabel>
                                <FormControl variant="outlined" fullWidth className="selectAccountManager">
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={this.state.accountManagerAssign}
                                        onChange={this.handleAccountManager}
                                        IconComponent={SelectArrowIcon}
                                        data-testId={"reassignAccountManagerChangeTestId"}
                                    >
                                        <MenuItem value="">{t("Select option")}</MenuItem>
                                        <MenuItem value={"Demo account manager"}>{t("Demo account manager")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className="inputModalLabel" id="demo-simple-select-filled-label">{t("Notes")}</InputLabel>
                                <TextareaAutosize value={this.state.noteText} onChange={this.handleNote} minRows={5}  placeholder="Notes" className="noteInput"  data-testId={"noteInputTestId"}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Box className="buttonContainer">
                                    <Button className="cancelBtn" variant="outlined" onClick={this.handleCloseReassignModal} data-testId={"reassignCancelBtnTestId"}>{t("Cancel")}</Button>
                                    <Button className="reassignBtn" variant="contained">{t("Reassign")}</Button>
                                </Box>
                            </Grid>
                    </Grid>
                    </Box>
                    </Paper>
                </SalesManagerReassignForm>
            </Modal>
        </Box>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.handleUserType('coo') && this.renderCooOwner()}
                {this.handleUserType('sales_manager') && this.renderSalesOwner()}
                {this.handleUserType('account_manager') && this.renderAccountOwner()}
                {this.renderReassignMainForm()}
                <AlertError show={this.state.showError} handleClose={this.handleErrorAlert} message={this.state.showErrorMessage} data-testId={"errorAlertTestId"} />
                <Loader loading={this.state.loader}/>
                <MapDialog
                    t={this.props.t}
                    IsOpen={this.state.openBuildingModal}
                    CloseDialog={this.handleCloseViewBuilding}
                    lat={this.state.latitude}
                    long={this.state.longitude}
                    data-testId={"viewBuildingModalTestId"}
                />
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(SalesManagerProfile);
export { SalesManagerProfile };
const AccountOwnerDetails = styled(Box)({
    padding: "30px",
    background: "#F4F7FF",
    "& .navigationHeading": {
        fontSize: "16px",
        color: "black",
        fontWeight: 400,
    },
    "& .navigateRouteActive": {
        color: "#4480ec",
    },
    "& .ownerName": {
        fontSize: "28px",
        fontWeight: 800,
        marginBottom: "20px",
        marginTop: "20px",
        lineHeight: "27px",
    },
    "& .generalDetails": {
        fontSize: "23px",
        fontWeight: 700,
        marginTop: "15px",
        marginBottom: "15px",
        lineHeight: "27px",
    },
    "& .addUnit": {
        fontWeight: 700,
        color: "#FC8434",
        "&.MuiButton-text": {
            padding: 0
        }
    },
    "& .hobbiesList": {
        color: "#FC8434",
        fontWeight: 600,
        marginRight: "10px",
        background: "#FFF6EF",
        "&.MuiChip-root": {
            width: "95px",
            height: "25px",
        }
    },
    "& .ownerLabel": {
        background: "#EEF4FE",
        fontWeight: 600,
        color: "#3779EE",
        "&.MuiChip-root": {
            width: "95px",
            height: "25px",
        }
    },
    "& .aboutHeading": {
        fontWeight: 600,
        color: "#A4A5A6",
        marginBottom: "5px",
        fontSize: "16px",
    },
    "& .aboutPara": {
        marginTop: "10px",
        textAlign: "justify",
    },
    "& .socialIcons": {
        marginTop: "15px",
        background: "#FFF6EF",
        padding: "5px",
        borderRadius: "10px",
        marginBottom: "15px",
    },
    "& .tabsChild1": {
        paddingLeft: 0,
        padding: 10,
    },
    "& .profileSection": {
        padding: "20px",
        background: "white",
        height: "250px",
        borderBottomLeftRadius: "10px",
        borderTopLeftRadius: "10px",
    },
    "& .profileIcons": {
        width: "130px",
        display: "flex",
        justifyContent: 'space-between',
    },
    "& .aboutSection": {
        padding: "20px",
        background: "white",
        height: "250px",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px",

    },
    "& .aboutDetails": {
        marginBottom: "15px",
        marginTop: "15px",
    },
    "& .tabSection": {
        justifyContent: "space-between",
        display: "flex",
    },
});

const TabsHeader = styled(Tabs)({
    marginTop: "20px",
    "& .MuiTabs-indicator": {
        background: "none !important"
    },
    "& .MuiTab-textColorInherit": {
        fontSize: 16,
        fontWeight: 600,
        color: 'white !important',
        background: "#A4A5A6 !important",
        borderRadius: "20px !important",
        textTransform: 'capitalize',
        marginRight: "20px",
        lineHeight: '24px !important',
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
        lineHeight: '24px !important',
        fontSize: 16,
        background: "#3779EE !important",
        borderRadius: "20px !important",
        color: 'white !important',
        marginRight: "20px",
        textTransform: 'capitalize',
        fontWeight: 600,
    },
});

const IndividualBuilding = styled(Box)({
    "& .headingContainer": {
        flexWrap: "wrap",
        display: "flex",
    },
    "& .selectData": {
        width: "190px",
        display: "flex",
        background: "white",
        marginRight: "15px",
        flexWrap: "wrap",
        border: "1px solid #ededed",
        marginTop: "15px",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
    },
    "& .searchButton": {
        textTransform: "capitalize",
        borderRadius: "10px",
        fontSize: "20px",
        fontWeight: 700,
        marginTop: "15px",
        marginLeft: "15px",
        height: "55px",
        backgroundColor: "#2b6fed",
    },
    "& .listHeading": {
        display: "flex",
        padding: "15px",
        alignItems: "center",
        marginBottom: "5px",
        justifyContent: "space-between",
    },
    "& .listPara": {
        fontSize: "22px",
        lineHeight: "27px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        fontSize: "18px",
        color: "black",
        fontWeight: 700,
    },
    "& .MuiTableCell-alignLeft": {
        alignSelf: "left",
        textAlign: "left",
    },
    "& .rentedStatus": {
        fontWeight: 700,
        color: "#2B6FEC",
        background: "#E8F0FD",
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .tableContainer": {
        borderRadius: "20px",
        background: "white",
        marginTop: "20px",
    },
});

const ComplexDetails = styled(Box)({
    "& .headingContainer": {
        flexWrap: "wrap",
        display: "flex",
    },
    "& .selectData": {
        width: "190px",
        background: "white",
        marginRight: "15px",
        display: "flex",
        border: "1px solid #ededed",
        flexWrap: "wrap",
        marginTop: "15px",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
    },
    "& .searchButton": {
        textTransform: "capitalize",
        fontWeight: 700,
        borderRadius: "10px",
        marginLeft: "15px",
        fontSize: "20px",
        height: "55px",
        backgroundColor: "#2b6fed",
        marginTop: "15px",
    },
    "& .listHeading": {
        justifyContent: "space-between",
        padding: "15px",
        display: "flex",
        marginBottom: "5px",
        alignItems: "center",
    },
    "& .listPara": {
        fontWeight: 700,
        lineHeight: "27px",
        fontSize: "22px",
    },
    "& .tableHeading": {
        fontSize: "18px",
        color: "black",
        fontWeight: 700,
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .rentedStatus": {
        background: "#E8F0FD",
        fontWeight: 700,
        color: "#2B6FEC",
    },
    "& .tableContainer": {
        background: "white",
        borderRadius: "20px",
        marginTop: "20px",
    },
});

const SearchTextField = styled(TextField)({
    background: "white",
    width: "260px",
    border: "1px solid #ededed",
    borderRadius: "15px",
    "& .MuiOutlinedInput-input": {
        padding: "14.5px 40px",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px"
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
});

const SalesOwnerDetails = styled(Box)({
    padding: "30px",
    background: "#F4F7FF",
    "& .navigationTopHeading": {
        fontSize: "16px",
        color: "black",
        fontWeight: 400,
    },

    "& .navigateRouteActive": {
        color: "#4480ec",
    },
    "& .ownerName": {
        fontSize: "28px",
        fontWeight: 700,
        marginTop: "15px",
        lineHeight: "27px",
        marginBottom: "15px",
    },
    "& .generalDetails": {
        fontSize: "23px",
        fontWeight: 700,
        marginBottom: "15px",
        marginTop: "15px",
        lineHeight: "27px",
    },
    "& .addUnit": {
        fontWeight: 700,
        color: "#FC8434",
        "&.MuiButton-text": {
            padding: 0
        }
    },
    "& .hobbiesList": {
        color: "#FC8434",
        marginRight: "10px",
        fontWeight: 600,
        background: "#FFF6EF",
        "&.MuiChip-root": {
            width: "95px",
            height: "25px",
        }
    },
    "& .ownerLabel": {
        background: "#EEF4FE",
        fontWeight: 600,
        color: "#3779EE",
        "&.MuiChip-root": {
            height: "25px",
            width: "95px"
        }
    },
    "& .payingLabel": {
        fontWeight: 600,
        background: "#E2FFF4",
        color: "#42CA64",
        "&.MuiChip-root": {
            height: "25px",
        }
    },
    "& .profileLabel": {
        width: "130px",
        display: "flex",
        gap: 10,
    },
    "& .aboutHeading": {
        fontWeight: 600,
        color: "#A4A5A6",
        marginBottom: "5px",
        fontSize: "16px",
    },
    "& .aboutPara": {
        marginTop: "10px",
        textAlign: "justify",
    },
    "& .socialIcons": {
        marginBottom: "15px",
        background: "#FFF6EF",
        marginTop: "15px",
        padding: "5px",
        borderRadius: "10px",
    },
    "& .tabsChild1": {
        paddingLeft: 0,
        padding: 10,
    },
    "& .profileSection": {
        padding: "20px",
        background: "white",
        height: "250px",
        borderBottomLeftRadius: "10px",
        borderTopLeftRadius: "10px",
    },
    "& .profileIcons": {
        width: "130px",
        justifyContent: 'space-between',
        display: "flex",
    },
    "& .aboutSection": {
        borderTopRightRadius: "10px",
        padding: "20px",
        background: "white",
        height: "250px",
        borderBottomRightRadius: "10px",

    },
    "& .aboutDetails": {
        marginBottom: "15px",
        marginTop: "15px",
    },
    "& .tabSection": {
        display: "flex",
        justifyContent: "space-between"
    },
});

const SalesBuildingDetails = styled(Box)({
    "& .headingContainer": {
        flexWrap: "wrap",
        display: "flex",
    },
    "& .selectData": {
        width: "190px",
        background: "white",
        display: "flex",
        marginRight: "15px",
        border: "1px solid #ededed",
        marginTop: "15px",
        flexWrap: "wrap",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
    },
    "& .searchButton": {
        textTransform: "capitalize",
        borderRadius: "10px",
        fontSize: "20px",
        fontWeight: 700,
        marginLeft: "15px",
        backgroundColor: "#2b6fed",
        height: "55px",
        marginTop: "15px",
    },
    "& .listHeading": {
        display: "flex",
        padding: "15px",
        marginBottom: "5px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    "& .listPara": {
        fontSize: "22px",
        lineHeight: "27px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        fontSize: "18px",
        color: "black",
        fontWeight: 700,
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .rentedStatus": {
        background: "#E8F0FD",
        fontWeight: 700,
        color: "#2B6FEC",
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .tableContainer": {
        marginTop: "20px",
        borderRadius: "20px",
        background: "white",
    },
    "& .tableContent": {
        marginLeft: "10px",
        fontWeight: 600,
    },
});

const SalesComplexDetails = styled(Box)({
    "& .headingContainer": {
        flexWrap: "wrap",
        display: "flex",
    },
    "& .selectData": {
        width: "190px",
        background: "white",
        display: "flex",
        marginRight: "15px",
        border: "1px solid #ededed",
        marginTop: "15px",
        flexWrap: "wrap",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
    },
    "& .searchButton": {
        textTransform: "capitalize",
        borderRadius: "10px",
        fontSize: "20px",
        fontWeight: 700,
        marginLeft: "15px",
        backgroundColor: "#2b6fed",
        marginTop: "15px",
        height: "55px",
    },
    "& .listHeading": {
        display: "flex",
        padding: "15px",
        marginBottom: "5px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    "& .listPara": {
        fontSize: "22px",
        lineHeight: "27px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        fontSize: "18px",
        color: "black",
        fontWeight: 700,
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .rentedStatus": {
        background: "#E8F0FD",
        fontWeight: 700,
        color: "#2B6FEC",
    },
    "& .tableContainer": {
        marginTop: "20px",
        borderRadius: "20px",
        background: "white",
    },
    "& .tableContent": {
        marginLeft: "10px",
        fontWeight: 600,
    },
});

const CooOwnerDetails = styled(Box)({
    padding: "30px",
    background: "#F4F7FF",
    "& .topHeading": {
        fontSize: "16px",
        color: "black",
        fontWeight: 400,
    },

    "& .navigateRouteActive": {
        color: "#4480ec",
    },
    "& .userProfile": {
        height: "120px",
        borderRadius: "50%",
        width: "120px",
    },
    "& .salesManagerName": {
        fontSize: "28px",
        fontWeight: 700,
        marginBottom: "15px",
        marginTop: "15px",
        lineHeight: "27px",
    },
    "& .generalDetails": {
        fontSize: "23px",
        fontWeight: 700,
        marginBottom: "15px",
        marginTop: "15px",
        lineHeight: "27px",
    },
    "& .addUnit": {
        background: "#FC8434",
        fontWeight: 700,
        color: "white",
        "&.MuiButton-text": {
            padding: 0
        }
    },
    "& .hobbiesList": {
        background: "#FFF6EF",
        color: "#FC8434",
        marginRight: "10px",
        fontWeight: 600,
        "&.MuiChip-root": {
            width: "95px",
            height: "25px",
        }
    },
    "& .payingLabel": {
        background: "#EEF4FE",
        fontWeight: 600,
        color: "#3779EE",
        "&.MuiChip-root": {
            height: "25px",
        }
    },
    "& .aboutHeading": {
        fontWeight: 600,
        color: "#A4A5A6",
        marginBottom: "5px",
        fontSize: "16px",
    },
    "& .aboutPara": {
        marginTop: "10px",
        textAlign: "justify",
    },
    "& .socialIcons": {
        marginTop: "15px",
        background: "#FFF6EF",
        padding: "5px",
        borderRadius: "10px",
        marginBottom: "15px",
    },
    "& .tabsChild1": {
        paddingLeft: 0,
        padding: 10,
    },
    "& .profileSection": {
        padding: "20px",
        background: "white",
        borderBottomLeftRadius: "10px",
        height: "250px",
        borderTopLeftRadius: "10px",
    },
    "& .profileIcons": {
        gap: 10,
        display: "flex",
    },
    "& .aboutSection": {
        padding: "20px",
        background: "white",
        borderBottomRightRadius: "10px",
        height: "250px",
        borderTopRightRadius: "10px",
    },
    "& .aboutDetails": {
        marginTop: "15px",
        marginBottom: "15px"
    },

});

const CooComplexDetails = styled(Box)({
    "& .headingContainer": {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
    },
    "& .selectSection": {
        display: "flex"
    },
    "& .selectData": {
        width: "190px",
        flexWrap: "wrap",
        background: "white",
        display: "flex",
        marginRight: "15px",
        border: "1px solid #ededed",
        marginTop: "15px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        }
    },
    "& .searchButton": {
        textTransform: "capitalize",
        fontSize: "20px",
        fontWeight: 700,
        borderRadius: "10px",
        marginLeft: "15px",
        height: "55px",
        backgroundColor: "#2b6fed",
        marginTop: "15px",
    },
    "& .assignButton": {
        fontWeight: 700,
        borderRadius: "10px",
        textTransform: "capitalize",
        height: "55px",
        fontSize: "20px",
        background: "#fc8e45",
        color: "white",
        width: "150px",
        marginTop: "15px",
    },
    "& .listHeading": {
        justifyContent: "space-between",
        padding: "15px",
        display: "flex",
        marginBottom: "5px",
        alignItems: "center",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontWeight: 700,
        fontSize: "22px",
    },
    "& .complexTableHeading": {
        color: "black",
        fontWeight: 700,
        fontSize: "18px",
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .rentedStatus": {
        fontWeight: 700,
        color: "#2B6FEC",
        background: "#E8F0FD",
    },
    "& .tableContainer": {
        marginTop: "20px",
        borderRadius: "20px",
        background: "white",
    },
    "& .tableContent": {
        fontWeight: 500,
        marginLeft: "10px"

    },
    "& .tableContentComplex": {
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    "& .paginationData": {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        margin: "20px",
    },
    "& .paginationBox": {
        "& .MuiPaginationItem-page.Mui-selected": {
            color: "#fff",
            background: "#fc8e45",
        },
    },
    "& .resultText": {
        fontWeight: 700
    },
    "& .orangeFilterText": {
        fontWeight: 700,
        color: "#fc8e45",
    },
});

const CooAccountDetails = styled(Box)({
    "& .headingContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .selectData": {
        background: "white",
        width: "190px",
        marginRight: "15px",
        flexWrap: "wrap",
        border: "1px solid #ededed",
        marginTop: "15px",
        display: "flex",
        "&.MuiOutlinedInput-root": {
            borderRadius: "10px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            paddingRight: "110px",
        }, 
    },
    "& .selectSection": {
        display: "flex"
    },
    "& .searchButton": {
        textTransform: "capitalize",
        fontSize: "20px",
        fontWeight: 700,
        marginLeft: "15px",
        borderRadius: "10px",
        height: "55px",
        backgroundColor: "#2b6fed",
        marginTop: "15px",
    },
    "& .listHeading": {
        display: "flex",
        padding: "15px",
        alignItems: "center",
        marginBottom: "5px",
        justifyContent: "space-between",
    },
    "& .listPara": {
        fontSize: "22px",
        lineHeight: "27px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        color: "black",
        fontSize: "18px",
        fontWeight: 700,
    },
    "& .MuiTableCell-alignLeft": {
        textAlign: "left"
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .rentedStatus": {
        background: "#E8F0FD",
        fontWeight: 700,
        color: "#2B6FEC",
    },
    "& .tableContainer": {
        borderRadius: "20px",
        marginTop: "20px",
        background: "white",
    },
    "& .tableContent": {
        marginLeft: "10px",
        fontWeight: 600,
    },
    "& .searchSortField": {
        width: "150px",
        background: "white",
        borderRadius: "15px",
        marginRight: "15px",
        border: "1px solid  #ededed",
        "& .MuiOutlinedInput-input": {
            padding: "14.5px",
        },
        "& .MuiInputAdornment-positionStart": {
            marginRight: "3",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "15px"
        }
    },
    "& .searchNameField": {
        width: "260px",
        background: "white",
        borderRadius: "15px",
        border: "1px solid  #ededed",
        "& .MuiOutlinedInput-input": {
            padding: "14.5px 40px",
        },
        "& .MuiInputAdornment-positionStart": {
            marginRight: "3",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "15px"
        }
    },
    "& .paginationData": {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px",
        alignItems: "center"
    },
    "& .paginationBox": {
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#fc8e45",
            color: "#fff"
        },
    },
    "& .assignButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        color: "white",
        background: "#fc8e45",
        marginTop: "15px",
        width: "150px"
    },
});

const TotalIndividual = styled(Box)({
    backgroundColor: "#F4F7FF",
    "& .tabActive": {
        fontSize: "18px",
        color: "#2b6fed"
    },
    "& .headingContainer": {
        flexWrap: "wrap",
        gap: "15px",
        display: "flex",
    },
    "& .listHeader": {
        display: "flex",
        padding: "15px",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5px",
        marginTop: "15px",
    },
    "& .searchButton": {
        textTransform: "capitalize",
        borderRadius: "10px",
        height: "50px",
        fontSize: "20px",
        fontWeight: 700,
        marginLeft: "15px",
        backgroundColor: "#2b70ed",
        marginTop: "15px",
    },
    "& .buildingSearchIcon": {
        color: "#808080"
    },
    "& .tableHeaderContainer": {
        borderRadius: "10px"
    },
    "& .navigateLink": {
        cursor: "pointer",
        fontWeight: 400,
        color: "black",
        fontSize: "16px",
    },
    "& .companiesText": {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "27px",
        marginTop: "25px",
        marginBottom: "25px",
    },
    "& .listPara": {
        fontSize: "22px",
        fontWeight: 700,
        lineHeight: "27px",
    },
    "& .tableHeading": {
        fontSize: "18px",
        fontWeight: 700,
        color: "black",
    },
    "& .statusField": {
        borderRadius: "20px",
        fontWeight: 700,
        textTransform: "capitalize",
        backgroundColor: "#E4F8EB",
        color: "#40CE74",
    },
    "& .errorMsg": {
        fontWeight: 700,
        backgroundColor: "#FEF0E6",
        color: "#FC924B",
        textTransform: "capitalize",
        borderRadius: "20px",
    },"& .filterContent": {
        color: "fc8e45",
        fontWeight: 700
    },
    "& .spanContent": {
        fontWeight: 700
    },
});

const SelectData = styled(Select)({
    background: "white",
    marginTop: "15px",
    border: "1px solid #f0f0f3",
    borderRadius: "10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "40px",
        border: "1px solid #f0f0f3",
        borderRadius: "10px",
    },
});

const PaginationData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff"
    },
});

const SearchField = styled(TextField)({
    width: "260px",
    border: "1px solid #f0f0f3",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
        padding: "14.5px 0px",
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
    }
});

const RegisteredOwnerContainer = styled(Box)({
    backgroundColor: "#F4F7FF",
    height: "100vh",
    overflow: "scroll",
    "& .registeredOwnerTopSectionContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "15px"
    },
    "& .registerHeading": {
        fontWeight: "900"
    },
    "& .textActive": {
        color: "#3f7cef"
    },
    "& .registeredOwnerTableContainer": {
        borderRadius: "10px"
    },
    "& .registeredOwnerTableTopContainer": {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        alignItems: "center"
    },
    "& .searchContainer": {
        display: "flex",
        gap: "20px"
    },
    "& .sortBySearch": {
        width: "180px",
        backgroundColor: "white",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA"
    },
    "& .payingClientsSelect": {
        width: "180px",
        backgroundColor: "white",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA"
    },
    "& .tableHeading": {
        fontWeight: "900"
    },
    "& .searchTextField": {
        width: "270px",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            height: "51px",
            border: "1px solid #EAEAEA",
        },
        "& .MuiOutlinedInput-inputAdornedStart": {
            paddingLeft: "45px"
        }
    },
    "& .searchIcon": {
        color: "#777a7f"
    },
    "& .tableContainer": {
        height: "100%",
        overflow: "scroll"
    },
    "& .tableColumnHeading": {
        fontWeight: "700"
    },
    "& .tableColumnHeadingOwnerStatus": {
        width: "250px",
        fontWeight: "700"
    },
    "& .ownerStatusBlue": {
        backgroundColor: "#e6edfc",
        color: "#3f7dee",
        fontWeight: "700",
        width: "100px"
    },
    "& .ownerStatusOrange": {
        backgroundColor: "#fef0e6",
        color: "#fc924b",
        fontWeight: "700",
        width: "200px"
    },
    "& .ownerStatusGreen": {
        backgroundColor: "#e4f8eb",
        color: "#40ce74",
        fontWeight: "700",
        width: "100px"
    },
    "& .ownerStatusChipContainer": {
        display: "flex",
        flexDirection: "column"
    },
    "& .ownerStatusContainer": {
        display: "flex",
        justifyContent: "space-between",
        width: "300px"
    },
    "& .MuiIconButton-root": {
        padding: "10px 0px",
        width: "40px"
    },
    "& .tablePaginationContainer": {
        padding: "20px",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .colorOrange": {
        color: "#fc8e45"
    },
    "& .colorBold": {
        fontWeight: "900"
    },
    "& .paginationContainer": {
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#fc8e45",
            border: "none",
            color: "#fff"
        },
        "& .MuiPaginationItem-rounded": {
            color: "#ebeced"
        }
    },
    "& .searchFilterContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .searchBtn": {
        backgroundColor: "#2a6fed",
        height: "45px",
        color: "#fff",
        borderRadius: "10px"
    },
    "& .searchbtnIcon": {
        color: "#ffff"
    }
});

const CooPropertyManagerStyle = styled(Box)({
    backgroundColor: "#f5f7ff",
    "& .headingManger": {
        fontSize: "24px",
        fontWeight: 600,
    },
    "& .tableHeaderStyle": {
        fontWeight: 900,
        fontSize: "14px",
    },
    "& .mangingDashboard": {
        fontSize: "18px",
        fontWeight: 400,
        color: "#3D424B",
    },
    "& .assignedAccount": {
        color: "#3e7aeb",
    },
    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },
    "& .selectAllItem": {
        display: "flex",
        alignItems: "center",
    },
    "& .tableMoreOption": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .selctStyles": {
        display: "flex",
        marginTop: "20px",
        alignItems: "center",
        color: "grey",
    },
    "& .dividerStyle": {
        height: "1px",
        backgroundColor: "#e9eaeb",
    },
    "& .selectManger": {
        background: "white",
        display: "flex",
        marginRight: "22px",
        width: "200px",
        flexWrap: "wrap",
        color: "grey",
        border: "1px solid #EBEBEC",
        borderRadius: "10px",
        height: "45px",
        padding: "0px 20px 0px 10px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {
        },
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
    },
    "& .searchButton": {
        backgroundColor: "#2b70ed",
        borderRaduis: "10px",
        height: "40px",
        padding: "15px 25px",
        color: "white",
    },
    "& .managerStyle": {
        padding: "20px",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
    },
    "& .selectStyle": {
        height: "40px",
        border: "1px solid #EBEBEC",
        marginRight: "15px",
        width: "130px",
        color: "grey",
        padding: "0px 15px",
        borderRadius: "8px",
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "& .MuiOutlinedInput-input ": {
            padding: "10px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
    },
    "& .paperStyle": {
        marginTop: "30px",
        width: "100%",
    },
    "& .selectStyles": {
        alignItems: "center",
        justifyContent: "space-Between",
        display: "flex",
    },
    "& .paginationStyle": {
        justifyContent: "space-between",
        display: "flex",
        marginLeft: "10px",
        padding: "20px",
        alignItems: "center",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-outlined": {
            color: "grey",
            border: " 1px solid #EBEBEC",
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            color: "white",
            background: "#FC8E45",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 800,
    },
    "& .defaultSelect": {
        color: "#EBEBEC",
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .searchInputStyle": {
        width: 240,
        height: 40,
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        border: "1px solid #EBEBEC",
        padding: "0px 15px 0px 15px",
    },
});

const SalesManagerReassignForm = styled(Box)({
    alignItems: "center",
    display: "flex",
    height:"100vh",
    margin:"auto",
    borderRadius:"20px",
    width:"50%",
    "& .reassignModalHeading":{
        fontWeight:900,
        fontSize:"25px",
        margin:"20px 0px 20px 20px",
        textAlign:"left",
    },
    "& .MuiDivider-root":{
        width:"100%"
    },
    "& .reassignForm":{
        padding:"20px"
    },
    "& .modalMainInputField":{
        border:"1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor:"#f0f0f0",
        borderRadius:"8px",
    },
    "& .noteInput":{
        padding:"10px",
        width:"100%",
        border:"1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor:"#f0f0f0",
        borderRadius:"10px",
    },
    "& .selectAccountManager":{
        backgroundColor:"#f0f0f0",
        borderRadius:"10px",
        border:"1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .inputModalLabel":{
        color:"black",
        marginBottom:"10px",
    },
    "& .mainModalContainer":{
        borderRadius:"20px",
        overflow:"scroll",
        height:"95%",
    },
    "& .buttonContainer":{
        justifyContent:"flex-end",
        alignItems:"center",
        gap:"20px",
        display:"flex",
    },
    "& .reassignBtn": {
        width:"150px",
        textTransform: "capitalize",
        backgroundColor: "#2B6FED",
        padding:"9px 0px",
        color: "#fff",
        borderRadius: "7px",
        fontWeight: 500,
        fontSize: "18px",
    },
    "& .cancelBtn": {
        borderRadius: "7px",
        border:"1px solid #2B6FED",
        width:"150px",
        textTransform: "capitalize",
        padding:"9px 0px",
        color: "#2B6FED",
        fontSize: "18px",
        fontWeight: 500,
    },
});
// Customizable Area End