// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import React,{ChangeEvent} from "react";
import { IdCartImage } from "./assets";
export const configJSON = require("./config");
const navigation = require("react-navigation");
import moment from "moment";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t: (value: string) => string;
  navigation:typeof navigation;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  openFindPersonModal: boolean;
  openFindShowPersonModal: boolean;
  country:string;
  hasSuccessError: HasSuccessErrorType;
  countryList: Array<string>;
  cityList: Array<string>;
  buildingList: Array<SingleBuilding>;
  complexList: Array<SingleComplex>;
  unitNumberList: Array<UnitNumberinterface>;
  currencyList:Array<Currency>;
  findPersonList:Array<GetFindPersonListItem>;
  selectedFindPerson:GetFindPersonListItem;
  tenantName:string;
  tenantID:string
  owenerName:string;
  tenantProfile:string;
  ownerProfile:string;
  ownerID:string;
  selectedCountry:string;
  selectedCity:string;
  selectedComplex:string;
  societyManagementId:number;
  buildingId:number;
  unitId:number;
  complex:string;
  complexSelectList:Array<SingleComplex>;
  selectedBuilding:string;
  agrementDuration: string;
  selectedDate:string;
  selectedRentAmount:string;
  selectedCurrency:string;
  unitValue:string;
  findPersonDate:string;
  findPersonIdNumber: string;
  findPersonPassportNumber: string;
  findPersonEmail: string;
  findPersonPhoneNumber: string;
  showError:boolean;
  error:string;
  contractData:ContractData;
  searchDetails: string;
    // Customizable Area End
}

// Customizable Area Start
export interface ContractData{
  country: string;
  templateId: string;
  complex: string;
  city: string;
  unitName: string;
  buildingName: string;
  tenant_name: TenantNameInterface;
  complexName: string;
  building_management_id: string;
  landlord_name: string;
  tenant_id: string;
  society_management_id: string;
  apartment_management_id: string;
  expires_on: string;
  start_date: string;
  agreement_duration: string;
  lease_template_id: string;
  term_ids: Array<number>;
  terms_label: Array<string>;
  currency: string;
  rent_amount: string;
  custom_contract: boolean;
  condition_ids: Array<number>;
  conditions_label: Array<string>;
  penanlty_late_payment: boolean;
  penalty_type: string;
  penalty_amount: string;
  contract_template: string;
  owner: string | null;
  ownerNumber: string | null;
  accountId: Account_id;
  ownerEmail: string | null;
  status: string;
  contractLink: string;
  isTenantNameEnabled: boolean;
  }

  type Account_id = string | number | null
  type TenantNameInterface = string | number | null
export interface SingleBuilding  {
  id: number,
  name: string,
  society_management_id: number,
  description: null,
  created_at: string,
  updated_at: string,
  per_floor_unit: number,
  generation_methods: string,
  number_of_floor: number,
  building_area: string,
  account_id: null | string | number,
  lat: null,
  long: null,
  city: null,
  country: null,
  googlemaplocation: null,
  real_estate_company_id: null
}

export interface GetCountryListSuccessResponse {
  data: {
      countries: Array<string>
  }
}

export interface GetCityListSuccessResponse {
  data: {
      cities: Array<string>
  }
}

export interface GetBuildingSuccessResponse {
  data: {
      buildings: Array<SingleBuilding>
  }
}

export interface SingleBuilding  {
  id: number,
  name: string,
  society_management_id: number,
  description: null,
  created_at: string,
  updated_at: string,
  per_floor_unit: number,
  generation_methods: string,
  number_of_floor: number,
  building_area: string,
  account_id: null | string | number,
  lat: null,
  long: null,
  city: null,
  country: null,
  googlemaplocation: null,
  real_estate_company_id: null
}

interface ComplexData {
  data: Array<SingleComplex>
}

export interface SingleComplex {
  type: string;
  id: string;
  attributes: {
      name: string;
      complex_area: string;
      measurement_unit: null;
      maintenance_per_square_feet: null;
      is_building: boolean;
      total_floor: null;
      total_unit: null;
      currency_id: null;
      language: null;
      account_manager_id: null;
      status: null;
      description: string;
      logo: {
        content_type: string;
        file_name: string
        url: string;
      };
      photos: [
          {
            url: string;
            file_name: string
            content_type: string;
          }
      ];
      subscription_id: null;
      subscription_end_in: null;
      building_list: [];
      is_building_list: null;
      shared_area: [
          {
            name: string;
            id: number;
            created_at: string;
            updated_at: string;
            details: string;
            total_area: string;
            currency_id: null;
            reservation_fee: number
            society_management_id: number;
          }
      ];
      building_documents: [];
      documents: [];
      incident_categories: {
        Carpenter: number;
        Electrician: number
        Plumbing: number;
      };
      active_users: null;
      inactive_users: null;
      pending_ownership_requests: number;
      collected_management_fee: null;
      company_name: null;
      rented: number;
      total_polls: number;
      total_buildings: number;
      total_surveys: number;
      last_date_of_surveys: null;
      building_ownership_rate: null;
      last_date_of_polls: null;
      ownership_onboarded: {
        ownership_count: number
          ownership_percent: string;
      };
      tenant_resquests: {
        tenant_count: number;
        tenant_percent: number;
      };
      sales_manager: null;
      country: string;
      streetname: string;
      total_units: [];
      lat: null;
      account_manager: null;
      long: null;
      city: string;
      zipcode: string;
      landmark: string;
      locationlink: null;
      complex_long: null;
      complex_lat: null;
      currency: {
        id: number;
          currency_ar: string;
          currency: string;
          updated_at: string;
          created_at: string;
      };
      plan_id: null;
      paying_month: null;
      subscription_type: null;
      googlemaplocation: null;
      jurisdiction: {
          data: {
              id: string;
              type: string;
              attributes: {
                  id: number;
                  jurisdiction_id: number;
                  jurisdiction_name: string;
                  currency: string;
                  country: string;
                  timezone: string;
                  zone_multiplier: number;
                  unit_of_measurement: string;
                  mobile_number_length: string;
                  formula: string;
                  value_added_tax: number;
                  complex: [
                      {
                          id: number;
                          description: string;
                          created_at: string;
                          updated_at: string;
                          complex_area: string;
                          maintenance_per_square_feet: null;
                          name: string;
                          ticket_days_configuration: number;
                          contigency: null;
                          zone_multiplier: null;
                          late_charge_percentage: null;
                          latitude: null;
                          longitude: null;
                          status: null
                          value_added_tax: null;
                          is_building: boolean;
                          real_estate_company_id: null;
                          measurement_unit: null;
                          total_floor: null;
                          currency_id: null;
                          account_manager_id: null;
                          language: null;
                          total_unit: null;
                      }
                  ];
                  total_buildings: number;
                  total_units: number;
                  contracts: [];
                  total_complex: number;
              }
          }
      }
  }
}

interface SocietyAttributes {
  name: string;
  description: string;
  complex_area: string;
  measurement_unit: string | null;
  maintenance_per_square_feet: number;
  is_building: boolean;
  total_floor: number | null;
  total_unit: number | null;
  currency_id: number | null;
  account_manager_id: number | null;
  language: string | null;
  logo: SocietyPhoto;
  photos: SocietyPhoto[];
  subscription_end_in: string | null;
  subscription_id: string | null;
  is_building_list: boolean | null;
  building_list: [];
  shared_area: [];
  documents: [];
  building_documents: [];
  incident_categories: Record<string, number>;
  total_buildings: number;
  active_users: number | null;
  inactive_users: number | null;
  pending_ownership_requests: number;
  collected_management_fee: number | null;
  status: string;
  company_name: string;
  rented: number;
  total_polls: number;
  last_date_of_polls: string | null;
  total_surveys: number;
  last_date_of_surveys: string | null;
  building_ownership_rate: number | null;
  ownership_onboarded: {
    ownership_percent: string;
    ownership_count: number;
  };
  tenant_resquests: {
    tenant_percent: number;
    tenant_count: number;
  };
  total_units: [];
  account_manager: null;
  sales_manager: null;
  country: string;
  city: string;
  lat: number;
  long: number;
  streetname: string | null;
  zipcode: string;
  landmark: string;
  locationlink: string;
  complex_lat: number | null;
  complex_long: number | null;
  currency: string | null;
  subscription_type: string | null;
  plan_id: string | null;
  paying_month: string | null;
  googlemaplocation: string | null;
  jurisdiction: JurisdictionData;
}

interface SocietyPhoto {
  url: string;
  content_type: string;
  file_name: string;
}

interface JurisdictionData {
  data: {
    id: string;
    type: string;
    attributes: JurisdictionAttributes;
  };
}

interface JurisdictionAttributes {
  id: number;
  jurisdiction_id: number;
  jurisdiction_name: string;
  currency: string;
  country: string | null;
  timezone: string;
  unit_of_measurement: string;
  mobile_number_length: string;
  formula: string;
  value_added_tax: string | null;
  zone_multiplier: number | null;
  complex: Complex[];
  total_complex: number;
  total_buildings: number;
  total_units: number;
  contracts: [];
}

interface Complex {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  complex_area: string;
  maintenance_per_square_feet: number;
  measurement_unit: string | null;
  ticket_days_configuration: number;
  contigency: number | null;
  zone_multiplier: number | null;
  late_charge_percentage: string | null;
  latitude: number | null;
  longitude: number | null;
  value_added_tax: string | null;
  is_building: boolean;
  real_estate_company_id: number | null;
  total_floor: number | null;
  total_unit: number | null;
  currency_id: number | null;
  account_manager_id: number | null;
  language: string | null;
}

export interface UnitNumberList {
  data: {
    unit_apartments: Array<UnitNumberinterface>
  }
}
export interface UnitNumberinterface  {
  id: number,
  society_management_id: number,
  building_management_id: number,
  created_at: string,
  updated_at: string,
  apartment_name: string,
  floor_number: number,
  size: null,
  purchase_price:string,
  configuration: null,
  purchase_date: null,
  unit_type: string,
  status: string,
  current_valuation: string,
  monthly_renting_income: string,
  account_id: null,
  owner_name: null,
  resident_name: null
}
interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
}
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError {
  contract: string
}
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}
export interface Currency {
  id: string;
  type: string;
  attributes: {
      currency: string;
      currency_ar: string;
      created_at: string;
      updated_at: string;
  };
}

export interface CurrencyListApiResponse {
  data: Currency[];
}
export interface GetFindPersonListResponse {
  data: Array<GetFindPersonListItem>
}

export interface GetFindPersonListNoData {
  message: string;
};

export interface GetFindPersonListItem {
  id: string,
  type: string,
  attributes: {
    activated: boolean,
    email: string,
    full_phone_number: string,
    full_name: string,
    type: string,
    company_name: null,
    manager_full_name: null,
    owner_full_name: null,
    owner_email: null,
    owner_phone_number: null,
    created_at: string,
    updated_at: string,
    disable_chat: boolean,
    gender: null,
    first_name: null,
    last_name: null,
    add_name: string;
    profile_pic: null;
    address: {
      address: string
    }
  }
}

export type TFunction = (value: string) => string;
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public inputRef: React.RefObject<HTMLInputElement>;
  countryApiCallId: string = "";
  cityListApiCallId: string = "";
  buildingListApiCallId: string = "";
  complexListApiCallId: string = "";
  unitNumberApiCallID: string = "";
  currencyListApiCallId: string = "";
  findPersonApiCallID:string = "";
  findTenantPersonApiCallID:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
        // Customizable Area End
    this.state = {
      // Customizable Area Start
      selectedFindPerson:{
        "id": "",
        "type": "",
        "attributes": {
          "activated": true,
          "email": "",
          "full_phone_number": "",
          "full_name": "",
          "type": "",
          "company_name": null,
          "manager_full_name": null,
          "owner_full_name": null,
          "owner_email": null,
          "owner_phone_number": null,
          "created_at": "",
          "updated_at": "",
          "disable_chat": false,
          "gender": null,
          "first_name": null,
          "last_name": null,
          "add_name": " ",
          "profile_pic": null,
          address: {
            address: ""
          }
        }
      },
      anchorEl: null,
      selectedId: null,
      openFindPersonModal: false,
      openFindShowPersonModal: false,
      selectedDate:"",
      country:"",
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      searchDetails: "",
      contractData: {
        country: "",
        city: "",
        templateId: "",
        buildingName: "",
        unitName: "",
        complexName: "",
        complex: "",
        landlord_name: "",
        building_management_id: "",
        society_management_id: "",
        tenant_name: "",
        tenant_id: "",
        apartment_management_id: "",
        agreement_duration: "",
        start_date: "",
        expires_on: "",
        lease_template_id: "",
        rent_amount: "",
        currency: "",
        term_ids: [],
        terms_label: [],
        condition_ids: [],
        conditions_label: [],
        penanlty_late_payment: false,
        penalty_type: "",
        penalty_amount: "",
        contract_template: "",
        accountId: "",
        custom_contract: false,
        ownerNumber: "",
        owner: "",
        ownerEmail: "",
        status: "create",
        contractLink: "",
        isTenantNameEnabled: true
      },
      countryList: [],
      cityList: [],
      buildingList: [],
      complexList: [],
      unitNumberList: [],
      currencyList:[],
      findPersonList:[],
      tenantName: "",
      tenantID: "",
      owenerName: "",
      ownerID: "",
      selectedCountry: "",
      selectedCity: "",
      complex:"",
      selectedComplex: "",
      complexSelectList: [],
      selectedBuilding: "",
      agrementDuration: "",
      selectedRentAmount: "",
      selectedCurrency: "",
      unitValue:"",
      findPersonDate: "",
      findPersonIdNumber: "",
      findPersonPassportNumber: "",
      findPersonEmail: "",
      findPersonPhoneNumber: "",
      showError:false,
      error:"",
      ownerProfile: IdCartImage,
      tenantProfile: IdCartImage,
      societyManagementId: 0,
      buildingId: 0,
      unitId: 0,
            // Customizable Area End
    }
    // Customizable Area Start
    this.inputRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.issueNewLeaseFormSuccessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.issueNewLeaseFormFailureCall(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = message.getData(getName(MessageEnum.CreateContractPayloadData));
            if (createContractData) {
        this.setState({ contractData: createContractData, tenantName: createContractData.tenant_name }, () => {
          this.filData();
          this.getCountryList();
          this.getCurencyList();
        })
      }
    }
  };

  filData = () => {
    let contractData = this.state.contractData;
    if (contractData.apartment_management_id !== "") {      
      this.setState({ unitId: Number(contractData.apartment_management_id), unitValue: contractData.unitName });
    }
    if (contractData.tenant_name !== "") {
      this.setState({ tenantName: String(contractData.tenant_name)});
    }
    if (contractData.landlord_name !== "") {
      this.setState({ owenerName: contractData.landlord_name});
    }
    if (contractData.country !== "") {
      this.setState({ selectedCountry: contractData.country }, () => {
        this.getCityList();
      });
    }
    if (contractData.city !== "") {
      this.setState({ selectedCity: contractData.city, complex: contractData.complex }, () => {
        this.getComplexList(false);
      });
    }
    if (contractData.complex !== "") {
      this.setState({ selectedComplex: contractData.complexName }, () => {
        if (this.state.complex === "Complex") {
          this.getComplexList(false);
        } else if (this.state.complex === "Individual Building") {
          this.getComplexList(true);
        }
      });
    }
    if (contractData.society_management_id !== "") {
      this.setState({ societyManagementId: Number(contractData.society_management_id), selectedBuilding: contractData.buildingName }, () => {
        this.getBuildingList();
      });
    }
    this.fillCreateContractData();
  };

  fillCreateContractData = () => {
    let contractData = this.state.contractData;
    if (contractData.building_management_id !== "") {
      this.setState({ buildingId: Number(contractData.building_management_id), unitValue: contractData.unitName }, () => {
        this.getUnitNumberList();
      });
    }
    if (contractData.agreement_duration !== "") {
      this.setState({ agrementDuration: contractData.agreement_duration });
    }
    if (contractData.start_date !== "") {
      this.setState({ findPersonDate: moment(contractData.start_date).format("YYYY-MM-DD") });
    }
    if (contractData.rent_amount !== "") {
      this.setState({ selectedRentAmount: contractData.rent_amount });
    }
    if (contractData.currency !== "") {
      this.setState({ selectedCurrency: contractData.currency });
    }
    if (contractData.owner !== "") {
      this.setState({ owenerName: String(contractData.owner) });
    }
  };

  issueNewContractFormApiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken")

    let issueNewContractFormMessageRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    issueNewContractFormMessageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    issueNewContractFormMessageRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    issueNewContractFormMessageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    issueNewContractFormMessageRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? issueNewContractFormMessageRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : issueNewContractFormMessageRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(issueNewContractFormMessageRequest.id, issueNewContractFormMessageRequest);
    return issueNewContractFormMessageRequest.messageId;
  };

  issueNewLeaseFormSuccessCell = async (
    apiRequestCallId: string,
    responseJson:
      GetCountryListSuccessResponse &
      GetCityListSuccessResponse &
      GetBuildingSuccessResponse &
      ComplexData &
      UnitNumberList & CurrencyListApiResponse & GetFindPersonListResponse & GetFindPersonListNoData) => {

    if (apiRequestCallId === this.countryApiCallId) {
      this.getCountryListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.getCityListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.getBuildingListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListApiCallId) {
      this.getComplexListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.unitNumberApiCallID) {
      this.getUnitNumberListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.currencyListApiCallId) {
      this.getCurrecyListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.findPersonApiCallID) {
      this.getFindPersonListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.findTenantPersonApiCallID) {
      this.getFindTenantPersonListSucessCallBack(responseJson);
    }
  };

  issueNewLeaseFormFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {

    if (apiRequestCallId === this.countryApiCallId) {
      this.getCountryListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.getCityListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.getBuildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListApiCallId) {
      this.getComplexListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.unitNumberApiCallID) {
      this.getUnitListNumberFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.currencyListApiCallId) {
      this.getCurrencyListNumberFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.findPersonApiCallID) {
      this.getFindPersonNumberFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.findTenantPersonApiCallID) {
      this.getFindTenantPersonNumberFailureCallBack(responseJson);
    }
  };
  getCountryList = async () => {
    this.countryApiCallId = await this.issueNewContractFormApiCall({
      method: configJSON.countryListMethod,
      endPoint: configJSON.countryEndPoint,
    });
  };

  getCountryListSucessCallBack = (response: GetCountryListSuccessResponse) => {
        this.setState({ countryList: response.data.countries })
  };

  getCountryListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getCityList = async () => {
    this.cityListApiCallId = await this.issueNewContractFormApiCall({
      method: configJSON.cityListMethod,
      endPoint: `${configJSON.cityEndPoint}${this.state.selectedCountry}`,
    });
  };

  getCityListSucessCallBack = (response: GetCityListSuccessResponse) => { 
        this.setState({ cityList: response.data.cities })
  };

  getCityListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getBuildingList = async () => {
    this.buildingListApiCallId = await this.issueNewContractFormApiCall({
      method: configJSON.buildingListMethod,
      endPoint: `${configJSON.buildingListEndPoint}${this.state.societyManagementId}`,
    });
  };

  getBuildingListSucessCallBack = (response: GetBuildingSuccessResponse) => { 
        this.setState({ buildingList: response.data.buildings })
  };

  getBuildingListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getComplexList = async (complex:boolean) => {
    this.complexListApiCallId = await this.issueNewContractFormApiCall({
      method: configJSON.complexListMethod,
      endPoint: `${configJSON.complexSelectListEndPoint}?city=${this.state.selectedCity}&is_building=${complex}`,
    });
  };

  getComplexListSucessCallBack = (response: ComplexData) => {
        this.setState({ complexList: response.data })
  };

  getComplexListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getUnitNumberList = async () => {
    this.unitNumberApiCallID = await this.issueNewContractFormApiCall({
      method: configJSON.unitNumberListMethod,
      endPoint: `${configJSON.unitNumberListEndPoint}${this.state.societyManagementId}&building_management_id=${this.state.buildingId}&unit_type=Rented`
    });
  };

  getUnitNumberListSucessCallBack = (response: UnitNumberList) => {
        this.setState({ unitNumberList: response.data.unit_apartments });
  };

  getUnitListNumberFailureCallBack = (response: ApiFailureResponse) => {    
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getCurencyList = async () => {
    this.currencyListApiCallId = await this.issueNewContractFormApiCall({
      method: configJSON.unitNumberListMethod,
      endPoint: configJSON.currencyListEndPoint,
    });
  };

  getCurrecyListSucessCallBack = (response: CurrencyListApiResponse) => {        
    this.setState({ currencyList: response.data })
  };

  getCurrencyListNumberFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getFindPersonList = async () => {
    this.findPersonApiCallID = await this.issueNewContractFormApiCall({
      method: configJSON.findPersonListMethod,
      endPoint: `${configJSON.findPersonListEndPoint}?id_number=${this.state.findPersonIdNumber}&phone_number=${this.state.findPersonPhoneNumber}&email=${this.state.findPersonEmail}&passport=${this.state.findPersonPassportNumber}&role=${this.state.searchDetails}`,
    });
  };

  getFindPersonListSucessCallBack = (response: GetFindPersonListResponse & GetFindPersonListNoData) => { 
    if(response.message){
      this.setState({findPersonList:[]})
    }else if(response.data){
      this.setState({ findPersonList: response.data })
    } 
  };

  getFindPersonNumberFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract});
  };

  getFindTenantPersonList = async () => {
    this.findTenantPersonApiCallID = await this.issueNewContractFormApiCall({
      method: configJSON.findPersonListMethod,
      endPoint: `${configJSON.findTenantPersonListEndPoint}?id_number=${this.state.findPersonIdNumber}&phone_number=${this.state.findPersonPhoneNumber}&email=${this.state.findPersonEmail}&passport=${this.state.findPersonPassportNumber}`,
    });
  };

  getFindTenantPersonListSucessCallBack = (response: GetFindPersonListResponse & GetFindPersonListNoData) => {
    if (response.message) {
      this.setState({ findPersonList: [] });
    } else if (response.data) {
      this.setState({ findPersonList: response.data });
    }
  };

  getFindTenantPersonNumberFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  findPerson = () => {
      this.getFindPersonList();
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, selectedId: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedId: selectedId });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };

  handleShowPerson = (person: GetFindPersonListItem) => {
    this.setState({
      openFindShowPersonModal: !this.state.openFindShowPersonModal,
      openFindPersonModal: !this.state.openFindPersonModal,
      anchorEl: null,
      selectedId: null,
      selectedFindPerson:person
    });
  };

  handleCloseShowPerson=()=>{
    this.setState({openFindShowPersonModal: !this.state.openFindShowPersonModal});
  };

  handleCloseFindPersonModal = () => {
    this.setState({ openFindPersonModal: false });
  };

  handlefocus = (userType: string) => {
    this.setState({ openFindPersonModal: true, searchDetails: userType });
  };

  handleBack = () => {
    let contractData = this.state.contractData
    contractData.society_management_id = String(this.state.societyManagementId)
    contractData.apartment_management_id = String(this.state.unitId)
    contractData.building_management_id = String(this.state.buildingId)
    contractData.agreement_duration = this.state.agrementDuration
    contractData.currency = this.state.selectedCurrency
    contractData.rent_amount = this.state.selectedRentAmount
    contractData.complexName = this.state.selectedComplex;
    contractData.buildingName = this.state.selectedBuilding;
    contractData.unitName = this.state.unitValue;
    contractData.owner = this.state.owenerName;
    contractData.start_date = String(this.state.findPersonDate);
    contractData.tenant_name = this.state.tenantName;
    contractData.buildingName = this.state.selectedBuilding;
    contractData.unitName = this.state.unitValue;
    contractData.owner = this.state.owenerName;
    this.setState({ contractData: contractData }, () => {
      const msgData: Message = new Message(getName(MessageEnum.NavigationMessageSendData));
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractTemplateDetails");
      msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.contractData);
      this.send(msgData);
    })
  };

  handleCountryChange = (event: {target:{value : unknown}}) => {
    const { contractData } =  this.state;
    contractData.country = event.target.value as string;
    this.setState({ selectedCountry: event.target.value as string },()=>{
      this.getCityList()
    });
  };

  handleTenantName = (event: any) => {
    this.setState({ tenantName: event.target.value as string })
  };

  hanldeComplex = (event: ChangeEvent<{ value: unknown }>) => {
    const { contractData } = this.state;
    contractData.complex = event.target.value as string;
    this.setState({ complex: event.target.value as string, contractData: this.state.contractData },()=>{
      if(this.state.complex === "Complex"){
        this.getComplexList(false)
      }else if(this.state.complex === "Individual Building"){
        this.getComplexList(true)
      }
    });
  };

  hanldeComplexList = (event: ChangeEvent<{ value: unknown }>) => {
    const { contractData } = this.state;
    let complexData = this.state.complexList.find((complex)=>{return complex.attributes.name === event.target.value });
    if(complexData){
      contractData.complexName = event.target.value as string;
      contractData.society_management_id = complexData.id;
      this.setState({ 
        selectedComplex: event.target.value as string,
        societyManagementId:Number(complexData.id),
        contractData: this.state.contractData
       },()=>{
        this.getBuildingList()
      });
    }
  };

  handleCityChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { contractData } = this.state;
    contractData.city = event.target.value as string;
    this.setState({ selectedCity: event.target.value as string, contractData: this.state.contractData });
  };

  handleBuildingChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { contractData } = this.state;
    let building = this.state.buildingList.find((building) => { return building.name === event.target.value });
    if (building) {
      contractData.buildingName = event.target.value as string;
      contractData.building_management_id = String(building.id);
      this.setState({
        selectedBuilding: event.target.value as string,
        buildingId: Number(building.id),
        contractData: this.state.contractData
      }, () => {
        this.getUnitNumberList()
      });
    }
  };

  handleUnitChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { contractData } = this.state;
    let unit = this.state.unitNumberList.find((unit) => { return unit.apartment_name === event.target.value });
    if (unit) {
      contractData.unitName = event.target.value as string;
      contractData.apartment_management_id = String(unit.id);
      this.setState({
        unitValue: event.target.value as string,
        unitId: Number(unit.id),
        contractData: this.state.contractData
      });
    }
  }

  handleAgrementDuration=(event: ChangeEvent<HTMLInputElement>)=>{
    if (/^\d*$/.test(event.target.value)) {
      const {contractData} = this.state;
      contractData.agreement_duration = event.target.value;
      this.setState({agrementDuration:event.target.value, contractData: this.state.contractData})
    } 
  };

  handleRentAmount=(event: ChangeEvent<HTMLInputElement>)=>{
    if (/^\d*$/.test(event.target.value)) {
      const {contractData} = this.state;
      contractData.rent_amount = event.target.value;
      this.setState({ selectedRentAmount: event.target.value, contractData: this.state.contractData });
    }
  };

  handleCurrencyChange = (event: ChangeEvent<{ value: unknown }>) => {
    const {contractData} = this.state;
    contractData.currency = event.target.value as string;
    this.setState({ selectedCurrency: event.target.value as string, contractData: this.state.contractData });
  };

  handleDateChange = (startDate: string) => {
    const { contractData } = this.state;
    contractData.expires_on = startDate;
    const date = moment(startDate).format("YYYY-MM-DD");
    this.setState({ findPersonDate: date, contractData: this.state.contractData });
  };

  handleIdNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ findPersonIdNumber: event.target.value });
  };

  hanldePassportNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ findPersonPassportNumber: event.target.value });
  };

  findPesonEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ findPersonEmail: event.target.value });
  };

  findPesonPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ findPersonPhoneNumber: event.target.value });
  };
  
  handleCloseAlertError = () => { this.setState({ showError: false, error: "" }) };

  handleNullValue = (value: number | string | null | undefined): string => {
    if (value === null) {
      return "-"
    } else {
      return String(value)
    }
  };

  handleSelectPerson = () => {
    this.state.contractData.tenant_name= this.state.selectedFindPerson.attributes.full_name
    this.state.contractData.tenant_id= this.state.selectedFindPerson.id
    if (this.state.searchDetails === "Tenant") {
      this.setState({ openFindPersonModal: false, openFindShowPersonModal: false, contractData: this.state.contractData, findPersonList: [],tenantName:this.state.selectedFindPerson.attributes.full_name });
    }
    else if (this.state.searchDetails === "Owner") {
      const { contractData } = this.state;
      contractData.landlord_name = this.state.selectedFindPerson.attributes.full_name;
      contractData.owner = this.state.selectedFindPerson.attributes.full_name;
      contractData.accountId = this.state.selectedFindPerson.id;
      this.setState({ openFindPersonModal: false, 
        openFindShowPersonModal: false, 
        owenerName: this.state.selectedFindPerson.attributes.full_name, 
        findPersonList: [],
        contractData: this.state.contractData
      });
    }
  };

  calculateExpiryDate = (startDate: string, months: number) => {
    let date = new Date(startDate);
    date.setMonth(date.getMonth() + months);
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let days = String(date.getDate()).padStart(2, '0');
    return `${days}-${month}-${year}`;
  };

  handleNext=()=>{
    let contractData=this.state.contractData
    contractData.agreement_duration=this.state.agrementDuration
    contractData.complexName=this.state.selectedComplex;
    contractData.rent_amount=this.state.selectedRentAmount
    contractData.unitName=this.state.unitValue;
    contractData.buildingName=this.state.contractData.buildingName;
    contractData.start_date = this.state.findPersonDate;
    contractData.expires_on=this.calculateExpiryDate(this.state.findPersonDate,Number(this.state.agrementDuration));
    contractData.owner=this.state.owenerName;
    contractData.buildingName = this.state.contractData.buildingName;
    contractData.currency=this.state.selectedCurrency
    contractData.tenant_name = this.state.tenantName;
    contractData.owner = this.state.owenerName;
    contractData.landlord_name = this.state.owenerName;
    contractData.unitName = this.state.unitValue;
    contractData.status = this.state.contractData.status;
    this.setState({contractData:this.state.contractData},()=>{
      const msgData = new Message(getName(MessageEnum.NavigationMessageSendData));
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractAddTerm");
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.contractData);
      this.send(msgData);
    });
  }

  handleExistRecordNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.contractData);
    this.send(msgData);
  };

  handleDisableNextButton = () => {
    const { contractData } = this.state;    
    if (
      contractData.country === "" ||
      contractData.templateId === "" ||
      contractData.complex === "" ||
      contractData.city === "" ||
      contractData.unitName === "" ||
      (contractData.complex === "Individual Building"?"":contractData.buildingName === "") ||
      this.state.tenantName   === "" ||
      contractData.complexName === "" ||
      contractData.building_management_id === "" ||
      contractData.landlord_name === "" ||
      contractData.tenant_id === "" ||
      contractData.society_management_id === "" ||
      contractData.apartment_management_id === "" ||
      contractData.expires_on === "" ||
      contractData.agreement_duration === "" ||
      contractData.lease_template_id === "" ||
      contractData.currency === "" ||
      contractData.rent_amount === "" ||
      contractData.contract_template === "" ||
      contractData.owner === "" 
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleNullAddress = (address: { address: string }) => {
    if (address === null) {
      return "-"
    } else if(address !== null){
      let addressValue = address.address;
      return addressValue
    }
  };

  handleTenantFieldDisable=()=>{        
    return !this.state.contractData.isTenantNameEnabled ? true :false
  }

  // Customizable Area End
}