// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Divider,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  MenuItem,
} from "@material-ui/core";
import RealEstateCompanyDetailsCardController, { Props } from "./RealEstateCompanyDetailsCardController.web";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import { Link as NavLink } from "react-router-dom";
import Loader from "../../../../components/src/Loader.web";
import ListIcon from "@material-ui/icons/List";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import CloseIcon from "@material-ui/icons/Close";
import RoomIcon from "@material-ui/icons/Room";
import { TabsComponent } from "../../../../components/src/TabsComponent/index";
import { statusMapping, RealEstateFilter } from "../../../../components/src/RealEstateFilter/index";
import { ComplexMapComponent } from "../../../../components/src/MapComponent/index";
import { complexIcon, buildingIcon } from "./assets";
import { DownloadDocIcon, PdfImage, Tick_Circle_Icon } from "../assets";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { Formik } from "formik";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import { Menu } from "@szhsin/react-menu";
import MapDialog from "../../../../components/src/MuiDialog/MapDialog.web";

const registerOptions = [
  {
    icon: complexIcon,
    title: "Complex",
    link: "/CompanyEmployee/ComplexMainBlock",
  },
  {
    icon: buildingIcon,
    title: "Individual Building",
    link: "/CompanyEmployee/BuildingMainBlock",
  },
];

class RealEstateCompanyDetailsCard extends RealEstateCompanyDetailsCardController {
  constructor(props: Props) {
    super(props);
  }

  cooCompanyDetails = (t: any) =>
    this.state.accountType === "coo" && (
      <>
        {this.handleDetailInfo(t, "Sales Manager", `${this.state.companyDetails?.attributes?.sales_manager}`)}
        {this.handleDetailInfo(t, "Account Manager", `${this.state.companyDetails?.attributes?.account_manager}`)}
      </>
    );

  handleDetailInfo = (t: (val: string) => string, title: string, value: any) => {
    return (
      <Box style={webStyle.detailBox}>
        <Typography style={webStyle.detailLabel}>{t(title)}:</Typography>
        <Typography style={webStyle.detailValue} className="bold-text">
          {value === "undefined" || !value ? "N/a" : value}
        </Typography>
      </Box>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box maxWidth="xl" style={webStyle.detailsBox}>
          <Typography style={{ fontSize: "16px" }}>
            <NavLink to="/CompanyEmployee/realestate-companies">{t("Real Estate Companies")} </NavLink>/
            <span style={webStyle.linkColor}> {t("Company Details")}</span>
          </Typography>

          <Box style={webStyle.titleBox}>
            <Typography style={webStyle.companyTitle} className="bold-text">
              {this.state.companyDetails?.attributes?.company_name}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <Button
                variant="outlined"
                style={webStyle.deleteBtn}
                className="bold-text"
                data-testid="delete_btn"
                onClick={this.handleDeleteModal}
              >
                <span className="bold-text">{t("DELETE COMPANY")}</span>
              </Button>
              <Button
                variant="contained"
                style={webStyle.editBtn}
                className="bold-text"
                color="primary"
                data-testid="edit_btn"
                onClick={this.handleEditClick}
              >
                <span className="bold-text">{t("EDIT DETAILS")}</span>
              </Button>
            </Box>
          </Box>

          <Box style={webStyle.detailsContainer}>
            <Box style={webStyle.companyDetailsHeader}>
              <Box style={webStyle.companyText}>
                {this.state.companyDetails?.attributes?.logo?.url ? (
                  <img style={webStyle.logoHolder} src={this.state.companyDetails?.attributes?.logo?.url} />
                ) : (
                  <Box style={webStyle.logoHolder}></Box>
                )}
                <Box style={webStyle.companyInfo}>
                  <Box style={webStyle.companyLocation}>
                    <Typography variant="h4" style={webStyle.companyInfoTitle} className="bold-text">
                      {this.state.companyDetails?.attributes?.company_name}
                    </Typography>
                    <a
                      style={webStyle.locationTag}
                      href={this.state.companyDetails?.attributes?.google_map_location || ""}
                      target="_blank"
                    >
                      <LocationOnOutlinedIcon style={webStyle.locationIcon} />
                      <Typography style={webStyle.locationLabel} className="bold-text">
                        {t("See Company on map")}
                      </Typography>
                    </a>
                  </Box>
                  <Typography variant="body1" style={webStyle.companyInfoLocation}>
                    {this.state.companyDetails?.attributes?.city}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <span
                  className="bold-text"
                  style={{
                    ...webStyle.companyTag,
                    ...webStyle[this.state.companyDetails?.attributes?.status],
                  }}
                >
                  {t(statusMapping[this.state.companyDetails?.attributes?.status])}
                </span>
              </Box>
            </Box>

            <Box>
              {this.handleDetailInfo(t, "Company ID", `#${this.state.companyDetails?.id}`)}
              {this.handleDetailInfo(
                t,
                `${t("Point of Contact")} (${t("Primary")})`,
                `${this.state.companyDetails?.attributes?.contact_name} | ${this.state.companyDetails?.attributes?.phone_number} | ${this.state.companyDetails?.attributes?.email_address}`
              )}
              {this.handleDetailInfo(
                t,
                `${t("Point of Contact")} (${t("Secondary")})`,
                `${this.state.companyDetails?.attributes?.point_of_contacts_secondary?.contact_name} | ${this.state.companyDetails?.attributes?.point_of_contacts_secondary?.phone_number} | ${this.state.companyDetails?.attributes?.point_of_contacts_secondary?.email_address}`
              )}
              {this.cooCompanyDetails(t)}
              {this.handleDetailInfo(t, "Managed By", `${this.state.companyDetails?.attributes?.managed_by}`)}
              {this.handleDetailInfo(t, "Country", `${this.state.companyDetails?.attributes?.country}`)}
              {this.handleDetailInfo(t, "Region", `${this.state.companyDetails?.attributes?.region}`)}
              {this.handleDetailInfo(t, "City", `${this.state.companyDetails?.attributes?.city}`)}
              {this.handleDetailInfo(t, "Zip code", `${this.state.companyDetails?.attributes?.zipcode}`)}
              {this.handleDetailInfo(
                t,
                "Commercial Registration Number",
                `${this.state.companyDetails?.attributes?.commercial_registration_number}`
              )}
              {this.handleDetailInfo(
                t,
                "HQ Location Address",
                `${this.state.companyDetails?.attributes?.hq_location_address}`
              )}
              {this.state.proofFileName && (
                <Box>
                  <Typography style={webStyle.detailLabel}>
                    {t("Registration Certificate (Government Proof)")}:{" "}
                  </Typography>
                  <Box style={webStyle.proofBox}>
                    <Box style={webStyle.pdfBox}>
                      <img src={PdfImage} style={webStyle.pdfImg} />
                      <Typography style={webStyle.detailValue}>{this.state.proofFileName}</Typography>
                    </Box>
                    <NavLink
                      target="_blank"
                      to={`/${this.state.companyDetails?.attributes?.registration_certificate?.url}`}
                      download
                    >
                      <img src={DownloadDocIcon} />
                    </NavLink>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box style={webStyle.tabsBox}>
            <TabsComponent
              tabNo={this.state.tabNo}
              handleChange={this.handleTabChange}
              tabs={[
                {
                  label: t("Complex"),
                },
                {
                  label: t("Individual Buildings"),
                  testId: "tab-check",
                },
              ]}
            />
            <Button data-test-id="register-btn" style={webStyle.registerBtn} onClick={this.handleRegisterModal}>
              <span className="bold-text">{t("Register New Complex / Building")}</span>
            </Button>
          </Box>

          <Box style={webStyle.selectBoxes}>
            <Box style={webStyle.selectBoxLeft}>
              <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                {this.state.accountType === "coo" ? (
                  <RealEstateFilter
                    language={language}
                    t={t}
                    classComponent={this}
                    filterList="coo_complex_details_filter"
                  />
                ) : (
                  <RealEstateFilter
                    language={language}
                    classComponent={this}
                    t={t}
                    filterList="other_real_estate_filter"
                  />
                )}
                <Box className="filter-filter">
                  <Button
                    className="handleSearchFilterForInvoices search-btn-estate"
                    onClick={this.handleFilterSubmit}
                    startIcon={<SearchIcon style={{ marginLeft: "10px" }} />}
                  >
                    {t("Search")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.tableContainer}>
            <Box style={webStyle.tableHeader}>
              <Box style={webStyle.tableHeading}>
                <Box style={webStyle.switchIcons}>
                  <Box
                    className={this.state.mapSwitchEstate ? "" : "active_switch"}
                    style={webStyle.listIcon}
                    onClick={(event) => this.handleMapSwitchEstate(event, false)}
                    data-testid="map_click_false"
                    dir={languageCondition(language, "rtl", "ltr")}
                  >
                    <ListIcon className={this.state.mapSwitchEstate ? "" : "active_switch_icon"} />
                  </Box>
                  <Box
                    className={this.state.mapSwitchEstate ? "active_switch" : ""}
                    style={webStyle.roomIcon}
                    data-testid="map_click"
                    onClick={(event) => this.handleMapSwitchEstate(event, true)}
                    dir={languageCondition(language, "rtl", "ltr")}
                  >
                    <RoomIcon className={this.state.mapSwitchEstate ? "active_switch_icon" : ""} />
                  </Box>
                </Box>
                <h2 className="bold-text">
                  {this.state.tabNo === 0
                    ? t("List of Managed Complexes By Team")
                    : t("List of Managed Individual Buildings By Team")}
                </h2>
              </Box>
              <SearchInput
                onChange={this.handleDebounceSearch}
                t={t}
                language={language}
                label={t("Search by complex name")}
                data-test-id="search-complex-name"
              />
            </Box>
            {this.state.mapSwitchEstate ? (
              <Box style={webStyle.mapBox} className="estate_map_new_swicth">
                <ComplexMapComponent
                  defaultCenter={{
                    lat: 10.99835602,
                    lng: 77.01502627,
                  }}
                  defaultZoom={1}
                  mapPoints={this.state.mapPoints}
                  manageClick={this.handleMapPointClick}
                  currentPoint={this.state.mapPointClick}
                  data-test-id="map-component"
                />
              </Box>
            ) : (
              <>
                <TableContainer>
                  <Table className="table-box">
                    <TableHead>
                      <TableRow>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Complex Name")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Total Buildings")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Total Units")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Subscription Ends in")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Paying Month")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Subscription Type")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Joining Date")}
                        </TableCell>
                        <TableCell
                          align={languageCondition(language, "right", "left")}
                          className="bold-text"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.complexList.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8}>{t("No data found")}</TableCell>
                        </TableRow>
                      )}
                      {this.state.complexList.map((complex: any) => {
                        return (
                          <TableRow key={complex.id}>
                            <TableCell>{complex.attributes.name}</TableCell>
                            <TableCell>{complex.attributes.total_buildings}</TableCell>
                            <TableCell>{complex.attributes.total_units_custum + ""}</TableCell>
                            <TableCell>{complex.attributes.subscription_end_in}</TableCell>
                            <TableCell>{complex.attributes.paying_month}</TableCell>
                            <TableCell>{complex.attributes.subscription_type}</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell className="complex-menu">
                              <Menu menuButton={<MoreVertIcon />} style={webStyle.btnMenu}>
                                <MenuItem
                                  className="map-menu"
                                  onClick={() =>
                                    this.setState({ lat: complex.attributes.lat, long: complex.attributes.long }, () =>
                                      this.handleMapModal()
                                    )
                                  }
                                >
                                  {t("View location on map")}
                                </MenuItem>
                                {this.state.tabNo ? (
                                  <MenuItem>
                                    <NavLink to={`/CompanyEmployee/BuildingDetailsPage/${complex.id}`}>
                                      {t("Go to building page")}
                                    </NavLink>
                                  </MenuItem>
                                ) : (
                                  <MenuItem>
                                    <NavLink to={`/CompanyEmployee/ComplexDetails/${complex.id}`}>
                                      {t("Go to complex page")}
                                    </NavLink>
                                  </MenuItem>
                                )}
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider />
                <Box style={webStyle.tableFooter} className="paginate_details">
                  <PaginationModule
                    pagination={this.state.pagination}
                    handlePagination={this.handlePaginationEstate}
                    page={this.state.page}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>

        <MapDialog
          t={t}
          language={language}
          IsOpen={this.state.isMapModalOpen}
          CloseDialog={this.handleMapModal}
          lat={this.state.lat}
          long={this.state.long}
        />

        <AlertDialog
          IsOpen={this.state.deleteConfirmModal}
          Image={Tick_Circle_Icon}
          CloseDialog={this.handleDeleteModal}
          Header={t("Delete Company?")}
          Content={
            this.state.accountType === "coo"
              ? `<p>${t("Are you sure you want to delete the company from the platform?")}</p>`
              : `<p>${t(
                  "Approval request has been sent to the COO once the request get approved. Company will be deleted."
                )}</p>`
          }
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handleDeleteModal}
          AcceptFun={this.handleOpenReasonPopup}
        />

        <Dialog
          open={this.state.reasonConfirmModal}
          scroll="paper"
          fullWidth
          maxWidth="sm"
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <DialogHeader t={t} title={`${t("Reasons for Deletion")}`} onClose={this.handleCloseReasonPopup} />
          <Formik
            enableReinitialize={true}
            initialValues={{ note: "" }}
            validationSchema={this.noteValidation}
            onSubmit={(values, { resetForm }) => {
              this.setState({ reasonConfirmModal: false }, () => {
                this.handleDelete(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <NormalTextArea
                      t={t}
                      language={language}
                      label="Please provide detailed justification why are you deleting the company"
                      value={values.note}
                      name="note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      maxRows={10}
                      minRows={10}
                    />
                    {ErrorHandler(t, touched.note, errors.note)}
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleCloseReasonPopup}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Submit")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          open={this.state.registerModal}
          scroll="paper"
          fullWidth
          maxWidth="xs"
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <MuiDialogTitle style={{ marginTop: "20px" }} disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text" style={{ width: "100%", textAlign: "center" }}>
              {t("Register New")}
            </Typography>
            <IconButton className="admin-invitation-close-icon" onClick={this.handleRegisterModal}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent style={webStyle.newComplexContent}>
            {registerOptions.map((icon, index) => {
              return (
                <Box
                  data-test-id="index-card"
                  style={webStyle.buildingOption}
                  key={index}
                  onClick={() => this.handleCreateItem(icon.title)}
                >
                  <Box style={webStyle.buildingCircle}>
                    <img src={icon.icon} style={webStyle.iconSize} />
                  </Box>
                  <span>{t(icon.title)}</span>
                </Box>
              );
            })}
          </DialogContent>
        </Dialog>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle: any = {
  newComplexContent: { display: "flex", justifyContent: "center", gap: "20px", margin: "20px" },
  switchIcons: { display: "flex", marginRight: "16px", cursor: "pointer" },
  buildingOption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
  },
  buildingLink: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
  },
  listIcon: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRadius: "4px 0px 0px 4px",
    padding: "4px",
    fontSize: "18px",
  },
  buildingCircle: {
    border: "1px solid #ececec",
    borderRadius: "50%",
    height: "80px",
    width: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15px",
  },
  roomIcon: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRadius: "0px 4px 4px 0px",
    padding: "4px",
    borderLeft: "0px",
    fontSize: "18px",
  },
  mapBox: { width: "100%", height: "400px", overflow: "hidden" },
  approved: { background: "rgb(30,198,91,0.2)", color: "rgb(30 198 91)" },
  rejected: { background: "rgb(242,23,23, 0.2)", color: "#F21717" },
  pending_approval: { background: "rgb(252, 132, 52, 0.2)", color: "rgb(252, 132, 52)" },
  selectBoxLeft: { width: "100%", display: "flex", flexWrap: "wrap" },
  selectBoxes: {
    marginTop: "30px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsBox: { padding: "0 30px 30px" },
  tabsBox: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  proofBox: {
    border: "1px solid #E4E4E4",
    borderRadius: "8px",
    padding: "10px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pdfImg: { marginRight: "10px" },
  pdfBox: { display: "flex", alignItems: "center" },
  registerBtn: {
    background: "#FC8434",
    color: "#fff",
    height: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "8px",
  },
  detailBox: {
    display: "flex",
    paddingBottom: "10px",
    borderBottom: "1px solid #f9f9f9",
    marginBottom: "10px",
  },
  locationTag: { display: "flex" },
  moreIcon: { color: "rgba(136,136,136,0.6)" },
  linkColor: { color: "rgb(43, 111, 237)" },
  tableHeader: {
    padding: "20px 30px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: { background: "#fff" },
  tableFooter: {
    padding: "20px 30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeading: { fontSize: "22px", display: "flex", alignItems: "center" },
  detailLabel: { fontSize: "15px", fontWeight: 400 },
  detailValue: {
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "5px",
    textTransform: "capitalize",
  },
  titleBox: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteBtn: {
    borderColor: "red",
    color: "red",
    fontWeight: 700,
    height: "44px",
    width: "184px",
    borderRadius: "8px",
    boxShadow: "4px 0px 14px #e9e9e9",
  },
  editBtn: {
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontWeight: 700,
    height: "44px",
    width: "184px",
    borderRadius: "8px",
    boxShadow: "4px 0px 14px #e9e9e9",
  },
  companyTitle: { fontSize: "22px", textTransform: "capitalize" },
  companyText: { display: "flex", alignItems: "center" },
  companyInfo: { marginLeft: "30px" },
  companyInfoTitle: { fontSize: "18px", fontWeight: 400, textTransform: "capitalize" },
  companyInfoLocation: { fontSize: "16px", fontWeight: 400 },
  companyLocation: { display: "flex", alignItems: "center", gap: "12px" },
  companyTag: {
    color: "#FC8434",
    background: "rgb(252, 132, 52, 0.1)",
    padding: "6px 28px",
    borderRadius: "25px",
    marginTop: "15px",
    fontSize: "14px",
  },
  locationIcon: { color: "#fc8434", height: "22px", marginLeft: "10px" },
  locationLabel: {
    color: "#FC8434",
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: "10px",
  },
  detailsContainer: {
    background: "#fff",
    padding: "15px",
    marginTop: "30px",
    borderRadius: "8px",
  },
  companyDetailsHeader: { display: "flex", justifyContent: "space-between", marginBottom: "30px" },
  logoHolder: {
    background: "#dcdcdc",
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    objectFit: "cover",
  },
};

export default withTranslation()(RealEstateCompanyDetailsCard);
// Customizable Area End
