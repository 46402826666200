// App.js - WEB
import React from 'react';
import { Box } from '@material-ui/core';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';

import { ROLE } from '../../framework/src/Enum';
import { Toaster } from 'react-hot-toast';

import Polling from '../../blocks/Polling/src/Polling';
import CEPoll from '../../blocks/Polling/src/CompanyEmployee/CEPollWrapper.web';
import CEPollSurvey from '../../blocks/Polling/src/CompanyEmployee/CEPollSurveyWrapper.web';
import CEPollSurveyList from '../../blocks/Polling/src/CompanyEmployee/CEPollSurveyListWrapper.web';
import CEPollSurveyDetails from '../../blocks/Polling/src/CompanyEmployee/CEPollSurveyDetailsWrapper.web';
import CEPollSurveyReports from '../../blocks/Polling/src/CompanyEmployee/CEPollSurveyReportsWrapper.web';
import CECommunityManagement from '../../blocks/dashboard/src/CECommunityManagement.web';
import SuggestionListing from '../../blocks/Feedback/src/Suggestion.web';
import NewSuggestion from '../../blocks/Feedback/src/NewSuggestion.web';
import SuggestionData from '../../blocks/Feedback/src/SuggestionDetails.web';
import NewRequestSuggestion from '../../blocks/Feedback/src/newRequest.web';
import ResponseSuggestion from '../../blocks/Feedback/src/ResponseSuggestion.web';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import EmailAlerts from '../../blocks/Settings5/src/EmailAlerts.web';
import './assets/css/constants/base/global.scss';
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import EmailAccountLogin from '../../blocks/email-account-login/src/OwnerResidentLogin.web';
import ChairmanLogin from '../../blocks/email-account-login/src/ManagementLogin.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ChairmanForgotPassword from '../../blocks/forgot-password/src/ForgotPasswordChairman.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import ChairmanForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTPChairman.web';
import ChangePassword from '../../blocks/forgot-password/src/ChangePassword.web';
import ChairmanChangePassword from '../../blocks/forgot-password/src/ChangePasswordChairman.web';
import ChangeSuccessfully from '../../blocks/forgot-password/src/ChangeSuccessfully.web';
import ChairmanChangeSuccessfully from '../../blocks/forgot-password/src/ChairmanChangeSuccessfully.web';
import RegistrationRequest from '../../blocks/email-account-login/src/RegistrationRequest.web';
import ChairmanRegistrationRequest from '../../blocks/email-account-login/src/ChairmanRegistrationRequest.web';
import DashboardGeneral from '../../blocks/dashboard/src/DashboardGeneral.web';
import DashboardTicket from '../../blocks/dashboard/src/DashboardTicket.web';
import AvgResolutionTicket from '../../blocks/dashboard/src/AvgResolutionTicket.web';
import TicketGeneratedYear from '../../blocks/dashboard/src/TicketGeneratedYear.web';
import TicketGeneratedDays from '../../blocks/dashboard/src/TicketGeneratedDays.web';
import DashboardActions from '../../blocks/dashboard/src/DashboardActions.web';
import DashboardBudget from '../../blocks/dashboard/src/DashboardBudget.web';
import BudgetDetails from '../../blocks/dashboard/src/BudgetDetails.web';
import Reports from '../../blocks/analytics/src/Reports.web';

import {
  CreatePolls,
  CECreatePoll
} from '../../blocks/Polling/src/CreatePolls.web';
import {
  CreateSurveys,
  CECreateSurvey
} from '../../blocks/Polling/src/CreateSurveys.web';
import {
  PollPreviewExport,
  CEPollPreviewExport
} from '../../blocks/Polling/src/PollPreview.web';
import {
  SurveyPreview,
  CESurveyPreview
} from '../../blocks/Polling/src/SurveyPreview.web';
import SurveyParticipate from '../../blocks/Polling/src/SurveyParticipate.web';
import SurveyDetailsMain from '../../blocks/Polling/src/SurveryDetailsMain.web';
import PollsallData from '../../blocks/Polling/src/PollsallData.web';
import SurveyallData from '../../blocks/Polling/src/SurveyallData.web';
import PollsSurvey from '../../blocks/Polling/src/PollsSurvey.web';
import SurveyReport from '../../blocks/Polling/src/SurveyReport.web';
import SubmitPoll from '../../blocks/Polling/src/SubmitPoll.web';
import SurveyInitial from '../../blocks/Polling/src/SurveyInitial.web';
import OwnerDashboard from '../../blocks/dashboard/src/OwnerDashboard.web';
import ResidentDashboard from '../../blocks/dashboard/src/ResidentDashboard.web';
import PollVoteSubmitted from '../../blocks/Polling/src/PollVoteSubmitted.web';
import PollResponseCompleted from '../../blocks/Polling/src/PollResponseCompleted.web';
import SurveyMyResponse from '../../blocks/Polling/src/SurveyMyResponse.web';
import SurveySubmitted from '../../blocks/Polling/src/SurveySubmitted';
import VeichleList from '../../blocks/customform/src/ListVehicle.web';
import NewVeichleList from '../../blocks/customform/src/VehicleRegistration.web';
import NewFamily from '../../blocks/customform/src/FamilyAdd.web';
import EditVeichleList from '../../blocks/customform/src/VehicleRegistration.web';
import EditFamily from '../../blocks/customform/src/FamilyEdit.web';
import EditRequest from '../../blocks/customform/src/VehicleEditRequest.web';
import NewRequest from '../../blocks/customform/src/VehicleNewRequest.web';
import ManagerList from '../../blocks/customform/src/ManagerVehicleList.web';
import OwnerChatInbox from '../../blocks/customform/src/OwnerChatInbox.web';
import OwnerIncidentChat from '../../blocks/customform/src/OwnerIncidentChat.web';
import AdminChat from '../../blocks/customform/src/AdminChat.web';

import OwnerChatBox from '../../blocks/customform/src/OwnerChatBox.web';
import Registration from '../../blocks/email-account-registration/src/Registration.web';
import OwnerRegistration from '../../blocks/email-account-registration/src/OwnerRegistration.web';
import ManagerRegistration from '../../blocks/email-account-registration/src/ManagerRegistration.web';
import SelectOwner from '../../blocks/email-account-registration/src/SelectOwner.web';

import VerifyOTP from '../../blocks/email-account-registration/src/VerifyOTP.web';
import SelectType from '../../blocks/email-account-registration/src/SelectType.web';
import UnitRegister from '../../blocks/email-account-registration/src/UnitRegister.web';
import RegisterUnitManually from '../../blocks/email-account-registration/src/ManuallyRegisterUnit.web';
import RegisterAddressLink from '../../blocks/email-account-registration/src/LinkageUnit.web';

import RegistrationRequestSignup from '../../blocks/email-account-registration/src/RegistrationRequestSignup.web';

import SearchComplex from '../../blocks/email-account-registration/src/SearchComplex.web';

import Address from '../../blocks/email-account-registration/src/Address.web';

import ViewVeichle from '../../blocks/customform/src/VehicleDetails.web';
import FamilyList from '../../blocks/customform/src/FamilyListResident.web';

import ManagerViewVehicle from '../../blocks/customform/src/ViewVehicleManager.web';

import PollDetails from '../../blocks/Polling/src/PollDetails.web';
import PollReport from '../../blocks/Polling/src/PollReport.web';
import CreateIncident from '../../blocks/ContentManagement/src/CreateIncident.web';
import IncidentDetails from '../../blocks/ContentManagement/src/IncidentDetails.web';
import IncidentListing from '../../blocks/ContentManagement/src/IncidentListing.web';
import IncidentReportedSuccessfully from '../../blocks/ContentManagement/src/IncidentReportedSuccessfully.web';
import IncidentPreview from '../../blocks/ContentManagement/src/IncidentPreview.web';
import IncidentManagement from '../../blocks/ContentManagement/src/IncidentManagement.web';
import IncidentManagementDetail from '../../blocks/ContentManagement/src/IncidentManagementDetail.web';

import ClassifiedManagerListing from '../../blocks/ContentManagement/src/ClassifiedManagerListing.web';
import ClassifiedManagerDetail from '../../blocks/ContentManagement/src/ClassifiedManagerDetail.web';
import CreateClassified from '../../blocks/ContentManagement/src/CreateClassified.web';
import ClassifiedType from '../../blocks/ContentManagement/src/ClassifiedType.web';
import ClassifiedListing from '../../blocks/ContentManagement/src/ClassifiedListing.web';
import ClassifiedReportedSuccessfully from '../../blocks/ContentManagement/src/ClassifiedReportedSuccessfully.web';
import ClassifiedPreview from '../../blocks/ContentManagement/src/ClassifiedPreview.web';
import ClassifiedDetails from '../../blocks/ContentManagement/src/ClassifiedDetails.web';
import ClassifiedEditSuccessfully from '../../blocks/ContentManagement/src/ClassifiedEditSuccessfully.web';
import ServiceProviderDashboard from '../../blocks/dashboard/src/ServiceProviderDashboard.web';
import ServiceProviderManagementDetail from '../../blocks/dashboard/src/ServiceProviderManagementDetail.web';

import NeighboursDetails from '../../blocks/search/src/NeighboursDetailsOwner.web';
import NeighboursListing from '../../blocks/search/src/NeighboursListingOwner.web';

import FacilityReservationListing from '../../blocks/RequestManagement/src/FacilityReservationListing.web';
import FacilityReservationDetails from '../../blocks/RequestManagement/src/FacilityReservationDetails.web';
import FacilityReservation from '../../blocks/RequestManagement/src/FacilityReservation.web';
import FacilityReservationReportedSuccessfully from '../../blocks/RequestManagement/src/FacilityReservationReportedSuccessfully.web';
import CreateFacilityReservation from '../../blocks/RequestManagement/src/CreateFacilityReservation.web';
import FacilityManagerDetail from '../../blocks/RequestManagement/src/FacilityManagerDetail.web';
import ManagerFacilityReservation from '../../blocks/RequestManagement/src/ManagerFacilityReservation.web';

// Help
import FaqChairman from '../../blocks/contactus/src/FaqChairman.web';
import FaqComplexList from '../../blocks/contactus/src/FaqComplexList.web';
import FaqOwner from '../../blocks/contactus/src/FaqOwner.web';
import FaqResident from '../../blocks/contactus/src/FaqResident.web';
import ContactUsChairman from '../../blocks/contactus/src/ContactUs.web';
import SubscriptionDetail from '../../blocks/contactus/src/SubscriptionDetails.web';

// Document
import DocumentChairman from '../../blocks/Notes/src/DocumentChairman.web';
import DocumentListChairman from '../../blocks/Notes/src/DocumentListChairman.web';
import DocumentViewChairman from '../../blocks/Notes/src/DocumentViewChairman.web';
import PersonalDocument from '../../blocks/Notes/src/PersonalDocuments.web';
import PersonalDocumentList from '../../blocks/Notes/src/PersonalDocumentList.web';
import ViewPersonalDocument from '../../blocks/Notes/src/ViewPersonalDocument.web';
import BuildingDocuments from '../../blocks/Notes/src/BuildingDocuments.web';
import BuildingDocumentList from '../../blocks/Notes/src/BuildingDocumentList.web';
import ViewBuildingDocument from '../../blocks/Notes/src/ViewBuildingDocument.web';

// Contract
import ContractsList from '../../blocks/RolesPermissions2/src/ContractsList.web';
import ContractDetail from '../../blocks/RolesPermissions2/src/ContractDetail.web';
import TemplateDetail from '../../blocks/RolesPermissions2/src/TemplateDetail.web';
import IssueContract from '../../blocks/RolesPermissions2/src/IssueContract.web';
import IssueLease from '../../blocks/RolesPermissions2/src/IssueLease.web';
import SelectedTemplate from '../../blocks/RolesPermissions2/src/SelectedTemplate.web';
import LeaseForm from '../../blocks/RolesPermissions2/src/LeaseForm.web';
import ChangedSelectedTemplate from '../../blocks/RolesPermissions2/src/ChangedSelectedTemplate.web';
import ReviewTemplate from '../../blocks/RolesPermissions2/src/ReviewTemplate.web';
import AddCondition from '../../blocks/RolesPermissions2/src/AddCondition.web';
import RenewContract from '../../blocks/RolesPermissions2/src/RenewContract.web';
import GrantedPermissions from '../../blocks/RolesPermissions2/src/GrantedPermissions.web';

// Meetings
import ChairmanScheduledMeeting from '../../blocks/BulkUploading/src/ScheduledMeeting.web';
import ScheduledMeetingDetails from '../../blocks/BulkUploading/src/ScheduledMeetingDetails.web';
import ChairmanMeetingMinutes from '../../blocks/BulkUploading/src/MeetingMinutes.web';
import MeetingMinuteDetails from '../../blocks/BulkUploading/src/MeetingMinuteDetails.web';
import MyMeetings from '../../blocks/BulkUploading/src/MyMeetings.web';
import MyMeetingDetail from '../../blocks/BulkUploading/src/MyMeetingDetail.web';
import MyMeetingMinuteDetail from '../../blocks/BulkUploading/src/MyMeetingMinuteDetail.web';
import MeetingMinuteNote from '../../blocks/BulkUploading/src/MeetingMinuteNote.web';

import CharmainInvoices from '../../blocks/InvoiceBilling/src/CharmainInvoices.web';
import PaymentHistory from '../../blocks/InvoiceBilling/src/PaymentHistory.web';
import OverDuePaymentWeb from '../../blocks/InvoiceBilling/src/OverDuePayment.web';
import ChairmanProfile from '../../blocks/Settings5/src/ChairmanProfile.web';

import Profile from '../../blocks/user-profile-basic/src/OwnerProfile.web';
import ChairmenProfile from '../../blocks/user-profile-basic/src/ChairmenProfile.web';
import CEAccountProfile from '../../blocks/mobile-account-registration/src/CEAccountProfile.web';

import AdminUserProfile from '../../blocks/user-profile-basic/src/AdminUserProfile.web';
import AdminGaMembers from '../../blocks/user-profile-basic/src/AdminGaMembers.web';
import EditProfile from '../../blocks/user-profile-basic/src/EditOwnerProfile.web';
import PublicView from '../../blocks/user-profile-basic/src/PublicViewOwner.web';

import ResidentsProfile from '../../blocks/user-profile-basic/src/ResidentsProfile.web';
import PropertysManager from '../../blocks/user-profile-basic/src/PropertysManager.web';
import Suggestions from '../../blocks/user-profile-basic/src/Suggestions.web';
import SuggestionDetails from '../../blocks/user-profile-basic/src/SuggestionDetails.web';
import Announcements from '../../blocks/user-profile-basic/src/Announcements.web';
import AnnouncementDetails from '../../blocks/user-profile-basic/src/AnnouncementDetails.web';
import CRequestManagement from '../../blocks/user-profile-basic/src/CRequestManagement.web';
import AdminSentInvitation from '../../blocks/user-profile-basic/src/AdminSentInvitation.web';
import PendingRequest from '../../blocks/user-profile-basic/src/PendingRequest.web';
import AdminAwaitingAcceptance from '../../blocks/user-profile-basic/src/AdminAwaitingAcceptance.web';
import AdminUserDetailedProfile from '../../blocks/user-profile-basic/src/AdminUserDetailedProfile.web';

// Announcement Imports
import Announcement from '../../blocks/BroadcastMessage/src/Announcement.web';
import BuildingAnnouncement from '../../blocks/BroadcastMessage/src/BuildingAnnouncement.web';
import AnnouncementInfo from '../../blocks/BroadcastMessage/src/AnnouncementDetails.web';

// Complex and Apartment
import Buildings from '../../blocks/LeadManagement/src/Buildings.web';
import Complex from '../../blocks/LeadManagement/src/Complex.web';
import UnitDetails from '../../blocks/LeadManagement/src/UnitDetails.web';
import SharedArea from '../../blocks/LeadManagement/src/SharedArea.web';
import OwnerComplexList from '../../blocks/LeadManagement/src/OwnerComplexList.web';
import OwnerComplex from '../../blocks/LeadManagement/src/OwnerComplex.web';
import OwnerBuildings from '../../blocks/LeadManagement/src/OwnerBuildings.web';

// Visitor Imports
import Visitors from '../../blocks/invitefriends/src/VisitorsMain.web';
import PastVisitors from '../../blocks/invitefriends/src/PastVisitors.web';
import ScheduledVisitors from '../../blocks/invitefriends/src/ScheduledVisitorsOwner.web';
import VisitorDetails from '../../blocks/invitefriends/src/VisitorDetailsOwner.web';
import VisitorAddSuccess from '../../blocks/invitefriends/src/VisitorAdded';
import VisitorUpdateSuccess from '../../blocks/invitefriends/src/VisitorUpdated';
import VisitorAdd from '../../blocks/invitefriends/src/VisitorAdd.web';
import VisitorList from '../../blocks/invitefriends/src/VisitorsList.web';
import Unit from '../../blocks/invitefriends/src/Unit.web';
import UnitGeneralDetails from '../../blocks/invitefriends/src/UnitGeneralDetails.web';
import VisitorsDetails from '../../blocks/invitefriends/src/VisitorDetailsManager.web';

// Register Tenant
import TenantList from '../../blocks/RequestManagement/src/TenantList.web';
import TenantDetails from '../../blocks/RequestManagement/src/TenantDetails.web';
import RegisterTenant from '../../blocks/RequestManagement/src/RegisterTenant.web';
import EditTenant from '../../blocks/RequestManagement/src/EditTenant.web';

// My Team Imports
import MyTeam from '../../blocks/FriendList/src/MyTeam.web';
import MyTeamAudi from '../../blocks/FriendList/src/AuditorMyTeam.web';
import MyTeamCore from '../../blocks/FriendList/src/MyTeamCore.web';
import MyTeamUserDetails from '../../blocks/FriendList/src/MyTeamUserDetails.web';
import ChairmanNominationMain from '../../blocks/FriendList/src/ChairmanNominationMain.web';
import ChairmanNominationPrint from '../../blocks/FriendList/src/ChairmanNominationPrint.web';
import NominationDetails from '../../blocks/FriendList/src/NominationDetails.web';
import NominationSuccess from '../../blocks/FriendList/src/NominationAdded';
import NominationUpdated from '../../blocks/FriendList/src/NominationUpdated';
import ChairmanNominations from '../../blocks/FriendList/src/ChairmanNomination';
import ChairmanNominationDetails from '../../blocks/FriendList/src/ChairmanNominationDetails.web';
import NominateMySelf from '../../blocks/FriendList/src/NominateMySelf.web';
import MyNomination from '../../blocks/FriendList/src/MyNomination.web';
import TaskManagement from '../../blocks/FriendList/src/TaskManagement.web';
import CEMyTeamTable from '../../blocks/FriendList/src/CompanyEmployee/CEMyTeamTable.web';
import CETeamMemberTable from '../../blocks/FriendList/src/CompanyEmployee/CETeamMemberTable.web';
import LeaseManagment from '../../blocks/ContentManagement/src/LeaseManagementMain.web';
import IssueNewLease from '../../blocks/ContentManagement/src/LeaseManagementIssueNewLease.web';
import IssueNewContract from '../../blocks/ContentManagement/src/LeaseManagementIssueNewContract.web';
import IssueNewContractTemplateDetails from '../../blocks/ContentManagement/src/LeaseManagementIssueNewContractTemplate.web';
import IssueNewContractReview from '../../blocks/ContentManagement/src/LeaseManagementIssueNewContractReview.web';
import IssueNewContractAddTerm from '../../blocks/ContentManagement/src/LeaseManagementIssueNewContractAddTerms.web';
import IssueNewContractForm from '../../blocks/ContentManagement/src/LeaseManagementIssueNewContractForm.web';
import ReviewDraft from '../../blocks/ContentManagement/src/LeaseManagementReviewDraft.web';
import ContractDetails from '../../blocks/ContentManagement/src/LeaseManagementContractDetails.web';
import DraftContractDetails from '../../blocks/ContentManagement/src/LeaseManagementDraftContractDetails.web';
import LeaseReport from '../../blocks/analytics/src/LeaseReport.web';
import SavedTemplateDetails from '../../blocks/ContentManagement/src/SavedTemplateDetails.web';

// Fees & Payment Imports
import FeesAndPayment from '../../blocks/CollectTransactionFees/src/FeesAndPayments.web';
import FeesAndPaymentOwner from '../../blocks/CollectTransactionFees/src/FeesAndPaymentsOwner.web';
import ViewMyInvoices from '../../blocks/CollectTransactionFees/src/ViewMyInvoices.web';
import InvoiceDetails from '../../blocks/CollectTransactionFees/src/InvoiceDetails.web';
import ViewMyReceipts from '../../blocks/CollectTransactionFees/src/ViewMyReceipts.web';
import ReceiptDetails from '../../blocks/CollectTransactionFees/src/ReceiptDetails.web';
import PreviousPayments from '../../blocks/CollectTransactionFees/src/PreviousPayments.web';
import PaymentDetails from '../../blocks/CollectTransactionFees/src/PaymentDetails.web';
import BudgetSpending from '../../blocks/CollectTransactionFees/src/BudgetSpending.web';
import BudgetSpendingDetails from '../../blocks/CollectTransactionFees/src/SpentDetails.web';
import BuildingBudget from '../../blocks/CollectTransactionFees/src/BuildingBudget.web';
import MyManagementFee from '../../blocks/CollectTransactionFees/src/ManagementFee.web';
import ComplexList from '../../blocks/CollectTransactionFees/src/ComplexList.web';

// Owner Side
import MyInvoicesAndReceipts from '../../blocks/CollectTransactionFees/src/MyInvoicesAndReceipts.web';
import MyInvoices from '../../blocks/CollectTransactionFees/src/MyInvoices.web';
import MyInvoiceDetails from '../../blocks/CollectTransactionFees/src/MyInvoiceDetails.web';
import MyReceipts from '../../blocks/CollectTransactionFees/src/MyReceipts.web';
import MyReceiptsDetails from '../../blocks/CollectTransactionFees/src/MyReceiptsDetails.web';

// Chairman Side
import CharmainReceipts from '../../blocks/InvoiceBilling/src/CharmainReceipts.web';

// Rent Payments
import RentPayments from '../../blocks/PricingEngine2/src/RentPayments.web';
import RentUnitLists from '../../blocks/PricingEngine2/src/RentUnitLists.web';
import ViewMyRents from '../../blocks/PricingEngine2/src/ViewMyRents.web';
import RentDetails from '../../blocks/PricingEngine2/src/RentDetails.web';
import AddRentPayment from '../../blocks/PricingEngine2/src/RegisterRentPayment.web';
import SessionManager from '../../components/src/SessionManager.web';

// Reports
import GenerateBudgetReport from '../../blocks/StoreCredits/src/GenerateBudgetReport.web';
import GenerateBudgetReportPreview from '../../blocks/StoreCredits/src/BudgetReportPreview.web';
import ReportDashboard from '../../blocks/StoreCredits/src/ReportDashboard.web';
import BudgetReport from '../../blocks/StoreCredits/src/BudgetReport.web';
import BudgetReportDetails from '../../blocks/StoreCredits/src/BudgetReportDetails.web';
import ExpenseReport from '../../blocks/StoreCredits/src/ExpenseReport.web';
import AuditReport from '../../blocks/StoreCredits/src/AuditReport.web';
import ManagementFeeReport from '../../blocks/StoreCredits/src/ManagementFeeReport.web';
import AuditReportDetails from '../../blocks/StoreCredits/src/AuditReportDetails.web';
import InvitationReport from '../../blocks/StoreCredits/src/InvitationReport.web';
import ExpenseReportDetails from '../../blocks/StoreCredits/src/ExpenseDetails.web';

// My Unit
import MyUnitList from '../../blocks/TaskAllocator/src/MyUnitList.web';
import RegisterMyUnit from '../../blocks/TaskAllocator/src/RegisterUnit.web';
import RegisterMyUnitSuccess from '../../blocks/TaskAllocator/src/RegisterUnitSuccess.web';
import MyUnitDetails from '../../blocks/TaskAllocator/src/UnitDetails.web';
import RentHistory from '../../blocks/TaskAllocator/src/RentHistory.web';
import EditMyUnit from '../../blocks/TaskAllocator/src/EditUnit.web';
import TenantProfile from '../../blocks/TaskAllocator/src/TenantProfile.web';

// Property Manager
import PropertyManagerList from '../../blocks/ReviewAndApproval/src/PropertyManagerList.web';
import RegisterPropertyManager from '../../blocks/ReviewAndApproval/src/RegisterPropertyManager.web';
import RegisterPropertyManagerSuccess from '../../blocks/ReviewAndApproval/src/PropertyManagerSuccess.web';
import PropertyManagerDetails from '../../blocks/ReviewAndApproval/src/PropertyManagerDetails.web';
import PropertyManagerRequest from '../../blocks/ReviewAndApproval/src/PropertyManagerRequest.web';
import EditPropertyManager from '../../blocks/ReviewAndApproval/src/EditPropertyManager.web';
import RequestPropertyManagerDetails from '../../blocks/ReviewAndApproval/src/RequestPropertyManagerDetails.web';

// My Lease
import MyLeaseList from '../../blocks/ContentManagement/src/MyLeaseList.web';

// Notification
import OwnerNotification from '../../blocks/notifications/src/OwnerNotifications.web';
import ChairmanNotification from '../../blocks/notifications/src/ChairmansNotification.web';

// My Expense
import MyExpenseList from '../../blocks/ExpenseTracking/src/MyExpenseList.web';
import UnitExpenseList from '../../blocks/ExpenseTracking/src/UnitExpenseList.web';
import ExpenseDetail from '../../blocks/ExpenseTracking/src/ExpenseDetail.web';
import AddEditExpense from '../../blocks/ExpenseTracking/src/AddEditExpense.web';
import AddExpenseSuccess from '../../blocks/ExpenseTracking/src/AddExpenseSuccess.web';

// Total Expense
import TotalExpense from '../../blocks/StoreCredits/src/MyExpenseReport/TotalExpense.web';
import UnitTotalExpense from '../../blocks/StoreCredits/src/MyExpenseReport/UnitTotalExpense.web';
import RentedVsEmpty from '../../blocks/StoreCredits/src/MyExpenseReport/RentedVsEmpty.web';
import RentedAndEmpty from '../../blocks/StoreCredits/src/MyExpenseReport/RentedAndEmpty.web';
import CityWiseRentedVsEmpty from '../../blocks/StoreCredits/src/MyExpenseReport/CityWiseRentedVsEmpty.web';
import CollectedVsDue from '../../blocks/StoreCredits/src/MyExpenseReport/CollectedVsDue.web';
import SpentVsCollected from '../../blocks/StoreCredits/src/MyExpenseReport/SpentVsCollected.web';

// Auditor dash
import AuditorDashboardGeneral from '../../blocks/customform/src/Auditor.web';

// Issue a report
import IssueAReport from '../../blocks/dashboard/src/IssueAReports.web';

//Company Employee
import EmailAccount from '../../blocks/email-account-login/src/Company Employee/EmailAccount.web';
import DashboardCompanyEmployee from '../../blocks/dashboard/src/Company Employee/DashboardCompanyEmployee.web';
import CompEmpFaq from '../../blocks/contactus/src/CompanyEmployee/CompEmpFaq.web';
import CompEmpAnnouncement from '../../blocks/BroadcastMessage/src/CompanyEmployee/CompEmpAnnouncement.web';
import CompEmpDocument from '../../blocks/Notes/src/CompanyEmployee/CompEmpDocument.web';
import ReportsStatistics from '../../../../src/./packages/./blocks/analytics/src/Reports.web';
import RealEstateCompanies from '../../blocks/ContentManagement/src/Company Employee/RealEstateCompanies.web';
import RealEstateCreateCompany from '../../blocks/ContentManagement/src/Company Employee/RealEstateCreateCompany.web';
import RealEstateCompanyDetails from '../../blocks/ContentManagement/src/Company Employee/RealEstateCompanyDetails.web';

import CompEmpChangePassSucess from '../../blocks/forgot-password/src/Company Employee/CompEmpChangePassSucess.web';
import CompEmpConfirmPass from '../../blocks/forgot-password/src/Company Employee/CompEmpConfirmPass.web';
import CompEmpForgotPassword from '../../blocks/forgot-password/src/Company Employee/CompEmpForgotPassword.web';
import CompEmpOTP from '../../blocks/forgot-password/src/Company Employee/CompEmpOTP.web';

import COOIncidentListing from '../../blocks/ContentManagement/src/Company Employee/COOIncidentListing.web';
import COOIncidentDetails from '../../blocks/ContentManagement/src/Company Employee/COOIncidentDetails.web';

import ComplexBuildingMainBlock from '../../blocks/ContentManagement/src/Company Employee/ComplexBuildingMainBlock.web';
import CEUnitDetail from '../../blocks/ContentManagement/src/Company Employee/CEUnitDetails.web';

import ComplexMainBlock from '../../blocks/customform/src/CompanyEmployee/ComplexMainBlock.web';
import JurisdictionFeed from '../../blocks/location/src/CompanyEmployee/JurisdictionFeed.web';
import BuildingMainBlock from '../../blocks/LeadManagement/src/CompanyEmployee/BuildingMainBlock.web';
import ViewJurisdiction from '../../blocks/location/src/CompanyEmployee/ViewJurisdiction.web';
import ComplexListing from '../../blocks/ContentManagement/src/Company Employee/ComplexListing.web';
import ComplexDetails from '../../blocks/ContentManagement/src/Company Employee/ComplexDetails.web';
import ComplexBuildingDetails from '../../blocks/ContentManagement/src/Company Employee/ComplexBuildingDetails.web';
import ComplexAddUnit from '../../blocks/ContentManagement/src/Company Employee/ComplexAddUnit.web';
import ComplexSharedFacility from '../../blocks/ContentManagement/src/Company Employee/ComplexSharedFacility.web';
import SubscriptionListing from '../../blocks/customisableusersubscriptions/src/SubscriptionListing.web';
import ComingSoon from '../../blocks/dashboard/src/Company Employee/ComingSoon.web';
import COOSubscriptionDetails from '../../blocks/customisableusersubscriptions/src/COOSubscriptionDetails.web';
import AddSubscription from '../../blocks/customisableusersubscriptions/src/AddSubscription.web';
import AddUnit from '../../blocks/ContentManagement/src/Company Employee/AddUnits.web';
import ComplexDocument from '../../blocks/ContentManagement/src/Company Employee/ComplexDocument.web';
import ComplexDocumentView from '../../blocks/ContentManagement/src/Company Employee/ComplexDocumentView.web';

// Company Employee Action Assign
import CEActionsMe from '../../blocks/TaskAllocator/src/CompanyEmployee/CEActionsMe.web';
import CEActionsTeam from '../../blocks/TaskAllocator/src/CompanyEmployee/CEActionsTeam.web';
import CEActionsUser from '../../blocks/TaskAllocator/src/CompanyEmployee/CEActionsUser.web';
import CEActionsProgress from '../../blocks/TaskAllocator/src/CompanyEmployee/CEActionsProgress.web';
import SharedAreaDetails from '../../blocks/ContentManagement/src/Company Employee/SharedAreaDetails.web';

// CE Request Management
import CERequestManagement from '../../blocks/customform/src/CompanyEmployee/CERequestManagement.web';
import CEAwaitAccept from '../../blocks/customform/src/CompanyEmployee/CEAwaitAccept.web';
import CEPendingJoinRequest from '../../blocks/customform/src/CompanyEmployee/CEPendingJoinRequest.web';
import CETotalSentInvite from '../../blocks/customform/src/CompanyEmployee/CETotalSentInvite.web';
import CETotalAcceptRejectInvite from '../../blocks/customform/src/CompanyEmployee/CETotalAcceptRejectInvite.web';

//Client Tickets
import ClientTicket from '../../blocks/contactus/src/ClientTicket.web';
import ReceivedClientTicket from '../../blocks/contactus/src/ReceivedClientTicket.web';
import ClientTicketsDetails from '../../blocks/contactus/src/ClientTicketsDetails.web';

// CE Nominations
import {
  CENominationDetailsExport,
  CENominationExport
} from '../../blocks/FriendList/src/CompanyEmployee/CENominationMain.web';

// CE Meeting
import CEScheduleMeeting from '../../blocks/BulkUploading/src/CompanyEmployee/CEScheduleMeeting.web';
import CEScheduleMeetingDetails from '../../blocks/BulkUploading/src/CompanyEmployee/CEScheduleMeetingDetails.web';
import CEAddScheduleMeetingMinute from '../../blocks/BulkUploading/src/CompanyEmployee/CEAddScheduleMeetingMinutes.web';
import CEMeetingMinutes from '../../blocks/BulkUploading/src/CompanyEmployee/CEMeetingMinutes.web';
import CEMeetingDetail from '../../blocks/BulkUploading/src/CompanyEmployee/CEMeetingDetail.web';
import LoginReports from '../../blocks/analytics/src/LoginReports.web';

// Report
import CEPropertyManagerReport from '../../blocks/analytics/src/CompanyEmployee/CEPropertyManagerReport.web';
import CEPropertyManagerReportDetails from '../../blocks/analytics/src/CompanyEmployee/CEPropertyManagerReportDetails.web';
import CEOwnersReports from '../../blocks/analytics/src/OwnersReports.web';
import CEOwnersReportsDetails from '../../blocks/analytics/src/OwnerReportDetails.web';
import CESubscriptionReports from '../../blocks/analytics/src/SubscriptionReports.web';
import CESubscriptionReportsDetails from '../../blocks/analytics/src/SubscriptionReportsDetails.web';
import CEDashboardApproval from '../../blocks/dashboard/src/DashboardApproval.web';
import CEDashboardsGranted from '../../blocks/dashboard/src/DashboardsGranted.web';
import RegisteredOwner from '../../blocks/dashboard/src/RegisteredOwners.web';
import RegisteredResidents from '../../blocks/dashboard/src/RegisteredResidents.web';
import MyTeamDashboard from '../../blocks/dashboard/src/MyTeam.web';
import OpenTicketsDashboard from '../../blocks/dashboard/src/OpenTickets.web';
import IndivisualBuildingDashboard from '../../blocks/dashboard/src/IndivisualBuilding.web';
import SalesManagerDashboard from '../../blocks/dashboard/src/SalesManager.web';
import SalesManagerProfileDashboard from '../../blocks/dashboard/src/SalesManagerProfile.web';
import PropertyManagerDashboard from '../../blocks/dashboard/src/PropertyManager.web';
import AccountManagerDashboard from '../../blocks/dashboard/src/AccountManager.web';
import TotalComplexesDashboard from '../../blocks/dashboard/src/TotalComplex.web';
import ManagedTeamDashboard from '../../blocks/dashboard/src/ManagementTeam.web';
import RequiredActivity from '../../blocks/dashboard/src/RequiredActivity.web';
import CEPropertyDeveloper from '../../blocks/analytics/src/PropertyDeveloper.web';
import CEPropertyManager from '../../blocks/analytics/src/PropertyManager.web';
import CEDashboardAnalytics from '../../blocks/dashboard/src/DashboardAnalytics.web';
import DashboardProfile from '../../blocks/dashboard/src/DashboardProfile.web'
import CEInvitationReport from '../../blocks/analytics/src/InvitationReport.web';
import CEInvitationUserProfile from '../../blocks/analytics/src/CEInviteUserProfile.web';
import CEGAMemberReport from '../../blocks/analytics/src/GAMemberReport.web';
import CEOwnerStatisticalReport from '../../blocks/analytics/src/OwnerStatisticalReport.web'

import CEAdminNotification from '../../blocks/notifications/src/CEAdminNotification.web';

const routeMap = {
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true,
    roles: [ROLE.PRIVATE]
  },
  CEAdminNotification: {
    component: CEAdminNotification,
    path: '/CompanyEmployee/Notification',
    exact: true
  },
  CEUnitDetail: {
    component: CEUnitDetail,
    path: '/CompanyEmployee/UnitDetails/:id',
    exact: true
  },
  CEPropertyManagerReport: {
    component: CEPropertyManagerReport,
    path: '/CompanyEmployee/PropertyManagerReport',
    exact: true
  },
  CEPropertyManagerReportDetails: {
    component: CEPropertyManagerReportDetails,
    path: '/CompanyEmployee/PropertyManagerReportDetails/:id',
    exact: true
  },
  CEOwnersReports: {
    component: CEOwnersReports,
    path: '/CompanyEmployee/OwnersReports',
    exact: true
  },
  CEOwnersReportsDetails: {
    component: CEOwnersReportsDetails,
    path: '/CompanyEmployee/OwnersReportsDetails/:id',
    exact: true
  },
  CESubscriptionReports: {
    component: CESubscriptionReports,
    path: '/CompanyEmployee/SubscriptionReports',
    exact: true
  },
  CEPropertyDeveloper: {
    component: CEPropertyDeveloper,
    path: '/CompanyEmployee/PropertyDeveloper',
    exact: true
  },
  CEGAMemberReport: {
    component: CEGAMemberReport,
    path: '/CompanyEmployee/GAMemberReport',
    exact: true
  },
  CEOwnerStatisticalReport : {
    component: CEOwnerStatisticalReport,
    path: '/CompanyEmployee/OwnerStatisticalReport',
    exact: true
  },
  CEPropertyManager: {
    component: CEPropertyManager,
    path: '/CompanyEmployee/PropertyManager',
    exact: true
  },
  CESubscriptionReportsDetails: {
    component: CESubscriptionReportsDetails,
    path: '/CompanyEmployee/SubscriptionReportsDetails',
    exact: true
  },
  CEDashboardApproval: {
    component: CEDashboardApproval,
    path: '/CompanyEmployee/DashboardApproval',
    exact: true
  },
  CEDashboardsGranted: {
    component: CEDashboardsGranted,
    path: '/CompanyEmployee/DashboardsGranted',
    exact: true
  },
  CEDashboardAnalytics: {
    component: CEDashboardAnalytics,
    path: '/CompanyEmployee/CEDashboardAnalytics',
    exact: true
  },
  CERequestManagement: {
    component: CERequestManagement,
    path: '/CompanyEmployee/RequestManagement',
    exact: true
  },
  CEAwaitAccept: {
    component: CEAwaitAccept,
    path: '/CompanyEmployee/AwaitAccept/:id/:complex',
    exact: true
  },
  CEPendingJoinRequest: {
    component: CEPendingJoinRequest,
    path: '/CompanyEmployee/PendingJoinRequest/:id/:complex',
    exact: true
  },
  CETotalSentInvite: {
    component: CETotalSentInvite,
    path: '/CompanyEmployee/TotalSentInvite/:id/:complex',
    exact: true
  },
  CETotalAcceptRejectInvite: {
    component: CETotalAcceptRejectInvite,
    path: '/CompanyEmployee/CETotalAcceptRejectInvite/:id/:type/:complex',
    exact: true
  },
  ActionAssignMe: {
    component: CEActionsMe,
    path: '/CompanyEmployee/ActionAssignMe',
    exact: true
  },
  ActionAssignTeam: {
    component: CEActionsTeam,
    path: '/CompanyEmployee/ActionAssignTeam',
    exact: true
  },
  ActionAssignProgress: {
    component: CEActionsProgress,
    path: '/CompanyEmployee/ActionAssignTeam/Progress',
    exact: true
  },
  ActionAssignUser: {
    component: CEActionsUser,
    path: '/CompanyEmployee/ActionAssignTeam/Progress/:id',
    exact: true
  },
  Profile: {
    component: Profile,
    path: '/profile',
    exact: true
  },
  ChairmenProfile: {
    component: ChairmenProfile,
    path: '/ChairmenProfile',
    exact: true
  },
  AdminProfile: {
    component: CEAccountProfile,
    path: '/CompanyEmployee/AdminProfile',
    exact: true
  },
  IncidentChat: {
    component: OwnerIncidentChat,
    path: '/IncidentChat',
    exact: true
  },
  PublicView: {
    component: PublicView,
    path: '/PublicView',
    exact: true
  },
  EditProfile: {
    component: EditProfile,
    path: '/editprofile',
    exact: true
  },
  Inbox: {
    component: OwnerChatInbox,
    path: '/inbox',
    exact: true
  },
  ChairmanChat: {
    component: AdminChat,
    path: '/ChairmanChat',
    exact: true
  },
  ChairmanChat2: {
    component: AdminChat,
    path: '/AuditorChat',
    exact: true
  },
  Chatbox: {
    component: OwnerChatBox,
    path: '/Chatbox',
    exact: true
  },
  NeighboursListing: {
    component: NeighboursListing,
    path: '/NeighboursListing',
    exact: true
  },
  NeighboursDetails: {
    component: NeighboursDetails,
    path: '/NeighboursDetails',
    exact: true
  },
  VeichleList: {
    component: VeichleList,
    path: '/VeichleList',
    exact: true
  },
  FamilyList: {
    component: FamilyList,
    path: '/FamilyList',
    exact: true
  },
  NewVeichleList: {
    component: NewVeichleList,
    path: '/NewVeichleList',
    exact: true
  },
  NewFamily: {
    component: NewFamily,
    path: '/NewFamily',
    exact: true
  },
  EditVeichleList: {
    component: EditVeichleList,
    path: '/editVehicle',
    exact: true
  },
  EditFamily: {
    component: EditFamily,
    path: '/EditFamily',
    exact: true
  },
  EditRequest: {
    component: EditRequest,
    path: '/editRequest',
    exact: true
  },
  NewRequest: {
    component: NewRequest,
    path: '/NewRequest',
    exact: true
  },
  ManagerList: {
    component: ManagerList,
    path: '/mv',
    exact: true
  },
  ManagerViewVehicle: {
    component: ManagerViewVehicle,
    path: '/mvv',
    exact: true
  },
  ViewVeichle: {
    component: ViewVeichle,
    path: '/viewVehicle',
    exact: true
  },
  ChairmanLogin: {
    component: ChairmanLogin,
    path: '/ManagementLogin',
    roles: [ROLE.PRIVATE]
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/EmailAccountLogin',
    exact: true,
    roles: [ROLE.PRIVATE]
  },
  ChangeSuccessfully: {
    component: ChangeSuccessfully,
    path: '/ChangeSuccessfully',
    exact: true
  },
  ChairmanChangeSuccessfully: {
    component: ChairmanChangeSuccessfully,
    path: '/ChairmanChangeSuccessfully',
    exact: true
  },
  ChangePassword: {
    component: ChangePassword,
    path: '/ChangePassword',
    exact: true
  },
  SuggestionListing: {
    component: SuggestionListing,
    path: '/SuggestionListing',
    exact: true
  },
  ResponseSuggestion: {
    component: ResponseSuggestion,
    path: '/ResponseSuggestion',
    exact: true
  },
  NewRequestSuggestion: {
    component: NewRequestSuggestion,
    path: '/NewRequestSuggestion',
    exact: true
  },
  NewSuggestion: {
    component: NewSuggestion,
    path: '/NewSuggestion',
    exact: true
  },
  AuditorDas: {
    component: AuditorDashboardGeneral,
    path: '/AuditorDashboard',
    exact: true
  },
  ChairmanChangePassword: {
    component: ChairmanChangePassword,
    path: '/ChairmanChangePassword',
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    exact: true
  },
  ChairmanForgotPassword: {
    component: ChairmanForgotPassword,
    path: '/ChairmanForgotPassword',
    exact: true
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
    exact: true
  },
  ChairmanForgotPasswordOTP: {
    component: ChairmanForgotPasswordOTP,
    path: '/ChairmanForgotPasswordOTP',
    exact: true
  },
  RegistrationRequest: {
    component: RegistrationRequest,
    path: '/RegistrationRequest',
    exact: true
  },
  ChairmanRegistrationRequest: {
    component: ChairmanRegistrationRequest,
    path: '/ChairmanRegistrationRequest',
    exact: true
  },
  RegistrationRequestSignup: {
    component: RegistrationRequestSignup,
    path: '/RegistrationRequestSignup',
    exact: true
  },
  CreateIncident: {
    component: CreateIncident,
    path: '/CreateIncident',
    exact: true
  },
  IncidentDetails: {
    component: IncidentDetails,
    path: '/IncidentDetails',
    exact: true
  },
  CharmainInvoices: {
    component: CharmainInvoices,
    path: '/CharmainInvoices',
    exact: true
  },
  OverDuePayments: {
    component: OverDuePaymentWeb,
    path: '/OverDuePayments',
    exact: true
  },
  PaymentHistory: {
    component: PaymentHistory,
    path: '/PaymentHistory',
    exact: true
  },
  CharmainReceipts: {
    component: CharmainReceipts,
    path: '/CharmainReceipts',
    exact: true
  },
  IncidentListing: {
    component: IncidentListing,
    path: '/IncidentListing',
    exact: true
  },
  IncidentPreview: {
    component: IncidentPreview,
    path: '/IncidentPreview',
    exact: true
  },
  IncidentReportedSuccessfully: {
    component: IncidentReportedSuccessfully,
    path: '/IncidentReportedSuccessfully',
    exact: true
  },
  IncidentManagement: {
    component: IncidentManagement,
    path: '/IncidentManagement',
    exact: true
  },
  IncidentManagementDetail: {
    component: IncidentManagementDetail,
    path: '/IncidentManagementDetail',
    exact: true
  },
  ServiceProviderDashboard: {
    component: ServiceProviderDashboard,
    path: '/ServiceProviderDashboard',
    exact: true
  },
  ServiceProviderManagementDetail: {
    component: ServiceProviderManagementDetail,
    path: '/ServiceProviderManagementDetail',
    exact: true
  },
  ClassifiedManagerListing: {
    component: ClassifiedManagerListing,
    path: '/ClassifiedManagerListing',
    exact: true
  },
  ClassifiedManagerDetail: {
    component: ClassifiedManagerDetail,
    path: '/ClassifiedManagerDetail',
    exact: true
  },
  CreateClassified: {
    component: CreateClassified,
    path: '/CreateClassified',
    exact: true
  },
  ClassifiedType: {
    component: ClassifiedType,
    path: '/ClassifiedType',
    exact: true
  },
  ClassifiedDetails: {
    component: ClassifiedDetails,
    path: '/ClassifiedDetails',
    exact: true
  },
  ClassifiedListing: {
    component: ClassifiedListing,
    path: '/ClassifiedListing',
    exact: true
  },
  ClassifiedPreview: {
    component: ClassifiedPreview,
    path: '/ClassifiedPreview',
    exact: true
  },
  ClassifiedReportedSuccessfully: {
    component: ClassifiedReportedSuccessfully,
    path: '/ClassifiedReportedSuccessfully',
    exact: true
  },
  ClassifiedEditSuccessfully: {
    component: ClassifiedEditSuccessfully,
    path: '/ClassifiedEditSuccessfully',
    exact: true
  },
  FacilityReservationListing: {
    component: FacilityReservationListing,
    path: '/FacilityReservationListing',
    exact: true
  },
  FacilityReservationDetails: {
    component: FacilityReservationDetails,
    path: '/FacilityReservationDetails',
    exact: true
  },
  FacilityReservation: {
    component: FacilityReservation,
    path: '/FacilityReservation',
    exact: true
  },
  CreateFacilityReservation: {
    component: CreateFacilityReservation,
    path: '/CreateFacilityReservation',
    exact: true
  },
  FacilityReservationReportedSuccessfully: {
    component: FacilityReservationReportedSuccessfully,
    path: '/FacilityReservationReportedSuccessfully',
    exact: true
  },
  FacilityManagerDetail: {
    component: FacilityManagerDetail,
    path: '/FacilityManagerDetail/:id',
    exact: true
  },
  ManagerFacilityReservation: {
    component: ManagerFacilityReservation,
    path: '/ManagerFacilityReservation',
    exact: true
  },
  Polling: {
    component: Polling,
    path: '/Polling',
    exact: true
  },
  CreatePolls: {
    component: CreatePolls,
    path: '/CreatePolls',
    exact: true
  },
  CECreatePolls: {
    component: CECreatePoll,
    path: '/CompanyEmployee/CreatePoll',
    exact: true
  },
  TakeSurvey: {
    component: SurveyInitial,
    path: '/TakeSurvey',
    exact: true
  },
  SurveyFill: {
    component: SurveyParticipate,
    path: '/SurveyParticipate',
    exact: true
  },
  SurveyResponse: {
    component: SurveyMyResponse,
    path: '/SurveyResponse',
    exact: true
  },
  SurveySuccess: {
    component: SurveySubmitted,
    path: '/SurveySuccess',
    exact: true
  },
  CreateSurveys: {
    component: CreateSurveys,
    path: '/CreateSurveys',
    exact: true
  },
  CECreateSurvey: {
    component: CECreateSurvey,
    path: '/CompanyEmployee/CreateSurvey',
    exact: true
  },
  PollPreview: {
    component: PollPreviewExport,
    path: '/PollPreview',
    exact: true
  },
  CEPollPreview: {
    component: CEPollPreviewExport,
    path: '/CompanyEmployee/PollPreview',
    exact: true
  },
  SurveyPreview: {
    component: SurveyPreview,
    path: '/SurveyPreview',
    exact: true
  },
  CESurveyPreview: {
    component: CESurveyPreview,
    path: '/CompanyEmployee/CESurveyPreview',
    exact: true
  },
  PollsallData: {
    component: PollsallData,
    path: '/PollsallData',
    exact: true
  },
  SurveyallData: {
    component: SurveyallData,
    path: '/SurveyallData',
    exact: true
  },
  PollsSurvey: {
    component: PollsSurvey,
    path: '/PollsSurvey',
    exact: true
  },
  SubmitPoll: {
    component: SubmitPoll,
    path: '/SubmitPoll',
    exact: true
  },
  PollVoteSubmitted: {
    component: PollVoteSubmitted,
    path: '/PollVoteView',
    exact: true
  },
  PollDetails: {
    component: PollDetails,
    path: '/PollDetails',
    exact: true
  },
  SurveyDetails: {
    component: SurveyDetailsMain,
    path: '/SurveyDetails',
    exact: true
  },
  SurveyReport: {
    component: SurveyReport,
    path: '/SurveyReport',
    exact: true
  },
  PollReport: {
    component: PollReport,
    path: '/PollReport',
    exact: true
  },
  PollResponseCompleted: {
    component: PollResponseCompleted,
    path: '/PollResponseCompleted',
    exact: true
  },
  OwnerDashboard: {
    component: OwnerDashboard,
    path: '/OwnerDashboard',
    exact: true,
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER]
  },
  ResidentDashboard: {
    component: ResidentDashboard,
    path: '/ResidentDashboard',
    exact: true,
    roles: [ROLE.OWNER_RESIDENT, ROLE.TENANT]
  },
  // Chairman - Help
  FaqChairman: {
    component: FaqChairman,
    path: '/FaqChairman',
    exact: true,
    roles: [
      ROLE.CHAIRMAN,
      ROLE.MANAGER,
      ROLE.SECURITY,
      ROLE.AUDITOR,
      ROLE.SERVICE_PROVIDER
    ]
  },
  FaqComplexList: {
    component: FaqComplexList,
    path: '/FaqComplexList',
    exact: true,
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER, ROLE.OWNER_RESIDENT, ROLE.TENANT]
  },
  FaqOwner: {
    component: FaqOwner,
    path: '/FaqOwner/:id',
    exact: true,
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER]
  },
  FaqResident: {
    component: FaqResident,
    path: '/FaqResident/:id',
    exact: true,
    roles: [ROLE.OWNER_RESIDENT, ROLE.TENANT]
  },
  ContactUsChairman: {
    component: ContactUsChairman,
    path: '/ContactUsChairman',
    exact: true
  },
  SubscriptionDetail: {
    component: SubscriptionDetail,
    path: '/SubscriptionDetail',
    exact: true
  },
  // Chairman - Document
  DocumentChairman: {
    component: DocumentChairman,
    path: '/DocumentChairman',
    exact: true
  },
  DocumentListChairman: {
    component: DocumentListChairman,
    path: '/DocumentChairman/:name',
    exact: true
  },
  DocumentViewChairman: {
    component: DocumentViewChairman,
    path: '/DocumentChairman/:name/:id/view',
    exact: true
  },
  // Owner, Resident - Personal Document, Building Document
  PersonalDocument: {
    component: PersonalDocument,
    path: '/PersonalDocument',
    exact: true
  },
  PersonalDocumentList: {
    component: PersonalDocumentList,
    path: '/PersonalDocument/:name',
    exact: true
  },
  ViewPersonalDocument: {
    component: ViewPersonalDocument,
    path: '/PersonalDocument/:name/:id/view',
    exact: true
  },
  BuildingDocuments: {
    component: BuildingDocuments,
    path: '/BuildingDocuments',
    exact: true
  },
  BuildingDocumentList: {
    component: BuildingDocumentList,
    path: '/BuildingDocuments/:name',
    exact: true
  },
  ViewBuildingDocument: {
    component: ViewBuildingDocument,
    path: '/BuildingDocuments/:name/:id/view',
    exact: true
  },
  //Client Tickets
  ClientTicket: {
    component: ClientTicket,
    path: '/CompanyEmployee/ClientTicket',
    exact: true
  },
  ClientTicketsDetails: {
    component: ClientTicketsDetails,
    path: '/CompanyEmployee/ClientTicketsDetails/:id',
    exact: true
  },
  ReceivedClientTicket: {
    component: ReceivedClientTicket,
    path: '/CompanyEmployee/ReceivedClientTicket/:id',
    exact: true
  },
  // Owner - Contract
  ContractsList: {
    component: ContractsList,
    path: '/Contracts',
    exact: true
  },
  ContractDetail: {
    component: ContractDetail,
    path: '/Contract/:id',
    exact: true
  },
  RenewContract: {
    component: RenewContract,
    path: '/Contract/:id/Renew',
    exact: true
  },
  TemplateDetail: {
    component: TemplateDetail,
    path: '/Template/:id',
    exact: true
  },
  IssueContract: {
    component: IssueContract,
    path: '/IssueContract',
    exact: true
  },
  IssueLease: {
    component: IssueLease,
    path: '/IssueLease',
    exact: true
  },
  SelectedTemplateTwo: {
    component: SelectedTemplate,
    path: '/IssueContract/:templateId',
    exact: true
  },
  LeaseFormIssueLease: {
    component: LeaseForm,
    path: '/IssueContract/:templateId/LeaseForm',
    exact: true
  },
  ChangedSelectedTemplate: {
    component: ChangedSelectedTemplate,
    path: '/IssueContract/:templateId/LeaseForm/Template',
    exact: true
  },
  AddCondition: {
    component: AddCondition,
    path: '/IssueContract/:templateId/LeaseForm/Template/AddCondition',
    exact: true
  },
  ReviewTemplate: {
    component: ReviewTemplate,
    path: '/IssueContract/:templateId/LeaseForm/Template/Review',
    exact: true
  },
  // Chairman - Meetings
  ChairmanScheduledMeeting: {
    component: ChairmanScheduledMeeting,
    path: '/ScheduledMeetings',
    exact: true,
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER]
  },
  ChairmanScheduledMeetingDetails: {
    component: ScheduledMeetingDetails,
    path: '/ScheduledMeeting/:id',
    exact: true,
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER]
  },
  ChairmanMeetingMinutes: {
    component: ChairmanMeetingMinutes,
    path: '/MeetingMinutes',
    exact: true,
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER]
  },
  ChairmanMeetingMinuteDetails: {
    component: MeetingMinuteDetails,
    path: '/MeetingMinute/:id',
    exact: true,
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER]
  },
  MeetingMinuteNote: {
    component: MeetingMinuteNote,
    path: '/MeetingMinute/:id/Note',
    exact: true,
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER]
  },
  ScheduleMeetingMinuteNote: {
    component: MeetingMinuteNote,
    path: '/ScheduledMeeting/:id/Note',
    exact: true,
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER]
  },
  // Owner - Meetings
  OwnerMeetingsList: {
    component: MyMeetings,
    path: '/MyMeetings',
    exact: true
  },
  OwnerMyMeetingDetail: {
    component: MyMeetingDetail,
    path: '/MyMeeting/:id',
    exact: true
  },
  OwnerMyMeetingMinuteDetail: {
    component: MyMeetingMinuteDetail,
    path: '/MeetingMinuteDetail/:id',
    exact: true
  },
  // Chairman - Profile
  ChairmanProfile: {
    component: ChairmanProfile,
    path: '/ManagementProfile',
    exact: true
  },
  AuditorProfile: {
    component: ChairmanProfile,
    path: '/AuditorProfile',
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    exact: true
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
    exact: true
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling',
    exact: true
  },
  DashboardGeneral: {
    component: DashboardGeneral,
    path: '/DashboardGeneral',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER],
    exact: true
  },
  DashboardTicket: {
    component: DashboardTicket,
    path: '/DashboardTicket',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.SERVICE_PROVIDER],
    exact: true
  },
  TicketGeneratedYear: {
    component: TicketGeneratedYear,
    path: '/DashboardTicket/Year/:year',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.SERVICE_PROVIDER],
    exact: true
  },
  TicketGeneratedDays: {
    component: TicketGeneratedDays,
    path: '/DashboardTicket/Days/:days',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.SERVICE_PROVIDER],
    exact: true
  },
  AvgResolutionTicket: {
    component: AvgResolutionTicket,
    path: '/AvgResolutionTicket',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.SERVICE_PROVIDER],
    exact: true
  },
  DashboardActions: {
    component: DashboardActions,
    path: '/DashboardActions',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR, ROLE.SERVICE_PROVIDER],
    exact: true
  },
  DashboardBudget: {
    component: DashboardBudget,
    path: '/DashboardBudget',
    exact: true
  },
  BudgetDetails: {
    component: BudgetDetails,
    path: '/BudgetDetails',
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    exact: true
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
    exact: true
  },
  Polling: {
    component: Polling,
    path: '/Polling',
    exact: true
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling',
    exact: true
  },
  Register: {
    component: Registration,
    path: '/register',
    exact: true
  },
  OwnerRegister: {
    component: OwnerRegistration,
    path: '/registerowner',
    exact: true
  },
  OwnerSelect: {
    component: SelectOwner,
    path: '/selectowner',
    exact: true
  },
  ManagerRegister: {
    component: ManagerRegistration,
    path: '/registermanager',
    exact: true
  },
  UnitRegister: {
    component: UnitRegister,
    path: '/registerunit',
    exact: true
  },
  RegisterUnitManually: {
    component: RegisterUnitManually,
    path: '/registerunitmanually',
    exact: true
  },
  RegisterUnitLink: {
    component: RegisterAddressLink,
    path: '/RegisterUnitLink',
    exact: true
  },
  SearchComplex: {
    component: SearchComplex,
    path: '/searchComplex',
    exact: true
  },
  VerifyOTP: {
    component: VerifyOTP,
    path: '/otp',
    exact: true
  },
  SelectType: {
    component: SelectType,
    path: '/selecttype',
    exact: true
  },
  AddressFill: {
    component: Address,
    path: '/addressfill',
    exact: true
  },
  // Community Management
  CommunityUserProfile: {
    component: AdminUserProfile,
    path: '/CommunityUserProfile',
    exact: true
  },
  GaMembers: {
    component: AdminGaMembers,
    path: '/ga_member',
    exact: true
  },
  resident: {
    component: AdminGaMembers,
    path: '/resident',
    exact: true
  },
  owner: {
    component: AdminGaMembers,
    path: '/owner',
    exact: true
  },
  property_manager: {
    component: AdminGaMembers,
    path: '/property_manager',
    exact: true
  },
  ResidentsProfile: {
    component: ResidentsProfile,
    path: '/ResidentsProfile',
    exact: true
  },
  PropertysManager: {
    component: PropertysManager,
    path: '/PropertysManager',
    exact: true
  },
  Suggestions: {
    component: Suggestions,
    path: '/Suggestions',
    exact: true
  },
  SuggestionDetails: {
    component: SuggestionDetails,
    path: '/SuggestionDetails',
    exact: true
  },
  SuggestionData: {
    component: SuggestionData,
    path: '/SuggestionData',
    exact: true
  },
  Announcement: {
    component: Announcement,
    path: '/Announcement',
    exact: true
  },
  BuildingAnnouncement: {
    component: BuildingAnnouncement,
    path: '/BuildingAnnouncement',
    exact: true
  },
  AnnouncementInfo: {
    component: AnnouncementInfo,
    path: '/AnnouncementInfo',
    exact: true
  },
  Announcements: {
    component: Announcements,
    path: '/Announcements',
    exact: true
  },
  AnnouncementDetails: {
    component: AnnouncementDetails,
    path: '/AnnouncementDetails',
    exact: true
  },
  Visitors: {
    component: Visitors,
    path: '/visitors',
    exact: true
  },
  VisitorAdd: {
    component: VisitorAdd,
    path: '/AddVisitor',
    exact: true
  },
  VisitorEdit: {
    component: VisitorAdd,
    path: '/UpdateVisitor/:id',
    exact: true
  },
  PastVisitors: {
    component: PastVisitors,
    path: '/PastVisitors',
    exact: true
  },
  VisitorList: {
    component: VisitorList,
    path: '/VisitorList',
    exact: true
  },
  Unit: {
    component: Unit,
    path: '/Unit',
    exact: true
  },
  UnitGeneralDetails: {
    component: UnitGeneralDetails,
    path: '/UnitGeneralDetails',
    exact: true
  },
  ScheduledVisitors: {
    component: ScheduledVisitors,
    path: '/ScheduledVisitors',
    exact: true
  },
  VisitorDetails: {
    component: VisitorDetails,
    path: '/VisitorDetails',
    exact: true
  },
  VisitorDetailsPast: {
    component: VisitorDetails,
    path: '/VisitorDetails/:type',
    exact: true
  },
  VisitorsDetails: {
    component: VisitorsDetails,
    path: '/VisitorsDetails',
    exact: true
  },
  VisitorAddSuccess: {
    component: VisitorAddSuccess,
    path: '/VisitorAddSuccess',
    exact: true
  },
  VisitorUpdateSuccess: {
    component: VisitorUpdateSuccess,
    path: '/VisitorUpdateSuccess',
    exact: true
  },
  // My Team
  MyTeam: {
    component: MyTeam,
    path: '/TeamMembers',
    exact: true
  },
  MyTeamAudi: {
    component: MyTeamAudi,
    path: '/AudiTeamMembers',
    exact: true
  },
  MyTeamCore: {
    component: MyTeamCore,
    path: '/TeamMembers/:type',
    exact: true
  },
  TeamUserDetails: {
    component: MyTeamUserDetails,
    path: '/TeamMember/userDetails',
    exact: true
  },
  ChairmanNominationMain: {
    component: ChairmanNominationMain,
    path: '/Nominations',
    exact: true
  },
  ChairmanNominationPrint: {
    component: ChairmanNominationPrint,
    path: '/NominationsPrint',
    exact: true
  },
  NominationDetails: {
    component: NominationDetails,
    path: '/NominationDetails',
    exact: true
  },
  ChairmanNominations: {
    component: ChairmanNominations,
    path: '/ChairmanNominations',
    exact: true
  },
  ChairmanNominationDetails: {
    component: ChairmanNominationDetails,
    path: '/ChairmanNominationDetails',
    exact: true
  },
  NominateMySelf: {
    component: NominateMySelf,
    path: '/NominateMySelf',
    exact: true
  },
  MyNomination: {
    component: MyNomination,
    path: '/MyNomination',
    exact: true
  },
  NominationSuccess: {
    component: NominationSuccess,
    path: '/NominationSuccess',
    exact: true
  },
  NominationUpdated: {
    component: NominationUpdated,
    path: '/NominationUpdated',
    exact: true
  },
  TaskManagement: {
    component: TaskManagement,
    path: '/TaskManagement',
    exact: true
  },
  // RentPayments
  RentPayments: {
    component: RentPayments,
    path: '/RentPayments',
    exact: true
  },
  RentUnitLists: {
    component: RentUnitLists,
    path: '/RentUnitList/:id',
    exact: true
  },
  UnitRentList: {
    component: ViewMyRents,
    path: '/UnitRentList/:id',
    exact: true
  },
  RentDetails: {
    component: RentDetails,
    path: '/RentDetails/:id',
    exact: true
  },
  // Fees & Payment
  FeesAndPayment: {
    component: FeesAndPayment,
    path: '/FeesAndPayment',
    exact: true
  },
  BudgetAndExpenseDetails: {
    component: FeesAndPaymentOwner,
    path: '/BudgetAndExpenseDetails',
    exact: true
  },
  ViewMyInvoices: {
    component: ViewMyInvoices,
    path: '/MyInvoices',
    exact: true
  },
  InvoiceDetails: {
    component: InvoiceDetails,
    path: '/Invoice/:id/:type',
    exact: true
  },
  ViewMyReceipts: {
    component: ViewMyReceipts,
    path: '/MyReceipts',
    exact: true
  },
  ReceiptDetails: {
    component: ReceiptDetails,
    path: '/Receipt/:id/:type',
    exact: true
  },
  PreviousPayments: {
    component: PreviousPayments,
    path: '/PreviousPayments',
    exact: true
  },
  PaymentDetails: {
    component: PaymentDetails,
    path: '/Payment/:id',
    exact: true
  },
  BudgetSpending: {
    component: BudgetSpending,
    path: '/BudgetSpending/ComplexList/:id',
    exact: true
  },
  BudgetSpendingComplexList: {
    component: ComplexList,
    path: '/BudgetSpending/ComplexList',
    exact: true
  },
  BudgetSpendingDetails: {
    component: BudgetSpendingDetails,
    path: '/BudgetSpending/ComplexList/:cid/Spent/:id',
    exact: true
  },
  BuildingBudget: {
    component: BuildingBudget,
    path: '/BuildingBudget/ComplexList/:id',
    exact: true
  },
  BuildingBudgetComplexList: {
    component: ComplexList,
    path: '/BuildingBudget/ComplexList',
    exact: true
  },
  MyManagementFee: {
    component: MyManagementFee,
    path: '/MyManagementFee/ComplexList/:id',
    exact: true
  },
  ManagementFeeComplexList: {
    component: ComplexList,
    path: '/MyManagementFee/ComplexList',
    exact: true
  },
  MyInvoicesAndReceipts: {
    component: MyInvoicesAndReceipts,
    path: '/InvoicesAndReceipts',
    exact: true
  },
  MyInvoices: {
    component: MyInvoices,
    path: '/MyInvoices/Owner',
    exact: true
  },
  MyInvoiceDetails: {
    component: MyInvoiceDetails,
    path: '/MyInvoices/Owner/:id/:type',
    exact: true
  },
  MyReceipts: {
    component: MyReceipts,
    path: '/MyReceipts/Owner',
    exact: true
  },
  AddRentPayment: {
    component: AddRentPayment,
    path: '/AddRentPayment',
    exact: true
  },
  MyReceiptsDetails: {
    component: MyReceiptsDetails,
    path: '/MyReceipts/Owner/:id/:type',
    exact: true
  },
  CRequestManagement: {
    component: CRequestManagement,
    path: '/CommunityRequestManagement',
    exact: true
  },
  SentInvitation: {
    component: AdminSentInvitation,
    path: '/SentInvitation',
    exact: true
  },
  PendingRequest: {
    component: PendingRequest,
    path: '/PendingRequest',
    exact: true
  },
  AwaitingAcceptece: {
    component: AdminAwaitingAcceptance,
    path: '/AwaitingAcceptece',
    exact: true
  },
  UserDetailedProfile: {
    component: AdminUserDetailedProfile,
    path: '/UserDetailedProfile',
    exact: true
  },
  //Schedule Meeting
  CEScheduleMeeting: {
    component: CEScheduleMeeting,
    path: '/CompanyEmployee/CEScheduleMeeting',
    exact: true
  },
  CEScheduleMeetingDetails: {
    component: CEScheduleMeetingDetails,
    path: '/CompanyEmployee/CEScheduleMeetingDetails/:id',
    exact: true
  },
  CEAddScheduleMeetingMinute: {
    component: CEAddScheduleMeetingMinute,
    path: '/CompanyEmployee/CEScheduleMeetingDetails/:id/Note',
    exact: true
  },
  CEMeetingMinutes: {
    component: CEMeetingMinutes,
    path: '/CompanyEmployee/CEMeetingMinutes',
    exact: true
  },
  CEMeetingDetail: {
    component: CEMeetingDetail,
    path: '/CompanyEmployee/CEMeetingDetail/:id',
    exact: true
  },
  // Building and Complex
  Buildings: {
    component: Buildings,
    path: '/Building/:id',
    roles: [
      ROLE.CHAIRMAN,
      ROLE.MANAGER,
      ROLE.SECURITY,
      ROLE.AUDITOR,
      ROLE.SERVICE_PROVIDER
    ],
    exact: true
  },
  Complex: {
    component: Complex,
    path: '/Complex',
    roles: [
      ROLE.CHAIRMAN,
      ROLE.MANAGER,
      ROLE.SECURITY,
      ROLE.AUDITOR,
      ROLE.SERVICE_PROVIDER
    ],
    exact: true
  },
  Buildings: {
    component: Buildings,
    path: '/Building/:id',
    roles: [
      ROLE.CHAIRMAN,
      ROLE.MANAGER,
      ROLE.SECURITY,
      ROLE.AUDITOR,
      ROLE.SERVICE_PROVIDER
    ],
    exact: true
  },
  UnitDetails: {
    component: UnitDetails,
    path: '/UnitDetail/:id',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.SECURITY, ROLE.AUDITOR],
    exact: true
  },
  SharedArea: {
    component: SharedArea,
    path: '/SharedArea/:id',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.SECURITY, ROLE.AUDITOR],
    exact: true
  },
  OwnerComplexList: {
    component: OwnerComplexList,
    path: '/ComplexList',
    roles: [
      ROLE.OWNER,
      ROLE.OWNER_RESIDENT,
      ROLE.TENANT,
      ROLE.PROPERTY_MANAGER
    ],
    exact: true
  },
  OwnerComplex: {
    component: OwnerComplex,
    path: '/ComplexDetails/:id',
    roles: [
      ROLE.OWNER,
      ROLE.OWNER_RESIDENT,
      ROLE.TENANT,
      ROLE.PROPERTY_MANAGER
    ],
    exact: true
  },
  OwnerBuildings: {
    component: OwnerBuildings,
    path: '/BuildingDetails/:cid/:id',
    roles: [
      ROLE.OWNER,
      ROLE.OWNER_RESIDENT,
      ROLE.TENANT,
      ROLE.PROPERTY_MANAGER
    ],
    exact: true
  },
  // My Tenant
  TenantList: {
    component: TenantList,
    path: '/Tenants',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  TenantDetails: {
    component: TenantDetails,
    path: '/Tenant/:id',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  RegisterTenant: {
    component: RegisterTenant,
    path: '/RegisterTenant',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  EditTenant: {
    component: EditTenant,
    path: '/EditTenant/:id',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  // My Unit
  MyUnitList: {
    component: MyUnitList,
    path: '/MyUnitList',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  RegisterMyUnit: {
    component: RegisterMyUnit,
    path: '/RegisterMyUnit',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  RegisterMyUnitSuccess: {
    component: RegisterMyUnitSuccess,
    path: '/RegisterMyUnit/Success',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  MyUnitDetails: {
    component: MyUnitDetails,
    path: '/MyUnitDetails/:id',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  EditMyUnit: {
    component: EditMyUnit,
    path: '/MyUnitDetails/Edit/:id',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  RentHistory: {
    component: RentHistory,
    path: '/MyUnitDetails/:id/RentHistory',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  TenantProfile: {
    component: TenantProfile,
    path: '/MyUnitDetails/:uId/TenantProfile/:id',
    roles: [ROLE.PUBLIC, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  // Property Manager
  PropertyManagerList: {
    component: PropertyManagerList,
    path: '/PropertyManagers',
    roles: [ROLE.OWNER],
    exact: true
  },
  PropertyManagerRequest: {
    component: PropertyManagerRequest,
    path: '/PropertyManagers/Request',
    roles: [ROLE.OWNER],
    exact: true
  },
  RequestPropertyManagerDetails: {
    component: RequestPropertyManagerDetails,
    path: '/PropertyManagers/Request/:id',
    roles: [ROLE.OWNER],
    exact: true
  },
  PropertyManagerDetails: {
    component: PropertyManagerDetails,
    path: '/PropertyManager/:id',
    roles: [ROLE.OWNER],
    exact: true
  },
  EditPropertyManager: {
    component: EditPropertyManager,
    path: '/PropertyManager/Edit/:id',
    roles: [ROLE.OWNER],
    exact: true
  },
  RegisterPropertyManager: {
    component: RegisterPropertyManager,
    path: '/RegisterPropertyManagers',
    roles: [ROLE.OWNER],
    exact: true
  },
  RegisterPropertyManagerSuccess: {
    component: RegisterPropertyManagerSuccess,
    path: '/RegisterPropertyManagers/Success',
    roles: [ROLE.OWNER],
    exact: true
  },
  // Reports
  ReportDashboard: {
    component: ReportDashboard,
    path: '/Reports',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER],
    exact: true
  },
  BudgetReport: {
    component: BudgetReport,
    path: '/BudgetReports',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  BudgetReportDetails: {
    component: BudgetReportDetails,
    path: '/BudgetReports/:id',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  GenerateBudgetReport: {
    component: GenerateBudgetReport,
    path: '/GenerateBudgetReport',
    roles: [ROLE.MANAGER],
    exact: true
  },
  GenerateBudgetReportPreview: {
    component: GenerateBudgetReportPreview,
    path: '/GenerateBudgetReportPreview',
    roles: [ROLE.MANAGER, ROLE.AUDITOR, ROLE.CHAIRMAN],
    exact: true
  },
  ExpenseReport: {
    component: ExpenseReport,
    path: '/ExpenseReports',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  ExpenseReportDetails: {
    component: ExpenseReportDetails,
    path: '/ExpenseReportDetails',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  AuditReport: {
    component: AuditReport,
    path: '/AuditReports',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  AuditReportBuilding: {
    component: AuditReport,
    path: '/AuditReport/:id',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  ManagementFeeReport: {
    component: ManagementFeeReport,
    path: '/ManagementFeeReports',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER],
    exact: true
  },
  AuditReportDetails: {
    component: AuditReportDetails,
    path: '/AuditReportDetails/:id',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR],
    exact: true
  },
  InvitationReport: {
    component: InvitationReport,
    path: '/InvitationReport',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER],
    exact: true
  },
  // My Lease
  MyLeaseList: {
    component: MyLeaseList,
    path: '/MyLeaseList',
    roles: [ROLE.TENANT, ROLE.OWNER_RESIDENT],
    exact: true
  },
  // Notification
  OwnerNotification: {
    component: OwnerNotification,
    path: '/Notifications',
    roles: [
      ROLE.OWNER,
      ROLE.PROPERTY_MANAGER,
      ROLE.OWNER_RESIDENT,
      ROLE.TENANT
    ],
    exact: true
  },
  ChairmanNotification: {
    component: ChairmanNotification,
    path: '/AdminNotification',
    roles: [ROLE.CHAIRMAN, ROLE.MANAGER, ROLE.AUDITOR, ROLE.SERVICE_PROVIDER],
    exact: true
  },
  // My Expense
  MyExpenseList: {
    component: MyExpenseList,
    path: '/MyExpenseList',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  UnitExpenseList: {
    component: UnitExpenseList,
    path: '/MyExpenseList/:id',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  ExpenseDetail: {
    component: ExpenseDetail,
    path: '/ExpenseDetail/:id',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  AddExpense: {
    component: AddEditExpense,
    path: '/AddExpense',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  AddExpenseSuccess: {
    component: AddExpenseSuccess,
    path: '/AddExpense/Success',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  EditExpense: {
    component: AddEditExpense,
    path: '/EditExpense/:id',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  // Total Expense
  TotalExpense: {
    component: TotalExpense,
    path: '/TotalExpense',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  UnitTotalExpense: {
    component: UnitTotalExpense,
    path: '/TotalExpense/:id',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  RentedVsEmpty: {
    component: RentedVsEmpty,
    path: '/RentedVsEmpty',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  RentedAndEmpty: {
    component: RentedAndEmpty,
    path: '/RentedVsEmpty/:id',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  CityWiseRentedVsEmpty: {
    component: CityWiseRentedVsEmpty,
    path: '/RentedVsEmpty/City/:id',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  CollectedVsDue: {
    component: CollectedVsDue,
    path: '/CollectedVsDue',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  SpentVsCollected: {
    component: SpentVsCollected,
    path: '/SpentVsCollected',
    roles: [ROLE.OWNER, ROLE.OWNER_RESIDENT, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  EmailAlerts: {
    component: EmailAlerts,
    path: '/EmailAlerts',
    roles: [ROLE.OWNER, ROLE.PROPERTY_MANAGER],
    exact: true
  },
  IssueAReport: {
    component: IssueAReport,
    path: '/IssueAReport',
    exact: true
  },
  //Company Employee
  EmailAccount: {
    component: EmailAccount,
    path: '/CompanyEmployee/Login',
    exact: true
  },
  ComingSoon: {
    component: ComingSoon,
    path: '/CompanyEmployee/ComingSoon',
    exact: true
  },
  SharedAreaDetails: {
    component: SharedAreaDetails,
    path: '/CompanyEmployee/:details/:cId/SharedAreaDetails/:id',
    exact: true
  },
  DashboardCompanyEmployee: {
    component: DashboardCompanyEmployee,
    path: '/CompanyEmployee/Dashboard',
    exact: true
  },
  CompEmpFaq: {
    component: CompEmpFaq,
    path: '/CompanyEmployee/CompEmpFaq',
    exact: true
  },
  CompEmpAnnouncement: {
    component: CompEmpAnnouncement,
    path: '/CompanyEmployee/CompEmpAnnouncement',
    exact: true
  },
  CompEmpDocument: {
    component: CompEmpDocument,
    path: '/CompanyEmployee/CompEmpDocument',
    exact: true
  },
  ReportsStatistics: {
    component: ReportsStatistics,
    path: '/CompanyEmployee/ReportsStatistics',
    exact: true
  },
  RealEstateCompanyEmployee: {
    component: RealEstateCompanies,
    path: '/CompanyEmployee/realestate-companies',
    exact: true
  },
  RealEstateCreateCompany: {
    component: RealEstateCreateCompany,
    path: '/CompanyEmployee/realestate-register-company',
    exact: true
  },
  RealEstateCompanyDetail: {
    component: RealEstateCompanyDetails,
    path: '/CompanyEmployee/realestate-company-details/:id',
    exact: true
  },
  CompEmpConfirmPass: {
    component: CompEmpConfirmPass,
    path: '/CompanyEmployee/CMConfirmPass',
    exact: true
  },
  CompEmpChangePassSucess: {
    component: CompEmpChangePassSucess,
    path: '/CompanyEmployee/CMChangePassSucess',
    exact: true
  },
  CompEmpForgotPassword: {
    component: CompEmpForgotPassword,
    path: '/CompanyEmployee/CMForgotPass',
    exact: true
  },
  CompEmpOTP: {
    component: CompEmpOTP,
    path: '/CompanyEmployee/CMOTP',
    exact: true
  },
  //COO incident listing
  COOIncidentListing: {
    component: COOIncidentListing,
    path: '/CompanyEmployee/cooincidentListing',
    exact: true
  },
  COOOIncidentDetails: {
    component: COOIncidentDetails,
    path: '/CompanyEmployee/cooincident-details/:id',
    exact: true
  },
  RealEstateEditCompany: {
    component: RealEstateCreateCompany,
    path: '/CompanyEmployee/realestate-edit-company/:id',
    exact: true
  },
  ComplexBuildingMainBlock: {
    component: ComplexBuildingMainBlock,
    path: '/CompanyEmployee/ComplexBuildingMainBlock/:id',
    exact: true
  },
  EditComplexBuildingMainBlock: {
    component: ComplexBuildingMainBlock,
    path: '/CompanyEmployee/ComplexBuildingMainBlock/:id/:bId',
    exact: true
  },
  JurisdictionFeed: {
    component: JurisdictionFeed,
    path: '/CompanyEmployee/jurisdication',
    exact: true
  },
  ViewJurisdiction: {
    component: ViewJurisdiction,
    path: '/CompanyEmployee/ViewJurisdiction/:id',
    exact: true
  },
  ComplexMainBlock: {
    component: ComplexMainBlock,
    path: '/CompanyEmployee/ComplexMainBlock',
    exact: true
  },
  EditComplexMainBlock: {
    component: ComplexMainBlock,
    path: '/CompanyEmployee/ComplexMainBlock/:id',
    exact: true
  },
  BuildingMainBlock: {
    component: BuildingMainBlock,
    path: '/CompanyEmployee/BuildingMainBlock',
    exact: true
  },
  EditBuildingMainBlock: {
    component: BuildingMainBlock,
    path: '/CompanyEmployee/BuildingMainBlock/:id',
    exact: true
  },
  ComplexListing: {
    component: ComplexListing,
    path: '/CompanyEmployee/ComplexListing',
    exact: true
  },
  CEComplexDetails: {
    component: ComplexDetails,
    path: '/CompanyEmployee/ComplexDetails/:id',
    exact: true
  },
  CEComplexDocument: {
    component: ComplexDocument,
    path: '/CompanyEmployee/:details/:id/Document/:name',
    exact: true
  },
  CEComplexDocumentView: {
    component: ComplexDocumentView,
    path: '/CompanyEmployee/:details/:id/Document/:name/view/:dId',
    exact: true
  },
  ComplexBuildingDetails: {
    component: ComplexBuildingDetails,
    path: '/CompanyEmployee/BuildingDetailsPage/:id',
    exact: true
  },
  AddUnit: {
    component: AddUnit,
    path: '/CompanyEmployee/addunit',
    exact: true
  },
  ComplexAddUnit: {
    component: ComplexAddUnit,
    path: '/CompanyEmployee/complex/add-unit/:id',
    exact: true
  },
  ComplexEditUnit: {
    component: ComplexAddUnit,
    path: '/CompanyEmployee/complex/edit-unit/:id/:uId',
    exact: true
  },
  ComplexSharedFacility: {
    component: ComplexSharedFacility,
    path: '/CompanyEmployee/complex/:id/sharedfacility/:facilityId',
    exact: true
  },
  SubscriptionListing: {
    component: SubscriptionListing,
    path: '/CompanyEmployee/SubsciptionListing',
    exact: true
  },
  SubscriptionDetails: {
    component: COOSubscriptionDetails,
    path: '/CompanyEmployee/SubsciptionDetails/:id',
    exact: true
  },
  AddSubscription: {
    component: AddSubscription,
    path: '/CompanyEmployee/AddSubscription',
    exact: true
  },
  CEMyTeam: {
    component: CEMyTeamTable,
    path: '/CompanyEmployee/CEMyTeam',
    exact: true
  },
  CEMyTeamMember: {
    component: CETeamMemberTable,
    path: '/CompanyEmployee/CEMyTeamMember/:id',
    exact: true
  },
  CEPoll: {
    component: CEPoll,
    path: '/CompanyEmployee/CEPoll',
    exact: true
  },
  CEPollSurvey: {
    component: CEPollSurvey,
    path: '/CompanyEmployee/CEPollSurvey',
    exact: true
  },
  CEPollSurveyList: {
    component: CEPollSurveyList,
    path: '/CompanyEmployee/CEPollSurveyList',
    exact: true
  },
  CEPollSurveyDetails: {
    component: CEPollSurveyDetails,
    path: '/CompanyEmployee/CEPollSurveyDetails',
    exact: true
  },
  CEPollSurveyReports: {
    component: CEPollSurveyReports,
    path: '/CompanyEmployee/CEPollSurveyReports',
    exact: true
  },
  CECommunityManagement: {
    component: CECommunityManagement,
    path: '/CompanyEmployee/communityManagementProfile',
    exact: true
  },
  CECommunityManagementUserProfile: {
    component: CECommunityManagement,
    path: '/CompanyEmployee/communityManagementProfile/:id',
    exact: true
  },
  LeaseManagment: {
    component: LeaseManagment,
    path: '/CompanyEmployee/LeaseManagment',
    exact: true
  },
  IssueNewLease: {
    component: IssueNewLease,
    path: '/CompanyEmployee/IssueNewLease',
    exact: true
  },
  IssueNewContract: {
    component: IssueNewContract,
    path: '/CompanyEmployee/IssueNewContract',
    exact: true
  },
  IssueNewContractTemplateDetails: {
    component: IssueNewContractTemplateDetails,
    path: '/CompanyEmployee/IssueNewContractTemplateDetails',
    exact: true
  },
  IssueNewContractReview: {
    component: IssueNewContractReview,
    path: '/CompanyEmployee/IssueNewContractReview',
    exact: true
  },
  IssueNewContractAddTerm: {
    component: IssueNewContractAddTerm,
    path: '/CompanyEmployee/IssueNewContractAddTerm',
    exact: true
  },
  IssueNewContractForm: {
    component: IssueNewContractForm,
    path: '/CompanyEmployee/IssueNewContractForm',
    exact: true
  },
  ReviewDraft: {
    component: ReviewDraft,
    path: '/CompanyEmployee/ReviewDraft',
    exact: true
  },
  ContractDetails: {
    component: ContractDetails,
    path: '/CompanyEmployee/ContractDetails',
    exact: true
  },
  DraftContractDetails: {
    component: DraftContractDetails,
    path: '/CompanyEmployee/DraftContractDetails',
    exact: true
  },
  RegisteredOwner: {
    component: RegisteredOwner,
    path: '/CompanyEmployee/RegisteredOwner',
    exact: true
  },
  RegisteredResidents: {
    component: RegisteredResidents,
    path: '/CompanyEmployee/RegisteredResidents',
    exact: true
  },
  MyTeamDashboard: {
    component: MyTeamDashboard,
    path: '/CompanyEmployee/MyTeamDashboard',
    exact: true
  },
  OpenTicketsDashboard: {
    component: OpenTicketsDashboard,
    path: '/CompanyEmployee/OpenTicketsDashboard',
    exact: true
  },
  IndivisualBuildingDashboard: {
    component: IndivisualBuildingDashboard,
    path: '/CompanyEmployee/IndivisualBuildingDashboard',
    exact: true
  },
  SalesManagerDashboard: {
    component: SalesManagerDashboard,
    path: '/CompanyEmployee/SalesManagerDashboard',
    exact: true
  },
  PropertyManagerDashboard: {
    component: PropertyManagerDashboard,
    path: '/CompanyEmployee/PropertyManagerDashboard',
    exact: true
  },
  AccountManagerDashboard: {
    component: AccountManagerDashboard,
    path: '/CompanyEmployee/AccountManagerDashboard',
    exact: true
  },
  SalesManagerProfileDashboard: {
    component: SalesManagerProfileDashboard,
    path: '/CompanyEmployee/SalesManagerProfileDashboard/:id',
    exact: true
  },
  TotalComplexesDashboard: {
    component: TotalComplexesDashboard,
    path: '/CompanyEmployee/TotalComplexesDashboard',
    exact: true
  },
  ManagedTeamDashboard: {
    component: ManagedTeamDashboard,
    path: '/CompanyEmployee/ManagedTeamDashboard',
    exact: true
  },
  RequiredActivity: {
    component: RequiredActivity,
    path: '/CompanyEmployee/RequiredActivity'
  },
  DashboardProfile: {
    component: DashboardProfile,
    path: '/CompanyEmployee/DashboardProfile'
  },
  LeaseReport: {
    component: LeaseReport,
    path: '/CompanyEmployee/LeaseReport',
    exact: true
  },
  SavedTemplateDetails: {
    component: SavedTemplateDetails,
    path: '/CompanyEmployee/SavedTemplateDetails',
    exact: true
  },
  CENomination: {
    component: CENominationExport,
    path: '/CompanyEmployee/CENomination',
    exact: true
  },
  CENominationDetails: {
    component: CENominationDetailsExport,
    path: '/CompanyEmployee/CENominationDetails',
    exact: true
  },
  InvitationReports: {
    component: CEInvitationReport,
    path: '/CompanyEmployee/InvitationReport',
    exact: true
  },
  InvitationUserProfile: {
    component: CEInvitationUserProfile,
    path: '/CompanyEmployee/InvitationReport/UserProfile/:id',
    exact: true
  },
  LoginReports: {
    component: LoginReports,
    path: '/CompanyEmployee/LoginReports',
    exact: true
  },
  GrantedPermissions: {
    component: GrantedPermissions,
    path: '/CompanyEmployee/RolesPermissions',
    exact: true
  }
};
const App = () => {
  return (
    <Box style={{ height: '100%', width: '100%' }}>
      <Toaster className="toast" position="top-right" reverseOrder={false} />
      {WebRoutesGenerator({ routeMap })}
      <ModalContainer />
      <SessionManager />
    </Box>
  );
};

export default App;
