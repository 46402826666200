import React from "react";
// Customizable Area Start
import {
  Box,
  Breadcrumbs,
  Button,
  Paper,
  MenuItem,
  Select,
  Typography,
  FormControl,
  TextField,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Grid,
  InputLabel,
  TextareaAutosize,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import {
  TFunction,
  I18nInterface,
  SalesManagerListItem,
  AccountManagerListItem,
} from "./IndivisualBuildingController.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import AlertError from "../../../components/src/AlertError.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import SuccessAlert from "../../../components/src/AlertSuccess.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// Customizable Area End

import IndivisualBuildingController, { Props, } from "./IndivisualBuildingController.web";

class IndivisualBuilding extends IndivisualBuildingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  renderCooTotalIndividual = () => {
    const { t, i18n }: { t: TFunction; i18n: I18nInterface } = this.props;
    const language = i18n.language;
    return (
      <TotalIndividual dir={languageCondition(language, "rtl", "ltr")}>
        <Box dir={languageCondition(language, "rtl", "ltr")}>
          <Breadcrumbs aria-label="breadcrumb" dir={languageCondition(language, "rtl", "ltr")}>
            <Typography
              className="navigateLink"
              dir={languageCondition(language, "rtl", "ltr")}
              onClick={() => this.handleRedirection("DashboardCompanyEmployee")}
              data-testId={"myDashboardTitleTestId"}
            >
              {t("My Dashboard")}
            </Typography>
            <Typography
              className="navigateLink"
              dir={languageCondition(language, "rtl", "ltr")}
              onClick={() => this.handleRedirection("DashboardCompanyEmployee")}
              data-testId={"generalDashboardTitleTestId"}
            >
              {t("General Dashboard")}
            </Typography>
            <Typography
              className="tabActive"
              data-test={"indivisualBuidlingTestId"}
              dir={languageCondition(language, "rtl", "ltr")}
            >
              {t("Total Individual Buildings")}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography className="companiesText" dir={languageCondition(language, "rtl", "ltr")}>
          {t("Total Individual Buildings")}
        </Typography>
        <Box className="filter-head-box" dir={languageCondition(language, "rtl", "ltr")}>
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.companyList?.map((item: any) => ({
                label: item.attributes.name,
                value: item.attributes.name,
              })),
            ]}
            label="Select Company"
            language={language}
            t={t}
            onChange={this.handleCompanyChange}
            value={this.state.company}
            data-testId="cooCompanySelectTestId"
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.complexList.map((item: any) => ({
                label: item.attributes.name,
                value: item.attributes.name,
              })),
            ]}
            label="Select Complex"
            language={language}
            t={t}
            onChange={this.handleComplexChange}
            value={this.state.complex}
            data-testId="cooComplexSelectTestId"
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.salesManagerList.map((item: any) => ({
                label: item.full_name,
                value: item.full_name,
              })),
            ]}
            label="Select Sales Manager"
            language={language}
            t={t}
            onChange={this.handleSalesManagerChange}
            value={this.state.salesManager}
            data-testId="cooSalesManagerSelectTestId"
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              { label: "Next 30 days", value: "30" },
              { label: "Next 60 days", value: "60" },
              { label: "Next 90 days", value: "90" },
              { label: "Next 6 months", value: "180" },
              { label: "Next 1 year", value: "365" },
            ]}
            label="Select Subscription Ends In"
            language={language}
            t={t}
            onChange={this.handleSubscriptionChange}
            value={this.state.subscriptionEndsIn}
            data-testId="cooSubscriptionSelectTestId"
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.countryList.map((item: any) => ({ label: item, value: item })),
            ]}
            label="Select Country"
            language={language}
            t={t}
            onChange={this.handleCountryChange}
            value={this.state.country}
            data-testId="cooCountrySelectTestId"
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.cityList.map((item: any) => ({ label: item, value: item })),
            ]}
            label="Select City"
            language={language}
            t={t}
            onChange={this.handleCityChange}
            value={this.state.city}
            data-testId="cooCitySelectTestId"
          />
          <Button
            data-testId="searchButtonTestId"
            startIcon={<SearchIcon />}
            onClick={() => this.getIndividualBuildingListing("1")}
          >
            {t("Search")}
          </Button>
        </Box>
      </TotalIndividual>
    );
  };

  renderSalesManagedIndividual = () => {
    const { t, i18n }: { t: TFunction; i18n: I18nInterface } = this.props;
    const language = i18n.language;
    return (
      <ManagedIndividual>
        <Breadcrumbs>
          <Typography
            className="navigateRoute"
            dir={languageCondition(language, "rtl", "ltr")}
            onClick={() => this.handleRedirection("DashboardCompanyEmployee")}
            data-testId={"myDashboardTitleTestId"}
          >
            {t("My Dashboard")}
          </Typography>
          <Typography
            className="navigateRoute"
            dir={languageCondition(language, "rtl", "ltr")}
            onClick={() => this.handleRedirection("DashboardCompanyEmployee")}
            data-testId={"generalDashboardTitleTestId"}
          >
            {t("General Dashboard")}
          </Typography>
          <Typography
            className="tabActive"
            data-testId={"salesManagerHeadingTestId"}
            dir={languageCondition(language, "rtl", "ltr")}
          >
            {t("Managed Individual Building")}
          </Typography>
        </Breadcrumbs>
        <Typography className="realEstateHeading">{t("Managed individual building")}</Typography>
        <Box className="filter-head-box">
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.countryList.map((item: any) => ({ label: item, value: item })),
            ]}
            label="Select Country"
            data-testId="salesManagerCountrySelectTestId"
            t={t}
            onChange={this.handleCountryChange}
            language={language}
            value={this.state.country}
          />
          <FilterSelect
            data-testId="salesManagerCitySelectTestId"
            option={[
              { label: "All", value: "all" },
              ...this.state.cityList.map((item: any) => ({ label: item, value: item })),
            ]}
            label="Select City"
            language={language}
            t={t}
            value={this.state.city}
            onChange={this.handleCityChange}
          />
          <FilterSelect
            data-testId="salesManagerBuildingSelectTestId"
            option={[
              { label: "All", value: "all" },
              ...this.state.buildingList.map((item: any) => ({ label: item.name, value: item.name })),
            ]}
            label="Select Building"
            language={language}
            t={t}
            value={this.state.building}
            onChange={this.handleBuildingChange}
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.accountManagerList.map((item: any) => ({
                label: item.full_name,
                value: item.full_name,
              })),
            ]}
            label="Select Account Manager"
            language={language}
            t={t}
            onChange={this.handleAccountManagerChange}
            value={this.state.accountManager}
            data-testId="salesAccountManagerSelectTestId"
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              { label: "Next 30 days", value: "30" },
              { label: "Next 60 days", value: "60" },
              { label: "Next 90 days", value: "90" },
              { label: "Next 6 months", value: "180" },
              { label: "Next 1 year", value: "365" },
            ]}
            label="Select Subscription Ends In"
            language={language}
            t={t}
            onChange={this.handleSubscriptionChange}
            value={this.state.subscriptionEndsIn}
            data-testId="complexSelectTestId"
          />
          <Button
            data-testId="searchButtonTestId"
            onClick={() => this.getIndividualBuildingListing("1")}
            startIcon={<SearchIcon />}
          >
            {t("Search")}
          </Button>
        </Box>
      </ManagedIndividual>
    );
  };

  renderAccountManagingData = () => {
    const { t, i18n }: { t: TFunction; i18n: I18nInterface } = this.props;
    const language = i18n.language;

    return (
      <TeamManagingWrapper dir={languageCondition(language, "rtl", "ltr")}>
        <Box dir={languageCondition(language, "rtl", "ltr")}>
          <Breadcrumbs aria-label="breadcrumb" dir={languageCondition(language, "rtl", "ltr")}>
            <Typography
              className="navigateData"
              dir={languageCondition(language, "rtl", "ltr")}
              onClick={() => this.handleRedirection("DashboardCompanyEmployee")}
              data-testId={"myDashboardTitleTestId"}
            >
              {t("My Dashboard")}
            </Typography>
            <Typography
              className="navigateData"
              dir={languageCondition(language, "rtl", "ltr")}
              onClick={() => this.handleRedirection("DashboardCompanyEmployee")}
              data-testId={"generalDashboardTitleTestId"}
            >
              {t("General Dashboard")}
            </Typography>
            <Typography
              className="tabActive"
              data-testId={"accountManagerHeadingTestId"}
              dir={languageCondition(language, "rtl", "ltr")}
            >
              {t("Managed individual building")}
            </Typography>
          </Breadcrumbs>
        </Box>

        <Typography className="companiesText" dir={languageCondition(language, "rtl", "ltr")}>
          {t("Managed individual building")}
        </Typography>

        <Box className="filter-head-box">
          <FilterSelect
            data-testId="accountBuildingSelectTestID"
            option={[
              { label: "All", value: "all" },
              ...this.state.buildingList.map((item: any) => ({ label: item.name, value: item.name })),
            ]}
            label="Select Building"
            language={language}
            t={t}
            value={this.state.building}
            onChange={this.handleBuildingChange}
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              ...this.state.countryList.map((item: any) => ({ label: item, value: item })),
            ]}
            label="Select Country"
            data-testId="accountCountrySelectTestId"
            t={t}
            onChange={this.handleCountryChange}
            language={language}
            value={this.state.country}
          />
          <FilterSelect
            data-testId="accountCitySelectTestId"
            option={[
              { label: "All", value: "all" },
              ...this.state.cityList.map((item: any) => ({ label: item, value: item })),
            ]}
            label="Select City"
            language={language}
            t={t}
            value={this.state.city}
            onChange={this.handleCityChange}
          />
          <FilterSelect
            option={[
              { label: "All", value: "all" },
              { label: "Next 30 days", value: "30" },
              { label: "Next 60 days", value: "60" },
              { label: "Next 90 days", value: "90" },
              { label: "Next 6 months", value: "180" },
              { label: "Next 1 year", value: "365" },
            ]}
            label="Select Subscription Ends In"
            language={language}
            t={t}
            onChange={this.handleSubscriptionChange}
            value={this.state.subscriptionEndsIn}
            data-testId="accountSubscriptionSelectTestId"
          />
          <Button
            data-testId="searchButtonTestId"
            onClick={() => this.getIndividualBuildingListing("1")}
            startIcon={<SearchIcon />}
          >
            {t("Search")}
          </Button>
        </Box>
      </TeamManagingWrapper>
    );
  };

  renderReassignFormMain = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <Box>
        <Modal
          open={this.state.openReassignModal}
          onClose={this.handleCloseReassignModal}
          data-testId={"reassignModal"}
        >
          <IndiVidualBuildingReassignForm>
            <Paper elevation={2} className="modalContainer">
              <Typography align="left" className="reassignModalHeading">
                {t("Assign to another Manager")}
              </Typography>
              <Divider />
              <Box className="reassignForm">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel className="modalMainLabel">{t("Building ID")}</InputLabel>
                    <TextField
                      disabled
                      fullWidth
                      value={this.state.modalBuildingId}
                      variant="outlined"
                      className="modalMainInputField"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel className="modalMainLabel">{t("Building Name")}</InputLabel>
                    <TextField
                      value={this.state.modalBuildingId}
                      fullWidth
                      disabled
                      className="modalMainInputField"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel className="modalMainLabel">{t("Sales Manager")}</InputLabel>
                    <TextField
                      className="modalMainInputField"
                      fullWidth
                      variant="outlined"
                      value={this.state.modalBuildingId}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel className="modalMainLabel">{t("Current Account Manager")}</InputLabel>
                    <TextField
                      value={this.state.modalBuildingId}
                      fullWidth
                      disabled
                      variant="outlined"
                      className="modalMainInputField"
                    />
                  </Grid>
                  {this.state.userRole === "coo" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel className="modalMainLabel" id="demo-simple-select-filled">
                        {t("New Sales Manager")}
                      </InputLabel>
                      <FormControl fullWidth variant="outlined" className="selectAccountManager">
                        <Select
                          value={this.state.salesManagerAssign}
                          onChange={this.handleSalesManager}
                          IconComponent={ExpandMoreIcon}
                          data-testId={"reassignSalesManagerChangeTestId"}
                        >
                          <MenuItem value="">{t("Select option")}</MenuItem>
                          {this.state.newSalesManagerList.map((sales: SalesManagerListItem, salesIndex: number) => {
                            return (
                              <MenuItem value={sales.full_name} key={salesIndex}>
                                {t(sales.full_name)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputLabel className="modalMainLabel">{t("New Account Manager")}</InputLabel>
                    <FormControl fullWidth variant="outlined" className="selectAccountManager">
                      <Select
                        onChange={this.handleAccountManager}
                        value={this.state.accountManagerAssign}
                        data-testId={"reassignAccountManagerChangeTestId"}
                        IconComponent={ExpandMoreIcon}
                      >
                        <MenuItem value="">{t("Select option")}</MenuItem>
                        {this.state.newAccountManagerList.map(
                          (account: AccountManagerListItem, accountIndex: number) => {
                            return (
                              <MenuItem value={account.first_name} key={accountIndex}>
                                {t(account.first_name)}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputLabel className="modalMainLabel">{t("Notes")}</InputLabel>
                    <TextareaAutosize
                      onChange={this.handleNoteModal}
                      minRows={5}
                      value={this.state.noteText}
                      placeholder="Notes"
                      className="noteInput"
                      data-testId={"noteInputTestId"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className="buttonContainer">
                      <Button
                        onClick={this.handleCloseReassignModal}
                        className="cancelBtn"
                        variant="outlined"
                        data-testId={"reassignCancelBtnTestId"}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        className="reassignBtn"
                        variant="contained"
                        data-testId={"reassignBtnTestID"}
                        onClick={this.reassignToAnotherAccountManager}
                      >
                        {t("Reassign")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </IndiVidualBuildingReassignForm>
        </Modal>
      </Box>
    );
  };
    // Customizable Area End

    render() {
        // Customizable Area Start
    const { t, i18n }: { t: TFunction; i18n: I18nInterface } = this.props;
    const language = i18n.language;
    const currentPage = this.state.paginationData?.current_page as number;

    return (
      <CEHeaderComponentWeb>
        {this.handleUserType("coo") && this.renderCooTotalIndividual()}
        {this.handleUserType("sales_manager") && this.renderSalesManagedIndividual()}
        {this.handleUserType("account_manager") && this.renderAccountManagingData()}

        <Box sx={{ padding: "0 25px" }}>
          <Grid container className="table-box-card">
            <Grid item sm={12} md={12} xs={12}>
              <Box className="table-top coo-table-head-box">
                <h4 className="bold-text coo-table-heading">{t("Total assigned individual building")}</h4>
                <Box className="filter-head-box" style={{ margin: "0" }}>
                  <SearchInput
                    onChange={this.handleSearchCompanyChange}
                    label="Search by building name"
                    t={t}
                    language={language}
                  />
                </Box>
              </Box>
              <Divider />
              <TableContainer className="table-container" style={{ boxShadow: "none" }}>
                <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Building")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Account Manager")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("No. of Floors")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Subscriptions Ends In")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Total Units")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Country")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("City")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.individualBuildingList.map((item: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {(currentPage - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.building_name}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.account_manager}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.no_of_floor}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.subscription_ends}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.totle_unit}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.country}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {item.attributes.city}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                            <Menu menuButton={<MoreVertIcon data-testId={"MorVertIcon"}  onClick={() =>{this.handleMapvalues(item)}}/>}>
                              <MenuItem
                                data-test-id="view-menu"
                                onClick={this.handleMapmodal}
                              >
                                {t("View location on map")}
                              </MenuItem>
                              <MenuItem
                                data-test-id="accept-menu"
                                onClick={() => this.handleOpenReassignModal(item.id)}
                              >
                                {t("Reassign")}
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Divider />
              </TableContainer>
              <Box className="table-bottom">
                <PaginationModule
                  pagination={this.state.paginationData}
                  handlePagination={this.handlePagination}
                  page={this.state.paginationData?.current_page}
                  data-testId={"cooPagination"}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {this.renderReassignFormMain()}

        <AlertError
          show={this.state.showError}
          handleClose={this.handleAlertError}
          message={this.state.errorMessage}
          data-testId={"errorAlertTestId"}
        />

        <Loader loading={this.state.loader} />

        <MapDialog
          t={this.props.t}
          IsOpen={this.state.openBuildingModal}
          CloseDialog={this.handleCloseViewBuilding}
          lat={this.state.latitude}
          long={this.state.longitude}
          data-testId={"viewBuildingModalTestId"}
        />

        <SuccessAlert
          show={this.state.showSuccess}
          message={this.state.showSuccessMessage}
          handleClose={this.handleSuccessAlert}
          data-testId={"successAlertTestId"}
        />
      </CEHeaderComponentWeb>
    );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(IndivisualBuilding);
export { IndivisualBuilding };

const TotalIndividual = styled(Box)({
  backgroundColor: "#F4F7FF",
  padding: "0 25px",
  "& .tabActive": {
    fontSize: "18px",
    color: "#2b6fed",
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
  },
  "& .navigateLink": {
    fontWeight: 400,
    cursor: "pointer",
    fontSize: "16px",
    color: "black",
  },
  "& .companiesText": {
    lineHeight: "27px",
    fontSize: "28px",
    fontWeight: 700,
    marginBottom: "15px",
    marginTop: "15px",
  },
});

const ManagedIndividual = styled(Box)({
  backgroundColor: "#F4F7FF",
  padding: "0 25px",
  "& .tabActive": {
    fontSize: "18px",
    color: "#2b6fed",
  },
  "& .navigateRoute": {
    fontWeight: 400,
    fontSize: "16px",
    color: "black",
  },
  "& .realEstateHeading": {
    fontSize: "28px",
    marginTop: "15px",
    lineHeight: "27px",
    fontWeight: 700,
    marginBottom: "15px",
  },
  "& .tableHeader": {
    fontWeight: 700,
    fontSize: "18px",
    color: "black",
  },
});

const TeamManagingWrapper = styled(Box)({
  backgroundColor: "#F4F7FF",
  padding: "0 25px",
  "& .tabActive": {
    fontSize: "18px",
    color: "#2b6fed",
  },
  "& .navigateData": {
    color: "black",
    fontWeight: 400,
    fontSize: "16px",
  },
  "& .companiesText": {
    lineHeight: "27px",
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  "& .realEstateHeading": {
    borderRadius: "10px",
  },
});

const IndiVidualBuildingReassignForm = styled(Box)({
  alignItems: "center",
  display: "flex",
  height: "100vh",
  width: "50%",
  borderRadius: "20px",
  margin: "auto",
  "& .reassignModalHeading": {
    fontWeight: 900,
    fontSize: "25px",
    margin: "20px 0px 20px 20px",
    textAlign: "left",
  },
  "& .MuiDivider-root": {
    width: "100%",
  },
  "& .reassignForm": {
    padding: "20px",
  },
  "& .modalMainInputField": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
  },
  "& .noteInput": {
    padding: "10px",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
  },
  "& .selectAccountManager": {
    borderRadius: "10px",
    backgroundColor: "#f0f0f0",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  "& .modalMainLabel": {
    color: "black",
    marginBottom: "10px",
  },
  "& .modalContainer": {
    overflow: "scroll",
    borderRadius: "20px",
    height: "95%",
  },
  "& .buttonContainer": {
    gap: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  "& .reassignBtn": {
    textTransform: "capitalize",
    borderRadius: "7px",
    padding: "9px 0px",
    backgroundColor: "#2B6FED",
    fontSize: "18px",
    color: "#fff",
    width: "150px",
    fontWeight: 500,
  },
  "& .cancelBtn": {
    borderRadius: "7px",
    border: "1px solid #2B6FED",
    fontSize: "18px",
    textTransform: "capitalize",
    padding: "9px 0px",
    color: "#2B6FED",
    width: "150px",
    fontWeight: 500,
  },
});
// Customizable Area End
