// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

import * as Yup from "yup";
import { apiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";

export type StyleObject = {
  [key: string]: {
    [key: string]: string;
  };
};

export interface Props {
  step: number;
  complexId: null | string | undefined;
  buildingId: string | number | null;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  handleBack: () => void;
  handleNext: () => void;
}

interface S {
  isUnitFormOpen: boolean;
  isEditUnitFormOpen: boolean;
  isFindPersonOpen: boolean;
  isUserDetailOpen: boolean;
  isUserSearched: boolean;
  isViewUnitOpen: boolean;

  complexId: string | number;

  allUnitList: UnitDetails[];
  pagination: {
    total_count: number | string;
    total_pages: number | undefined;
    current_page: number | undefined;
  } | null;
  page: number;
  unitId: string | number;

  personSearch: PersonSearch;
  userList: UserList[];
  userDetail: UserDetail;

  selectedType: string;
  OwnerId: string;
  ResidentId: string;
  OwnerName: string;
  ResidentName: string;
  formValue: FormValue;

  configList: SelectOption[];

  unitDetails: FormValue;

  loading: boolean;
}

interface SS {
  id: any;
}

export interface UnitDetails {
  id: number | string;
  attributes: {
    apartment_name: string;
    floor_number: string;
    configuration: string;
    size: string;
    purchase_price: string;
    purchase_date: string;
    current_valuation: string;
    resident: {
      id: number;
      full_name: string;
    };
    owner: {
      id: number;
      full_name: string;
    };
  };
}

interface UserList {
  id: number;
  attributes: {
    full_name: string;
    full_phone_number: string;
    email: string;
    complex: null | {
      name: string;
    };
    address: null | {
      address: string;
    };
  };
}

interface SelectOption {
  label: string;
  value: string;
}

interface UserDetail {
  name: string;
  complex: string;
  idNumber: string | number;
  idUrl: string;
  email: string;
  address: string;
}

interface FormValue {
  UnitNumber: string;
  FloorNumber: string;
  Configuration: string;
  Size: string;
  PurchasePrice: string;
  PurchaseDate: string;
  CurrentValuation: string;
}

interface PersonSearch {
  id: string;
  passport: string;
  email: string;
  phone: string;
}

export default class CompEmpBuildingBlockStep5Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  AddUnitsApiCallId: string = "";
  EditUnitsApiCallId: string = "";
  GetFindPersonListCallId: string = "";
  GetFindPersonDetailCallId: string = "";
  GetConfigurationListCallId: string = "";
  GetUnitListCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      isUnitFormOpen: false,
      isEditUnitFormOpen: false,
      isFindPersonOpen: false,
      isUserSearched: false,
      isUserDetailOpen: false,
      isViewUnitOpen: false,

      complexId: "",

      allUnitList: [],
      pagination: null,
      page: 1,
      unitId: "",

      configList: [],

      personSearch: {
        id: "",
        passport: "",
        email: "",
        phone: "",
      },
      userList: [],
      userDetail: {
        name: "",
        complex: "",
        idNumber: "",
        idUrl: "",
        email: "",
        address: "",
      },

      selectedType: "",
      OwnerName: "",
      ResidentName: "",
      OwnerId: "",
      ResidentId: "",
      formValue: {
        UnitNumber: "",
        FloorNumber: "",
        Configuration: "",
        Size: "",
        PurchasePrice: "",
        PurchaseDate: "",
        CurrentValuation: "",
      },

      unitDetails: {
        UnitNumber: "",
        FloorNumber: "",
        Configuration: "",
        Size: "",
        PurchasePrice: "",
        PurchaseDate: "",
        CurrentValuation: "",
      },

      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.EditUnitsApiCallId:
        case this.AddUnitsApiCallId:
          return this.handleAddUnitResponse(responseJsonBlock);
        case this.GetFindPersonListCallId:
          return this.handleGetFindPersonListResponse(responseJsonBlock);
        case this.GetFindPersonDetailCallId:
          return this.handleGetFindPersonDetailResponse(responseJsonBlock);
        case this.GetConfigurationListCallId:
          return this.handleConfigurationListResponse(responseJsonBlock);
        case this.GetUnitListCallId:
          return this.handleUnitListResponse(responseJsonBlock);
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.getConfigurationList();
    this.getUnitList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getUnitList();
    }
  }

  getUnitList = async () => {
    this.GetUnitListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/apartment_list?building_management_id=${this.props.buildingId}&page=${this.state.page}`,
    });
  };

  handleUnitListResponse = (response: {
    apartment_managements: { data: UnitDetails[] };
    meta: { pagination: null };
  }) => {
    if (response && response.apartment_managements) {
      this.setState({ allUnitList: response.apartment_managements.data, pagination: response.meta.pagination });
    }
  };

  handleClosePersonModal = () => {
    this.setState({ isFindPersonOpen: !this.state.isFindPersonOpen });
  };

  handleCloseFormModal = () => {
    this.setState({ isUnitFormOpen: !this.state.isUnitFormOpen });
  };

  handleCloseViewUnitModal = () => {
    this.setState({ isViewUnitOpen: !this.state.isViewUnitOpen });
  };

  validationSchema = Yup.object().shape({
    UnitNumber: Yup.string().required("Unit Number is required"),
    FloorNumber: Yup.string().required("Floor Number is required"),
    Configuration: Yup.string().required("Configuration is required"),
    Size: Yup.string().required("Size is required"),
  });

  addUnits = async (unit: FormValue) => {
    const formData = new FormData();
    formData.append(`apartment_management[floor_number]`, unit.FloorNumber);
    formData.append(`apartment_management[configuration]`, unit.Configuration);
    formData.append(`apartment_management[size]`, unit.Size);
    formData.append(`apartment_management[purchase_price]`, unit.PurchasePrice);
    formData.append(`apartment_management[purchase_date]`, unit.PurchaseDate);
    formData.append(`apartment_management[current_valuation]`, unit.CurrentValuation);
    formData.append("apartment_management[resident_id]", this.state.ResidentId + "");
    formData.append("apartment_management[owner_id]", this.state.OwnerId);
    formData.append("apartment_management[resident_name]", this.state.ResidentName + "");
    formData.append("apartment_management[owner_name]", this.state.OwnerName);
    formData.append("apartment_management[apartment_name]", unit.UnitNumber);
    formData.append("apartment_management[building_management_id]", this.props.buildingId + "");
    formData.append("apartment_management[society_management_id]", this.props.complexId + "");

    if (this.state.isEditUnitFormOpen) {
      this.EditUnitsApiCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_settings/apartment_managements/${this.state.unitId}`,
        body: formData,
      });
    } else {
      this.AddUnitsApiCallId = await apiCall({
        method: "POST",
        endPoint: `bx_block_settings/apartment_managements`,
        body: formData,
      });
    }
  };

  handleAddUnitResponse = (response: any) => {
    this.setState(
      {
        isEditUnitFormOpen: false,
        isUserDetailOpen: false,
        personSearch: {
          id: "",
          passport: "",
          email: "",
          phone: "",
        },
        userList: [],
        selectedType: "",
        OwnerName: "",
        ResidentName: "",
        OwnerId: "",
        ResidentId: "",
      },
      () => {
        if (response && response.data) {
          this.getUnitList();
        }
      }
    );
  };

  handleOpenOwnerModal = (type: string) => {
    this.setState(
      {
        selectedType: type,
        isUserDetailOpen: false,
        isUserSearched: false,
        personSearch: {
          id: "",
          passport: "",
          email: "",
          phone: "",
        },
        userList: [],
      },
      () => {
        this.handleClosePersonModal();
      }
    );
  };

  getFindPersonList = async () => {
    this.setState({ loading: true });
    const { id, email, passport, phone } = this.state.personSearch;
    const role = this.state.selectedType === "OwnerName" ? "Owner" : "Tenant";
    this.GetFindPersonListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${this.props.complexId}/bx_block_contract/employee_contracts/find_person?role=${role}&id_number=${id}&phone_number=${phone}&email=${email}&passport=${passport}`,
    });
  };

  handleGetFindPersonListResponse = (responseJson: { data: UserList[] }) => {
    this.setState({ loading: false, isUserSearched: true }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ userList: responseJson.data });
      }
    });
  };

  getFindPersonDetail = (id: string | number) => {
    this.setState({ isUserDetailOpen: true }, async () => {
      this.GetFindPersonDetailCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `society_managements/${this.props.complexId}/bx_block_contract/employee_contracts/find_person_details?id=${id}`,
      });
    });
  };

  handleGetFindPersonDetailResponse = (responseJson: { data: UserList }) => {
    if (responseJson && responseJson.data) {
      const user = responseJson.data;
      this.setState({
        userDetail: {
          name: user.attributes.full_name,
          complex: user.attributes.complex?.name || "",
          idNumber: user.id,
          idUrl: "",
          email: user.attributes.email,
          address: user.attributes.address?.address || "",
        },
      });
    }
  };

  handleSelectUser = () => {
    if (this.state.selectedType !== "") {
      const newState = { isFindPersonOpen: false, [this.state.selectedType]: this.state.userDetail.name + "" };
      if (this.state.selectedType === "OwnerName") {
        newState["OwnerId"] = this.state.userDetail.idNumber + "";
      } else {
        newState["ResidentId"] = this.state.userDetail.idNumber + "";
      }
      this.setState({ ...this.state, ...newState }, () => {});
    }
  };

  getConfigurationList = async () => {
    this.GetConfigurationListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/apartment_managements/unit_configuration`,
    });
  };

  handleConfigurationListResponse = (responseJson: { configuration: { id: number | string; title: string }[] }) => {
    if (responseJson && responseJson.configuration) {
      this.setState({
        configList: responseJson.configuration.map((config) => ({ label: config.title, value: config.id + "" })),
      });
    }
  };

  handleEditUnit = (unitDetail: UnitDetails) => {
    this.setState({
      isUnitFormOpen: true,
      isEditUnitFormOpen: true,
      unitId: unitDetail.id + "",
      OwnerId: unitDetail.attributes.owner?.id + "",
      ResidentId: unitDetail.attributes.resident?.id + "",
      OwnerName: unitDetail.attributes.owner?.full_name + "",
      ResidentName: unitDetail.attributes.resident?.full_name + "",
      formValue: {
        UnitNumber: unitDetail.attributes.apartment_name,
        FloorNumber: unitDetail.attributes.floor_number,
        Configuration: unitDetail.attributes.configuration,
        Size: unitDetail.attributes.size,
        PurchasePrice: unitDetail.attributes.purchase_price,
        PurchaseDate: unitDetail.attributes.purchase_date,
        CurrentValuation: unitDetail.attributes.current_valuation,
      },
    });
  };

  handleViewUnit = (unitDetail: UnitDetails) => {
    this.setState({
      isViewUnitOpen: true,
      unitDetails: {
        UnitNumber: unitDetail.attributes.apartment_name,
        FloorNumber: unitDetail.attributes.floor_number,
        Configuration: unitDetail.attributes.configuration,
        Size: unitDetail.attributes.size,
        PurchasePrice: unitDetail.attributes.purchase_price,
        PurchaseDate: unitDetail.attributes.purchase_date,
        CurrentValuation: unitDetail.attributes.current_valuation,
      },
    });
  };

  handleBox = (value: any, style: any) => {
    if (value) {
      return style["modalFieldBlack"];
    } else {
      return style["modalField"];
    }
  };

  handleText = (value: any, style: any) => {
    if (value) {
      return style["modalFieldTitleBlack"];
    } else {
      return style["modalFieldTitle"];
    }
  };
}
// Customizable Area End
